import React from 'react';

import { AttachmentsEdit, CompanyFieldsEdit } from '../../components';

export default function EligibleSolicitorEdit({
  solicitor,
  onChange,
  index,
  validator,
  addDirtyField,
}) {
  const onSolicitorPartChange = (newData) => {
    onChange({ ...solicitor, ...newData }, index);
  };

  const addAttachment = (attachment) => {
    const attachments = [...(solicitor.attachments ?? []), attachment];
    onChange(
      {
        ...solicitor,
        attachments: attachments,
      },
      index
    );
  };

  const deleteAttachment = (attachmentIndex) => {
    let attachmentsCopy = [...solicitor.attachments];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      onChange(
        {
          ...solicitor,
          attachments: attachmentsCopy,
        },
        index
      );
    }
  };

  return (
    <>
      <CompanyFieldsEdit
        data={solicitor.company}
        onChange={onSolicitorPartChange}
        validator={validator}
        addDirtyField={addDirtyField}
      />
      <AttachmentsEdit
        addAttachment={addAttachment}
        deleteAttachment={deleteAttachment}
        attachments={solicitor?.attachments}
      />
    </>
  );
}
