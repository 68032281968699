import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

function Footer({ version }) {
  const classes = useStyles();
  return <div className={classes.footer}>{version}</div>;
}

Footer.propTypes = {
  version: PropTypes.string.isRequired,
};

export default Footer;
