import React, {useEffect, useState} from 'react';
import { TextField } from '@material-ui/core';

export default function VoidMovement({ movement, onChange, setCustomIsValid, showAllValidationErrors }) {

  const [dirtyFields, setDirtyFieldsState] = useState([]);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const setDirtyFields = (fieldName) => setDirtyFieldsState([...dirtyFields, fieldName]);

  const validation = {
    isCommentValid: () => !!movement?.comment,
  };

  const isFormValid = () => {
    return validation.isCommentValid();
  }

  return (
    <>
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
        error={(showAllValidationErrors || dirtyFields.includes('comment')) && !validation.isCommentValid()}
        onBlur={(event) => {setDirtyFields(event.target.name);}}
      />
    </>
  );
}
