import React, { useEffect, useState } from 'react';

import { SelectWithOptions } from '../../components';
import { eventNotificationTypesOptions, eventNotificationTypes } from '../../support/constants'; 

import LiabilityAdmitted from './EventNotifications/LiabilityAdmitted';
import LiabilityWithdrawn from './EventNotifications/LiabilityWithdrawn';
import LiabilityDenied from './EventNotifications/LiabilityDenied';
import TheClaimIsAllocatedToMediationOrArbitration from './EventNotifications/TtheClaimIsAllocatedToMediationOrArbitration';
import FailureToComplyWithPolicyTermsAndConditions from './EventNotifications/FailureToComplyWithPolicyTermsAndConditions';
import NowPartOfMultipartyAction from './EventNotifications/NowPartOfMultipartyAction';
import FailureToAcceptSolicitorAdvice from './EventNotifications/FailureToAcceptSolicitorAdvice';
import CaseLostNoClaimOnPolicy from './EventNotifications/CaseLostNoClaimOnPolicy';
import LetterOfClaimSent from './EventNotifications/LetterOfClaimSent';
import ResponseToLetterOfClaimReceived from './EventNotifications/ResponseToLetterOfClaimReceived';

export default function EventNotificationMovement({ movement, onChange, setCustomIsValid, showAllValidationErrors }) {

  const [dirtyFields, setDirtyFieldsState] = useState([]);
  const [isSubformValid, setIsSubformValid] = useState([]);

  useEffect(() => {
    setCustomIsValid(false);
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);

  const validation = {
    notificationTypeIsSelected: () => !!movement?.eventNotificationType,
  };

  const isFormValid = () => {
    return isSubformValid && validation.notificationTypeIsSelected();
  }

  return (
    <>
      <SelectWithOptions
        name="eventNotificationType"
        label="Event notification type"
        value={movement.eventNotificationType}
        onChange={onChange}
        options={eventNotificationTypesOptions}
        required={true}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={(showAllValidationErrors || dirtyFields.includes('eventNotificationType')) && !validation.notificationTypeIsSelected()}
      />

      {movement.eventNotificationType == eventNotificationTypes.liabilityAdmitted.value 
        && <LiabilityAdmitted 
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.liabilityWithdrawn.value 
        && <LiabilityWithdrawn
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.liabilityDenied.value 
        && <LiabilityDenied
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.theClaimIsAllocatedToMediationOrArbitration.value 
        && <TheClaimIsAllocatedToMediationOrArbitration
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.failureToComplyWithPolicyTermsAndConditions.value 
        && <FailureToComplyWithPolicyTermsAndConditions
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.nowPartOfMultipartyAction.value 
        && <NowPartOfMultipartyAction
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.failureToAcceptSolicitorAdvice.value 
        && <FailureToAcceptSolicitorAdvice
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.caseLostNoClaimOnPolicy.value 
        && <CaseLostNoClaimOnPolicy
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.letterOfClaimSent.value
        && <LetterOfClaimSent
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}

      {movement.eventNotificationType == eventNotificationTypes.responseToLetterOfClaimReceived.value
        && <ResponseToLetterOfClaimReceived
              movement={movement} 
              onChange={onChange} 
              setCustomIsValid={setIsSubformValid}
              showAllValidationErrors={showAllValidationErrors} />}
    </>
  );
}
