import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { saveGroupsForSchema } from '../../store/reducers/scheme/groupsForScheme';
import { Modal } from '../../components';

import ManageAccess from './ManageAccess';
import { useStyles } from './styles';

export default function ManageAccessModal(props) {
  const { open, handleClose, scheme } = props;
  const dispatch = useDispatch();

  const {
    wasSaved,
    hasErrorForAvailableGroups,
    isLoadingForAvailableGroup,
    hasErrorForSchemeGroups,
    isLoadingForSchemeGroups,
    isSaving,
  } = useSelector((state) => state.scheme.groupsForScheme);

  useEffect(() => {
    if (wasSaved) {
      handleClose();
    }
  }, [wasSaved]);

  const saveGroups = () => {
    dispatch(saveGroupsForSchema(scheme.id));
  };

  const classes = useStyles();

  return (
    <Modal
      onClose={handleClose}
      onConfirm={saveGroups}
      open={open}
      titleText={`Manage access to Scheme ${scheme.name}`}
      showActionButtons={true}
      isReadyToConfirm={true}
      isLoading={
        isSaving || isLoadingForAvailableGroup || isLoadingForSchemeGroups
      }
      PaperProps={{ className: classes.modal }}
    >
      {hasErrorForAvailableGroups || hasErrorForSchemeGroups ? (
        'Problem with downloading groups'
      ) : (
        <ManageAccess schemeId={scheme.id} />
      )}
    </Modal>
  );
}

ManageAccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  scheme: PropTypes.object.isRequired,
};
