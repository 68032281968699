import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Link as MaterialLink,
} from '@material-ui/core';

import { menuItems } from './menuItems';
import logo from '../../assets/images/logo.svg';
import { useStyles } from './styles';
import userManager from '../../store/userManager';
import {
  checkIfAccountIsValidIfUserIsInsurer,
  checkIfAccountIsValidIfUserIsSolicitor,
  getAssignedGroupsCount,
} from '../../support/userHelpers';

export default function AsideMenu() {
  const classes = useStyles();
  const [userGroups, setUserGroups] = React.useState([]);
  const [cachedUserProfile, setCachedUserProfile] = React.useState({});

  const { isUserProfileCached } = useSelector(
    (state) => state.global.userProfile
  );

  React.useEffect(() => {
    userManager.getUser().then((user) => {
      const groupsAssignedToUser =
        user && user.profile ? user.profile.groups : [];
      setUserGroups(groupsAssignedToUser);

      const cachedUserProfileFromSession = JSON.parse(
        sessionStorage.getItem('cachedUserInfo')
      );
      setCachedUserProfile(cachedUserProfileFromSession);
    });
  }, [isUserProfileCached]);

  const isUserAccountValid = () =>
    getAssignedGroupsCount(userGroups) === 1 &&
    checkIfAccountIsValidIfUserIsSolicitor(cachedUserProfile, userGroups) &&
    checkIfAccountIsValidIfUserIsInsurer(cachedUserProfile, userGroups);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar className={classes.logo}>
        <MaterialLink href="/">
          <img src={logo} title="Resolve" alt="Resolve logo" />
        </MaterialLink>
      </Toolbar>
      {isUserProfileCached && isUserAccountValid() && (
        <List disablePadding={true}>
          {menuItems
            .filter(
              (menuItem) =>
                !menuItem.groupRequired ||
                menuItem.groupRequired
                  .map((requiredRole) => userGroups.includes(requiredRole))
                  .reduce(
                    (accumulated, current) => accumulated && current,
                    true
                  )
            )
            .map((menuItem) =>
              menuItem.type === 'internal' ? (
                <NavLink
                  className={classes.menuElementLink}
                  exact
                  to={menuItem.url}
                  activeClassName="active"
                >
                  {menuItem.icon}
                  <span className={classes.menuElementTxt}>
                    {menuItem.title}
                  </span>
                </NavLink>
              ) : (
                <a
                  href={menuItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.menuElementLink}
                >
                  {menuItem.icon}
                  <span className={classes.menuElementTxt}>
                    {menuItem.title}
                  </span>
                </a>
              )
            )
            .map((text, index) => (
              <ListItem button key={index} className={classes.menuElement}>
                <ListItemText
                  className={classes.menuElement}
                  primary={<Typography variant="h6">{text}</Typography>}
                />
              </ListItem>
            ))}
        </List>
      )}
    </Drawer>
  );
}
