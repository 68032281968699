import React from 'react';
import { Divider, Paper, Typography } from '@material-ui/core';

export const SchemeNotesTabItem = ({ scheme, classes }) => {
  return (
    <>
      <Typography variant="h4">Notes</Typography>
      <Paper className={classes.root}>
        <Typography variant="subtitle1">Note</Typography>
        <Divider />

        <Typography variant="body1" className={classes.multiline}>
          {scheme.notes}
        </Typography>
      </Paper>
    </>
  );
};
