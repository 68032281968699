import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { sumBy } from 'lodash';

import { useStyles } from './styles';
import { getFormattedMoney, transformToLabel } from '../../support/helpers';
import { disbursementTypes } from '../../support/constants';

export default function MovementClaimsTotals({ movements }) {
  const classes = useStyles();
  const [claimsTotals, setClaimsTotals] = useState([]);

  useEffect(() => {
    const getSum = (claims) => sumBy(claims, (claim) => claim.value);
    const getClaimsByClaimTypeAndDisbursementType = (
      claimType,
      disbursementType
    ) =>
      claimsToTakeIntoCalculations.filter(
        (movement) =>
          movement.claimType === claimType &&
          movement.disbursementType === disbursementType
      );

    let newClaimsTotals = [];
    const claimsToTakeIntoCalculations = movements.filter(
      (movement) => movement.status !== 3 // Rejected movements
    );

    Object.keys(disbursementTypes)
      .sort((x, y) => disbursementTypes[x].localeCompare(disbursementTypes[y]))
      .forEach((disbursementType) => {
        disbursementType = parseInt(disbursementType);
        const claimsReserveSum = getSum(
          getClaimsByClaimTypeAndDisbursementType(1, disbursementType)
        );
        const claimsPaymentSum = getSum(
          getClaimsByClaimTypeAndDisbursementType(2, disbursementType)
        );
        newClaimsTotals.push({
          disbursementType,
          claimsPaymentSum,
          claimsReserveSum,
        });
      });

    setClaimsTotals(newClaimsTotals);
  }, [movements]);
  return (
    <>
      <Paper>
        <Grid container>
          <Grid item xs={3} className={classes.headerGridElement}>
            <Typography variant="overline">Expense Type</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerGridElement}>
            <Typography variant="overline">Claim-Payment</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerGridElement}>
            <Typography variant="overline">Claim-Reserve</Typography>
          </Grid>
          <Grid item xs={3} className={classes.headerGridElement}>
            <Typography variant="overline">Total</Typography>
          </Grid>
        </Grid>

        {claimsTotals?.map((totalRow, index) => (
          <Grid container key={index}>
            <Grid item xs={3} className={classes.gridElement}>
              {transformToLabel(disbursementTypes[totalRow.disbursementType])}
            </Grid>
            <Grid item xs={3} className={classes.gridElement}>
              {getFormattedMoney(totalRow.claimsPaymentSum)}
            </Grid>
            <Grid item xs={3} className={classes.gridElement}>
              {getFormattedMoney(totalRow.claimsReserveSum)}
            </Grid>
            <Grid item xs={3} className={classes.totalGridElement}>
              {getFormattedMoney(
                totalRow.claimsPaymentSum + totalRow.claimsReserveSum
              )}
            </Grid>
          </Grid>
        ))}

        <Grid container>
          <Grid item xs={3} className={classes.totalGridElement}>
            <Typography variant="overline">Total</Typography>
          </Grid>
          <Grid item xs={3} className={classes.totalGridElement}>
            {getFormattedMoney(
              sumBy(claimsTotals, (claim) => claim.claimsPaymentSum)
            )}
          </Grid>
          <Grid item xs={3} className={classes.totalGridElement}>
            {getFormattedMoney(
              sumBy(claimsTotals, (claim) => claim.claimsReserveSum)
            )}
          </Grid>
          <Grid item xs={3} className={classes.totalGridElement}>
            {getFormattedMoney(
              sumBy(
                claimsTotals,
                (claim) => claim.claimsPaymentSum + claim.claimsReserveSum
              )
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
