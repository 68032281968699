import { makeStyles } from '@material-ui/core';

import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  breadcrumbs: {
    color: colors.grey7F,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 600,
    '& .MuiBreadcrumbs-separator': {
      marginLeft: '4px',
      marginRight: '4px',
    },
    '& .MuiBreadcrumbs-separator > *': {
      height: '20px',
      opacity: 0.8,
    },
    '& .current-page': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    marginBottom: '16px',
    '& a': {
      height: '12px',
      lineHeight: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textDecoration: 'none',
      color: colors.grey7F,
      '&:hover': {
        color: colors.text,
      },
    },
    '& img, & svg': {
      height: '16px',
      '&:hover': {
        color: colors.text,
      },
    },
  },
}));
