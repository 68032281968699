/* eslint-disable no-console */
import { createUserManager } from 'redux-oidc';
import { Log } from 'oidc-client';
import config from '../config';
export const userManagerConfig = {
  client_id: config.aadClientId,
  response_type: 'token id_token',
  scope: `api://${config.aadClientId}/Resolve.AccessAll openid profile`,
  authority: `${config.identityProviderUrl}/.well-known/openid-configuration`,
  manage: `${config.identityProviderUrl}/manage`,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/silent`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}`,
};

// save pathname in storage to redirect to place we paste or open from email after login
if (
  !sessionStorage.getItem('redirectUri') &&
  !['/login', '/callback'].includes(window.location.pathname)
) {
  sessionStorage.setItem('redirectUri', window.location.pathname);
}

const DEV = process.env.NODE_ENV !== 'production';

if (DEV) {
  Log.level = Log.INFO;
  Log.logger = {
    error: console.error,
    info: console.info,
    debug: console.debug,
    warn: console.warn,
  };
}

const userManager = createUserManager(userManagerConfig);

export default userManager;
