import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

const numericInput = {
  '& input': {
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
};

export const useStyles = makeStyles(() => ({
  form: {
    marginBottom: '15px',
  },
  actionButton: common.actionButton,
  contactTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    '& img': {
      marginRight: 12,
      filter: 'grayscale(1)',
    },
  },
  icon: {
    minWidth: 'auto',
    marginRight: 12,
  },
  pairOfInputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
    '& > *': {
      flex: '0 0 49%',
    },
    '& > &': {
      marginBottom: 0,
    },
  },
  fourOfInputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
    '& > *': {
      flex: '0 0 23.5%',
    },
  },
  halfOfWidth: {
    width: '49%',
    marginBottom: 35,
  },
  fullWidth: {
    width: '100%',
    marginBottom: 35,
  },
  textarea: {
    '& textarea': {
      height: '200px !important',
    },
  },
  multiline: {
    whiteSpace: 'pre-line',
  },

  criterionRow: {
    display: 'flex',
  },
  criterionHeader: {
    flex: '3%',
    paddingTop: '15px',
  },

  qddlInput: numericInput,
  narrowQddlInput: {
    ...numericInput,
    width: '45%',
    minWidth: '65px',

    '& input[readonly]': {
      fontStyle: 'italic',
      opacity: '50%',
    },
  },
  quantumBandSeparator: {
    display: 'inline-block',
    padding: '6px',
  },
  qddlTableRow: {
    '&:nth-child(odd)': {
      backgroundColor: colors.greyF5,
    },
  },
  qddlTableCell: {
    borderRight: `1px solid ${colors.greyE6}`,
    borderLeft: `1px solid ${colors.greyE6}`,
    fontWeight: 600,
  },

  modal: {
    width: 550,
  },
}));
