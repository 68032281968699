import React from 'react';
import { Typography, Checkbox } from '@material-ui/core';


import { useStyles } from './PoliciesReportStyles';

export default function ReportConfigurationOption({caption, subsection, fieldName, onChange, value, disabled = false}) {
    
    const classes = useStyles();

    return (
      <>
        <Typography variant="body1">
        <Checkbox 
            color="primary" 
            className={classes.reportOptionCheckbox}
            checked={value} 
            disabled={disabled}
            onChange={(event) => { onChange(subsection, event)}}
            name={fieldName}/>
        {caption}
        </Typography>       
      </>
    );
  }
  