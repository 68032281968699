import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { Breadcrumbs } from '../../components';
import SchemeWizard from '../../components/SchemeWizard/SchemeWizard';
import schemesIcon from '../../assets/images/schemes.svg';
import { cleanReducer } from '../../store/reducers/scheme/save';

import { useStyles } from './styles';

export default function CreateScheme() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const savedSchemeId = useSelector(
    (state) => state.scheme.create.savedSchemeId
  );
  const shouldRedirect = useSelector(
    (state) => state.scheme.create.shouldRedirect
  );

  useEffect(() => {
    if (shouldRedirect) {
      history.push({
        pathname: `/schemes/${savedSchemeId}`,
        state: { updated: true },
      });
    }
  }, [shouldRedirect, savedSchemeId]);

  useEffect(() => {
    return () => {
      dispatch(cleanReducer());
    };
  }, []);

  return (
    <>
      <Breadcrumbs current="Create Scheme">
        <Link to={'/'}>
          <img src={schemesIcon} alt="Schemes" />
        </Link>
      </Breadcrumbs>

      <Typography variant="h3" className={classes.header}>
        Create a New Scheme
      </Typography>
      <SchemeWizard />
    </>
  );
}
