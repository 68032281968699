import { makeStyles } from '@material-ui/core';
import { common } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  header: {
    padding: '24px 34px',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  closeButton: {
    position: 'absolute',
    top: 22,
    right: 22,
  },
  content: {
    padding: '16px 34px 34px',
  },
  dialogActions: {
    padding: '10px 34px 34px',
  },

  confirmButton: common.primaryButton,
  cancelButton: common.secondaryButton,
}));
