import PropTypes from 'prop-types';

export const linkShape = PropTypes.shape({
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
});

export const matchShape = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const locationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  key: PropTypes.string,
});

export const historyShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  location: locationShape.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

export const userProfileShape = PropTypes.shape({
  sid: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired,
  auth_time: PropTypes.number.isRequired,
  idp: PropTypes.string.isRequired,
  amr: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  given_name: PropTypes.string.isRequired,
  family_name: PropTypes.string.isRequired,
  website: PropTypes.string,
});

export const userShape = PropTypes.shape({
  id_token: PropTypes.string.isRequired,
  session_state: PropTypes.string.isRequired,
  access_token: PropTypes.string.isRequired,
  token_type: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  profile: userProfileShape,
  expires_at: PropTypes.number.isRequired,
});
