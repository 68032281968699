import React from 'react';

import { ViewSchemeCriteria } from '../../Policy/ViewSchemeCriteria';
import { useStyles } from '../styles.js';
import { PreviewElementContainer } from './PreviewElementContainer';

export const PreviewSchemeCriteria = ({ scheme, policy }) => {
  const classes = useStyles();
  return (
    <PreviewElementContainer
      title={`1. Criteria from Scheme ${scheme.codePrefix} ${scheme.codeSuffix}`}
      classes={classes}
    >
      <ViewSchemeCriteria
        scheme={scheme}
        answeredCriteria={policy.schemeCriteriaResults}
      />
    </PreviewElementContainer>
  );
};
