import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const information = {
  marginTop: 10,
  fontStyle: 'italic',
};

export const useStyles = makeStyles(() => ({
  criteriaHeading: {
    marginBottom: 8,
    '& > div': {
      paddingRight: 26,
    },
  },
  answerRow: {
    '& + &': {
      borderTop: `1px solid ${colors.greyE6}`,
    },
    '& > div': {
      padding: '12px 32px 12px 0',
      lineHeight: '24px',
    },
    '& strong': {
      display: 'block',
      marginBottom: 4,
      fontSize: 14,
    },
  },
  numberColumn: {
    width: 50,
    '& strong': {
      marginBottom: 0,
    },
  },
  error: {
    ...information,
    color: `${colors.error}`,
  },
  warning: {
    ...information,
    color: `${colors.warning}`,
  },
}));
