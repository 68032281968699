import React from 'react';

import WizardStep from '../WizardStep';

import SchemeInformation from './SchemeInformation';
import Introducer from './Introducer';
import FrontingInsurer from './FrontingInsurer';
import Reinsurers from './Reinsurers';

export default function GeneralInformation({ stepNumber }) {
  const sections = {
    SchemeInformation: 0,
    Introducer: 1,
    FrontingInsurer: 2,
    Reinsurers: 3,
  };
  return (
    <WizardStep
      sectionsCount={Object.keys(sections).length}
      stepNumber={stepNumber}
    >
      <SchemeInformation sectionKey={sections.SchemeInformation} />
      <Introducer sectionKey={sections.Introducer} />
      <FrontingInsurer sectionKey={sections.FrontingInsurer} />
      <Reinsurers sectionKey={sections.Reinsurers} />
    </WizardStep>
  );
}
