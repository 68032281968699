import React from 'react';

import {
  Grid,
  Typography,
} from '@material-ui/core';

import { ListItem } from '../../components';
import { policyStage } from '../../support/constants';
import {
  getFormattedDate,
  getFormattedMoney,
  getFormattedPercent,
} from '../../support/helpers';

import IconMail from '../../assets/email.svg';

import { useStyles } from './policyDetailsTabsStyles';

export default function PolicyDetailsTabItemCauseOfAction(props) {
  const classes = useStyles();
  const { policy, restrictedForInsurer } = props;

  return (
    <>
      <Typography className={classes.panelSectionHeading} variant="h5">
        Cause of Action Details
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="CFA Signed Date"
            secondary={getFormattedDate(policy.causeOfAction.cfaSignedDate)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Date of Knowledge"
            secondary={getFormattedDate(policy.causeOfAction.dateOfKnowledge)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Prospects of Success"
            secondary={getFormattedPercent(policy.causeOfAction.prospectsOfSuccess)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Success Fee"
            secondary={getFormattedPercent(policy.causeOfAction.successFee)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Estimated Damages - Special"
            secondary={getFormattedMoney(policy.causeOfAction.estimatedDamagesSpecial)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Estimated Damages - General"
            secondary={getFormattedMoney(policy.causeOfAction.estimatedDamagesGeneral)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Total Quantum"
            secondary={getFormattedMoney(policy.causeOfAction.totalQuantum)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Funded Current Premium excl. IPT"
            secondary={getFormattedMoney(policy.policyPremium.fundedCurrentExclIPT)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Deferred Current Premium excl. IPT"
            secondary={getFormattedMoney(policy.policyPremium.deferredCurrentExclIPT)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="IPT"
            secondary={getFormattedPercent(policy.policyPremium.ipt)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Total Current Premium incl. IPT"
            secondary={getFormattedMoney(policy.policyPremium.totalCurrentInclIPT, 2, true)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Policy Inception Date"
            secondary={getFormattedDate(policy.causeOfAction.policyInceptionDate)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Policy Stage"
            secondary={policyStage[policy.stage]?.displayName.toUpperCase()}
          />
        </Grid>
      </Grid>

      <Typography className={classes.panelSectionHeading} variant="h5">
        Solicitor
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Solicitor Name"
            secondary={policy.causeOfAction.solicitorName}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Solicitor Operating Address"
            secondary={policy.causeOfAction.solicitorOperatingAddress}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Case Handler Name"
            secondary={restrictedForInsurer(policy.causeOfAction.caseHandlerName)}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Case Handler E-mail Address"
            secondary={
              <>
                <img src={IconMail} alt="mail" className={classes.icon} />
                {restrictedForInsurer(policy.causeOfAction.caseHandlerEmailAddress)}
              </>
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Case Handler Case Reference"
            secondary={policy.causeOfAction.caseReference}
          />
        </Grid>
      </Grid>
    </>
  );
}
