import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Typography } from '@material-ui/core';

import { AttachmentsEdit } from '../../components';
import { useStyles } from './styles';

export default function RestrictionWithdrawalRequest({ movement, onChange, setCustomIsValid, showAllValidationErrors }) {

  const classes = useStyles();
  const [dirtyFields, setDirtyFieldsState] = useState([]);

  useEffect(() => {
    setCustomIsValid(false);
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });


  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);

  const validation = {
    isCommentValid: () => !!movement?.comment,
  };

  const isFormValid = () => {
    return validation.isCommentValid();
  }

  const addAttachment = (attachmentName, attachment) => {
    const attachments = [...(movement[attachmentName] ?? []), attachment];
    updateAttachments(attachmentName, attachments);
  };

  const deleteAttachment = (attachmentName, attachmentIndex) => {
    let attachmentsCopy = [...movement[attachmentName]];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      updateAttachments(attachmentName, attachmentsCopy);
    }
  };

  const updateAttachments = (attachmentName, attachments) =>{
    onChange({
      target: {
        name: attachmentName,
        value: attachments
      }
    });
  };

  return (
    <>
      <div>
        <Typography 
          variant="subtitle2" 
          className={classes.normalText}>
          Attach supporting evidence
        </Typography>
        <AttachmentsEdit
          className={classes.formFieldMarginBottom}
          addAttachment={(attachment) => addAttachment('restrictionWithdrawalRequestAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('restrictionWithdrawalRequestAttachments', attachmentIndex)}
          attachments={movement?.restrictionWithdrawalRequestAttachments}
          limitListItemsWidth={false}
        />
      </div>

      <TextField
        name="comment"
        label="Comment"
        placeholder="Explain how restriction was satisfied"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
        required={true}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={(showAllValidationErrors || dirtyFields.includes('comment')) && !validation.isCommentValid()}
        InputLabelProps={{
            shrink: true
          }}
      />
    </>
  );
}
