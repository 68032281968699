import React from 'react';

import Modal from '../Modal/Modal';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { useStyles } from './styles';

export const MovementNotCreatedPopup = ({ modalOpen, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal
      titleText={`Movement discarded`}
      open={modalOpen}
      onClose={handleClose}
    >
      <div className={classes.modalContainer}>
        <div>
          <CancelOutlinedIcon className={`${classes.modalIconLarge} ${classes.error}`} />
        </div>
        <div className={classes.modalText}>
          <p>You have entered invalid data into the form, thus your movement has not been saved.</p>
          <p>Resolve has been notified about this fact and our staff will contact you soon.</p>
        </div>
      </div>
    </Modal>
  );
};
