import React from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { useStyles } from './styles';

export default function InfoTooltip(props) {

  const classes = useStyles();

  return (
    <Tooltip title={props.title}>
        <InfoOutlined fontSize="small" className={classes.icon}/>
    </Tooltip>
  );
};
