import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Grid, TextField } from '@material-ui/core';

import { CurrencyTextField, Warning, Danger } from '../../components';
import { getMatchingQddlRow, cleanReducer } from '../../store/reducers/policy/matchingQddlRow';

import { 
  shouldShowWarningAboutChangedBand,
  shouldShowDangerAboutQuantumOutsideQddl } from '../../support/movementHelpers';

export default function QuantumChangeMovement({ policy, movement, onChange, setCustomIsValid, showAllValidationErrors }) {

  const dispatch = useDispatch();
  const [dirtyFields, setDirtyFieldsState] = useState([]);
  const [totalQuantum, setTotalQuantum] = useState(null);

  const {
    matchingQddlRow,
    isError,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.movement.matchingQddlRow);

  useEffect(() => {
    setCustomIsValid(false);
    dispatch(cleanReducer());
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const checkWhetherQuantumBandHasIsDifferentThanInPolicy = () => {
    if (movement?.quantumSpecial && movement?.quantumGeneral) {
      delayGettingQddlRow(movement.quantumSpecial, movement.quantumGeneral);
    } else {
      delayGettingQddlRow.cancel();
    }
  }

  const delayGettingQddlRow = useCallback(debounce((quantumSpecial, quantumGeneral) => getQddlRow(quantumSpecial, quantumGeneral), 300),[]);

  const getQddlRow = (quantumSpecial, quantumGeneral) => {
    dispatch(
      getMatchingQddlRow(
        policy.id,
        { quantumSpecial, quantumGeneral })
    );
  };

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);

  const validation = {
    quantumGeneralIsValid: () => !!movement?.quantumGeneral,
    quantumSpecialIsValid: () => !!movement?.quantumSpecial,
  };

  const isFormValid = () => {
    return validation.quantumGeneralIsValid() && validation.quantumSpecialIsValid();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <CurrencyTextField
            name="quantumGeneral"
            label="Quantum general"
            onChange={onChange}
            helperText="Must be equal to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('quantumGeneral')) && !validation.quantumGeneralIsValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
              setTotalQuantum(+movement?.quantumSpecial + +movement?.quantumGeneral);
              checkWhetherQuantumBandHasIsDifferentThanInPolicy();
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyTextField
            name="quantumSpecial"
            label="Quantum special"
            onChange={onChange}
            helperText="Must be equal to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('quantumSpecial')) && !validation.quantumSpecialIsValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
              setTotalQuantum(+movement?.quantumSpecial + +movement?.quantumGeneral);
              checkWhetherQuantumBandHasIsDifferentThanInPolicy();
            }}
          />
        </Grid>
      </Grid>

      <CurrencyTextField
        className='reset-mb'
        name="totalQuantum"
        label="Total quantum"
        value={totalQuantum}
        isReadOnly={true}
        mb={0.5}
      />

      {shouldShowWarningAboutChangedBand(policy, matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning className={'reset-mb warning-mt'}>Quantum has changed band.</Warning>}
      {shouldShowDangerAboutQuantumOutsideQddl(wasRecognizedAsOutsideQddl) && <Danger className={'reset-mb warning-mt'}>Quantum is outisde allowed bands! Please correct the input, otherwise your request will not be saved.</Danger>}
      <div></div>

      <TextField
        name="comment"
        label="Please detail the cause of the change in quantum"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
      />
    </>
  );
}
