import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

export const useStyles = makeStyles({
  contextMenu: {
    color: colors.green,
  },
  button: {
    ...common.primaryButton,
    paddingTop: 6,
    paddingBottom: 6,
    marginLeft: 50,
  },
  header: {
    marginBottom: 20,
  },
});
