import { includesAny } from './helpers';
import { userGroupTypes, userRoles } from './constants';

const checkIfAccountIsValidFor = (cachedUserProfile, requiredGroupType) => {
  const requiredCompaniesCount = (cachedUserProfile?.groups || []).filter(
    (g) => g.type === requiredGroupType
  ).length;

  const invalidCompaniesCount = (cachedUserProfile?.groups || []).filter(
    (g) => g.type !== requiredGroupType && g.type !== userGroupTypes.security
  ).length;

  return requiredCompaniesCount === 1 && invalidCompaniesCount === 0;
};

export const checkIfAccountIsValidIfUserIsSolicitor = (
  cachedUserProfile,
  groupsAssignedToUser = []
) => {
  if (includesAny(groupsAssignedToUser, [userRoles.solicitor])) {
    return checkIfAccountIsValidFor(
      cachedUserProfile,
      userGroupTypes.solicitor
    );
  } else {
    return true;
  }
};

export const checkIfAccountIsValidIfUserIsInsurer = (
  cachedUserProfile,
  groupsAssignedToUser = []
) => {
  if (includesAny(groupsAssignedToUser, [userRoles.insurer])) {
    return checkIfAccountIsValidFor(cachedUserProfile, userGroupTypes.insurer);
  } else {
    return true;
  }
};

export const getAssignedGroupsCount = (groupsAssignedToUser = []) => {
  return Object.values(userRoles).filter((userRole) =>
    groupsAssignedToUser.includes(userRole)
  ).length;
};
