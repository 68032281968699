import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export default function NumberFormatCustom(props) {
  const {
    inputRef,
    onChange,
    allowNegative,
    allowZero,
    decimalScale,
    ...other
  } = props;

  const validateForZeroValue = (values) => {
    if (allowZero) {
      return true;
    }

    const { formattedValue, floatValue } = values;
    return formattedValue === '' || floatValue !== 0;
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      isAllowed={validateForZeroValue}
    />
  );
}

NumberFormatCustom.defaultProps = {
  allowNegative: false,
  allowZero: true,
  decimalScale: 4,
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  allowNegative: PropTypes.bool,
  allowZero: PropTypes.bool,
  decimalScale: PropTypes.number,
};
