import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const drawerWidth = 260;
const logoHeight = 65;
const borderWidth = 1;

export const useStyles = makeStyles(() => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    flex: `0 0 ${drawerWidth}px`,
    height: `calc(100vh - ${logoHeight}px)`,
    borderRight: `${borderWidth}px solid ${colors.greyE6}`,
    ['@media print']: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth - borderWidth,
    flex: '0 0 100%',
    border: 0,
  },
  menuElement: {
    padding: 0,
    margin: 0,
  },
  menuElementLink: {
    padding: '18px 0 18px 19px',
    display: 'block',
    color: colors.text,
    borderBottom: `${borderWidth}px solid ${colors.greyE6}`,
    '&:hover,&.active': {
      backgroundColor: `rgba(${colors.primaryRgb.r},${colors.primaryRgb.g},${colors.primaryRgb.b},0.08)`,
      color: colors.primary,
      textDecoration: 'none',
    },
    '&.active::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'block',
      width: 4,
      height: '100%',
      backgroundColor: colors.primary,
    },
  },
  menuElementTxt: {
    paddingLeft: '8px',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  logo: {
    height: `${logoHeight}px`,
    borderBottom: `${borderWidth}px solid ${colors.greyE6}`,
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  },
}));
