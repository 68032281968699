import { createAction, createSlice } from '@reduxjs/toolkit';

import { cancelledRequestErrorMessage } from '../../../support/constants';

const initialState = {
  isPremiumCalculating: false,
  isError: false,
  wasRecognizedAsOutsideQddl: false,
  premium: null,
  emptyPremium: {
    fundedCurrentExclIPT: '',
    deferredCurrentExclIPT: '',
    totalCurrentInclIPT: '',
    ipt: '',
  },
};

export const policyPremiumSlice = createSlice({
  name: 'policyPremium',
  initialState: initialState,
  reducers: {
    startQuery: (state, action) => {
      if (state.requestSource) {
        state.requestSource.cancel(cancelledRequestErrorMessage);
      }

      state.isPremiumCalculating = true;
      state.requestSource = action.requestSource;
    },
    hasError: (state, payload) => {
      state.isPremiumCalculating = false;
      state.isError = true;
      state.wasRecognizedAsOutsideQddl = payload.error.response.status === 400;
      state.premium = state.emptyPremium;
    },
    success: (state, payload) => {
      state.isPremiumCalculating = false;
      state.wasRecognizedAsOutsideQddl = false;
      state.isError = false;
      state.premium = payload.response.data;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
      state.premium = state.emptyPremium;
    },
  },
});

export const {
  startQuery,
  hasError,
  success,
  cleanReducer,
} = policyPremiumSlice.actions;

export const getPolicyPremium = (policyPremium) => (dispatch) => {
  const getPolicyPremium = createAction(
    'getPolicyPremium',
    (policyPremium) => ({
      meta: {
        callAPITypes: [startQuery, success, hasError],
        requestURL: `/policies/premium`,
        method: 'GET',
        showErrorNotification: false,
        requestConfig: {
          params: {
            ...policyPremium,
          },
        },
      },
      type: 'getPolicyPremium',
      payload: null,
    })
  );

  dispatch(getPolicyPremium(policyPremium)).catch(() => {
    // invalid result for policy premium - action in "hasError" reducer
  });
};

export default policyPremiumSlice.reducer;
