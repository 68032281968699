import React from 'react';
import { TextField } from '@material-ui/core';

export default function AuditMovement({ onChange }) {
  return (
    <>
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
      />
    </>
  );
}
