import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '20px',
    padding: '22px 34px',
    borderLeft: `6px solid ${colors.primary}`,
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  },
  listFullWidth: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  divider: {
    marginRight: 25,
    marginLeft: 25,
  },
  userIcon: {
    margin: 'auto',
    opacity: '10%',
    width: '140px',
  },
  icon: {
    color: colors.warning,
    marginLeft: '5px',
    verticalAlign: 'bottom',
  },
  warning: {
    marginBottom: '10px',
  },
}));
