import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  downloadSucceeded: false,
  isLoading: false,
  url: null,
  filename: null,
};

export const policiesSlice = createSlice({
  name: 'policiesReport',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state) => {
      state.isLoading = false;
      state.downloadSucceeded = false;
    },
    policiesSuccess: (state, action) => {
      const getFilename = (contentDisposition) =>
        contentDisposition
          .split(';')
          .find((item) => item.includes('filename='))
          .replace('filename=', '')
          .replaceAll('"', '')
          .trim();

      state.filename = getFilename(
        action.response.headers['content-disposition']
      );

      state.url = window.URL.createObjectURL(new Blob([action.response.data]));

      state.isLoading = false;
      state.downloadSucceeded = true;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  policiesSuccess,
  cleanReducer,
} = policiesSlice.actions;

export const fetchPoliciesReport = createAction(
  'fetchPolicies',
  (reportConfiguration) => ({
    meta: {
      callAPITypes: [startLoading, policiesSuccess, hasError],
      requestURL: '/reports/policies',
      method: 'POST',
      shouldShowLoader: false,
      requestConfig: {
        responseType: 'blob',
        data: {
          ...reportConfiguration,
        },
      },
    },
    type: 'fetchPoliciesReport',
    payload: null,
  })
);

export default policiesSlice.reducer;
