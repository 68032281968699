import React, { useRef, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import UploadIcon from '../../assets/upload.svg';
import { uniqueId as getUniqueId } from '../../support/helpers';

import { useStyles } from './styles';

export default function Uploader({ saveFile, disabled }) {
  const classes = useStyles();
  const uploadInput = useRef(null);
  const [uniqueId] = useState(getUniqueId());
  const pdfMimeType = 'application/pdf';
  const xlsMimeType = 'application/vnd.ms-excel';
  const xlsxMimeType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const docMimeType = 'application/msword';
  const docxMimeType =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const handleCapture = ({ target }) => {
    if (target.files.length > 0) {
      saveFile(target.files[0]);
    }

    // fix for Chrome problem (impossible to upload the same file twice)
    uploadInput.current.value = null;
  };

  return (
    <>
      <input
        accept={`${pdfMimeType},${xlsMimeType},${xlsxMimeType},${docMimeType},${docxMimeType}`}
        className={classes.input}
        id={`fileInput` + uniqueId}
        type="file"
        onChange={handleCapture}
        disabled={disabled}
        ref={uploadInput}
      />
      <Tooltip title="Select File">
        <label htmlFor={`fileInput` + uniqueId}>
          <Button
            color="primary"
            aria-label="Upload file"
            component="span"
            disabled={disabled}
            classes={{
              root: classes.uploadButton,
              disabled: classes.disabledUploadButton,
            }}
          >
            <img src={UploadIcon} alt="Upload" />
            Upload file
          </Button>
        </label>
      </Tooltip>
    </>
  );
}
