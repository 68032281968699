import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userProfileSelector } from '../../store/selectors';
import { userProfileShape } from '../../support/prop-types';

function PrivateContent({
  userProfile,
  requiredRoles,
  additionalCondition,
  alternativeCondition,
  children,
}) {
  if (!userProfile || !additionalCondition(userProfile)) return null;
  const orConditionResult = alternativeCondition(userProfile);
  if (requiredRoles !== '*') {
    const userRole = userProfile.role;
    if (Array.isArray(requiredRoles)) {
      if (!requiredRoles.includes(userRole) && !orConditionResult) {
        return null;
      }
    } else if (requiredRoles !== userRole && !orConditionResult) {
      return null;
    }
  }
  return children;
}

PrivateContent.defaultProps = {
  userProfile: undefined,
  requiredRoles: '*',
  additionalCondition: () => true,
  alternativeCondition: () => false,
  children: null,
};

PrivateContent.propTypes = {
  children: PropTypes.node,
  userProfile: userProfileShape,
  requiredRoles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  additionalCondition: PropTypes.func,
  alternativeCondition: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    userProfile: userProfileSelector(state),
  };
}

export default connect(mapStateToProps)(PrivateContent);
