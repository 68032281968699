import React from 'react';
import { MTableToolbar } from 'material-table';

import styles from './ManageGroupsGridToolbar.module.scss';

export default function ManageGroupsGridToolbar(props) {
  return (
    <div className={styles.toolbar}>
      <MTableToolbar {...props} />
    </div>
  );
}
