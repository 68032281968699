import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

export const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.greyE6}`,
    boxShadow: 'none',
    padding: '20px 20px 40px',
    marginTop: '10vh',
  },
  logo: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 'min(6rem, 15vw)',
    color: colors.grey7F,

    '&::first-letter': {
      color: colors.green,
    },
  },
  actions: {
    justifyContent: 'center',
  },
  button: common.primaryButton,
});
