import React from 'react';
import { FormHelperText } from '@material-ui/core';

export default function Info(props) {
  return (
    <FormHelperText className={props.className}>
      {props.children}
    </FormHelperText>
  );
}
