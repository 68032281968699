import React from 'react';
import { Route, Switch } from 'react-router';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Main,
  Profile,
  Callback,
  Login,
  NotFound,
  CreateScheme,
  AmendScheme,
  SchemeList,
  SchemeDetails,
  AmendPolicy,
  CreatePolicy,
  PolicyList,
  PolicyDetails,
  BordereauReport,
  PoliciesReport
} from './scenes';
import { PrivateRoute } from './components';
import { userRoles } from './support/constants';
import { mainTheme } from './support/mainTheme';

class App extends React.Component {
  render() {
    const theme = createMuiTheme(mainTheme);
    return (
      <ThemeProvider theme={theme}>
        <Main>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={SchemeList}
            />
            <PrivateRoute
              path="/policies/:policyId/amend"
              component={AmendPolicy}
              requiredRoles={[userRoles.resolve, userRoles.solicitor]}
            />
            <PrivateRoute path="/policies/:policyId" component={PolicyDetails} />
            <PrivateRoute path="/policies" component={PolicyList} />
            <PrivateRoute
              exact
              path="/schemes/new-scheme"
              component={CreateScheme}
              requiredRoles={[userRoles.resolve]}
            />
            <PrivateRoute
              exact
              path="/schemes/:schemeId/amend"
              component={AmendScheme}
              requiredRoles={[userRoles.resolve]}
            />
            <PrivateRoute
              exact
              path="/schemes/:schemeId"
              component={SchemeDetails}
              requiredRoles={[
                userRoles.resolve,
                userRoles.insurer,
                userRoles.auditor
              ]}
            />
            <PrivateRoute
              path="/schemes/:schemeId/new-policy"
              component={CreatePolicy}
              requiredRoles={[userRoles.solicitor]}
            />
            <PrivateRoute path="/bordereau" component={BordereauReport} requiredRoles={userRoles.resolve} />
            <PrivateRoute path="/policies-report" component={PoliciesReport} requiredRoles={userRoles.resolve} />
            <PrivateRoute path="/profile" component={Profile} />
            <Route path="/login" component={Login} />
            <Route path="/callback" component={Callback} />
            <Route component={NotFound} />
          </Switch>
        </Main>
      </ThemeProvider>
    );
  }
}

export default App;
