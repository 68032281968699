import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  listItem: {
    padding: '0 30px 0 0',
    marginBottom: 8,
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
}));
