import { createAction, createSlice } from '@reduxjs/toolkit';
import { showNotification } from '../global/notifications';
import {
  saveToStorage,
  removeFromStorage,
  getFromStorage,
} from '../global/backendStorage';

const initialScheme = { periodFrom: null, periodTo: null };
const initialState = {
  schemeInitForStep: { ...initialScheme },
  schemeToSave: { ...initialScheme },
  schemeDraftValidation: {},
  isSchemeValidForDraft: false,
  schemeLiveValidation: {},
  isSchemeValidForLive: false,
  schemeCode: null,
  savedSchemeId: null,
  shouldRedirect: false,
  isLoading: true,
  stepsCount: 6,
  isCodeAvailableChecking: false,
  isCodeAvailable: null,
};

export const schemesSlice = createSlice({
  name: 'create',
  initialState: initialState,
  reducers: {
    // fetch scheme
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasFetchError: (state) => {
      state.isLoading = false;
    },
    schemeFetchSuccess: (state, action) => {
      const downloadedScheme = action.response.data;
      state.schemeInitForStep = downloadedScheme;
      state.schemeToSave = downloadedScheme;
      state.savedSchemeId = downloadedScheme.id;
      state.schemeCode =
        downloadedScheme?.codePrefix + downloadedScheme?.codeSuffix;
    },
    schemeInfoLoadingFinished: (state, action) => {
      state.isLoading = false;
    },

    // save scheme to API
    startSaving: (state) => {
      state.isSaving = true;
      state.hasError = false;
    },
    hasError: (state) => {
      state.isSaving = false;
      state.hasError = true;
    },
    draftingSuccess: (state, action) => {
      state.isSaving = false;
      state.savedSchemeId = action.response.data;
      state.shouldRedirect = true;
    },
    beforeLive: (state, action) => {
      state.isSaving = true;
      state.savedSchemeId = action.response.data;
    },
    liveSuccess: (state, action) => {
      state.isSaving = false;
      state.shouldRedirect = true;
    },
    updatingSuccess: (state, action) => {
      state.isSaving = false;
      state.savedSchemeId = action.response.data;
      state.shouldRedirect = true;
    },
    saveSchemeValidation: (state, action) => {
      const downloadedValidationInfo = action.payload;
      state.schemeDraftValidation = downloadedValidationInfo?.draft || {};
      state.schemeLiveValidation = downloadedValidationInfo?.live || {};
    },

    refreshSchemeInitForNextStep: (state) => {
      state.schemeInitForStep = { ...state.schemeToSave };
    },
    saveValidationChanges: (state, action) => {
      const checkIfLiveIsPossible = (validLiveSteps) => {
        const isAllValid =
          Object.keys(validLiveSteps).length === state.stepsCount - 1 &&
          Object.keys(validLiveSteps).reduce(
            (isValid, key) => isValid && validLiveSteps[key],
            true
          );

        return isAllValid;
      };

      const checkIfDraftIsPossible = (validDraftSteps) => {
        // only enabled button if first step is valid
        return !validDraftSteps[0];
      };

      const { live, draft, step } = action.payload;

      state.schemeDraftValidation = {
        ...state.schemeDraftValidation,
        [step]: draft,
      };
      state.schemeLiveValidation = {
        ...state.schemeLiveValidation,
        [step]: live,
      };

      state.isSchemeValidForDraft = checkIfDraftIsPossible(
        state.schemeDraftValidation
      );
      state.isSchemeValidForLive = checkIfLiveIsPossible(
        state.schemeLiveValidation
      );
    },
    saveSchemeChanges: (state, action) => {
      const { schemePart } = action.payload;

      state.schemeToSave = {
        ...state.schemeToSave,
        ...schemePart,
      };
    },

    startCheckIfCodeIsAvailable: (state) => {
      state.isCodeAvailableChecking = true;
    },
    checkIfCodeIsAvailableSuccess: (state, action) => {
      state.isCodeAvailableChecking = false;
      state.isCodeAvailable = action.response.data;
    },
    checkIfCodeIsAvailableError: (state) => {
      state.isCodeAvailableChecking = false;
      state.isCodeAvailable = null;
    },

    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startLoading,
  hasFetchError,
  schemeFetchSuccess,
  schemeInfoLoadingFinished,
  startSaving,
  hasError,
  draftingSuccess,
  beforeLive,
  liveSuccess,
  updatingSuccess,
  saveSchemeValidation,
  refreshSchemeInitForNextStep,
  saveValidationChanges,
  saveSchemeChanges,
  startCheckIfCodeIsAvailable,
  checkIfCodeIsAvailableSuccess,
  checkIfCodeIsAvailableError,
  cleanReducer,
} = schemesSlice.actions;

export const fetchSchemeInfo = (id) => (dispatch) => {
  const fetchSchemeById = createAction('fetchSchemeById', (id) => ({
    meta: {
      callAPITypes: [startLoading, schemeFetchSuccess, hasFetchError],
      requestURL: `/schemes/${id}`,
      method: 'GET',
    },
    type: 'fetchSchemeById',
    payload: null,
  }));

  dispatch(fetchSchemeById(id))
    .then(({ response }) => {
      dispatch(getFromStorage(response.data.id))
        .then(({ response }) => {
          dispatch(saveSchemeValidation(response.data));
          dispatch(schemeInfoLoadingFinished());
        })
        .catch(() => {
          dispatch(schemeInfoLoadingFinished());
          dispatch(
            showNotification({
              color: 'danger',
              message: 'Getting validation info about scheme failed.',
            })
          );
        });
    })
    .catch(() => {
      dispatch(schemeInfoLoadingFinished());
      dispatch(
        showNotification({
          color: 'danger',
          message: 'Fetching scheme failed.',
        })
      );
    });
};

export const saveAsLive = () => (dispatch, getState) => {
  const state = getState().scheme.create;
  const saveAsDraftBeforeLive = createAction(
    'saveDraftSchemeBeforeLive',
    (scheme) => ({
      meta: {
        callAPITypes: [startSaving, beforeLive, hasError],
        requestURL: state.savedSchemeId
          ? `/schemes/${state.savedSchemeId}`
          : '/schemes',
        method: state.savedSchemeId ? 'PUT' : 'POST',
        requestConfig: {
          data: {
            ...scheme,
          },
        },
      },
      type: 'saveDraftSchemeBeforeLive',
      payload: null,
    })
  );

  const goLive = createAction('goLiveScheme', (id) => ({
    meta: {
      callAPITypes: [startSaving, liveSuccess, hasError],
      requestURL: `/schemes/${id}/golive`,
      method: 'POST',
      requestConfig: {},
    },
    type: 'goLiveScheme',
    payload: id,
  }));

  dispatch(saveAsDraftBeforeLive(state.schemeToSave)).then(({ response }) => {
    const schemeId = response.data;
    dispatch(goLive(schemeId))
      .then(() => {
        dispatch(removeFromStorage(schemeId));
      })
      .catch(() => {
        dispatch(
          saveToStorage(
            {
              draft: state.schemeDraftValidation,
              live: state.schemeLiveValidation,
            },
            schemeId
          )
        );
        dispatch(
          showNotification({
            color: 'danger',
            message: 'Confirm as Live failed but Scheme Draft was saved.',
          })
        );
      });
  });
};

export const saveAsDraft = () => (dispatch, getState) => {
  const state = getState().scheme.create;
  const update = createAction('updateSchemeAsDraft', (scheme) => ({
    meta: {
      callAPITypes: [startSaving, updatingSuccess, hasError],
      requestURL: `/schemes/${state.savedSchemeId}`,
      method: 'PUT',
      requestConfig: {
        data: {
          ...scheme,
        },
      },
    },
    type: 'updateScheme',
    payload: scheme,
  }));

  const insert = createAction('insertSchemeAsDraft', (scheme) => ({
    meta: {
      callAPITypes: [startSaving, draftingSuccess, hasError],
      requestURL: '/schemes',
      method: 'POST',
      requestConfig: {
        data: {
          ...scheme,
        },
      },
    },
    type: 'saveDraftScheme',
    payload: null,
  }));

  dispatch(
    state.savedSchemeId
      ? update(state.schemeToSave)
      : insert(state.schemeToSave)
  ).then(({ response }) => {
    dispatch(
      saveToStorage(
        {
          draft: state.schemeDraftValidation,
          live: state.schemeLiveValidation,
        },
        response.data
      )
    );
  });
};

export const checkIfSchemeCodeIsAvailable = (type, periodFrom, codePrefix) => (
  dispatch,
  getState
) => {
  const state = getState().scheme.create;
  const checkAvailability = createAction(
    'checkIfSchemeCodeIsAvailable',
    (type, periodFrom, codePrefix) => ({
      meta: {
        callAPITypes: [
          startCheckIfCodeIsAvailable,
          checkIfCodeIsAvailableSuccess,
          checkIfCodeIsAvailableError,
        ],
        requestURL: `/schemes/IsCodeAvailable`,
        method: 'GET',
        requestConfig: {
          params: {
            schemeId: state.savedSchemeId,
            type: type,
            periodFrom: periodFrom,
            codePrefix: codePrefix,
          },
        },
      },
      type: 'checkIfSchemeCodeIsAvailable',
      payload: null,
    })
  );

  return dispatch(checkAvailability(type, periodFrom, codePrefix));
};

export default schemesSlice.reducer;
