import React from 'react';
import { sortBy } from 'lodash';
import { Button, Paper, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { uniqueId as getUniqueId } from '../../support/helpers';
import { Divider } from '../Divider/Divider';

import { ClaimantForm, validateClaimant } from './ClaimantForm';

export const Claimants = ({
  onChange,
  titleClassName,
  contentClassName,
  subtitleClassName,
  actionsClassName,
  currentStepState,
  isBlockedForSolicitor,
}) => {
  const [claimants, setClaimants] = React.useState(
    sortBy(currentStepState ?? [{ isLead: true }], [(c) => !c.isLead])
  );
  const [formValidationStatus, setFormValidationStatus] = React.useState(
    currentStepState
      ? currentStepState.reduce(
          (accu, current) => accu && validateClaimant(current),
          true
        )
      : false
  );

  const validateClaimants = (claimants) =>
    claimants.reduce(
      (accu, current) => accu && validateClaimant(current),
      true
    );

  const addEmptyClaimant = () => {
    setClaimants([
      ...claimants,
      {
        uniqueId: getUniqueId(),
        postcode: '',
        address: '',
        town: '',
        county: '',
      },
    ]);
    setFormValidationStatus(false);
  };

  const updateClaimant = (updated, index) => {
    const allClaimants = [
      ...claimants.map((c, i) => (i === index ? updated : c)),
    ];
    setClaimants(allClaimants);
    setFormValidationStatus(validateClaimants(allClaimants));
  };

  const deleteClaimant = (index) => {
    const remainingClaimants = [...claimants.filter((c, i) => i !== index)];
    setClaimants(remainingClaimants);
    setFormValidationStatus(validateClaimants(remainingClaimants));
  };

  const copyLeadClaimantAddress = (claimantIndex) => {
    const leadClaimant = claimants.find((claimant) => claimant.isLead);
    let claimantToChange = claimants[claimantIndex];
    claimantToChange.postcode = leadClaimant.postcode;
    claimantToChange.address = leadClaimant.address;
    claimantToChange.town = leadClaimant.town;
    claimantToChange.county = leadClaimant.county;

    updateClaimant(claimantToChange, claimantIndex);
  };

  React.useEffect(() => onChange(formValidationStatus, { claimants }), [
    formValidationStatus,
    claimants,
  ]);

  return (
    <>
      <Typography variant="h4" className={titleClassName}>
        Claimants
      </Typography>
      <Paper className={contentClassName}>
        {claimants.map((claimant, index) => (
          <ClaimantForm
            titleClassName={subtitleClassName}
            claimant={claimant}
            claimantIndex={index}
            onClaimantChange={updateClaimant}
            onDelete={deleteClaimant}
            isClaimantBlocked={isBlockedForSolicitor && !!claimant.id}
            key={claimant.id ?? claimant.uniqueId}
            copyLeadClaimantAddress={copyLeadClaimantAddress}
          />
        ))}
        <Divider />
        {claimants.length < 5 ? (
          <div className={actionsClassName}>
            <Button onClick={addEmptyClaimant}>
              <AddCircleOutlineIcon /> Add Claimant
            </Button>
          </div>
        ) : null}
      </Paper>
    </>
  );
};
