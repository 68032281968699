import React from 'react';
import { TextField } from '@material-ui/core';

import { CurrencyTextField, SelectWithOptions } from '../../components';
import { claimTypes, disbursementTypes } from '../../support/constants';

export default function ClaimMovement({ movement, onChange }) {
  return (
    <>
      <SelectWithOptions
        name="claimType"
        label="Claim Movement Type"
        value={movement.claimType}
        onChange={onChange}
        options={claimTypes}
        required={true}
      />
      <SelectWithOptions
        name="disbursementType"
        label="Expense Type"
        value={movement.disbursementType}
        onChange={onChange}
        options={disbursementTypes}
        required={true}
      />
      <CurrencyTextField
        name="value"
        label="Value"
        onChange={onChange}
        helperText="Allowed positive and negative"
        required={true}
        allowNegative={true}
      />
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
      />
    </>
  );
}
