import React, { useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import IconArrowExpand from '../../assets/arrow_down.svg';
import IconArrowCollapse from '../../assets/arrow_up.svg';

import { useStyles } from './styles';

export default function ExpanderPanel(props) {
  const classes = useStyles();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const { count, children, expandedText } = props;

  const handleChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <Accordion
      className={classes.accordion}
      onChange={handleChange}
    >
      <AccordionSummary className={classes.accordionAction}>
        {accordionExpanded
          ? `Show less ${expandedText}`
          : `Show all ${expandedText}`
        } ({count})
        {accordionExpanded
          ? <img src={IconArrowCollapse} alt="collapse"/>
          : <img src={IconArrowExpand} alt="expand"/>
        }
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
