import React from 'react';
import { Divider, Paper, Typography } from '@material-ui/core';

export const SchemePolicyTabItem = ({ scheme, classes }) => {
  return (
    <>
      <Typography variant="h4">Policy</Typography>
      <Paper className={classes.root}>
        <Typography variant="subtitle1">
          Narrative of Condition Precedent
        </Typography>
        <Divider />

        <Typography variant="body1" className={classes.multiline}>
          {scheme.narrativeOfConditionPrecedent}
        </Typography>
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="subtitle1">Narrative of Unwind</Typography>
        <Divider />
        <Typography variant="body1" className={classes.multiline}>
          {scheme.narrativeOfUnwind}
        </Typography>
      </Paper>
    </>
  );
};
