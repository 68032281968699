import moment from 'moment';
import _ from 'lodash';
import { areDatesSame, checkDateInRange } from './helpers';
import { courts, movementTypes } from './constants';

export function getMovementsByType(movements, movementType) {
  return _.filter(movements, { type: movementType.value });
}

export function shouldShowWarningAboutDatesNotInRange(movement) {
  return (
    movement?.dateOfProceedingsToBeIssued &&
    movement?.dateOfProceedingsToBeServed &&
    !checkDateInRange(
      movement.dateOfProceedingsToBeIssued,
      moment(movement.dateOfProceedingsToBeIssued).add(7, 'd'),
      movement.dateOfProceedingsToBeServed
    )
  );
}

export function shouldShowWarningAboutNotSameDates(movement) {
  return (
    !shouldShowWarningAboutDatesNotInRange(movement) &&
    !areDatesSame(
      movement?.dateOfProceedingsToBeIssued,
      movement?.dateOfProceedingsToBeServed
    )
  );
}

export function shouldShowWarningAboutDateHeld(movement) {
  return movement?.isDateOfProceedingsHeld;
}

export function shouldShowWarningAboutChangedBand(
  policy,
  matchingQddlRow,
  wasRecognizedAsOutsideQddl
) {
  return (
    !wasRecognizedAsOutsideQddl &&
    matchingQddlRow &&
    matchingQddlRow.id !== policy?.policyPremium?.matchingQddlRow?.id
  );
}

export function shouldShowDangerAboutQuantumOutsideQddl(
  wasRecognizedAsOutsideQddl
) {
  return !!wasRecognizedAsOutsideQddl;
}

export function shouldShowWarningAboutIncorrectCourt(movement) {
  return (
    !!movement?.court &&
    !(
      +movement.court === +courts.countyCourt.value ||
      +movement.court === +courts.highCourt.value
    )
  );
}

export function areThereAnyMissingDocumensForTrialRequest(movements) {
  return _.filter(
    movements,
    (movement) => movement.type == movementTypes.TrialRequest.value
  ).some(
    (movement) =>
      !movement.supportiveCounselsAdviceAttachments.length ||
      !movement.propertyReSurveyedByHeadSurveyorAttachments.length
  );
}
