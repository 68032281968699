import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  listExpandable: {
    padding: 0,
    '& > li': {
      padding: 0,
    },
  },
  listFull: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    color: colors.primary,
    '& > *': {
      width: '100%',
    },
  },
  limitListItemsWidth: {
    '& > *': {
      width: '25%',
    },
  },
  link: {
    padding: 0,
    color: colors.primary,
  },
  linkText: {
    color: colors.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkDelete: {
    color: colors.primary,
  },
  icon: {
    minWidth: 'auto',
    marginRight: 12,
  },
  more: {
    paddingTop: 10,
    color: colors.primary,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  modal: {
    maxHeight: 500,
    minWidth: 550,
  },
  content: {
    padding: '8px 16px',
    border: `2px solid ${colors.greyE6}`,
    borderRadius: 4,
    '& > a': {
      paddingBottom: 10,
    },
  },
}));
