import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: null,
  currentUserProfile: null,
  isUserProfileCached: false,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state) => {
      state.isLoading = false;
    },
    currentProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.currentUserProfile = action.response.data;
    },
    markUserProfileAsCached: (state) => {
      state.isUserProfileCached = true;
    },
  },
});

export const {
  startLoading,
  hasError,
  currentProfileSuccess,
  markUserProfileAsCached,
} = userProfileSlice.actions;

export const getCurrentUserProfile = createAction(
  'getCurrentUserProfile',
  () => ({
    meta: {
      callAPITypes: [startLoading, currentProfileSuccess, hasError],
      requestURL: `/users/current`,
      method: 'GET',
    },
    type: 'getCurrentUserProfile',
    payload: null,
  })
);

export default userProfileSlice.reducer;
