import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

import {
  FormGroup,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { getCurrentUserProfile } from '../../store/reducers/global/userProfile';

import { getCurrentDate, checkDateInRange, getFormattedAddress, capitalizeWords } from '../../support/helpers';
import { DatePickerWithClear } from '../../components';
import { dateFormat, policyStage, userGroupTypes } from '../../support/constants';

import { CauseOfActionPremium } from './CauseOfActionPremium';
import { useStyles } from './styles';

export const CauseOfAction = ({
  onChange,
  titleClassName,
  contentClassName,
  subtitleClassName,
  scheme,
  policy,
  currentCauseOfAction,
  currentPolicyPremium,
  isBlockedForSolicitor,
  isCreate,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [causeOfAction, setCauseOfAction] = React.useState(
    currentCauseOfAction ?? {
      cfaSignedDate: null,
      dateOfKnowledge: null,
      policyInceptionDate: getCurrentDate(),
      policyStage: 1,
    }
  );

  const [policyPremium, setPolicyPremium] = React.useState(
    currentPolicyPremium ?? {}
  );

  const [formValidationStatus, setFormValidationStatus] = React.useState(false);
  const [
    policyPremiumValidationStatus,
    setPolicyPremiumValidationStatus,
  ] = React.useState(false);
  const [dirtyFields, setDirtyFieldsState] = React.useState([]);
  const formRef = useRef(null);
  const setDirtyFields = (event) => {
    setDirtyFieldsState([...dirtyFields, event.target.name]);
  };

  const { currentUserProfile, isLoading } = useSelector(
    (state) => state.global.userProfile
  );
  const [currentUserSolicitorGroupName, setCurrentUserColicitorGroupName] = React.useState(null);
  const [matchingSchemeSolicitorRegisteredAddress, setMatchingSchemeSolicitorRegisteredAddress] = React.useState(null);

  useEffect(() => {
    dispatch(getCurrentUserProfile());
  }, []);

  const getSolicitorGroupName = (userProfile) => {
    var solicitorGroups = (userProfile?.groups || []).filter(
      (g) =>
        g.type === userGroupTypes.solicitor
    );

    return solicitorGroups && solicitorGroups.length ? solicitorGroups[0].name : null;
  };

  useEffect(() => {
    if (currentUserProfile && currentUserProfile.groups){
      setCurrentUserColicitorGroupName(getSolicitorGroupName(currentUserProfile));
      return;
    }
  }, [currentUserProfile]);

  useEffect(()=>{
    if (currentUserSolicitorGroupName) {
      capitalizeInputChange(
        {
          target: {
            name: 'solicitorName',
            value: currentUserSolicitorGroupName,
          },
        });

        var matchingSchemeSolicitor = find(scheme.eligibleSolicitors, { company: {name: currentUserSolicitorGroupName}});
        if (matchingSchemeSolicitor){
          setMatchingSchemeSolicitorRegisteredAddress(getFormattedAddress(matchingSchemeSolicitor.company.registeredAddress));
        }
    }
  }, [currentUserSolicitorGroupName]);

  useEffect(() => {
    if (matchingSchemeSolicitorRegisteredAddress){
      capitalizeInputChange({
        target: {
          name: 'solicitorOperatingAddress',
          value: matchingSchemeSolicitorRegisteredAddress,
        },
      });
    }
  }, [matchingSchemeSolicitorRegisteredAddress]);

  useEffect(
    () =>
      onChange(formValidationStatus && policyPremiumValidationStatus, {
        causeOfAction,
      }),
    [formValidationStatus, causeOfAction]
  );

  useEffect(
    () =>
      onChange(formValidationStatus && policyPremiumValidationStatus, {
        policyPremium,
      }),
    [policyPremiumValidationStatus, policyPremium]
  );

  useEffect(() => {
    const validate = () =>
      setFormValidationStatus(
        formRef?.current &&
        formRef.current.checkValidity() &&
        hasRequiredDate('cfaSignedDate') &&
        hasRequiredDate('dateOfKnowledge') &&
        !isPolicyInceptionDateOutOfBounds()
      );

    validate();
  }, [causeOfAction]);

  const onCauseOfActionEventChange = (event) => {
    setCauseOfAction({
      ...causeOfAction,
      [event.target.name]: event.target.value,
    });
  };

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onCauseOfActionEventChange(event);
  }

  const onCauseOfActionDataChange = (propertyName) => (data) => {
    setCauseOfAction({
      ...causeOfAction,
      [propertyName]: data,
    });
  };

  const onDateChange = (date, fieldName) => {
    setCauseOfAction({
      ...causeOfAction,
      [fieldName]: date ? date.toISOString() : date,
    });
  };

  const hasError = (property) => {
    return (
      !formRef?.current || !formRef.current.elements[property].validity.valid
    );
  };

  const hasErrorToDisplay = (property) => {
    return dirtyFields.includes(property) && hasError(property);
  };

  // DatePicker control is rendered with readonly input inside. For HTML5 validation, readonly elements are not validated
  // Additional validate function is necessary instead of default HTML 5 validation
  const hasRequiredDate = (property) => {
    return !!causeOfAction[property];
  };

  const isPolicyInceptionDateOutOfBounds = () => {
    return !checkDateInRange(scheme.periodFrom, scheme.periodTo, causeOfAction.policyInceptionDate);
  };

  return (
    <>
      <form ref={formRef} noValidate>
        <Typography variant="h4" className={titleClassName}>
          Cause of Action
        </Typography>
        <Paper className={contentClassName}>
          <FormGroup className={classes.wizardForm}>
            <h4 className={subtitleClassName}>Cause of Action Details</h4>
            <FormGroup className={classes.fourOfInputs}>
              <DatePickerWithClear
                name="cfaSignedDate"
                label="CFA Signed Date"
                required={true}
                value={causeOfAction.cfaSignedDate}
                onChange={(date) => onDateChange(date, 'cfaSignedDate')}
                error={
                  dirtyFields.includes('cfaSignedDate') &&
                  !hasRequiredDate('cfaSignedDate')
                }
                onBlur={setDirtyFields}
                isReadOnly={isBlockedForSolicitor}
              />
              <DatePickerWithClear
                name="dateOfKnowledge"
                label="Date of Knowledge"
                required={true}
                value={causeOfAction.dateOfKnowledge}
                onChange={(date) => onDateChange(date, 'dateOfKnowledge')}
                error={
                  dirtyFields.includes('dateOfKnowledge') &&
                  !hasRequiredDate('dateOfKnowledge')
                }
                onBlur={setDirtyFields}
                isReadOnly={isBlockedForSolicitor}
              />
              <div />
              <div />
            </FormGroup>
            <FormGroup className={classes.fourOfInputs}>
              <TextField
                name="prospectsOfSuccess"
                label="Prospects of Success"
                value={causeOfAction.prospectsOfSuccess}
                onChange={onCauseOfActionEventChange}
                type="number"
                helperText="Must be between 0 and 100"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0, max: 100 },
                  readOnly: isBlockedForSolicitor,
                }}
                variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
                required={true}
                error={hasErrorToDisplay('prospectsOfSuccess')}
                onBlur={setDirtyFields}
              />
              <TextField
                name="successFee"
                label="Success Fee"
                value={causeOfAction.successFee}
                onChange={onCauseOfActionEventChange}
                type="number"
                helperText="Must be between 0 and 100"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0, max: 100 },
                  readOnly: isBlockedForSolicitor,
                }}
                variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
                required={true}
                error={hasErrorToDisplay('successFee')}
                onBlur={setDirtyFields}
              />
              <div />
              <div />
            </FormGroup>
            <CauseOfActionPremium
              scheme={scheme}
              policyStatus={policy.status}
              causeOfAction={causeOfAction}
              policyPremium={policyPremium}
              setPolicyPremium={setPolicyPremium}
              setPolicyPremiumValidationStatus={
                setPolicyPremiumValidationStatus
              }
              setDirtyFields={setDirtyFields}
              onEventChange={onCauseOfActionEventChange}
              onDataChange={onCauseOfActionDataChange}
              hasError={hasError}
              hasErrorToDisplay={hasErrorToDisplay}
              isBlockedForSolicitor={isBlockedForSolicitor}
              isCreate={isCreate}
            />
            <FormGroup className={classes.fourOfInputs}>
              <DatePicker
                name="policyInceptionDate"
                label="Policy Inception Date"
                format={dateFormat}
                variant="inline"
                inputVariant="filled"
                value={causeOfAction.policyInceptionDate}
                required={true}
                readOnly={true}
                minDate={scheme.periodFrom}
                maxDate={scheme.periodTo}
                minDateMessage="Must be within Scheme period"
                maxDateMessage="Must be within Scheme period"
                error={isPolicyInceptionDateOutOfBounds()}
              />
              <TextField
                name="policyStage"
                label="Policy Stage"
                variant="filled"
                required={true}
                value={policyStage[
                  causeOfAction.policyStage
                ]?.displayName.toUpperCase()}
                InputProps={{
                  readOnly: true,
                }}
              />
              <div />
              <div />
            </FormGroup>
            <h4 className={subtitleClassName}>Solicitor</h4>
            <FormGroup className={classes.pairOfInputs}>
              <TextField
                name="solicitorName"
                label="Solicitor Name"
                value={causeOfAction.solicitorName}
                onChange={capitalizeInputChange}
                variant={currentUserSolicitorGroupName ? "filled" : "outlined"}
                required={true}
                error={hasErrorToDisplay('solicitorName')}
                onBlur={setDirtyFields}
                inputProps={{
                  style: {textTransform: 'capitalize'},
                  readOnly: !!currentUserSolicitorGroupName,
                }}
              />
              <TextField
                name="solicitorOperatingAddress"
                label="Solicitor Operating Address"
                value={causeOfAction.solicitorOperatingAddress}
                onChange={capitalizeInputChange}
                variant={matchingSchemeSolicitorRegisteredAddress ? "filled" : "outlined"}
                error={hasErrorToDisplay('solicitorOperatingAddress')}
                onBlur={setDirtyFields}
                inputProps={{
                  style: {textTransform: 'capitalize'},
                  readOnly: !!matchingSchemeSolicitorRegisteredAddress,
                }}
              />
            </FormGroup>
            <FormGroup className={classes.pairOfInputs}>
              <TextField
                name="caseHandlerName"
                label="Case Handler Name"
                value={causeOfAction.caseHandlerName}
                onChange={capitalizeInputChange}
                variant="outlined"
                required={true}
                error={hasErrorToDisplay('caseHandlerName')}
                onBlur={setDirtyFields}
                inputProps={{
                  style: {textTransform: 'capitalize'},
                }}
              />
              <TextField
                name="caseHandlerEmailAddress"
                label="Case Handler E-mail Address"
                value={causeOfAction.caseHandlerEmailAddress}
                onChange={onCauseOfActionEventChange}
                variant="outlined"
                required={true}
                error={hasErrorToDisplay('caseHandlerEmailAddress')}
                onBlur={setDirtyFields}
              />
            </FormGroup>
            <FormGroup className={classes.pairOfInputs}>
              <TextField
                name="caseReference"
                label="Case Reference"
                value={causeOfAction.caseReference}
                onChange={onCauseOfActionEventChange}
                variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
                required={true}
                error={hasErrorToDisplay('caseReference')}
                onBlur={setDirtyFields}
                InputProps={{
                  readOnly: isBlockedForSolicitor,
                }}
              />
            </FormGroup>
          </FormGroup>
        </Paper>
      </form>
    </>
  );
};
