import React from 'react';

import { Grid } from '@material-ui/core';

import { AttachmentsListFull, ListItem, Section } from '../../../components';

import {
  schemeType,
  reinsuranceBasis,
  remunerationType,
} from '../../../support/constants';

import {
  getFormattedDate,
  getFormattedMoney,
  getFormattedPercent,
} from '../../../support/helpers';

import RoleDetails from './RoleDetails';
import RoleListDetails from './RoleListDetails';

export default function PreviewGeneralInformation({ scheme, isValidForLive }) {
  const getRemunerationText = (type) => {
    return remunerationType[type]?.fieldsToDisplay.reduce(
      (previousText, field, index) => {
        return index === 0
          ? scheme?.introducer?.[field]
          : `${previousText}\r\n\r\n${scheme?.introducer?.[field]}`;
      },
      ''
    );
  };

  return (
    <Section
      title="1. General Information"
      expanded={true}
      preview={true}
      areDataValidOnPreview={isValidForLive}
    >
      <Section title="Scheme Information" inside={true}>
        <Grid container>
          <Grid item lg={3}>
            <ListItem primary="Scheme Name" secondary={scheme?.name ?? ''} />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Scheme Type"
              secondary={schemeType[scheme?.type]?.type ?? ''}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Scheme Code Prefix"
              secondary={scheme?.codePrefix ?? ''}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Scheme Code Suffix"
              secondary={scheme?.codeSuffix ?? ''}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Scheme Period"
              secondary={`
                ${getFormattedDate(scheme?.periodFrom)} - ${getFormattedDate(
                scheme?.periodTo
              )}
              `}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Reinsurance Basis"
              secondary={reinsuranceBasis[scheme?.reinsuranceBasis] ?? ''}
            />
          </Grid>
        </Grid>

        <AttachmentsListFull
          attachments={scheme?.attachments}
          isPreview={true}
        />
      </Section>

      <RoleDetails data={scheme?.introducer} sectionTitle="Introducer">
        <Grid container>
          <Grid item lg={3}>
            <ListItem
              primary="Type of Remuneration"
              secondary={
                remunerationType[scheme?.introducer?.remunerationType]?.type ??
                ''
              }
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Remuneration"
              secondary={
                getRemunerationText(scheme?.introducer?.remunerationType) ?? ''
              }
            />
          </Grid>
        </Grid>
      </RoleDetails>

      <RoleDetails
        data={scheme?.frontingInsurer}
        sectionTitle="Fronting Insurer"
      >
        <Grid container>
          <Grid item lg={3}>
            <ListItem
              primary="Share"
              secondary={getFormattedPercent(scheme?.frontingInsurer?.share)}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Fee"
              secondary={getFormattedPercent(scheme?.frontingInsurer?.fee)}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Referral Limit Claim"
              secondary={getFormattedMoney(
                scheme?.frontingInsurer?.referralLimitClaim
              )}
            />
          </Grid>
        </Grid>
      </RoleDetails>

      <RoleListDetails
        data={scheme?.reinsurers}
        expanderText="reinsurers"
        sectionTitle="Reinsurers"
      >
        {(roleToDisplay) => (
          <Grid container>
            <Grid item lg={3}>
              <ListItem
                primary="Share"
                secondary={getFormattedPercent(roleToDisplay?.share)}
              />
            </Grid>

            <Grid item lg={3}>
              <ListItem
                primary="Referral Limit Claim"
                secondary={getFormattedMoney(roleToDisplay?.referralLimitClaim)}
              />
            </Grid>
          </Grid>
        )}
      </RoleListDetails>
    </Section>
  );
}
