export const colors = {
  primary: '#1AA41A',
  primaryRgb: {
    r: 26,
    g: 164,
    b: 26,
  },
  secondary: '#F5F7F5',
  text: '#333',
  green: '#00B019',
  lightGreen: '#D4EBD4',
  white: '#FFF',
  greyC: '#CCC',
  greyE6: '#E6E6E6',
  grey7F: '#7F7F7F',
  greyF5: '#F5F5F5',
  greyFA: '#FAFAFA',
  warning: '#EB6E00',
  error: '#F44336',
  info: '#2196f3',
};

const button = {
  boxShadow:
    '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  borderRadius: '4px',
  padding: '10px 26px',
  fontSize: '14px',
  fontWeight: 'bold',
};

export const common = {
  primaryButton: {
    ...button,
    background: colors.primary,
    color: colors.white,
    '&:hover': {
      background: colors.primary,
      opacity: 0.8,
    },
  },

  secondaryButton: {
    ...button,
    background: colors.white,
    color: colors.primary,
    '&:hover': {
      background: colors.white,
      opacity: 0.8,
    },
  },

  actionButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    '& img': {
      marginRight: 8,
    },
    '& button': {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    '& [disabled]': {
      '& img': {
        filter: 'opacity(40%)',
      },
    },
  },
};
