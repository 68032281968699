import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Popper from '@material-ui/core/Popper';
import { AppBar, Button, MenuItem, Toolbar } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { AccountCircle } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { userProfileSelector } from '../../store/selectors';
import userManager from '../../store/userManager';
import { useStyles } from './styles';

export default function Header() {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userProfile = useSelector(userProfileSelector);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProfile = () => {
    history.push(`/profile`);
    handleClose();
  };

  const logout = () => {
    userManager.signoutRedirect();
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <AppBar className={classes.header} position="sticky">
      <Toolbar>
        {userProfile && (
          <div>
            <Button
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="primary"
            >
              <AccountCircle className={classes.avatar} />
              <span className={classes.accountName}>{userProfile.name}</span>
              <ArrowDropDownIcon />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorEl}
              transition
              disablePortal
              className={classes.userMenu}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'right bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open}>
                        <MenuItem onClick={goToProfile}>Profile</MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
