import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { PolicyWizard } from '../../components/PolicyWizard/PolicyWizard';
import { useStyles } from './styles';
import { Breadcrumbs } from '../../components';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import { prepareSchemaCriteriaForPolicy } from '../../support/policyHelpers';
import { cleanReducer as cleanPoliciesPremiumReducer } from '../../store/reducers/policy/premium';
import {
  fetchPolicy,
  updatePolicy,
  cleanReducer as cleanPoliciesCreateReducer,
} from '../../store/reducers/policy/save';

export const AmendPolicy = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [scheme, setScheme] = React.useState();
  const [policy, setPolicy] = React.useState();
  const { downloadedPolicy, savedPolicyId, isSaving } = useSelector(
    (state) => state.policy.save
  );

  React.useEffect(() => {
    dispatch(fetchPolicy(match.params.policyId));
  }, []);

  React.useEffect(() => {
    if (downloadedPolicy) {
      const schemeCriteria = prepareSchemaCriteriaForPolicy(
        downloadedPolicy.scheme.criteria
      );

      const schemeCriteriaResults = schemeCriteria.map((c) => ({
        ...c,
        answer:
          downloadedPolicy.schemeCriteriaResults.find(
            (scr) => scr.schemeCriterionId === c.schemeCriterionId
          )?.answer || null,
      }));

      setScheme({ ...downloadedPolicy.scheme });
      setPolicy({ ...downloadedPolicy, schemeCriteriaResults });
    }
  }, [downloadedPolicy]);

  React.useEffect(() => {
    if (savedPolicyId) {
      history.push({
        pathname: `/policies/${savedPolicyId}`,
        state: { updated: true },
      });
    }
  }, [savedPolicyId]);

  React.useEffect(() => {
    return () => {
      dispatch(cleanPoliciesCreateReducer());
      dispatch(cleanPoliciesPremiumReducer());
    };
  }, []);

  return (
    <LoadingWrapper loading={!scheme}>
      {scheme && policy ? (
        <>
          <Breadcrumbs current="Amend Policy">
            <Link to={'/policies'}>
              <FileCopyOutlinedIcon fontSize="small" />
            </Link>
            <Link
              to={`/policies/${match.params.policyId}`}
            >{`Policy ${downloadedPolicy.number}`}</Link>
          </Breadcrumbs>

          <h1 className={classes.header}>
            {`Amend Policy ${downloadedPolicy.number}`}
          </h1>
          <PolicyWizard
            scheme={scheme}
            incomingPolicy={policy}
            classes={classes}
            isCreate={false}
            onSave={(policy) => {
              dispatch(
                updatePolicy(scheme.type, {
                  ...policy,
                  schemeId: scheme.id,
                })
              );
            }}
            isSaving={isSaving}
          />
        </>
      ) : null}
    </LoadingWrapper>
  );
};
