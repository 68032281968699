import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialValidationMap: {},
  validationMap: {},
  isStepValidForLive: false,
  isStepValidForDraft: false,
  wasInitialValidationSet: false,
  sectionsCount: null,
  stepNumber: null,
};

const getValidationStatusFor = (stage, validationMap, sectionsCount) => {
  let isValid = true;
  [...Array(sectionsCount).keys()].forEach((sectionValue) => {
    const sectionInfo = validationMap[sectionValue];
    isValid = isValid && (sectionInfo ? sectionInfo[stage] : false);
  });

  return isValid;
};

export const schemeWizardStepSlice = createSlice({
  name: 'schemeWizardStepSlice',
  initialState: initialState,
  reducers: {
    initializeStep: (state, action) => {
      const { sectionsCount, stepNumber } = action.payload;
      for (const property in initialState) {
        state[property] = initialState[property];
      }

      state.sectionsCount = sectionsCount;
      state.stepNumber = stepNumber;
    },
    addInitialValidationForSection: (state, action) => {
      let { sectionKey, sectionValidationStatus } = action.payload;
      state.initialValidationMap = {
        ...state.initialValidationMap,
        [sectionKey]: sectionValidationStatus,
      };

      if (
        Object.keys(state.initialValidationMap).length === state.sectionsCount
      ) {
        state.wasInitialValidationSet = true;
        state.validationMap = { ...state.initialValidationMap };
        state.isStepValidForLive = getValidationStatusFor(
          'live',
          state.validationMap,
          state.sectionsCount
        );
        state.isStepValidForDraft = getValidationStatusFor(
          'draft',
          state.validationMap,
          state.sectionsCount
        );
      }
    },
    setValidationForSection: (state, action) => {
      if (state.wasInitialValidationSet) {
        let { sectionKey, sectionValidationStatus } = action.payload;
        state.validationMap = {
          ...state.validationMap,
          [sectionKey]: sectionValidationStatus,
        };

        state.isStepValidForLive = getValidationStatusFor(
          'live',
          state.validationMap,
          state.sectionsCount
        );
        state.isStepValidForDraft = getValidationStatusFor(
          'draft',
          state.validationMap,
          state.sectionsCount
        );
      }
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  initializeStep,
  addInitialValidationForSection,
  setValidationForSection,
  cleanReducer,
} = schemeWizardStepSlice.actions;

export default schemeWizardStepSlice.reducer;
