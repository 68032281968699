import React from 'react';
import { PreviewElementContainer } from './PreviewElementContainer';
import { useStyles } from '../styles';
import { ViewCauseOfAction } from '../../Policy/ViewCauseOfAction';

export const PreviewCauseOfAction = ({ causeOfAction, policyPremium }) => {
  const classes = useStyles();
  return (
    <PreviewElementContainer classes={classes} title="3. Cause of Action">
      <ViewCauseOfAction
        causeOfAction={causeOfAction}
        policyPremium={policyPremium}
      />
    </PreviewElementContainer>
  );
};
