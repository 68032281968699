import React from 'react';
import { Grid } from '@material-ui/core';

import { ListItem } from '../../components';
import { getFormattedDate, getFormattedMoney } from '../../support/helpers';

import { useStyles } from './styles';

export const ViewSpecificCauseOfActionMMC = ({ typeSpecificCauseOfAction }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>Mortgage Details</h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Date of Mortgage"
          secondary={getFormattedDate(typeSpecificCauseOfAction.dateOfMortgage)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Original Lender"
          secondary={typeSpecificCauseOfAction.originalLender}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Mortgage Number"
          secondary={typeSpecificCauseOfAction.mortgageNumber}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Amount"
          secondary={getFormattedMoney(typeSpecificCauseOfAction.amount)}
        />
      </Grid>

      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>Re-mortgage Details</h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Re-mortgage"
          secondary={
            typeSpecificCauseOfAction.hasThereBeenRemortgage ? 'Yes' : 'No'
          }
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Date of Re-mortgage"
          secondary={
            typeSpecificCauseOfAction.hasThereBeenRemortgage
              ? getFormattedDate(typeSpecificCauseOfAction.dateOfRemortgage)
              : '-'
          }
        />
      </Grid>

      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>Other party</h3>
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="New Lender Name"
          secondary={typeSpecificCauseOfAction.newLenderName}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="New Lender Reference"
          secondary={typeSpecificCauseOfAction.newLenderReference}
        />
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Audit Report Name"
          secondary={typeSpecificCauseOfAction.auditReportName}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Audit Version Number"
          secondary={typeSpecificCauseOfAction.auditVersionNumber}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Full Audit Report Date"
          secondary={getFormattedDate(
            typeSpecificCauseOfAction.fullAuditReportDate
          )}
        />
      </Grid>
    </Grid>
  );
};
