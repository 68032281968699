import React from 'react';

import { PreviewElementContainer } from './PreviewElementContainer';
import { useStyles } from '../styles';
import { ViewClaimantDetails } from '../../Policy/ViewClaimantDetails';

export const PreviewClaimants = ({ claimants }) => {
  const classes = useStyles();
  return (
    <PreviewElementContainer classes={classes} title="2. Claimants">
      {claimants.map((claimant, index) => (
        <ViewClaimantDetails claimant={claimant} index={index} />
      ))}
    </PreviewElementContainer>
  );
};
