import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';

import IconCloseModal from '../../assets/close.svg';

import { useStyles } from './styles';

export default function Modal(props) {
  const classes = useStyles();
  const {
    onClose,
    onConfirm,
    open,
    maxWidth,
    titleText,
    content,
    children,
    showActionButtons,
    isReadyToConfirm,
    isLoading,
    ...rest
  } = props;

  const DialogTitle = (props) => {
    const { onClose, children, ...rest } = props;

    return (
      <MuiDialogTitle className={classes.header} disableTypography {...rest}>
        <Typography className={classes.title}>{props.children}</Typography>

        {onClose && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <img src={IconCloseModal} alt="Close" />
          </IconButton>
        )}
      </MuiDialogTitle>
    );
  };

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      maxWidth={props.maxWidth}
      {...rest}
    >
      <DialogTitle onClose={props.onClose}>{props.titleText}</DialogTitle>
      <Divider />

      <DialogContent className={classes.content}>
        {props.children}
      </DialogContent>

      {showActionButtons && (
        <DialogActions
          classes={{
            root: classes.dialogActions,
          }}
        >
          <Button className={classes.cancelButton} onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            autoFocus
            className={classes.confirmButton}
            onClick={props.onConfirm}
            disabled={!isReadyToConfirm || isLoading}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

Modal.defaultProps = {
  maxWidth: 'md',
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  titleText: PropTypes.string,
  maxWidth: PropTypes.string,
  showActionButtons: PropTypes.bool,
  isReadyToConfirm: PropTypes.bool,
  isLoading: PropTypes.bool,
};
