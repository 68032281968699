import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import PreviewGeneralInformation from './PreviewGeneralInformation';
import PreviewSchemeDetails from './PreviewSchemeDetails';
import PreviewSchemeCriteria from './PreviewSchemeCriteria';
import PreviewPolicy from './PreviewPolicy';
import PreviewNotes from './PreviewNotes';

export default function Preview() {
  const schemeToSave = useSelector(
    (state) => state.scheme.create.schemeToSave,
    isEqual
  );
  const schemeLiveValidation = useSelector(
    (state) => state.scheme.create.schemeLiveValidation,
    isEqual
  );

  return (
    <>
      <PreviewGeneralInformation
        scheme={schemeToSave}
        isValidForLive={schemeLiveValidation[0]}
      />
      <PreviewSchemeDetails
        scheme={schemeToSave}
        isValidForLive={schemeLiveValidation[1]}
      />
      <PreviewSchemeCriteria
        scheme={schemeToSave}
        isValidForLive={schemeLiveValidation[2]}
      />
      <PreviewPolicy
        scheme={schemeToSave}
        isValidForLive={schemeLiveValidation[3]}
      />
      <PreviewNotes
        scheme={schemeToSave}
        isValidForLive={schemeLiveValidation[4]}
      />
    </>
  );
}
