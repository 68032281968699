import axios from 'axios';
import config from '../../config';
import { showNotification } from '../reducers/global/notifications';
import {
  showLoader,
  hideLoader,
} from '../reducers/global/loadingWithScreenBlock';
import { accessTokenSelector } from '../selectors/global';
import { cancelledRequestErrorMessage } from '../../support/constants';

function callAPIMiddleware({ dispatch, getState }) {
  return (next) => async (action) => {
    if (!action) {
      return;
    }

    if (!action.meta || !action.meta.callAPITypes) {
      return next(action);
    }

    const {
      callAPITypes,
      requestURL,
      shouldCallAPI = () => true,
      requestConfig = {},
      method,
      showErrorNotification = true,
      shouldShowLoader = null,
    } = action.meta;
    const { payload = {} } = action;
    const showLoadingWithScreenBlock =
      shouldShowLoader != null
        ? shouldShowLoader
        : method === 'PUT' || method == 'POST';

    if (!Array.isArray(callAPITypes) || callAPITypes.length !== 3) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof requestURL !== 'string' && requestURL.length <= 0) {
      throw new Error('Must provide a request URL.');
    }

    const currentState = getState();

    if (!shouldCallAPI(currentState)) {
      return undefined;
    }

    const [requestType, successType, failureType] = callAPITypes;

    if (showLoadingWithScreenBlock) {
      dispatch(showLoader());
    }

    const requestSource = axios.CancelToken.source();
    dispatch(
      Object.assign(
        {},
        payload,
        { requestSource: requestSource },
        {
          type: requestType,
        }
      )
    );

    const accessToken = accessTokenSelector(currentState);
    return axios({
      method,
      url: config.apiBaseUrl + requestURL,
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      cancelToken: requestSource.token,
      ...requestConfig,
    })
      .then((response) =>
        dispatch({
          ...payload,
          response,
          type: successType,
        })
      )
      .catch((error) => {
        if (error?.message === cancelledRequestErrorMessage) {
          return;
        }

        if (showErrorNotification) {
          let errorMessage = 'Action failed.';

          let allErrors = [];
          if (error?.response?.data?.errors) {
            allErrors = Object.values(error.response.data.errors).reduce(
              function(previousValue, currentValue) {
                return previousValue.concat(currentValue);
              },
              []
            );
          }

          if (allErrors.length > 0) {
            errorMessage = `${errorMessage} The following errors were found:\n- ${allErrors.join(
              '\n- '
            )}`;
          } else if (error?.response?.data?.title) {
            errorMessage = `${errorMessage} ${error.response.data.title}`;
          } else {
            errorMessage = `${errorMessage} ${error.message}`;
          }

          dispatch(
            showNotification({ color: 'danger', message: errorMessage })
          );
        }

        dispatch({
          ...payload,
          error,
          type: failureType,
        });

        return Promise.reject(error);
      })
      .finally(() => {
        if (showLoadingWithScreenBlock) {
          dispatch(hideLoader());
        }
      });
  };
}

export default callAPIMiddleware;
