import React from 'react';

import { SchemeCriteria } from '../../components';
import { schemeType } from '../../support/constants';

import { useStyles } from './styles';

export const ViewSchemeCriteria = ({ scheme, answeredCriteria = [] }) => {
  const classes = useStyles();

  const getAnswer = (criterion) => {
    return criterion.answer;
  }

  const showWarning = (criterion) => {
    return criterion.answer === criterion.inconclusiveAnswer;
  }

  return (
    <>
      <h3 className={classes.gridSectionTitle}>
        {schemeType[scheme.type].description}
      </h3>

      {answeredCriteria.length > 0
        ? <SchemeCriteria
            criteria={answeredCriteria}
            getAnswer={getAnswer}
            showWarning={showWarning}
          />
        : <SchemeCriteria
            criteria={scheme.criteria}
            showQuestionName={false}
          />
      }
    </>
  );
};
