import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '16px',
    marginBottom: '4px',
  },
  gridSectionTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: colors.text,
    marginTop: '16px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
  gridDivider: {
    marginTop: '12px',
    marginBottom: '12px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
  icon: {
    marginRight: 8,
  },
}));
