import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId as getUniqueId } from '../../support/helpers';

import { Grid, List, Paper, Typography } from '@material-ui/core';

import { ListItem, Modal } from '../../components';

import {
  isEmpty,
  defaultIfEmpty,
  getFormattedAddress,
} from '../../support/helpers';

import IconCompany from '../../assets/person.svg';
import { getValueByRole } from './getValueByRole';
import { useStyles } from './companyStyles';

export default function CompanyName(props) {
  const classes = useStyles();
  const { scheme, role, index, restrictedForInsurer } = props;
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const roleCompany = getValueByRole(scheme, role, 'company', index);
  const name = roleCompany?.name;

  const handleOpenModal = (e) => {
    e.preventDefault();
    setContactModalOpen(true);
  };

  const handleCloseModal = () => {
    setContactModalOpen(false);
  };

  const getAddress = (type, restricted = true, addressObj) => {
    const address = type && !addressObj ? roleCompany?.[type] : addressObj;

    if (isEmpty(address)) {
      return '-';
    }

    const formattedAddress = getFormattedAddress(address);

    return restricted
      ? restrictedForInsurer(formattedAddress)
      : formattedAddress;
  };

  const Contact = (props) => {
    const { contact, position } = props;
    const address = getAddress(null, true, contact.address);

    return (
      <Paper className={classes.contactContainer}>
        <Typography variant="overline" className={classes.contactHeading}>
          <img src={IconCompany} alt="contact" />
          Contact {position + 1}
        </Typography>
        <Grid container>
          <Grid item lg={6}>
            <List className={classes.leftColumn}>
              <ListItem
                primary="Name"
                secondary={restrictedForInsurer(contact.name)}
              />
              <ListItem primary="Address" secondary={address} />
            </List>
          </Grid>
          <Grid item lg={6}>
            <List className={classes.rightColumn}>
              <ListItem
                primary="Contact Number"
                secondary={restrictedForInsurer(contact.telephone)}
              />
              <ListItem
                primary="E-mail"
                secondary={restrictedForInsurer(contact.email)}
              />
            </List>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <>
      <Typography className={classes.companyName} onClick={handleOpenModal}>
        <img src={IconCompany} alt="company" />
        {name}
      </Typography>

      <Modal
        onClose={handleCloseModal}
        open={contactModalOpen}
        titleText={name}
        maxWidth="sm"
        PaperProps={{ className: classes.modal }}
      >
        <>
          <Grid container>
            <Grid item lg={6}>
              <List className={classes.leftColumn}>
                <ListItem
                  primary="Company Number"
                  secondary={defaultIfEmpty(roleCompany?.companyNumber)}
                />
                <ListItem
                  primary="Registered Address"
                  secondary={getAddress('registeredAddress', false)}
                />
              </List>
            </Grid>
            <Grid item lg={6}>
              <List className={classes.rightColumn}>
                <ListItem
                  primary="Operating Address"
                  secondary={getAddress('operatingAddress', false)}
                />
                <ListItem
                  primary="Notice Address"
                  secondary={getAddress('noticeAddress', false)}
                />
              </List>
            </Grid>
          </Grid>

          {roleCompany?.contacts?.map((contact, index) => {
            return (
              <Contact
                contact={contact}
                position={index}
                key={contact.order || getUniqueId()}
              />
            );
          })}
        </>
      </Modal>
    </>
  );
}

CompanyName.propTypes = {
  scheme: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  index: PropTypes.number,
  restrictedForInsurer: PropTypes.func,
};
