import { createAction, createSlice } from '@reduxjs/toolkit';

export const schemesSlice = createSlice({
  name: 'schemes',
  initialState: {
    schemes: [],
    isLoading: null,
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: state => {
      state.isLoading = false;
    },
    schemesSuccess: (state, action) => {
      state.schemes = action.response.data;
      state.isLoading = false;
    },
  },
});

export const { startLoading, hasError, schemesSuccess } = schemesSlice.actions;

export const fetchSchemes = createAction('fetchSchemes', () => ({
  meta: {
    callAPITypes: [startLoading, schemesSuccess, hasError],
    requestURL: '/schemes',
    method: 'GET',
  },
  type: 'fetchSchemes',
  payload: null,
}));

export default schemesSlice.reducer;
