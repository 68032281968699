import React from 'react';

import { ExpanderPanel } from '../../../components';

import RoleDetails from './RoleDetails';

export default function RoleListDetails({
  data,
  expanderText,
  sectionTitle,
  children,
}) {
  const roleData = Array.isArray(data) ? data : [{ ...data }];
  const getRoleSectionTitle = (index) => `${sectionTitle} ${index + 1}`;

  return (
    <>
      {/* get display only first element and other display in accordion */}
      {roleData?.slice(0, 1).map((data, index) => (
        <RoleDetails
          data={data}
          sectionTitle={getRoleSectionTitle(0)}
          key={index}
        />
      ))}

      {roleData?.length > 1 && (
        <ExpanderPanel count={roleData.length - 1} expandedText={expanderText}>
          {roleData.slice(1, roleData.length).map((roleToDisplay, index) => {
            return (
              <RoleDetails
                data={roleToDisplay}
                key={index}
                sectionTitle={getRoleSectionTitle(index + 1)}
              >
                {children && children(roleToDisplay)}
              </RoleDetails>
            );
          })}
        </ExpanderPanel>
      )}
    </>
  );
}
