import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

export const LoadingWithScreenBlock = () => {
  const { isDisplayed } = useSelector(
    (state) => state.global.loadingWithScreenBlock
  );
  const classes = useStyles();

  return (
    isDisplayed && (
      <div className={classes.fullScreenBackground}>
        <div className={classes.fullScreenSpinner}>
          <CircularProgress />
        </div>
      </div>
    )
  );
};
