import React from 'react';
import { Grid } from '@material-ui/core';
import { find } from 'lodash';

import { AttachmentsListExpandable, ListItem } from '../../components';
import { typesOfPart36Offer } from '../../support/constants';
import {
  getFormattedDate,
  getFormattedPercent,
  getFormattedMoney,
  defaultIfEmpty,
} from '../../support/helpers';

export default function TheirPart36Details({movement}) {

  const shouldDisplayAmount = () => (movement.typeOfOffer == typesOfPart36Offer.quantum.value || movement.typeOfOffer == typesOfPart36Offer.liabilityAndQuantum.value);
  const shouldDisplayClaimantPercentage = () => (movement.typeOfOffer == typesOfPart36Offer.liability.value || movement.typeOfOffer == typesOfPart36Offer.liabilityAndQuantum.value);
  const getTypeOfOfferDisplayName = (types) => find(types, {value: movement.typeOfOffer}).displayName;

  return (
    <Grid container>
      <Grid item lg={6}>
        <ListItem
          primary="Date of offer"
          secondary={getFormattedDate(movement.dateOfOffer)}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Type of offer"
          secondary={getTypeOfOfferDisplayName(typesOfPart36Offer)}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Quantum Amount Offered (compared to initial survey)"
          secondary={shouldDisplayAmount() ? getFormattedMoney(movement.amount) : '-'}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Split Liability Claimant Share"
          secondary={shouldDisplayClaimantPercentage() ? getFormattedPercent(movement.claimantPercentage) : '-'}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Total value of offer"
          secondary={getFormattedMoney(movement.value)}
        />
      </Grid>
      <Grid item lg={12}>
        <ListItem
          primary="Comment including solicitor recommendation"
          secondary={defaultIfEmpty(movement.comment)}
        />
      </Grid>
      <Grid item lg={12}>
        <AttachmentsListExpandable attachments={movement.attachments} />
      </Grid>
    </Grid>
  );
};