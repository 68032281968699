import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';

import { transformToLabel } from '../../support/helpers';

export default function SelectWithOptions(props) {
  const {
    name,
    label,
    value,
    onChange,
    options,
    required,
    error,
    onBlur,
    isReadOnly,
    className,
    getValueToDisplay = (value) => value,
    performTransformingToLabel = true,
    startAdornment,
  } = props;

  const classes = useStyles();

  return (
    <FormControl variant={isReadOnly ? 'filled' : 'outlined'} error={error} className={className}>
      <InputLabel id={`${name}-label`}>
        {label}
        {required ? ' *' : ''}
      </InputLabel>
      <Select
        name={name}
        id={`${name}-select`}
        labelId={`${name}-label`}
        value={value ? value : ''}
        onChange={onChange}
        label={label}
        onBlur={onBlur}
        required={required}
        startAdornment={startAdornment}
        inputProps={{ readOnly: isReadOnly }}
        className={classes.select}
      >
        <MenuItem value={null}>
          <em>None</em>
        </MenuItem>
        {Object.keys(options)
          .sort((x, y) => {
              var left = getValueToDisplay(options[x]);
              var right = getValueToDisplay(options[y]);
              
              if ((left.toLowerCase() === 'other') != (right.toLowerCase() === 'other')) { // hack for displaying Other options at the end of lists
                return left.toLowerCase() === 'other' ? 1 : -1;
              }

              return left.localeCompare(right);
            }
          )
          .map((key) => (
            <MenuItem key={key} value={key}>
              { performTransformingToLabel ? transformToLabel(getValueToDisplay(options[key])) : getValueToDisplay(options[key])}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
