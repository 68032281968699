import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  policy: null,
  isMovementsLoading: false,
  isActionOnMovementInProgress: false,
};

export const detailsSlice = createSlice({
  name: 'policyDetails',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      if (!state.policy) {
        state.isLoading = true;
      }
    },
    hasError: (state) => {
      if (!state.policy) {
        state.isLoading = false;
      }
    },
    policySuccess: (state, action) => {
      state.policy = { ...state.policy, ...action.response.data };
      state.isLoading = false;
    },
    startLoadingMovements: (state) => {
      state.isMovementsLoading = true;
    },
    loadingMovementsFailed: (state) => {
      state.isMovementsLoading = false;
      if (state.policy) {
        state.policy.movements = [];
      }
    },
    loadingMovementsSuccess: (state, action) => {
      state.isMovementsLoading = false;
      if (state.policy) {
        state.policy.movements = action.response.data;
      }
    },
    startActionOnMovement: (state) => {
      state.isActionOnMovementInProgress = true;
    },
    actionOnMovementFailed: (state) => {
      state.isActionOnMovementInProgress = false;
    },
    actionOnMovementSucceeded: (state) => {
      state.isActionOnMovementInProgress = false;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  policySuccess,
  startActionOnMovement,
  actionOnMovementFailed,
  actionOnMovementSucceeded,
  startLoadingMovements,
  loadingMovementsFailed,
  loadingMovementsSuccess,
  cleanReducer,
} = detailsSlice.actions;

export const fetchPolicyById = createAction('fetchPolicyById', (id) => ({
  meta: {
    callAPITypes: [startLoading, policySuccess, hasError],
    requestURL: `/policies/${id}`,
    method: 'GET',
  },
  type: 'fetchPolicyById',
  payload: null,
}));

export const fetchMovements = createAction('fetchMovements', (policyId) => ({
  meta: {
    callAPITypes: [
      startLoadingMovements,
      loadingMovementsSuccess,
      loadingMovementsFailed,
    ],
    requestURL: `/movements?policyId=${policyId}`,
    method: 'GET',
  },
  type: 'fetchMovements',
  payload: null,
}));

export const closeMovement = (
  policyId,
  movementId,
  additionalData,
  operationType
) => (dispatch) => {
  const closeMovement = createAction(
    'closeMovement',
    (movementId, additionalData, operationType) => ({
      meta: {
        callAPITypes: [
          startActionOnMovement,
          actionOnMovementSucceeded,
          actionOnMovementFailed,
        ],
        requestURL: `/movements/${movementId}/${operationType}`,
        method: 'PUT',
        requestConfig: {
          data: { additionalData: additionalData },
        },
      },
      type: 'closeMovement',
      payload: { additionalData: additionalData },
    })
  );

  dispatch(closeMovement(movementId, additionalData, operationType)).then(
    () => {
      dispatch(fetchPolicyById(policyId));
      dispatch(fetchMovements(policyId));
    }
  );
};

export const payMovement = (policyId, movementId) => (dispatch) => {
  const payMovement = createAction('payMovement', (movementId) => ({
    meta: {
      callAPITypes: [
        startActionOnMovement,
        actionOnMovementSucceeded,
        actionOnMovementFailed,
      ],
      requestURL: `/movements/${movementId}/pay`,
      method: 'PUT',
    },
    type: 'payMovement',
    payload: null,
  }));

  dispatch(payMovement(movementId)).then(() => {
    dispatch(fetchPolicyById(policyId));
    dispatch(fetchMovements(policyId));
  });
};

export default detailsSlice.reducer;
