export default class FormValidator {
  constructor(formRef) {
    this.formRef = formRef;
    this.dirtyFields = [];
  }

  isFormValid() {
    return this.formRef?.current && this.formRef.current.checkValidity();
  }

  addDirtyField(property) {
    this.dirtyFields.push(property);
  }

  hasError(property) {
    return !this.formRef?.current?.elements[property]?.validity?.valid;
  }

  hasRequiredDateError(property, objectToValidate) {
    return !objectToValidate[property];
  }

  hasErrorToDisplay(property) {
    return this.dirtyFields.includes(property) && this.hasError(property);
  }

  hasDateErrorToDisplay(property, objectToValidate) {
    return (
      this.dirtyFields.includes(property) &&
      this.hasRequiredDateError(property, objectToValidate)
    );
  }
}
