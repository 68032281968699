import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 25,
  },
  title: {
    marginRight: 20,
  },
  header: {
    padding: '22px 34px',
    borderLeft: `6px solid ${colors.primary}`,
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  },
  detailIcon: {
    marginRight: 30,
    marginLeft: 30,
  },
  divider: {
    marginRight: 25,
    marginLeft: 25,
  },
  list: {
    padding: 0,
    minWidth: 180,
    '& > li': {
      padding: 0,
    },
  },
  listFullWidth: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));
