import React from 'react';

import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  AttachmentsListExpandable,
  ExpanderPanel,
  ListItem,
  ContactInformation,
} from '../../components';

import { remunerationType } from '../../support/constants';
import {
  getFormattedMoney,
  getFormattedPercent,
  transformToLabel,
} from '../../support/helpers';

import Company from './Company';
import { getValueByRole } from './getValueByRole';
import { useStyles } from './schemeDetailsBasicStyles';

export default function SchemeDetailsBasic(props) {
  const classes = useStyles();
  const { scheme, restrictedForInsurer } = props;
  const introducerRemunerationType = getValueByRole(
    scheme,
    'introducer',
    'remunerationType'
  );

  const getRemunerationText = () =>
    remunerationType[introducerRemunerationType]?.fieldsToDisplay.reduce(
      (previousText, field, index) => {
        return index === 0
          ? getValueByRole(scheme, 'introducer', field)
          : `${previousText}\r\n\r\n${getValueByRole(
              scheme,
              'introducer',
              field
            )}`;
      },
      0
    );

  const Reinsurer = (props) => {
    const { index } = props;

    return (
      <>
        <Divider />
        <Company
          scheme={scheme}
          role="reinsurers"
          index={index}
          restrictedForInsurer={restrictedForInsurer}
        />
        <Grid container className={classes.reinsurerContainer}>
          <Grid item lg={4}>
            <ContactInformation
              scheme={scheme}
              role="reinsurers"
              index={index}
              getValueByRole={getValueByRole}
              restrictedForInsurer={restrictedForInsurer}
            />
          </Grid>

          <Grid item lg={5}>
            <List className={classes.detailsList}>
              <ListItem
                primary="Share"
                secondary={getValueByRole(
                  scheme,
                  'reinsurers',
                  'share',
                  index,
                  getFormattedPercent
                )}
              />
              <ListItem
                primary="Referral Limit Claim"
                secondary={getValueByRole(
                  scheme,
                  'reinsurers',
                  'referralLimitClaim',
                  index,
                  getFormattedMoney
                )}
              />
            </List>
          </Grid>

          <Grid item lg={3}>
            <AttachmentsListExpandable
              attachments={getValueByRole(
                scheme,
                'reinsurers',
                'attachments',
                index
              )}
              showHeading={false}
              modalHeadingText="Reinsurer Attachments"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item lg={4}>
          <Typography variant="overline">Name & contact information</Typography>
        </Grid>
        <Grid item lg={5}>
          <Typography variant="overline">Details</Typography>
        </Grid>
        <Grid item lg={3}>
          <Typography variant="overline">Attachments</Typography>
        </Grid>
      </Grid>

      <Typography variant="subtitle1">Introducer</Typography>

      <Divider />
      <Company
        scheme={scheme}
        role="introducer"
        restrictedForInsurer={restrictedForInsurer}
      />
      <Grid container>
        <Grid item lg={4}>
          <ContactInformation
            scheme={scheme}
            role="introducer"
            getValueByRole={getValueByRole}
            restrictedForInsurer={restrictedForInsurer}
          />
        </Grid>

        <Grid item lg={5}>
          <List className={classes.detailsList}>
            <ListItem
              primary="Type of Remuneration"
              secondary={transformToLabel(
                remunerationType[introducerRemunerationType]?.type
              )}
            />
            <ListItem
              primary="Remuneration"
              secondary={getRemunerationText()}
            />
          </List>
        </Grid>

        <Grid item lg={3}>
          <AttachmentsListExpandable
            attachments={getValueByRole(scheme, 'introducer', 'attachments')}
            showHeading={false}
            modalHeadingText="Introducer Attachments"
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1">Fronting insurer</Typography>

      <Divider />
      <Company
        scheme={scheme}
        role="frontingInsurer"
        restrictedForInsurer={restrictedForInsurer}
      />
      <Grid container>
        <Grid item lg={4}>
          <ContactInformation
            scheme={scheme}
            role="frontingInsurer"
            getValueByRole={getValueByRole}
            restrictedForInsurer={restrictedForInsurer}
          />
        </Grid>

        <Grid item lg={5}>
          <List className={classes.detailsList}>
            <ListItem
              primary="Share"
              secondary={getValueByRole(
                scheme,
                'frontingInsurer',
                'share',
                undefined,
                getFormattedPercent
              )}
            />
            <ListItem
              primary="Fee"
              secondary={getValueByRole(
                scheme,
                'frontingInsurer',
                'fee',
                undefined,
                getFormattedPercent
              )}
            />
            <ListItem
              primary="Referral Limit Claim"
              secondary={getValueByRole(
                scheme,
                'frontingInsurer',
                'referralLimitClaim',
                undefined,
                getFormattedMoney
              )}
            />
          </List>
        </Grid>

        <Grid item lg={3}>
          <AttachmentsListExpandable
            attachments={getValueByRole(
              scheme,
              'frontingInsurer',
              'attachments'
            )}
            showHeading={false}
            modalHeadingText="Fronting Insurer Attachments"
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1">Reinsurers</Typography>
      {scheme.reinsurers?.length > 0 && <Reinsurer index={0} />}
      {scheme.reinsurers?.length > 1 && (
        <ExpanderPanel
          count={scheme.reinsurers.length - 1}
          expandedText="reinsurers"
        >
          {scheme.reinsurers
            .slice(1, scheme.reinsurers.length)
            .map((reinsurer, index) => {
              return (
                // index plus one, because we want render elements without first one, which one is display before
                <Reinsurer index={index + 1} key={reinsurer.id} />
              );
            })}
        </ExpanderPanel>
      )}
    </Paper>
  );
}
