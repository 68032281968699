const { makeStyles } = require('@material-ui/core');
const { colors } = require('../../styles/styles');

export const useStyles = makeStyles(() => ({
  boldSubtitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: colors.text,
  },
  wizardForm: {
    marginBottom: '15px',
    '& + &': {
      padding: '23px 0 0 0',
    },
    '& > *:not(:last-child)': {
      marginBottom: '37px',
    },
  },
  pairOfInputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
    '& > *': {
      flex: '0 0 48%',
    },
    '& > &': {
      marginBottom: 0,
    },
  },
  fourOfInputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
    '& > *': {
      flex: '0 0 24%',
    },
  },
  halfOfWidth: {
    width: '48%',
    marginBottom: 35,
  },

  claimantTitle: {
    maxWidth: '25%',
  },
  deleteClaimant: {
    maxWidth: '25%',
    '& button': {
      color: colors.green,
      fontWeight: 'bold',
    },
    '& span img': {
      marginRight: '7px',
    },
  },

  previewTitle: {
    marginBottom: '20px',
    position: 'relative',
    '&::before': {
      height: '100%',
      width: '5px',
      backgroundColor: colors.green,
      position: 'absolute',
      top: 0,
      left: '-34px',
      content: '""',
    },
  },

  modalContainer: {
    width: '566px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '34px 0',
  },

  modalText: {
    fontWeight: '600',
    fontSize: '18px',
    flex: '0 1 320px',
  },
}));
