import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { AttachmentsListExpandable, ListItem, Warning } from '../../components';
import { getPolicyPremium } from '../../store/reducers/policy/premium';
import { shouldShowWarningAboutChangedBand } from '../../support/movementHelpers';
import { policyStages } from '../../support/constants'; 

import { useStyles } from './styles';

import {
  defaultIfEmpty,
  getFormattedDate,
  getFormattedMoney,
} from '../../support/helpers';

export default function SettlementNotificationDetails({movement}) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    premium,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.policy.premium);

  const { policy } = useSelector(
    (state) => state.policy.details
  );

  useEffect(() => {
    dispatch(
        getPolicyPremium({
            schemeId: policy.scheme.id,
            policyId: policy.id,
            policyStatus: policy.status,
            policyStage: movement.settlementStage,
            estimatedDamagesSpecial: movement.damagesSpecial,
            estimatedDamagesGeneral: movement.damagesGeneral,
          })
    );
  }, [])

  return (
    <Grid container>

      <Grid item lg={12}>
      <FormControl
        component="fieldset"
      >
        <Typography variant="subtitle2">
          Confirmed Policy stage when settlement was created
        </Typography>
        <RadioGroup value={+movement.settlementStage} className={classes.radios}>
          {Object.keys(policyStages).map((key, index) => {
            return (+movement?.settlementStage === policyStages[key].value && <FormControlLabel
              key={index}
              value={policyStages[key].value}
              name="settlementStage"
              control={<Radio color="primary" disabled/>}
              label={policyStages[key].displayName}
            />)})}
        </RadioGroup>
      </FormControl>
      </Grid>

      <Grid item lg={12}>
        <ListItem
          primary="Settlement Date"
          secondary={getFormattedDate(movement.settlementDate)}
        />
      </Grid>

      <Grid item lg={12}>
        <FormControl component="fieldset">
          <Typography variant="subtitle2">
            This notification is concerning a:
          </Typography>
          <RadioGroup  name="globalSettlement" value={movement?.globalSettlement} className={classes.radios}>
            <FormControlLabel
                key={1}
                value={1}
                control={<Radio color="primary"/>}
                label={"Global settlement"}
                disabled
              />
            <FormControlLabel
                key={2}
                value={0}
                control={<Radio color="primary"/>}
                label={"Damages settlement"}
                disabled
              />
          </RadioGroup>
        </FormControl>
      </Grid>

      {!!movement?.globalSettlement && <>
        <Grid item lg={12}>
          <ListItem
            primary="Amount"
            secondary={getFormattedMoney(movement.amountReceived)}
          />
        </Grid>

        <Grid item lg={12}>
          <ListItem
            primary="If received, date of receipt of settlement"
            secondary={getFormattedDate(movement.dateOfReceiptOfSettlement)}
          />
        </Grid>

        <Grid item lg={12}>
          <ListItem
            primary="Total Premium payable incl.IPT."
            secondary={getFormattedMoney(policy?.policyPremium.totalCurrentInclIPT, 2, true)}
          />
          <Alert severity="info" className={classes.smallAlert}>You must remit this amount less the Premium you have already paid.</Alert>
        </Grid>
      </>}

      {!movement?.globalSettlement && <>
        <Grid item lg={6}>
          <ListItem
            primary="Damages general"
            secondary={getFormattedMoney(movement.damagesGeneral)}
          />
        </Grid>

        <Grid item lg={6}>
          <ListItem
            primary="Damages special"
            secondary={getFormattedMoney(movement.damagesSpecial)}
          />
        </Grid>

        <Grid item lg={12}>
          <ListItem
            primary="Total quantum"
            secondary={getFormattedMoney(+movement.damagesSpecial + +movement.damagesGeneral)}
          />
        </Grid>

        <Grid item lg={12} className={classes.warning}>
          {shouldShowWarningAboutChangedBand(policy, premium?.matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning className={'reset-mb'}>Quantum has changed band.</Warning>}
        </Grid>

        <Grid item lg={12}>
          <ListItem
            primary="Total Premium payable incl.IPT"
            secondary={getFormattedMoney(premium?.totalCurrentInclIPT, 2, true)}
          />
          <Alert severity="info" className={classes.smallAlert}>You must remit this amount less the Premium you have already paid.</Alert>
        </Grid>

        <Grid item lg={12}>
          <Typography variant="subtitle2">
            Breakdown of settlement
          </Typography>
          <AttachmentsListExpandable attachments={movement.attachments} showHeading={false} />
        </Grid>
      </>}

      <Grid item lg={12}>
        <ListItem
          primary="Comment"
          secondary={defaultIfEmpty(movement.comment)}
        />
      </Grid>
    </Grid>
  );
};