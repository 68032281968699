import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '22px 34px',
    margin: '12px 0',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  },
  tabs: {
    marginTop: '34px',
    marginBottom: '20px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
  tabsVertical: {
    flex: '0 0 auto',
    width: 240,
    borderRight: `1px solid ${colors.greyE6}`,
    boxSizing: 'content-box',
    '& button': {
      padding: '23px 33px',
      borderBottom: `1px solid ${colors.greyE6}`,
      '& span': {
        alignItems: 'flex-start',
        fontWeight: 700,
        textAlign: 'left',
        textTransform: 'none',
      },
    },
    '& .MuiTabs-indicator': {
      left: 0,
      width: 4,
    },
  },
  tabVerticalPanel: {
    padding: '12px 34px 34px',
    flex: 'auto',
  },
  tabHeader: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  policyDetailsTabItem: {
    display: 'flex',
    minHeight: 415,
  },
  panelSectionHeading: {
    alignItems: 'baseline',
    borderBottom: `1px solid ${colors.greyE6}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingTop: 18,
    paddingBottom: 10,
  },
  icon: {
    marginRight: 8,
  },
  button: {
    ...common.primaryButton,
    marginLeft: '10px',
  },
  editIcon: {
    filter: 'brightness(0) invert(1)',
  },
}));
