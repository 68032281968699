import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

export default function StatusBadge(props) {
  const classes = useStyles();

  return (
    <Chip
      size={props.size}
      label={props.statusType[props.status]?.displayName}
      className={`${classes[props.statusType[props.status]?.color]} ${props.className}`}
    />
  );
}

StatusBadge.defaultProps = {
  size: 'small',
};

StatusBadge.propTypes = {
  status: PropTypes.number.isRequired,
  statusType: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};
