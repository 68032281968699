import { colors } from '../../styles/styles';

const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(() => ({
  modal: {
    width: 550,
  },

  formControl: {
    width: '100%',
    marginBottom: '20px',
  },

  radios: {
    marginLeft: '15px',
    '& > label': {
      marginBottom: '0',
      minWidth: '48%',
    },
    '&.error span': {
      color: colors.error,
    },
  },
}));
