import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { saveSchemeChanges } from '../../store/reducers/scheme/save';
import {
  addInitialValidationForSection,
  setValidationForSection,
} from '../../store/reducers/scheme/wizardStep';
import FormValidator from '../../support/FormValidator';

import { useStyles } from './styles';

export default function WizardStepSection({
  dataToChange,
  dataArrayToTriggerUpdate,
  sectionKey,
  validator,
  setValidator,
  checkIfLiveIsValid = null,
  checkIfDraftIsValid = null,
  children,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  useEffect(() => {
    setValidator(new FormValidator(formRef));
  }, []);

  useEffect(() => {
    if (validator) {
      dispatch(
        addInitialValidationForSection({
          sectionKey,
          sectionValidationStatus: {
            live: checkIfLiveIsValid
              ? checkIfLiveIsValid()
              : validator?.isFormValid(),
            draft: checkIfDraftIsValid ? checkIfDraftIsValid() : true,
          },
        })
      );
    }
  }, [validator]);

  useEffect(() => {
    dispatch(saveSchemeChanges({ schemePart: dataToChange }));
    dispatch(
      setValidationForSection({
        sectionKey,
        sectionValidationStatus: {
          live: checkIfLiveIsValid
            ? checkIfLiveIsValid()
            : validator?.isFormValid(),
          draft: checkIfDraftIsValid ? checkIfDraftIsValid() : true,
        },
      })
    );
  }, dataArrayToTriggerUpdate);

  return (
    <form ref={formRef} noValidate className={classes.form}>
      {children}
    </form>
  );
}
