import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';

import IconCancel from '../../assets/cancel.svg';
import IconDraft from '../../assets/draft.svg';
import { saveAsLive, saveAsDraft } from '../../store/reducers/scheme/save';

import GeneralInformation from './GeneralInformation/GeneralInformation';
import SchemeDetails from './SchemeDetails/SchemeDetails';
import SchemeCriteria from './SchemeCriteria/SchemeCriteria';
import Policy from './PolicyAndNotes/Policy';
import Notes from './PolicyAndNotes/Notes';
import Preview from './Preview/Preview';
import SaveAsLiveConfirmationModal from './SaveAsLiveConfirmationModal';
import { useStyles } from './wizardStyles';

export default function SchemeWizard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const nonLinear = true;
  const [activeStep, setActiveStep] = useState(0);
  const [
    openSaveAsLiveConfirmationModal,
    setOpenSaveAsLiveConfirmationModal,
  ] = useState(false);
  const schemeLiveValidation = useSelector(
    (state) => state.scheme.create.schemeLiveValidation
  );
  const isSchemeValidForDraft = useSelector(
    (state) => state.scheme.create.isSchemeValidForDraft
  );
  const isSchemeValidForLive = useSelector(
    (state) => state.scheme.create.isSchemeValidForLive
  );

  const steps = [
    'General Information',
    'Scheme Details',
    'Scheme Criteria',
    'Policy',
    'Notes',
    'Preview',
  ];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => () => {
    if (nonLinear) {
      setActiveStep(step);
    }
  };

  const handleCancel = () => {
    history.push('/');
  };

  const handleSaveAsLiveConfirmation = () => {
    setOpenSaveAsLiveConfirmationModal(true);
  };

  const handleSaveAsLive = () => {
    dispatch(saveAsLive());
  };

  const handleSaveAsDraft = () => {
    dispatch(saveAsDraft());
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <GeneralInformation stepNumber={0} />;
      case 1:
        return <SchemeDetails stepNumber={1} />;
      case 2:
        return <SchemeCriteria stepNumber={2} />;
      case 3:
        return <Policy stepNumber={3} />;
      case 4:
        return <Notes stepNumber={4} />;
      case 5:
        return <Preview />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <>
      <Stepper
        nonLinear={nonLinear}
        activeStep={activeStep}
        className={classes.stepper}
      >
        {steps.map((label, index) => {
          const getCircleIconClass = (state) => {
            switch (true) {
              case state.active && state.error:
                return classes.circleActiveWithErrors;
              case state.active:
                return classes.circleActiveValid;
              case state.completed:
                return classes.circleCompleted;
              case state.warning:
                return classes.circleWarning;
              case state.error:
                return classes.circleError;
              default:
                return classes.circle;
            }
          };

          const stepProps = {};
          const labelProps = {
            StepIconComponent: (props) => (
              <span className={getCircleIconClass(props)}>{props.icon}</span>
            ),
            error: schemeLiveValidation[index] === false,
          };

          return (
            <Step
              key={label}
              completed={schemeLiveValidation[index]}
              className={nonLinear ? classes.clickableStep : ''}
              {...stepProps}
            >
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  label: classes.label,
                }}
                onClick={handleStep(index)}
                {...labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>
        {steps.map(
          (label, index) =>
            activeStep === index && (
              <Typography variant="h4" key={label}>
                {label}
              </Typography>
            )
        )}

        {getStepContent(activeStep)}

        <div className={classes.navigation}>
          <Button
            color="primary"
            className={classes.button}
            onClick={handleCancel}
          >
            <img src={IconCancel} alt="cancel" className={classes.icon} />
            Cancel
          </Button>
          <div>
            <Button
              disabled={isSchemeValidForDraft}
              color="primary"
              className={classes.button}
              onClick={handleSaveAsDraft}
            >
              <img
                src={IconDraft}
                alt="save as draft"
                className={classes.icon}
              />
              Save as draft
            </Button>
            {activeStep !== 0 && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Button
              disabled={
                activeStep === steps.length - 1 ? !isSchemeValidForLive : false
              }
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={
                activeStep === steps.length - 1
                  ? handleSaveAsLiveConfirmation
                  : handleNext
              }
            >
              {activeStep === steps.length - 1 ? 'Go Live' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
      {openSaveAsLiveConfirmationModal && (
        <SaveAsLiveConfirmationModal
          openModal={openSaveAsLiveConfirmationModal}
          setOpenModal={setOpenSaveAsLiveConfirmationModal}
          handleSaveAsLive={handleSaveAsLive}
        />
      )}
    </>
  );
}
