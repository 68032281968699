import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';

import { AttachmentsEdit, CurrencyTextField, SelectWithOptions, DatePickerWithClear, Warning, Danger } from '../../components';
import { getMatchingQddlRow, cleanReducer } from '../../store/reducers/policy/matchingQddlRow';
import { courtsOptions, userRoles } from '../../support/constants'; 
import { getCurrentDate, includesAny } from '../../support/helpers';
import { 
  shouldShowWarningAboutDatesNotInRange,
  shouldShowWarningAboutNotSameDates,
  shouldShowWarningAboutDateHeld,
  shouldShowWarningAboutChangedBand,
  shouldShowDangerAboutQuantumOutsideQddl,
  shouldShowWarningAboutIncorrectCourt } from '../../support/movementHelpers';
import { userGroupsSelector } from '../../store/selectors/global';

import { useStyles } from './styles';

export default function ProceedingsRequestMovement({ policy, movement, onChange, setCustomIsValid, showAllValidationErrors }) {

  const maxProspectsValue = 100;
  const minProspectsValue = 60;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dirtyFields, setDirtyFieldsState] = useState([]);
  const [ricsSurveyConfirmed, setRicsSurveyConfirmed] = useState(false);
  const [scheduleOfDamagesConfirmed, setScheduleOfDamagesConfirmed] = useState(false);
  const userGroups = useSelector(userGroupsSelector);

  const {
    matchingQddlRow,
    isError,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.movement.matchingQddlRow);

  useEffect(() => {
    setCustomIsValid(false);
    dispatch(cleanReducer());
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  useEffect(() => {
    if (movement?.dateOfProceedingsToBeIssued && !movement?.isDateOfProceedingsHeld && !movement?.dateOfProceedingsToBeServed) {
      onDateChange('dateOfProceedingsToBeServed', moment(movement?.dateOfProceedingsToBeIssued));
    }

    if (movement?.isDateOfProceedingsHeld) {
      onDateChange('dateOfProceedingsToBeServed', null);
    }
  }, [movement?.dateOfProceedingsToBeIssued, movement?.isDateOfProceedingsHeld]);

  const checkWhetherQuantumBandHasIsDifferentThanInPolicy = () => {
    if (movement?.quantumSpecial && movement?.quantumGeneral) {
      delayGettingQddlRow(movement.quantumSpecial, movement.quantumGeneral);
    } else {
      delayGettingQddlRow.cancel();
    }
  }

  const delayGettingQddlRow = useCallback(debounce((quantumSpecial, quantumGeneral) => getQddlRow(quantumSpecial, quantumGeneral), 300),[]);

  const getQddlRow = (quantumSpecial, quantumGeneral) => {
    dispatch(
      getMatchingQddlRow(
        policy.id,
        { quantumSpecial, quantumGeneral })
    );
  };

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);


  const onDateChange = (fieldName, date) => {
    onChange({
        target: {
          name: fieldName,
          value: date ? date.toISOString() : date
        }
      });
  };

  const onCheckboxChange = (event) => onChange({
    target: {
      name: event.target.name, 
      value: event.target.checked 
    }
  })

  const validation = {
    ricsSurveyIsConfirmed: () => ricsSurveyConfirmed,
    ricsSurveyAttachmentsIsValid: () => !!movement?.ricsSurveyAttachments?.length,
    letterOfClaimProofOfDeliveryIsConfirmed: () => !!movement?.letterOfClaimProofOfDeliveryConfirmed,
    letterOfClaimProofOfDeliveryAttachmentsIsValid: () => !!movement?.letterOfClaimProofOfDeliveryAttachments?.length,
    defendantResponseToLetterOfClaimIsConfirmed: () => !!movement?.defendantResponseToLetterOfClaimConfirmed,
    defendantResponseToTheLetterOfClaimAttachmentsIsValid: () => (!!movement?.defendantResponseToLetterOfClaimConfirmed &&!!movement?.defendantResponseToTheLetterOfClaimAttachments?.length) || !movement?.defendantResponseToLetterOfClaimConfirmed,
    defendantInsurerPolicyWordingIsConfirmed: () => !!movement?.defendantInsurerPolicyWordingConfirmed,
    defendantInsurerPolicyWordingAttachmentsIsValid: () => !!movement?.defendantInsurerPolicyWordingAttachments?.length,
    scheduleOfDamagesIsConfirmed: () => scheduleOfDamagesConfirmed,
    scheduleOfDamagesAttachmentsIsValid: () => !!movement?.scheduleOfDamagesAttachments?.length,
    limitationValidatedIsValid: () => !!movement?.limitationValidated,
    dateOfProceedingsToBeIssuedIsValid: () => !!movement?.dateOfProceedingsToBeIssued,
    dateOfProceedingsToBeServedIsValid: () => !!movement?.isDateOfProceedingsHeld || !!movement?.dateOfProceedingsToBeServed,
    quantumGeneralIsValid: () => !!movement?.quantumGeneral,
    quantumSpecialIsValid: () => !!movement?.quantumSpecial,
    prospectsIsValid: () => !!movement?.prospects,
    courtIsValid: () => !!movement?.court
  };

  const isFormValid = () => {
    return ricsSurveyConfirmed && validation.ricsSurveyAttachmentsIsValid()
      && !!movement?.viableDefendantConfirmed
      && !!movement?.letterOfClaimSentToCorrectDefendantConfirmed && !!movement?.letterOfClaimProofOfDeliveryConfirmed && validation.letterOfClaimProofOfDeliveryAttachmentsIsValid()
      && validation.defendantResponseToTheLetterOfClaimAttachmentsIsValid()
      && !!movement?.defendantInsurerPolicyWordingConfirmed && validation.defendantInsurerPolicyWordingAttachmentsIsValid()
      && scheduleOfDamagesConfirmed && validation.scheduleOfDamagesAttachmentsIsValid()
      && validation.limitationValidatedIsValid() 
      && validation.dateOfProceedingsToBeIssuedIsValid()
      && validation.dateOfProceedingsToBeServedIsValid()
      && validation.quantumGeneralIsValid()
      && validation.quantumSpecialIsValid()
      && validation.prospectsIsValid()
      && validation.courtIsValid();
  }

  const addAttachment = (attachmentName, attachment) => {
    const attachments = [...(movement[attachmentName] ?? []), attachment];
    updateAttachments(attachmentName, attachments);
  };

  const deleteAttachment = (attachmentName, attachmentIndex) => {
    let attachmentsCopy = [...movement[attachmentName]];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      updateAttachments(attachmentName, attachmentsCopy);
    }
  };

  const updateAttachments = (attachmentName, attachments) =>{
    onChange({
      target: {
        name: attachmentName,
        value: attachments
      }
    });
  };

  // This small hack allows us to show Attachments in red, while user clicked checkbox, unchecked it (in this moment we display Attachments red), and checked it again
  // (in this moment we want still attachments in red, so that's this small hack is here). The same applies for other sections.
  const [isRicsSurveyAlreadyConfirmedOnceAndDirty, setIsRicsSurveyAlreadyConfirmedOnceAndDirty] = useState(false);
  const isRicsSurveyConfirmationDirty = () => showAllValidationErrors || dirtyFields.includes("ricsSurveyConfirmed");
  const shouldShowRicsSurveyError = () => isRicsSurveyConfirmationDirty() && !validation.ricsSurveyIsConfirmed();

  const shouldShowViableDefendantError = () => (showAllValidationErrors || dirtyFields.includes("viableDefendantConfirmed")) && !movement?.viableDefendantConfirmed;

  const shouldShowLetterOfClaimSentToCorrectDefendantError = () => (showAllValidationErrors || dirtyFields.includes("letterOfClaimSentToCorrectDefendantConfirmed")) && !movement?.letterOfClaimSentToCorrectDefendantConfirmed;

  const [isLetterOfClaimProofOfDeliveryAlreadyConfirmedOnceAndDirty, setIsLetterOfClaimProofOfDeliveryAlreadyConfirmedOnceAndDirty] = useState(false);
  const isLetterOfClaimProofOfDeliveryConfirmationDirty = () => showAllValidationErrors || dirtyFields.includes("isLetterOfClaimProofOfDeliveryConfirmed");
  const shouldShowLetterOfClaimProofOfDeliveryError = () => isLetterOfClaimProofOfDeliveryConfirmationDirty() && !validation.letterOfClaimProofOfDeliveryIsConfirmed();
  
  const [isDefendantInsurerPolicyWordingAlreadyConfirmedOnceAndDirty, setIsDefendantInsurerPolicyWordingAlreadyConfirmedOnceAndDirty] = useState(false);
  const isDefendantInsurerPolicyWordingConfirmationDirty = () => showAllValidationErrors || dirtyFields.includes("defendantInsurerPolicyWordingConfirmed");
  const shouldShowDefendantInsurerPolicyWordingError = () => isDefendantInsurerPolicyWordingConfirmationDirty() && !validation.defendantInsurerPolicyWordingIsConfirmed();

  const [isScheduleOfDamagesAlreadyConfirmedOnceAndDirty, setIsScheduleOfDamagesAlreadyConfirmedOnceAndDirty] = useState(false);
  const isScheduleOfDamagesConfirmationDirty = () => showAllValidationErrors || dirtyFields.includes("scheduleOfDamagesConfirmed");
  const shouldShowScheduleOfDamagesError = () => isScheduleOfDamagesConfirmationDirty() && !validation.scheduleOfDamagesIsConfirmed();

  const shouldShowDangerProspectsNotInRange = (movement) => movement?.prospects && (movement.prospects < minProspectsValue || movement.prospects > maxProspectsValue)

  return (
    <>
      <div>
        <Typography 
          variant="subtitle2" 
          className={`
            ${classes.normalText} 
            ${shouldShowRicsSurveyError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            checked={ricsSurveyConfirmed} 
            onChange={(event) => {
              if (dirtyFields.includes('ricsSurveyConfirmed') && !event.target.checked) {
                setIsRicsSurveyAlreadyConfirmedOnceAndDirty(true);
              }
              
              setDirtyFields(event.target.name);
              setRicsSurveyConfirmed(event.target.checked)}
            }
            className={`${shouldShowRicsSurveyError() ? classes.error : ''}`}
            name="ricsSurveyConfirmed" />
          Full & supportive RICS survey in court approved format on file*
        </Typography>
        <AttachmentsEdit
          title="Attachments (Survey)"
          className={classes.formFieldMarginBottom}
          addAttachment={(attachment) => addAttachment('ricsSurveyAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('ricsSurveyAttachments', attachmentIndex)}
          attachments={movement?.ricsSurveyAttachments}
          limitListItemsWidth={false}
          hasError={
            shouldShowRicsSurveyError() || 
            (showAllValidationErrors && !validation.ricsSurveyAttachmentsIsValid()) ||
            (isRicsSurveyAlreadyConfirmedOnceAndDirty && !validation.ricsSurveyAttachmentsIsValid())
          }
        />
      </div>

      <div>
        <Typography 
          variant="subtitle2" 
          className={`
            ${classes.normalText} 
            ${shouldShowViableDefendantError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            checked={movement?.viableDefendantConfirmed || false} 
            onChange={(event) => {
              setDirtyFields(event.target.name);
              onCheckboxChange(event);
            }}
            className={`${shouldShowViableDefendantError() ? classes.error : ''}`}
            name="viableDefendantConfirmed" />
            Still viable defendant with funds confirmed*
        </Typography>
        <AttachmentsEdit
          title="Attachments (optional)"
          addAttachment={(attachment) => addAttachment('viableDefendantAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('viableDefendantAttachments', attachmentIndex)}
          attachments={movement?.viableDefendantAttachments}
          limitListItemsWidth={false}
        />
      </div>

      <div>
      <Typography 
          variant="subtitle2" 
          className={`
            ${classes.normalText} 
            ${shouldShowLetterOfClaimSentToCorrectDefendantError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            checked={movement?.letterOfClaimSentToCorrectDefendantConfirmed || false} 
            onChange={(event) => {
              setDirtyFields(event.target.name);
              onCheckboxChange(event);
            }}
            className={`${shouldShowLetterOfClaimSentToCorrectDefendantError() ? classes.error : ''}`}
            name="letterOfClaimSentToCorrectDefendantConfirmed" />
          Letter of Claim sent to correct defendant*
        </Typography>
        <Typography 
          variant="subtitle2" 
          className={`
            ${classes.normalText} 
            ${shouldShowLetterOfClaimProofOfDeliveryError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            checked={movement?.letterOfClaimProofOfDeliveryConfirmed || false} 
            onChange={(event) => {
              if (dirtyFields.includes('letterOfClaimProofOfDeliveryConfirmed') && !event.target.checked) {
                setIsLetterOfClaimProofOfDeliveryAlreadyConfirmedOnceAndDirty(true);
              }

              setDirtyFields(event.target.name);
              onCheckboxChange(event);
            }}
            className={`${shouldShowLetterOfClaimProofOfDeliveryError() ? classes.error : ''}`}
            name="letterOfClaimProofOfDeliveryConfirmed" />
          Proof of delivery*
        </Typography>
        <AttachmentsEdit
          title="Attachments (LOC)"
          addAttachment={(attachment) => addAttachment('letterOfClaimProofOfDeliveryAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('letterOfClaimProofOfDeliveryAttachments', attachmentIndex)}
          attachments={movement?.letterOfClaimProofOfDeliveryAttachments}
          limitListItemsWidth={false}
          hasError={
            shouldShowLetterOfClaimProofOfDeliveryError() || 
            (showAllValidationErrors && !validation.letterOfClaimProofOfDeliveryAttachmentsIsValid()) ||
            (isLetterOfClaimProofOfDeliveryAlreadyConfirmedOnceAndDirty && !validation.letterOfClaimProofOfDeliveryAttachmentsIsValid())
          }
        />
      </div>

      <div>
        <Typography 
          variant="subtitle2" 
          className={`${classes.normalText}`}>
          <Checkbox 
            color="primary" 
            checked={movement?.defendantResponseToLetterOfClaimConfirmed || false} 
            onChange={(event) => {
              onCheckboxChange(event);
            }}
            name="defendantResponseToLetterOfClaimConfirmed" />
          Defendant response to the Letter of Claim received?
        </Typography>
        <AttachmentsEdit
          title="Attachments (Response to LOC)"
          addAttachment={(attachment) => addAttachment('defendantResponseToTheLetterOfClaimAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('defendantResponseToTheLetterOfClaimAttachments', attachmentIndex)}
          attachments={movement?.defendantResponseToTheLetterOfClaimAttachments}
          limitListItemsWidth={false}
          hasError={(showAllValidationErrors && !validation.defendantResponseToTheLetterOfClaimAttachmentsIsValid())
          }
        />
      </div>

      <div>
        <Typography 
          variant="subtitle2"
          className={`
            ${classes.normalText} 
            ${shouldShowDefendantInsurerPolicyWordingError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            checked={movement?.defendantInsurerPolicyWordingConfirmed || false} 
            onChange={(event) => {
              if (dirtyFields.includes('defendantInsurerPolicyWordingConfirmed') && !event.target.checked) {
                setIsDefendantInsurerPolicyWordingAlreadyConfirmedOnceAndDirty(true);
              }

              setDirtyFields(event.target.name);
              onCheckboxChange(event);
            }}
            className={`${shouldShowDefendantInsurerPolicyWordingError() ? classes.error : ''}`}
            name="defendantInsurerPolicyWordingConfirmed" />
          Defendant insurer policy wording, schedule and cover note*
        </Typography>
        <AttachmentsEdit
          title="Attachments (Defendant Insurer Policy Wording)"
          addAttachment={(attachment) => addAttachment('defendantInsurerPolicyWordingAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('defendantInsurerPolicyWordingAttachments', attachmentIndex)}
          attachments={movement?.defendantInsurerPolicyWordingAttachments}
          limitListItemsWidth={false}
          hasError={
            shouldShowDefendantInsurerPolicyWordingError() || 
            (showAllValidationErrors && !validation.defendantInsurerPolicyWordingAttachmentsIsValid()) ||
            (isDefendantInsurerPolicyWordingAlreadyConfirmedOnceAndDirty && !validation.defendantInsurerPolicyWordingAttachmentsIsValid())
          }
        />
      </div>

      <div>
        <Typography 
          variant="subtitle2"
          className={`
            ${classes.normalText} 
            ${shouldShowScheduleOfDamagesError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            checked={scheduleOfDamagesConfirmed} 
            onChange={(event) => {
              if (dirtyFields.includes('scheduleOfDamagesConfirmed') && !event.target.checked) {
                setIsScheduleOfDamagesAlreadyConfirmedOnceAndDirty(true);
              }

              setDirtyFields(event.target.name);
              setScheduleOfDamagesConfirmed(event.target.checked)
            }}
            className={`${shouldShowScheduleOfDamagesError() ? classes.error : ''}`}
            name="scheduleOfDamagesConfirmed" />
          Schedule of damages*
        </Typography>
        <AttachmentsEdit
          title="Attachments (Details of damages claimed)"
          addAttachment={(attachment) => addAttachment('scheduleOfDamagesAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('scheduleOfDamagesAttachments', attachmentIndex)}
          attachments={movement?.scheduleOfDamagesAttachments}
          limitListItemsWidth={false}
          hasError={
            shouldShowScheduleOfDamagesError() || 
            (showAllValidationErrors && !validation.scheduleOfDamagesAttachmentsIsValid()) ||
            (isScheduleOfDamagesAlreadyConfirmedOnceAndDirty && !validation.scheduleOfDamagesAttachmentsIsValid())
          }
        />
      </div>

      <DatePickerWithClear
        name="limitationValidated"
        label="Limitation validated"
        required={true}
        value={movement.limitationValidated || null}
        onChange={(date) => onDateChange('limitationValidated', date)}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={
          (showAllValidationErrors || dirtyFields.includes('limitationValidated')) && !validation.limitationValidatedIsValid()
        }
      />

      <DatePickerWithClear
        name="dateOfProceedingsToBeIssued"
        label="Date of proceedings to be issued"
        required={true}
        minDate={includesAny(userGroups, [userRoles.solicitor]) ? getCurrentDate() : undefined }
        value={movement.dateOfProceedingsToBeIssued || null}
        onChange={(date) => onDateChange('dateOfProceedingsToBeIssued', date)}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={
          (showAllValidationErrors || dirtyFields.includes('dateOfProceedingsToBeIssued')) && !validation.dateOfProceedingsToBeIssuedIsValid()
        }
      />

      <DatePickerWithClear
        name="dateOfProceedingsToBeServed"
        label="Date of proceedings to be served"
        className={'reset-mb'}
        required={!movement?.isDateOfProceedingsHeld}
        minDate={movement?.dateOfProceedingsToBeIssued || undefined }
        value={movement.dateOfProceedingsToBeServed || null}
        onChange={(date) => onDateChange('dateOfProceedingsToBeServed', date)}
        onBlur={(event) => setDirtyFields(event.target.name)}
        isReadOnly={movement?.isDateOfProceedingsHeld}
        error={
          (showAllValidationErrors || dirtyFields.includes('dateOfProceedingsToBeServed')) && !validation.dateOfProceedingsToBeServedIsValid()
        }
      />

      <Typography variant="subtitle2" className={`${classes.normalText} reset-mb`}>
        <Checkbox 
          color="primary" 
          checked={movement?.isDateOfProceedingsHeld || false} 
          onChange={onCheckboxChange} 
          name="isDateOfProceedingsHeld" />
        Held
      </Typography>

      {shouldShowWarningAboutDatesNotInRange(movement) && <Warning className={'reset-mb'}>Served Date is more than one week grater than Issue Date.</Warning>}
      {shouldShowWarningAboutDateHeld(movement) && <Warning className={'reset-mb'}>Served Date is on hold.</Warning>}
      {shouldShowWarningAboutNotSameDates(movement) && <Warning className={'reset-mb'}>Served Date and Issue Date should generally be the same.</Warning>}

      <div></div>

      <Grid container className='reset-mb'>
        <Grid item xs={6}>
          <CurrencyTextField
            name="quantumGeneral"
            label="Quantum general"
            onChange={onChange}
            helperText="Must be equal to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('quantumGeneral')) && !validation.quantumGeneralIsValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
              checkWhetherQuantumBandHasIsDifferentThanInPolicy();
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyTextField
            name="quantumSpecial"
            label="Quantum special"
            onChange={onChange}
            helperText="Must be equal to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('quantumSpecial')) && !validation.quantumSpecialIsValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
              checkWhetherQuantumBandHasIsDifferentThanInPolicy();
            }}
          />
        </Grid>
      </Grid>

      {shouldShowWarningAboutChangedBand(policy, matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning className={'reset-mb'}>Quantum has changed band.</Warning>}
      {shouldShowDangerAboutQuantumOutsideQddl(wasRecognizedAsOutsideQddl) && <Danger className={'reset-mb'}>Quantum is outisde allowed bands! Please correct the input, otherwise your request will not be saved.</Danger>}
      <div></div>

      <TextField
          name="prospects"
          label="Prospects"
          className='reset-mb'
          onChange={onChange}
          type="number"
          helperText={`Must be between ${minProspectsValue} and ${maxProspectsValue}`}
          variant="outlined"
          required={true}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">%</InputAdornment>
            ),
            inputProps: { step: 1 },
          }}
          error={
            (showAllValidationErrors || dirtyFields.includes('prospects')) && !validation.prospectsIsValid()
          }
          onBlur={(event) => setDirtyFields(event.target.name)}
        />

      {shouldShowDangerProspectsNotInRange(movement) && <Danger className={'reset-mb'}>{`Prospects must be between ${minProspectsValue} and ${maxProspectsValue}! Please correct the input, otherwise your request will not be saved.`}</Danger>}
      <Alert severity="info" className={`${classes.smallAlert} reset-mb`}>Consideration must be given to any valid response to the LOC.</Alert>
      <div></div>

      <SelectWithOptions
        name="court"
        label="Court"
        className={'reset-mb'}
        value={movement.court}
        onChange={onChange}
        options={courtsOptions}
        required={true}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={(showAllValidationErrors || dirtyFields.includes('court')) && !validation.courtIsValid()}
      />

      {shouldShowWarningAboutIncorrectCourt(movement) && <Warning className={'reset-mb'}>'County Court' or 'High court' not selected.</Warning>}
      <div></div>

      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
      />

      <div>
        <Typography variant="overline" display="block" gutterBottom className={classes.disclaimer}>
          By pressing 'Confirm' you are confirming that all the evidence to support the case and the policy conditions precedent are in place and validated.
        </Typography>
        <Typography variant="overline" display="block" gutterBottom className={classes.disclaimer}>
          Approvals based on incomplete or inaccurate data will be invalid.
        </Typography>
      </div>
    </>
  );
}
