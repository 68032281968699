import { createSlice } from '@reduxjs/toolkit';

export const gridRowMenuSlice = createSlice({
  name: 'gridRowMenu',
  initialState: {
    anchorElement: null,
    rowIdentifier: null,
  },
  reducers: {
    clickGridRowMenu: (state, action) => {
      const { anchorElement, rowIdentifier } = action.payload;
      state.anchorElement = anchorElement;
      state.rowIdentifier = rowIdentifier;
    },
    closeGridRowMenu: (state, action) => {
      state.anchorElement = null;
      state.rowIdentifier = null;
    },
  },
});

export const { clickGridRowMenu, closeGridRowMenu } = gridRowMenuSlice.actions;

export const anchorElement = state => state.global.gridRowMenu.anchorElement;
export const rowIdentifier = state => state.global.gridRowMenu.rowIdentifier;

export default gridRowMenuSlice.reducer;
