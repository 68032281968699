import React, { useState } from 'react';
import { FormGroup, TextField } from '@material-ui/core';

import { uniqueId as getUniqueId, capitalizeWords } from '../../support/helpers';

import AddressFieldsEdit from './AddressFieldsEdit';
import { useStyles } from './styles';

export default function ContactFieldsEdit({
  data,
  onChange,
  validator,
  addDirtyField,
  index,
}) {
  const classes = useStyles();
  const [uniqueId] = useState(getUniqueId());

  const onEventChange = (event) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );

    onChange(
      {
        ...data,
        [propertyName]: event.target.value,
      },
      index
    );
  };

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onEventChange(event);
  }

  const onDataPartChange = (newData, dataType) => {
    onChange({ ...data, [dataType]: newData }, index);
  };

  return (
    <>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          variant="outlined"
          label="Name"
          id={`contact-${uniqueId}-name`}
          name={`contact-${uniqueId}-name`}
          value={data.name}
          onChange={capitalizeInputChange}
          required={true}
          error={validator?.hasErrorToDisplay(`contact-${uniqueId}-name`)}
          onBlur={addDirtyField}
          inputProps={{
            style: {textTransform: 'capitalize'},
          }}
        />
        <FormGroup className={classes.pairOfInputs}>
          <TextField
            variant="outlined"
            label="Telephone"
            id={`contact-${uniqueId}-telephone`}
            name={`contact-${uniqueId}-telephone`}
            value={data.telephone}
            onChange={onEventChange}
            required={true}
            error={validator?.hasErrorToDisplay(
              `contact-${uniqueId}-telephone`
            )}
            onBlur={addDirtyField}
          />
          <TextField
            variant="outlined"
            label="E-mail"
            id={`contact-${uniqueId}-email`}
            name={`contact-${uniqueId}-email`}
            value={data.email}
            onChange={onEventChange}
            required={true}
            error={validator?.hasErrorToDisplay(`contact-${uniqueId}-email`)}
            onBlur={addDirtyField}
          />
        </FormGroup>
      </FormGroup>
      <AddressFieldsEdit
        type="address"
        required={false}
        data={data.address}
        onChange={onDataPartChange}
        validator={validator}
        addDirtyField={addDirtyField}
      />
    </>
  );
}
