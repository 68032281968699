import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  qddlTable: {
    marginTop: 16,
    marginBottom: 20,
    border: `1px solid ${colors.greyE6}`,
    borderRadius: 4,
    textTransform: 'uppercase',
  },
  qddlTableRow: {
    '&:nth-child(odd)': {
      backgroundColor: colors.greyF5,
    },
  },
  qddlTableCell: {
    borderRight: `1px solid ${colors.greyE6}`,
    borderLeft: `1px solid ${colors.greyE6}`,
    fontWeight: 600,
  },
  qddlTableSubheaderLeft: {
    borderLeft: `1px solid ${colors.greyE6}`,
  },
}));
