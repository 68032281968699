import React from 'react';
import { Grid } from '@material-ui/core';

import {
  ClaimLimit,
  ListItem,
  Section,
  QddlTablePreview,
} from '../../../components';
import { complaintLimit } from '../../../support/constants';
import { isEmpty, transformToLabel } from '../../../support/helpers';

import RoleListDetails from './RoleListDetails';

export default function PreviewSchemeDetails({ scheme, isValidForLive }) {
  return (
    <Section
      title="2. Scheme Details"
      expanded={true}
      preview={true}
      areDataValidOnPreview={isValidForLive}
    >
      <RoleListDetails
        data={scheme?.eligibleSolicitors}
        expanderText="eligible solicitors"
        sectionTitle="Eligible Solicitor"
      />

      <Section title="QDDL - Incremental" inside={true}>
        <QddlTablePreview qddl={scheme?.qddlRows} />
      </Section>

      <Section title="Refferal Limits" inside={true}>
        <Grid container>
          <Grid item lg={3}>
            <ListItem
              primary="Claim Limit:"
              secondary={<ClaimLimit claimLimit={scheme?.claimLimit} />}
            />
          </Grid>
          <Grid item lg={3}>
            <ListItem
              primary="Decline Referral:"
              secondary={
                isEmpty(scheme?.declineReferral)
                  ? '-'
                  : scheme?.declineReferral
                  ? 'Yes'
                  : 'No'
              }
            />
          </Grid>
          <Grid item lg={3}>
            <ListItem
              primary="Complaint Limit:"
              secondary={transformToLabel(
                complaintLimit[scheme?.complaintLimit]
              )}
            />
          </Grid>
        </Grid>
      </Section>
    </Section>
  );
}
