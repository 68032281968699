import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  downloadSucceeded: false,
  isLoading: false,
  url: null,
  filename: null,
};

export const bordereauSlice = createSlice({
  name: 'bordereau',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state) => {
      state.isLoading = false;
      state.downloadSucceeded = false;
    },
    bordereauSuccess: (state, action) => {
      const getFilename = (contentDisposition) =>
        contentDisposition
          .split(';')
          .find((item) => item.includes('filename='))
          .replace('filename=', '')
          .replaceAll('"', '')
          .trim();

      state.filename = getFilename(
        action.response.headers['content-disposition']
      );

      state.url = window.URL.createObjectURL(new Blob([action.response.data]));

      state.isLoading = false;
      state.downloadSucceeded = true;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  bordereauSuccess,
  cleanReducer,
} = bordereauSlice.actions;

export const fetchBordereau = createAction('fetchBordereau', () => ({
  meta: {
    callAPITypes: [startLoading, bordereauSuccess, hasError],
    requestURL: '/reports/bordereau',
    method: 'GET',
    requestConfig: { responseType: 'blob' },
  },
  type: 'fetchBordereau',
  payload: null,
}));

export default bordereauSlice.reducer;
