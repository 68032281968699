import React from 'react';
import { TextField } from '@material-ui/core';

import { SelectWithOptions } from '../../components';
import { unwindReasonsOptions } from '../../support/constants';

export default function UnwindRequestMovement({ movement, onChange }) {
  return (
    <>
      <SelectWithOptions
        name="reason"
        label="Reason for Unwinding"
        value={movement.reason}
        onChange={onChange}
        options={unwindReasonsOptions}
        required={true}
        performTransformingToLabel={false}
      />
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
      />
    </>
  );
}
