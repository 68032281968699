import React from 'react';

import { Typography } from '@material-ui/core';

import { Section } from '../../../components';
import { useStyles } from '../styles';

export default function PreviewNotes({ scheme, isValidForLive }) {
  const classes = useStyles();

  return (
    <Section
      title="5. Notes"
      expanded={true}
      preview={true}
      areDataValidOnPreview={isValidForLive}
    >
      <Section title="Notes" inside={true}>
        <Typography variant="body1" className={classes.multiline}>
          {scheme?.notes}
        </Typography>
      </Section>
    </Section>
  );
}
