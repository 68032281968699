import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 34px 22px',
    margin: '12px 0',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  },
  heading: {
    paddingTop: 20,
  },
  tabHeader: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabs: {
    marginTop: '34px',
    marginBottom: '20px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
  solicitorsContainer: {
    paddingBottom: 15,
  },
  addSolicitor: common.actionButton,
  multiline: {
    whiteSpace: 'pre-line',
  },
  editIcon: {
    filter: 'brightness(0) invert(1)',
  },
}));
