import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dropdownBox: {
    '& > div': {
      paddingLeft: '6px',
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
}));
