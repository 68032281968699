import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
} from '@material-ui/core';

import IconCollapse from '../../assets/collapse.svg';
import IconExpand from '../../assets/expand.svg';

import { useStyles } from './styles';

export default function Section(props) {
  const {
    title,
    additionalActionComponent,
    children,
    highlighted,
    mainSection,
    inside,
    expanded,
    preview,
    areDataValidOnPreview,
    additionalClasses,
    error,
    warning,
  } = props;

  const classes = useStyles();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const mainSectionClass = mainSection ? 'mainSection' : '';
  const insideClass = inside ? 'inside' : '';
  const previewClass = preview
    ? `preview ${
        typeof areDataValidOnPreview === 'boolean'
          ? areDataValidOnPreview
            ? 'withoutErrors'
            : 'withErrors'
          : ''
      }`
    : '';
  const highlightedClass = highlighted ? 'highlighted' : '';
  const errorClass = error ? 'error' : '';
  const warningClass = warning ? 'warning': '';
  const sectionClasses = `${classes.root} ${mainSectionClass} ${insideClass} ${previewClass} ${highlightedClass} ${warningClass} ${errorClass} ${additionalClasses}`;

  const handleChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <>
      {expanded ? (
        <Accordion
          className={`${sectionClasses} ${classes.accordion}`}
          onChange={handleChange}
        >
          <AccordionSummary className={classes.accordionAction}>
            <Typography variant="h5" className={classes.title}>
              {title}

              {accordionExpanded ? (
                <div>
                  <img src={IconCollapse} alt="collapse" />
                  <span>Collapse</span>
                </div>
              ) : (
                <div>
                  <img src={IconExpand} alt="expand" />
                  <span>Expand</span>
                </div>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {children}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Paper className={`${sectionClasses}`}>
          <span className={`${classes.sectionHeader} ${classes.title} ${warningClass} ${errorClass}`}>
            {title && <Typography variant="h5">{title}</Typography>}
            {!!additionalActionComponent && (
              <span>{additionalActionComponent}</span>
            )}
          </span>
          {children}
        </Paper>
      )}
    </>
  );
}

Section.defaultProps = {
  expanded: false,
  mainSection: false,
  inside: false,
  preview: false,
  highlighted: false,
};

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additionalActionComponent: PropTypes.element,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  mainSection: PropTypes.bool,
  inside: PropTypes.bool,
  preview: PropTypes.bool,
  hasErrorsOnPreview: PropTypes.bool,
  highlighted: PropTypes.bool,
};
