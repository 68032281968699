import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, InputAdornment, TextField } from '@material-ui/core';
import { debounce, isEmpty } from 'lodash';

import { CurrencyTextField } from '../../components';
import { getPolicyPremium } from '../../store/reducers/policy/premium';
import { isNumber } from '../../support/helpers';

import { useStyles } from './styles';
import { useEffect } from 'react';

export const CauseOfActionPremium = ({
  scheme,
  policyStatus,
  causeOfAction,
  policyPremium,
  setPolicyPremium,
  setPolicyPremiumValidationStatus,
  setDirtyFields,
  onEventChange,
  onDataChange,
  hasError,
  hasErrorToDisplay,
  isBlockedForSolicitor,
  isCreate,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    premium,
    emptyPremium,
    isError,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.policy.premium);

  const fundedCurrentExclIPTValid = isNumber(
    policyPremium.fundedCurrentExclIPT
  );
  const deferredCurrentExclIPTValid = isNumber(
    policyPremium.deferredCurrentExclIPT
  );
  const totalCurrentInclIPTValid = isNumber(policyPremium.totalCurrentInclIPT);
  const iptValid = isNumber(policyPremium.ipt);

  const totalQuantumValid = isNumber(causeOfAction.totalQuantum) && !isError;

  const updatePremium = (currentCauseOfAction) => {
    dispatch(
      getPolicyPremium({
        schemeId: scheme.id,
        policyId: null,
        policyStatus,
        policyStage: currentCauseOfAction.policyStage,
        estimatedDamagesSpecial: currentCauseOfAction.estimatedDamagesSpecial,
        estimatedDamagesGeneral: currentCauseOfAction.estimatedDamagesGeneral,
      })
    );
  };

  const delayUpdatePremium = useCallback(
    debounce(
      (currentCauseOfAction) => updatePremium(currentCauseOfAction),
      300
    ),
    []
  );

  useEffect(() => {
    let totalQuantumToSave = '';
    if (
      !hasError('estimatedDamagesSpecial') &&
      !hasError('estimatedDamagesGeneral')
    ) {
      let totalWithoutPrecision =
        +causeOfAction.estimatedDamagesSpecial +
        +causeOfAction.estimatedDamagesGeneral;

      totalQuantumToSave = +totalWithoutPrecision.toFixed(2);
      if (totalQuantumToSave !== causeOfAction.totalQuantum) {
        onDataChange('totalQuantum')(totalQuantumToSave);
        delayUpdatePremium(causeOfAction);
      }
    } else {
      delayUpdatePremium.cancel();
      onDataChange('totalQuantum')(totalQuantumToSave);
      setPolicyPremium(emptyPremium);
    }
  }, [
    causeOfAction.estimatedDamagesGeneral,
    causeOfAction.estimatedDamagesSpecial,
  ]);

  useEffect(() => {
    if (!isEmpty(premium) && !(isBlockedForSolicitor || !isCreate)) {
      setPolicyPremium(premium);
    }
  }, [premium]);

  useEffect(() => {
    const validate = () =>
      fundedCurrentExclIPTValid &&
      deferredCurrentExclIPTValid &&
      totalCurrentInclIPTValid &&
      iptValid &&
      totalQuantumValid;

    setPolicyPremiumValidationStatus(validate());
  }, [policyPremium, causeOfAction.totalQuantum]);

  const getTotalQuantumMessage = () => {
    if (causeOfAction.totalQuantum) {
      if (isError) {
        return wasRecognizedAsOutsideQddl
          ? 'Total quantum must be within QDDL values.'
          : 'Error appeared during downloading premium data.';
      }
    }
  };

  return (
    <>
      <FormGroup className={classes.fourOfInputs}>
        <CurrencyTextField
          name="estimatedDamagesSpecial"
          label="Estimated Damages - Special"
          value={causeOfAction.estimatedDamagesSpecial}
          onChange={onEventChange}
          required={true}
          error={hasErrorToDisplay('estimatedDamagesSpecial')}
          onBlur={setDirtyFields}
          isReadOnly={isBlockedForSolicitor || !isCreate}
          decimalScale={0}
        />
        <CurrencyTextField
          name="estimatedDamagesGeneral"
          label="Estimated Damages - General"
          value={causeOfAction.estimatedDamagesGeneral}
          onChange={onEventChange}
          required={true}
          error={hasErrorToDisplay('estimatedDamagesGeneral')}
          onBlur={setDirtyFields}
          isReadOnly={isBlockedForSolicitor || !isCreate}
          decimalScale={0}
        />
        <CurrencyTextField
          name="totalQuantum"
          label="Total Quantum"
          value={causeOfAction.totalQuantum}
          required={true}
          error={!!getTotalQuantumMessage()}
          helperText={getTotalQuantumMessage()}
          isReadOnly={true}
        />
        <div />
      </FormGroup>
      <FormGroup className={classes.fourOfInputs}>
        <CurrencyTextField
          name="fundedCurrentExclIPT"
          label="Funded Current Premium excl. IPT"
          value={policyPremium.fundedCurrentExclIPT}
          required={true}
          isReadOnly={true}
        />
        <CurrencyTextField
          name="deferredCurrentExclIPT"
          label="Deferred Current Premium excl. IPT"
          value={policyPremium.deferredCurrentExclIPT}
          required={true}
          isReadOnly={true}
        />
        <TextField
          name="ipt"
          label="IPT"
          type="number"
          value={policyPremium.ipt}
          required={true}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            readOnly: true,
          }}
          variant="filled"
        />
        <CurrencyTextField
          name="TotalCurrentInclIPT"
          label="Total Current Premium incl. IPT"
          value={Number(policyPremium.totalCurrentInclIPT)?.toFixed(2)}
          required={true}
          isReadOnly={true}
        />
      </FormGroup>
    </>
  );
};
