import React from 'react';

import { formattedMoneyWithoutPound } from '../../support/helpers';
import IconGbp from '../../assets/images/gbp-icon.svg';

import { useStyles } from './styles';

export default function ClaimLimit(props) {
  const classes = useStyles();
  const { claimLimit } = props;

  return (
    <span className={classes.moneyContainer}>
      <span className={classes.money}>
        <img src={IconGbp} alt="GBP" />
        {formattedMoneyWithoutPound(claimLimit)}
      </span>
    </span>
  );
};
