import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  movementGridHeaderElement: {
    padding: '22px 34px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
  movementGridElement: {
    padding: '10px 34px',
    whiteSpace: 'pre-line',
  },
  movementRow: {
    alignItems: 'center',
    '& + &': {
      borderTop: `1px solid ${colors.greyE6}`,
    },
  },
  textButton: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: '5px',
    },
  },
  gridItemTitleWithSubtitle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  gridItemTitleWithSubtitleMain: {
    flex: '0 0 100%',
  },
  modal: {
    width: 550,
  },
  normalText: {
    fontWeight: 'normal',
  },
  formControl: {
    width: '100%',
  },
  iconColor: {
    color: colors.green,
  },
  additionalActionsColumn: {
    minWidth: '170px',
  },
  topWarning: {
    margin: '10px 0',
  },
  movementNameWarningIcon: {
    '&.italic': {
      fontStyle: 'italic',
    },
    '& > span': {
      display: 'inline',
      marginLeft: '-9px',
      position: 'relative',
      top: '-1px',
    },
  },
  textarea: {
    width: '100%',
  },
}));
