import { createSlice } from '@reduxjs/toolkit';

export const loadingWithScreenBlockSlice = createSlice({
  name: 'loadingWithScreenBlock',
  initialState: {
    isDisplayed: false,
  },
  reducers: {
    showLoader: (state) => {
      state.isDisplayed = true;
    },
    hideLoader: (state) => {
      state.isDisplayed = false;
    },
  },
});

export const { showLoader, hideLoader } = loadingWithScreenBlockSlice.actions;

export default loadingWithScreenBlockSlice.reducer;
