import React from 'react';
import { find } from 'lodash';

import { movementTypes } from '../../support/constants';
import { Modal } from '../../components';
import TheirPart36Details from './TheirPart36Details';
import ProceedingsRequestDetails from './ProceedingsRequestDetails';
import SettlementNotificationDetails from './SettlementNotificationDetails';
import TrialRequestDetails from './TrialRequestDetails';
import TrialDocumentationDetails from './TrialDocumentationDetails';
import RestrictionWithdrawalRequestDetails from './RestrictionWithdrawalRequestDetails';

import { useStyles } from './styles';

export default function MovementDetailsModal({
    viewMovementDetailsModal,
    setViewMovementDetailsModal,
    movement
}) {
    const classes = useStyles();

    const getMovementDisplayName = (movement) => {
      return find(movementTypes, {value: movement.type}).displayName || 'Unknown';
    };

    return (
        <Modal
          onClose={() => setViewMovementDetailsModal(false)}
          open={viewMovementDetailsModal}
          titleText={getMovementDisplayName(movement)}
          showActionButtons={false}
          PaperProps={{ className: classes.modal }}
        >
          <div>
            {(() => {
                if (movement.type == movementTypes.TheirPart36.value) {
                  return (
                      <TheirPart36Details movement={movement} />
                  );
                } else if (movement.type == movementTypes.ProceedingsRequest.value){
                  return (
                    <ProceedingsRequestDetails movement={movement} />
                  );
                } else if (movement.type == movementTypes.SettlementNotification.value){
                  return (
                    <SettlementNotificationDetails movement={movement} />
                  );
                } else if (movement.type == movementTypes.TrialRequest.value){
                  return (
                    <TrialRequestDetails movement={movement} />
                  );
                } else if (movement.type == movementTypes.TrialDocumentation.value){
                  return (
                    <TrialDocumentationDetails movement={movement} />
                  );
                } else if (movement.type == movementTypes.RestrictionWithdrawalRequest.value){
                  return (
                    <RestrictionWithdrawalRequestDetails movement={movement} />
                  );
                }
                else {
                  return (
                      <div>Movement not supported.</div>
                  );
                }
            })()}
          </div>
        </Modal>
      );
}