import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { Error, Warning } from '../../components';
import { schemeCriteriaPosibleAnswers } from '../../support/constants';

import { useStyles } from './styles';

export default function SchemeCriteria(props) {
  const classes = useStyles();
  const {
    criteria,
    getAnswer,
    showQuestionName,
    showWarningAboutPending,
    showWarningAboutNo,
  } = props;

  return (
    <>
      <Grid container className={classes.criteriaHeading}>
        <Grid item className={classes.numberColumn}>
          <Typography variant="overline">No.</Typography>
        </Grid>
        <Grid item lg={10}>
          <Typography variant="overline">Question</Typography>
        </Grid>
        <Grid item lg={1}>
          <Typography variant="overline">
            {getAnswer ? 'Answer' : 'Answers'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        {criteria
          ?.slice()
          .sort((a, b) => a.order - b.order)
          .map((criterion, index) => {
            return (
              <Grid container key={index} className={classes.answerRow}>
                <Grid item className={classes.numberColumn}>
                  <strong>{index + 1}.</strong>
                </Grid>
                <Grid item lg={10}>
                  {showQuestionName && criterion.name && (
                    <strong>{criterion.name}</strong>
                  )}
                  {criterion.question}

                  {showWarningAboutPending &&
                    showWarningAboutPending(criterion) && (
                      <Warning>
                        Any “Pending” information must be supplied before the
                        issue of proceedings or a claim will be allowed
                      </Warning>
                    )}
                  {showWarningAboutNo && showWarningAboutNo(criterion) && (
                    <Error>
                      Policy was moved to Unwind status because of “No” answer
                    </Error>
                  )}
                </Grid>
                <Grid item lg={1}>
                  {getAnswer
                    ? getAnswer(criterion)
                    : schemeCriteriaPosibleAnswers[criterion.answerType]}
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

SchemeCriteria.defaultProps = {
  showQuestionName: true,
};

SchemeCriteria.propTypes = {
  criteria: PropTypes.array,
  getAnswer: PropTypes.func,
  showWarningAboutPending: PropTypes.func,
  showWarningAboutNo: PropTypes.func,
  showQuestionName: PropTypes.bool,
};
