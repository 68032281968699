import React from 'react';

import { Modal } from '../../components';

import { useStyles } from './styles';

export default function SaveAsLiveConfirmationModal({
  openModal,
  setOpenModal,
  handleSaveAsLive,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      onClose={handleClose}
      onConfirm={handleSaveAsLive}
      open={openModal}
      titleText="Scheme Confirmation"
      showActionButtons={true}
      isReadyToConfirm={true}
      PaperProps={{ className: classes.modal }}
    >
      Before clicking “Confirm”, make sure all the information is correct. After
      confirmation, a Scheme with 'Live' status will be created and no further
      modifications will be allowed.
    </Modal>
  );
}
