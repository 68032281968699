import React from 'react';

import Modal from '../Modal/Modal';
import IconApprove from '../../assets/icon_approve.svg';

import { useStyles } from './styles';

export const MovementCreatedPopup = ({ titleText, content, modalOpen, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal
      titleText={titleText || `Movement confirmed`}
      open={modalOpen}
      onClose={handleClose}
    >
      <div className={classes.modalContainer}>
        <div>
          <img src={IconApprove} alt="approve" />
        </div>
        <div className={classes.modalText}>
          {content}
        </div>
      </div>
    </Modal>
  );
};
