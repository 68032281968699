import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { Paper, TextField, Typography, Grid, Button, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import IconView from '../../assets/view.svg';
import MovementDetailsModal from '../MovementDetails/MovementDetailsModal';
import { checkDateInRange, defaultIfEmpty, getCurrentDate } from '../../support/helpers';
import { Modal, Warning } from '../../components';

import { areThereAnyMissingDocumensForTrialRequest } from '../../support/movementHelpers';

import { useStyles } from './styles';
import {
  getFormattedDate,
  includesAny,
  camelCaseToSentence,
  getFormattedMoney,
} from '../../support/helpers';
import {
  userRoles,
  movementStatuses,
  movementTypes,
  unwindReasons,
  disbursementTypes,
  claimTypes,
  premiumTypes,
  paymentTypes,
  claimNotificationReasons,
  getConstByValue,
  eventNotificationTypes,
  auditActions,
} from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';
import {
  closeMovement,
  payMovement,
} from '../../store/reducers/policy/details';
import { requestMoreInfo } from '../../store/reducers/movement/requestMoreInfo';
import RejectionReasonModal from './RejectionReasonModal';
import ApprovalConditionModal from './ApprovalConditionModal';
import ApproveEventNotificationWithEffect from './ApproveEventNotificationWithEffect';

export default function MovementsList({ movements, policy, setOpenMovementCreationWindow }) {
  const classes = useStyles();
  const userGroups = useSelector(userGroupsSelector);
  const [ viewMovementDetailsModal, setViewMovementDetailsModal ] = useState(false);
  const [ movementDetailsToDisplay, setMovementDetailsToDisplay ] = useState({});
  const [ displayRejectionReasonModal, setDisplayRejectionReasonModal] = useState(false);
  const [ displayApprovalConditionsModal, setDisplayApprovalConditionsModal] = useState(false);
  const [ movementToHandle, setMovementToHandle] = useState(null);
  const [ rejectionData, setRejectionData] = useState(null);
  const [ approvalData, setApprovalData] = useState(null);
  const [ showApproveEventNotificationWithEffectModal, setShowApproveEventNotificationWithEffectModal] = useState(false);
  const [ showRequestMoreDetailsModal, setShowRequestMoreDetailsModal] = useState({openModal: false, movementId: null});
  const [ requestMoreDetailsMessage, setRequestMoreDetailsMessage] = useState('');
  const { isActionOnMovementInProgress } = useSelector(
    (state) => state.policy.details
  );

  const dispatch = useDispatch();

  const getMovementDisplayName = (types, movement) => {
    return _.find(types, {value: movement.type}).displayName || 'Unknown';
  };

  const getMovementTypeColumn = (movement) => {
    switch (movement.type) {
      case movementTypes.Claim.value:
        return (
          <span className={classes.gridItemTitleWithSubtitleMain}>
            {getMovementDisplayName(movementTypes, movement)}-{claimTypes[movement.claimType]}-
            {disbursementTypes[movement.disbursementType]}
          </span>
        );
      case movementTypes.Premium.value:
        return (
          <span className={classes.gridItemTitleWithSubtitleMain}>
            {getMovementDisplayName(movementTypes, movement)}-
            {getConstByValue(premiumTypes, movement.premiumType).displayName}-
            {paymentTypes[movement.paymentType]}
          </span>
        );
      case movementTypes.EventNotification.value:
        return (
          <span className={classes.gridItemTitleWithSubtitleMain}>
            {getMovementDisplayName(movementTypes, movement)}-{getConstByValue(eventNotificationTypes, movement.eventNotificationType).displayName}
          </span>
        );
      case movementTypes.TrialRequest.value:
        return <span className={`${classes.movementNameWarningIcon} ${shouldAllowUploadAdditionalDocuments(movement) ? 'italic' : ''}`}>
            {shouldAllowUploadAdditionalDocuments(movement) ? <Warning/> : ''}{getMovementDisplayName(movementTypes, movement)}
          </span>;
      case movementTypes.Audit.value:
        return (
          <span className={classes.gridItemTitleWithSubtitleMain}>
            {getMovementDisplayName(movementTypes, movement)}-{getConstByValue(auditActions, movement.action).displayName}
          </span>
        );
      default:
        return <span>{getMovementDisplayName(movementTypes, movement)}</span>;
    }
  };

  const getActionColumn = (movement) => {
    const isMovementNew = movement.status === 1;
    switch (movement.type) {
      case movementTypes.TheirPart36.value:
      case movementTypes.UnwindRequest.value:
      case movementTypes.ProceedingsRequest.value:
      case movementTypes.TrialRequest.value:
      case movementTypes.RestrictionWithdrawalRequest.value:
        return getPanelForClosableMovement(movement, isMovementNew);
      case movementTypes.Claim.value:
        /// Claim Payment
        if (movement.claimType === 2) {
          return getPanelForClosableMovement(movement, isMovementNew);
        } else {
          return '-';
        }
      case movementTypes.Premium.value:
        if (isMovementNew) {
          if (includesAny(userGroups, [userRoles.resolve])) {
            return getPaymentActionPanel(movement);
          } else {
            return 'Waiting for Payment.';
          }
        } else {
          return getPaymentInfo(movement);
        }
      case movementTypes.EventNotification.value:
        if ([
          eventNotificationTypes.failureToComplyWithPolicyTermsAndConditions.value,
          eventNotificationTypes.failureToAcceptSolicitorAdvice.value,
          eventNotificationTypes.nowPartOfMultipartyAction.value,
          eventNotificationTypes.caseLostNoClaimOnPolicy.value,
        ].includes(movement.eventNotificationType)){
          getPanelForClosableMovement(movement, isMovementNew);
        }
        else {
          return '-';
        }
        return getPanelForClosableMovement(movement, isMovementNew);
      default:
        return '-';
    }
  };

  const getPaymentActionPanel = (movement) => (
    <div>
      <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        disabled={isActionOnMovementInProgress}
        onClick={() => {
          dispatch(payMovement(policy.id, movement.id));
        }}
      >
        <CheckCircleOutlineIcon /> Mark as Paid
      </Button>
    </div>
  );

  const getPaymentInfo = (movement) => (
    <>
      <p>Paid: {getFormattedDate(movement.closedDate)}</p>
      <p>
        {movement.closedBy?.name} {movement.closedBy?.surname}
      </p>
    </>
  );

  const getPanelForClosableMovement = (movement, isMovementNew) => {
    if (isMovementNew) {
      if (includesAny(userGroups, [userRoles.resolve])) {
        return getPanelToCloseMovement(movement);
      } else {
        return 'Waiting for Resolve action.';
      }
    } else {
      return getMovementClosedInfo(movement);
    }
  };

  const isMovementRejectableWithReason = (movement) => {
    if ((movement.type === movementTypes.EventNotification.value &&
      (movement.eventNotificationType === eventNotificationTypes.failureToComplyWithPolicyTermsAndConditions.value ||
        movement.eventNotificationType === eventNotificationTypes.failureToAcceptSolicitorAdvice.value || 
        movement.eventNotificationType === eventNotificationTypes.nowPartOfMultipartyAction.value))
    || movement.type == movementTypes.RestrictionWithdrawalRequest.value
    || movement.type == movementTypes.ProceedingsRequest.value
    || movement.type == movementTypes.TheirPart36.value
    || movement.type == movementTypes.TrialRequest.value
    || movement.type == movementTypes.UnwindRequest.value) {
      return true;
    }

    return false;
  }

  const handleRejectButton = (movement) => {
    if (isMovementRejectableWithReason(movement)){
          setMovementToHandle(movement);
          setRejectionData(null);
          setDisplayRejectionReasonModal(true);
        }
    else {
      rejectMovement(movement);
    }
  };

  const rejectMovement = (movement) => {
    dispatch(closeMovement(policy.id, movement.id, rejectionData, 'reject'));
  };

  const isApprovableEventNotificationWithCancelationEffect = (movement) => 
    movement.type === movementTypes.EventNotification.value &&
      (movement.eventNotificationType === eventNotificationTypes.failureToComplyWithPolicyTermsAndConditions.value ||
      movement.eventNotificationType === eventNotificationTypes.failureToAcceptSolicitorAdvice.value || 
      movement.eventNotificationType === eventNotificationTypes.nowPartOfMultipartyAction.value);

  const isPolicyYoungerThan14Days = () => {
    var policyInceptionDate = moment(policy.causeOfAction.policyInceptionDate);
    var maxSimpleCancellationDate = moment(policy.causeOfAction.policyInceptionDate).add(14, 'd');
    return checkDateInRange(policyInceptionDate, maxSimpleCancellationDate, getCurrentDate());
  }

  const handleApproveButton = (movement) => {
    dispatch(closeMovement(policy.id, movement.id, approvalData, 'approve'));
  }

  const canApproveWithConditions = (movement) => {
    return movement.type === movementTypes.TrialRequest.value;
  }

  const canApproveEventNotificationWithEffect = (movement) => {
    return isApprovableEventNotificationWithCancelationEffect(movement) && !isPolicyYoungerThan14Days()
  }

  const handleApproveWithConditionsButton = (movement) => {
    setMovementToHandle(movement);
    setApprovalData(null);
    setDisplayApprovalConditionsModal(true);
  };

  const handleApproveEventNotificationWithEffectButton = (movement) => {
    setMovementToHandle(movement);
    setApprovalData(null);
    setShowApproveEventNotificationWithEffectModal(true);
  };

  const canRequestMoreDetails = (movement) => {
    return movement.type === movementTypes.TrialRequest.value;
  }

  const handleRequestMoreDetails = (movement) => {
    setShowRequestMoreDetailsModal({openModal: true, movementId: movement.id});
  }

  const getPanelToCloseMovement = (movement) => (
    <div>
      {canApproveWithConditions(movement) && <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        disabled={isActionOnMovementInProgress}
        onClick={() => handleApproveWithConditionsButton(movement)}
      >
        <CheckCircleOutlineIcon /> Approve
      </Button>}

      {canApproveEventNotificationWithEffect(movement) && <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        disabled={isActionOnMovementInProgress}
        onClick={() => handleApproveEventNotificationWithEffectButton(movement)}
      >
        <CheckCircleOutlineIcon /> Approve
      </Button>}

      <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        disabled={isActionOnMovementInProgress}
        onClick={() => handleRejectButton(movement)}
      >
        <CancelOutlinedIcon /> Reject
      </Button>{' '}

      {!canApproveWithConditions(movement) && !canApproveEventNotificationWithEffect(movement) && <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        disabled={isActionOnMovementInProgress}
        onClick={() => handleApproveButton(movement)}
      >
        <CheckCircleOutlineIcon /> Approve
      </Button>}

      {canRequestMoreDetails(movement) && <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        disabled={isActionOnMovementInProgress}
        onClick={() => handleRequestMoreDetails(movement)}
      >
        <SendOutlinedIcon /> Request more details  
      </Button>}
    </div>
  );

  const getMovementClosedInfo = (movement) => (
    <>
      <p>
        {getConstByValue(movementStatuses, movement.status)?.displayName}:{' '}
        {getFormattedDate(movement.closedDate)}
      </p>
      <p>
        {movement.closedBy?.name} {movement.closedBy?.surname}
      </p>
    </>
  );

  // TODO: refactore getDetailsDescription - prepare separate getDescription method for each movement
  const getMovementDescription = (movement) =>
  {
    switch (movement.type){
      case movementTypes.Audit.value:
        return getAuditDescription(movement);
      default:
        return '';
    }
  }

  const getAuditDescription = (movement) => {
    return `${defaultIfEmpty(movement.comment, '')}`;
  }

  const getDetailsDescription = (movement) => {
    var movementDescription = getMovementDescription(movement);
    var additionalInfo = getEventNotificationAdditionalInfo(movement);
    var movementReason = getMovementReason(movement);
    var comment = movement.comment || '';

    if (movementDescription) {
      return movementDescription;
    }

    return `${additionalInfo}${additionalInfo && movementReason ? ' • ' : ''}${movementReason}${(additionalInfo || movementReason) && comment ? ' • ' : ''}${comment}${!additionalInfo && !movementReason && !comment ? '-': ''}`;
  };

  const getMovementReason = (movement) => {
    var reason = null;

    if (movement.type === movementTypes.UnwindRequest.value) {
      reason = getConstByValue(unwindReasons, movement.reason).displayName;
    }

    if (movement.type === movementTypes.ClaimNotification.value) {
      reason = camelCaseToSentence(claimNotificationReasons[movement.reason]);
    }

    if (movement.type === movementTypes.EventNotification.value && !!movement.reason) {
      reason = movement.reason;
    }

    return reason ? `Reason: ${reason}` : '';
  };

  const getEventNotificationAdditionalInfo = (movement) => {
    if (movement.type !== movementTypes.EventNotification.value){
      return '';
    }

    if ([
        eventNotificationTypes.liabilityAdmitted.value,
        eventNotificationTypes.liabilityWithdrawn.value,
        eventNotificationTypes.liabilityDenied.value,
        eventNotificationTypes.letterOfClaimSent.value,
        eventNotificationTypes.responseToLetterOfClaimReceived.value,
      ].includes(movement.eventNotificationType)) {
        return `${getFormattedDate(movement.date)}`;
      }

    return '';
  }

  const getMovementValue = (movement) => {
    if (movement.type === movementTypes.QuantumChange.value) {
      return getFormattedMoney(movement.quantumSpecial + movement.quantumGeneral);
    }

    return getFormattedMoney(movement.value);
  }

  const openMovementDetails = (movement) => {
    setViewMovementDetailsModal(true);
    setMovementDetailsToDisplay(movement);
  };

  const shouldShowLinkForMovementDetails = (movement) => {
    return movement.type == movementTypes.TheirPart36.value 
      || movement.type == movementTypes.ProceedingsRequest.value
      || movement.type == movementTypes.SettlementNotification.value
      || movement.type == movementTypes.TrialRequest.value
      || movement.type == movementTypes.TrialDocumentation.value
      || movement.type == movementTypes.RestrictionWithdrawalRequest.value
  };

  const shouldAllowUploadAdditionalDocuments = (movement) => {
    return movement.type == movementTypes.TrialRequest.value && (!movement.supportiveCounselsAdviceAttachments.length || !movement.propertyReSurveyedByHeadSurveyorAttachments.length);
  };

  const uploadAdditionalFiles = (movement) =>{
    setOpenMovementCreationWindow( {
      openWindow: true,
      movementType: movementTypes.TrialDocumentation.value,
    });
  };

  const sendMessageToCaseHandler = (message) => {
    dispatch(requestMoreInfo(showRequestMoreDetailsModal.movementId, requestMoreDetailsMessage));
    setShowRequestMoreDetailsModal({openModal: false, movementId: null});
    setRequestMoreDetailsMessage('');
  }

  return (
    <>
      {areThereAnyMissingDocumensForTrialRequest(movements) && <Alert severity="warning" className={classes.topWarning}>
        The policy contains trial request, but some of the documents are missing.
      </Alert>}
      <Paper>
        <Grid container>
          <Grid item xs={2} className={classes.movementGridHeaderElement}>
            <Typography variant="overline">Movement Type</Typography>
          </Grid>
          <Grid item xs={1} className={classes.movementGridHeaderElement}>
            <Typography variant="overline">Value</Typography>
          </Grid>
          <Grid item xs={2} className={classes.movementGridHeaderElement}>
            <Typography variant="overline">Created</Typography>
          </Grid>
          <Grid item xs={4} className={classes.movementGridHeaderElement}>
            <Typography variant="overline">Details Description</Typography>
          </Grid>
          <Grid item xs={2} className={classes.movementGridHeaderElement}>
            <Typography variant="overline">Action</Typography>
          </Grid>
          <Grid item xs={1} className={classes.movementGridHeaderElement}>
          </Grid>
          {!movements?.length && (
            <Grid item xs={12} className={classes.movementGridElement}>
              No movements defined for policy.
            </Grid>
          )}
        </Grid>

        {movements?.map((movement, index) => {
          return (
            <Grid container key={index} className={classes.movementRow}>
              <Grid item xs={2} className={classes.movementGridElement}>
                <Typography
                  variant="overline"
                  className={classes.gridItemTitleWithSubtitle}
                >
                  {getMovementTypeColumn(movement)}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.movementGridElement}>
                {getMovementValue(movement)}
              </Grid>
              <Grid item xs={2} className={classes.movementGridElement}>
                <p>
                  {movement.createdBy.name} {movement.createdBy.surname}
                </p>
                <p>{getFormattedDate(movement.createdDate)}</p>
              </Grid>
              <Grid item xs className={classes.movementGridElement}>
                {getDetailsDescription(movement)}
              </Grid>
              <Grid item xs={2} className={classes.movementGridElement}>
                {getActionColumn(movement)}
              </Grid>
              <Grid item xs={1} className={`${classes.movementGridElement} ${classes.additionalActionsColumn}`}>
                {shouldShowLinkForMovementDetails(movement) && 
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => openMovementDetails(movement)}
                >
                  <img src={IconView} alt="View" />
                </IconButton>
                }
                {shouldAllowUploadAdditionalDocuments(movement) &&
                  <IconButton
                    className={classes.iconColor}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => uploadAdditionalFiles(movement)}
                  >
                    <CloudUploadOutlinedIcon/>
                  </IconButton>
                }
              </Grid>
            </Grid>
          );
        })}
      </Paper>

      {viewMovementDetailsModal && (
        <MovementDetailsModal
          viewMovementDetailsModal={viewMovementDetailsModal}
          setViewMovementDetailsModal={setViewMovementDetailsModal}
          movement={movementDetailsToDisplay}
        />
      )}

      {displayRejectionReasonModal && (
        <RejectionReasonModal
          openModal={displayRejectionReasonModal}
          setOpenModal={setDisplayRejectionReasonModal}
          setRejectionData={setRejectionData}
          onConfirm={() => rejectMovement(movementToHandle)} />
      )}

      {displayApprovalConditionsModal && (
        <ApprovalConditionModal
          openModal={displayApprovalConditionsModal}
          setOpenModal={setDisplayApprovalConditionsModal}
          setApprovalData={setApprovalData}
          onConfirm={() => handleApproveButton(movementToHandle)} />
      )}

      {showApproveEventNotificationWithEffectModal && (
        <ApproveEventNotificationWithEffect
          movements={movements}
          openModal={showApproveEventNotificationWithEffectModal}
          setOpenModal={setShowApproveEventNotificationWithEffectModal}
          setApprovalData={setApprovalData}
          onConfirm={() => handleApproveButton(movementToHandle)} />
      )}

      {showRequestMoreDetailsModal.openModal && (
        <Modal
          open={showRequestMoreDetailsModal.openModal}
          onClose={() => setShowRequestMoreDetailsModal({openModal: false, movementId: showRequestMoreDetailsModal.movementId })}
          onConfirm={() => sendMessageToCaseHandler(requestMoreDetailsMessage)}
          showActionButtons={true}
          titleText="Request more details"
          isReadyToConfirm={!!requestMoreDetailsMessage}
        >
            <Typography variant="subtitle2" className={`${classes.normalText} reset-mb`}>
              The following message will be sent to the case handler who created the trial request.
            </Typography>
            <TextField
              name="requestMoreDetailsMessage"
              label="Message"
              onChange={(e) => setRequestMoreDetailsMessage(e.target.value)}
              variant="outlined"
              required={true}
              multiline={true}
              className={classes.textarea}
              rows={4}
            />
        </Modal>
      )}
    </>
  );
}
