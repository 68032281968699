import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { Breadcrumbs } from '../../components';
import { StatusBadge } from '../../components';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import { userGroupsSelector } from '../../store/selectors/global';
import {
  fetchPolicyById,
  fetchMovements,
  cleanReducer,
} from '../../store/reducers/policy/details';

import { cleanReducer as cleanStatusChangeReducer } from '../../store/reducers/policy/statusChange';

import {
  restrictedData,
  includesAny,
  transformObject,
} from '../../support/helpers';

import {
  userRoles,
  policyStatuses,
  policyStage,
} from '../../support/constants';

import PolicyStatus from './PolicyStatus';
import PolicyDetailsHeader from './PolicyDetailsHeader';
import PolicyDetailsTabs from './PolicyDetailsTabs';
import { ConfirmationModal } from '../../components/PolicyWizard/ConfirmationModal';
import { useStyles } from './policyDetailsHeaderStyles';

export default function PolicyDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { policy, isLoading } = useSelector((state) => state.policy.details);
  const { status } = useSelector((state) => state.policy.status);
  const userGroups = useSelector(userGroupsSelector);
  const { policyId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [policyStatusesWithIdKey] = useState(
    transformObject(policyStatuses, (obj) => obj.value)
  );

  useEffect(() => {
    dispatch(fetchPolicyById(policyId)).then(() => {
      dispatch(fetchMovements(policyId));
    });
  }, [dispatch, policyId]);

  const handleOpen = (isCreate) => {
    setIsCreate(isCreate);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (location.state?.fresh) {
      handleOpen(true);
    }

    if (location.state?.updated) {
      handleOpen(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(cleanReducer());
      dispatch(cleanStatusChangeReducer());
    };
  }, []);

  const restrictedForInsurer = (element) => {
    return restrictedData(userGroups, userRoles.insurer, element);
  };

  return (
    <div>
      <LoadingWrapper loading={isLoading}>
        {!isLoading && policy && (
          <>
            <Breadcrumbs current={`Policy ${policy.number}`}>
              <Link to={'/policies'}>
                <FileCopyOutlinedIcon fontSize="small" />
              </Link>
            </Breadcrumbs>

            <div className={classes.toolbar}>
              <Typography variant="h3" className={classes.title}>
                Policy {policy.number}
              </Typography>
              <StatusBadge status={policy.stage} statusType={policyStage} />
              <span className={classes.badgeSeparator} />
              <StatusBadge
                status={policy.status}
                statusType={policyStatusesWithIdKey}
              />
              {includesAny(userGroups, [userRoles.resolve]) ? (
                <PolicyStatus
                  status={policy.status}
                  policyId={policyId}
                  policy={policy}
                />
              ) : null}
            </div>

            <PolicyDetailsHeader
              policy={policy}
              restrictedForInsurer={restrictedForInsurer}
            />
            <PolicyDetailsTabs
              policy={policy}
              restrictedForInsurer={restrictedForInsurer}
            />
          </>
        )}
      </LoadingWrapper>
      <ConfirmationModal
        handleClose={handleClose}
        modalOpen={modalOpen}
        isCreate={isCreate}
      />
    </div>
  );
}
