export const getValueByRole = (
  scheme,
  role,
  key,
  index,
  formatFunc = (value) => value
) => {
  return index !== undefined
    ? formatFunc(scheme?.[role]?.[index]?.[key])
    : formatFunc(scheme?.[role]?.[key]) || '-';
};
