import React, { useEffect } from 'react';
import { Grid, Typography} from '@material-ui/core';
import { AttachmentsListExpandable, ListItem } from '../../components';
import { useStyles } from './styles';
import {defaultIfEmpty,} from '../../support/helpers';

export default function RestrictionWithdrawalRequestDetails({movement}) {

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2">
          Attached supporting evidence
        </Typography>
        <AttachmentsListExpandable attachments={movement.restrictionWithdrawalRequestAttachments} showHeading={false} />
      </Grid>
      <hr/>
      <Grid item lg={12}>
        <ListItem
          primary="Comment"
          secondary={defaultIfEmpty(movement.comment)}
        />
      </Grid>
    </Grid>
  );
};