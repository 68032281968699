import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { Breadcrumbs } from '../../components';
import { StatusBadge } from '../../components';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import { userGroupsSelector } from '../../store/selectors/global';
import {
  fetchSchemeById,
  cleanReducer,
} from '../../store/reducers/scheme/details';
import { restrictedData } from '../../support/helpers';
import { userRoles, schemeStatus } from '../../support/constants';

import SchemeDetailsHeader from './SchemeDetailsHeader';
import SchemeDetailsBasic from './SchemeDetailsBasic';
import { useStyles } from './schemeDetailsHeaderStyles';
import { SchemeDetailsTabs } from './SchemeDetailsTabs';

import schemesIcon from '../../assets/images/schemes.svg';

export default function SchemeDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { scheme, isLoading } = useSelector((state) => state.scheme.details);
  const userGroups = useSelector(userGroupsSelector);
  const { schemeId } = useParams();

  useEffect(() => {
    dispatch(fetchSchemeById(schemeId));
  }, [dispatch, schemeId]);

  useEffect(() => {
    return () => {
      dispatch(cleanReducer());
    };
  }, []);

  const restrictedForInsurer = (element) => {
    return restrictedData(userGroups, userRoles.insurer, element);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      {!isLoading && scheme && (
        <>
          <Breadcrumbs current={`Scheme ${scheme.codePrefix} ${scheme.codeSuffix}`}>
            <Link to={'/'}>
              <img src={schemesIcon} alt="Schemes" />
            </Link>
          </Breadcrumbs>

          <div className={classes.toolbar}>
            <Typography variant="h3" className={classes.title}>
              Scheme {scheme.codePrefix} {scheme.codeSuffix}
            </Typography>
            <StatusBadge status={scheme.status} statusType={schemeStatus} />
          </div>

          <SchemeDetailsHeader scheme={scheme} />
          <SchemeDetailsBasic
            scheme={scheme}
            restrictedForInsurer={restrictedForInsurer}
          />
          <SchemeDetailsTabs
            scheme={scheme}
            restrictedForInsurer={restrictedForInsurer}
          />
        </>
      )}
    </LoadingWrapper>
  );
}
