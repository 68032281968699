import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Modal } from '../../components';
import { useStyles } from './styles';

export default function RejectionReasonModal({
  openModal,
  setOpenModal,
  setRejectionData,
  onConfirm
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
    <Modal
      onClose={handleClose}
      onConfirm={onConfirm}
      open={openModal}
      titleText="Rejection reason"
      showActionButtons={true}
      isReadyToConfirm={true}
      PaperProps={{ className: classes.modal }}
    >
      <div>
        <Typography variant="subtitle2" className={`${classes.normalText} reset-mb`}>
          Please provide the reason of rejection. A notification with the following reason will be send to the case handler.
        </Typography>
        
        <TextField
          name="reason"
          label="Reason"
          className={classes.formControl}
          onChange={(event) => setRejectionData({reason: event.target.value})}
          variant="outlined"
          multiline={true}
          rows={3}
        />
      </div>
    </Modal>
    </>
  );
}
