import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const gridElement = {
  padding: '5px 10px',
  border: `1px solid ${colors.greyE6}`,
};

export const useStyles = makeStyles(() => ({
  gridElement: gridElement,
  totalGridElement: {
    ...gridElement,
    backgroundColor: colors.greyF5,
  },
  headerGridElement: {
    ...gridElement,
    backgroundColor: colors.lightGreen,
  },
}));
