import React, { useState, memo } from 'react';
import { FormGroup, InputAdornment, TextField } from '@material-ui/core';

import {
  AttachmentsEdit,
  CurrencyTextField,
  CompanyFieldsEdit,
  MultiplyAction,
  Section,
} from '../../../components';
import { uniqueId as getUniqueId } from '../../../support/helpers';
import { useStyles } from '../styles';

const Reinsurer = memo(function Component(props) {
  const {
    reinsurer,
    reinsurersCount,
    onChange,
    index,
    validator,
    deleteReinsurer,
    addEmptyReinsurer,
    addDirtyField,
  } = props;

  const classes = useStyles();
  const [uniqueId] = useState(getUniqueId());

  const onReinsurerPartChange = (newData) => {
    onChange({ ...reinsurer, ...newData }, index);
  };

  const onEventChange = (event) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );
    onChange(
      {
        ...reinsurer,
        [propertyName]: event.target.value,
      },
      index
    );
  };

  const addAttachment = (attachment) => {
    const attachments = [...(reinsurer.attachments ?? []), attachment];
    onChange(
      {
        ...reinsurer,
        attachments: attachments,
      },
      index
    );
  };

  const deleteAttachment = (attachmentIndex) => {
    let attachmentsCopy = [...reinsurer.attachments];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      onChange(
        {
          ...reinsurer,
          attachments: attachmentsCopy,
        },
        index
      );
    }
  };

  return (
    <Section title={`Reinsurer ${index + 1}`} expanded={true} inside={true}>
      <MultiplyAction
        dataLength={reinsurersCount}
        index={index}
        actionText="reinsurer"
        possibleLength={4}
        onDeleteItem={deleteReinsurer}
        onAddItem={addEmptyReinsurer}
      >
        <CompanyFieldsEdit
          data={reinsurer.company}
          onChange={onReinsurerPartChange}
          index={index}
          validator={validator}
          addDirtyField={addDirtyField}
        />
        <Section title="Details" inside={true}>
          <FormGroup
            className={`${classes.pairOfInputs} ${classes.halfOfWidth}`}
          >
            <TextField
              name={`${uniqueId}-share`}
              label="Share"
              type="number"
              value={reinsurer.share}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, step: 1 },
              }}
              variant="outlined"
              onChange={onEventChange}
              error={validator?.hasErrorToDisplay(`${uniqueId}-share`)}
              onBlur={addDirtyField}
            />
            <CurrencyTextField
              name={`${uniqueId}-referralLimitClaim`}
              label="Referral Limit Claim"
              value={reinsurer.referralLimitClaim}
              required={true}
              onChange={onEventChange}
              error={validator?.hasErrorToDisplay(
                `${uniqueId}-referralLimitClaim`
              )}
              onBlur={addDirtyField}
              allowNegative={false}
            />
          </FormGroup>
          <AttachmentsEdit
            addAttachment={addAttachment}
            deleteAttachment={deleteAttachment}
            attachments={reinsurer?.attachments}
          />
        </Section>
      </MultiplyAction>
    </Section>
  );
});

export default Reinsurer;
