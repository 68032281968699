import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { findKey } from 'lodash';

import { movementTypes, movementCreationOutcome } from '../../support/constants';
import { Modal } from '../../components';
import { saveMovement } from '../../store/reducers/movement/create';
import { cleanReducer as cleanMovementsCreateReducer } from '../../store/reducers/movement/create';

import AddMovement from './AddMovement';
import { MovementNotCreatedPopup } from './MovementNotCreatedPopup';
import { useStyles } from './styles';
import { MovementCreatedPopup } from './MovementCreatedPopup';

export default function AddMovementModal({
  openModal,
  setOpenModal,
  policy,
  onlyClaims = false,
  onSuccess = null,
  onClose = null,
  defaultMovementType,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { wasSaved, isSaving, creationOutcome } = useSelector((state) => state.movement.create);
  const [movement, setMovement] = useState({ 
    type: defaultMovementType ?? '',
    policyId: policy.id
  });
  const [isMovementReadyToConfirm, setIsMovementReadyToConfirm] = useState(
    false
  );
  const [customIsValid, setCustomIsValid] = useState(true);
  const [shouldShowAllValidationErrors, setShouldShowAllValidationErrors] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState(false);

  useEffect(() => {
    if (wasSaved) {
      handleClose();

      if (onSuccess) {
        onSuccess();
      }
    }
  }, [wasSaved]);

  const handleMovementConfirm = () => {
    // This works only for proceedings request now (and QuantumChange), by default for other movements it should be true 
    // (except for TheirPart36 which uses it for internal validation also, but in different way than proceedings)
    // For more details see AddMovement.jsx
    if (!customIsValid) {
      return setShouldShowAllValidationErrors(true);
    }

    dispatch(
      saveMovement(findKey(movementTypes, ['value', movement.type]), {
        ...movement,
      })
    );
  };

  const handleClose = () => {
    dispatch(cleanMovementsCreateReducer());
    var shouldShowConfirmation = creationOutcome === movementCreationOutcome.Ok.value && !!confirmationContent;
    setOpenModal(creationOutcome === movementCreationOutcome.Discarded.value || shouldShowConfirmation);
    setShowDiscardConfirmation(creationOutcome === movementCreationOutcome.Discarded.value);
    setShowConfirmation(shouldShowConfirmation);
  };

  const handleMovementDiscardedClose = () => {
    setShowDiscardConfirmation(false);
    setOpenModal(false);
    if (onClose !== null) {
      onClose();
    }
  };

  const handleMovementConfirmationClose = () => {
    setShowConfirmation(false);
    setOpenModal(false);
    if (onClose !== null) {
      onClose();
    }
  }

  const getMovementDisplayName = (movement) => {
    return find(movementTypes, {value: movement.type})?.displayName || 'Unknown';
  };

  return (
    <>
    <Modal
      onClose={handleClose}
      onConfirm={handleMovementConfirm}
      open={openModal}
      titleText="Add New Movement"
      showActionButtons={true}
      isReadyToConfirm={isMovementReadyToConfirm}
      isLoading={isSaving}
      PaperProps={{ className: classes.modal }}
    >
      <div>
        <AddMovement
          policy={policy}
          movement={movement}
          setMovement={setMovement}
          setIsReadyToConfirm={setIsMovementReadyToConfirm}
          onlyClaims={onlyClaims}
          setCustomIsValid={setCustomIsValid}
          customIsValid={customIsValid}
          shouldShowAllValidationErrors={shouldShowAllValidationErrors}
          setConfirmationContent={setConfirmationContent}
        />
      </div>
    </Modal>

    <MovementNotCreatedPopup modalOpen={showDiscardConfirmation} handleClose={handleMovementDiscardedClose} />
    <MovementCreatedPopup titleText={`${getMovementDisplayName(movement)} confirmed`} content={confirmationContent} modalOpen={showConfirmation} handleClose={handleMovementConfirmationClose} />
    </>
  );
}
