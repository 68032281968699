import React, { useState, memo } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import { MultiplyAction, Section } from '../../../components';
import { schemeCriteriaPosibleAnswers } from '../../../support/constants';
import { uniqueId as getUniqueId } from '../../../support/helpers';
import { useStyles } from '../styles';

const Criterion = memo(function Component(props) {
  const {
    criterion,
    criteriaCount,
    index,
    validator,
    onChange,
    deleteCriterion,
    addEmptyCriterion,
    addDirtyField,
  } = props;

  const classes = useStyles();
  const [uniqueId] = useState(getUniqueId());

  const onEventChange = (event) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );
    onChange(
      {
        ...criterion,
        [propertyName]: event.target.value,
      },
      index
    );
  };

  return (
    <Section inside={true}>
      <MultiplyAction
        dataLength={criteriaCount}
        index={index}
        actionText="Criterion"
        possibleLength={24}
        onDeleteItem={deleteCriterion}
        onAddItem={addEmptyCriterion}
        removeShowForFirst={true}
      >
        <div className={classes.criterionRow}>
          <div className={classes.criterionHeader}>{index + 1}.</div>
          <div className={classes.fullWidth}>
            <FormGroup className={classes.fullWidth}>
              <TextField
                name={`${uniqueId}-criterion-name`}
                label="Criterion"
                value={criterion.name || ''}
                required={true}
                variant="outlined"
                onChange={onEventChange}
                error={validator?.hasErrorToDisplay(
                  `${uniqueId}-criterion-name`
                )}
                onBlur={addDirtyField}
              />
            </FormGroup>
            <FormGroup className={classes.fullWidth}>
              <TextField
                name={`${uniqueId}-criterion-question`}
                label="Question That Will Appear in The Policy"
                value={criterion.question || ''}
                required={true}
                variant="outlined"
                onChange={onEventChange}
                error={validator?.hasErrorToDisplay(
                  `${uniqueId}-criterion-question`
                )}
                onBlur={addDirtyField}
              />
            </FormGroup>
            <FormControl
              component="fieldset"
              error={validator?.hasErrorToDisplay(
                `${uniqueId}-criterion-answerType`
              )}
              className={classes.radioGroup}
            >
              <FormLabel component="legend">Eligible Answers*:</FormLabel>
              <RadioGroup
                name={`${uniqueId}-criterion-answerType`}
                value={String(criterion?.answerType)}
                onChange={onEventChange}
              >
                {Object.keys(schemeCriteriaPosibleAnswers).map((answerKey) => (
                  <FormControlLabel
                    key={answerKey}
                    value={answerKey.toString()}
                    control={<Radio color="primary" required={true} />}
                    label={schemeCriteriaPosibleAnswers[answerKey]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </MultiplyAction>
    </Section>
  );
});

export default Criterion;
