import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const messageCommon = {
  fontSize: '12px',
  lineHeight: '12px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const useStyles = makeStyles(() => ({
  warning: {
    ...messageCommon,
    color: colors.warning,
    fontStyle: 'italic',
  },
  error: {
    ...messageCommon,
    color: colors.error,
    fontWeight: 'bold',
  },
  success: {
    ...messageCommon,
    color: colors.green,
  },
}));
