import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 25,
  },
  badgeSeparator: {
    width: 15,
    height: 3,
    backgroundColor: colors.lightGreen,
  },
  title: {
    marginRight: 20,
  },
  header: {
    padding: '22px 34px',
    borderLeft: `6px solid ${colors.primary}`,
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
  },
  detailIcon: {
    marginRight: 30,
    marginLeft: 30,
  },
  divider: {
    marginRight: 25,
    marginLeft: 25,
  },
  list: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '& + *': {
      marginTop: 16,
    },
  },
  twoColumn: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& li': {
      flex: '1 1 50%',
    },
  },
  listFullWidth: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  overline: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    '& img, & svg': {
      marginRight: 8,
      filter: 'grayscale(1)',
      color: '#7F7F7F',
    },
  },
  premiumStatus: {
    fontSize: '0.875em',
    marginLeft: '5px',
    marginTop: '-3px',
  },
  premiumStatusLoader: {
    marginLeft: '10px',
  },
}));
