import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import { Grid, IconButton } from '@material-ui/core';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import {
  fetchAvailableGroups,
  fetchSchemeGroups,
  addGroupToScheme,
  removeGroupFromSchema,
} from '../../store/reducers/scheme/groupsForScheme';
import { Warning } from '../../components';
import IconPlus from '../../assets/plus.svg';
import IconMinus from '../../assets/minus.svg';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';

import { manageAccessGridStyles, useStyles } from './styles';
import ManageGroupsGridToolbar from './ManageGroupsGridToolbar';

export default function ManageAccess(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { schemeId } = props;
  const theme = createMuiTheme({
    overrides: manageAccessGridStyles,
  });

  const { availableGroups } = useSelector(
    (state) => state.scheme.groupsForScheme
  );

  const { schemeGroups, hasAnyUnavailableGroups } = useSelector(
    (state) => state.scheme.groupsForScheme
  );

  const { isReadyToDisplay } = useSelector(
    (state) => state.scheme.groupsForScheme
  );

  useEffect(() => {
    dispatch(fetchAvailableGroups());
    dispatch(fetchSchemeGroups(schemeId));
  }, [dispatch, schemeId]);

  return (
    <LoadingWrapper loading={!isReadyToDisplay}>
      <Grid container>
        {hasAnyUnavailableGroups && (
          <Grid item xs={12}>
            <Warning>
              Some groups assigned to scheme are not longer valid. They will be
              removed while saving.
            </Warning>
          </Grid>
        )}
        <Grid item xs={6}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              title="All companies"
              columns={[
                {
                  title: 'Company Name',
                  field: 'name',
                },
                {
                  title: 'Action',
                  field: 'id',
                  width: 20,
                  align: 'center',
                  searchable: false,
                  render: (rowData) => {
                    if (!rowData.isAssignedToScheme) {
                      return (
                        <IconButton
                          className={classes.narrowIcon}
                          onClick={() => dispatch(addGroupToScheme(rowData))}
                        >
                          <img src={IconPlus} alt="Plus" />
                        </IconButton>
                      );
                    } else {
                      return <span className={classes.addedGroup}>Added</span>;
                    }
                  },
                },
              ]}
              data={availableGroups.map((o) => ({ ...o }))}
              components={{
                Toolbar: (props) => <ManageGroupsGridToolbar {...props} />,
              }}
              options={{
                sorting: false,
                search: true,
                draggable: false,
              }}
            />
          </MuiThemeProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              title="Companies with access"
              columns={[
                {
                  title: 'Company Name',
                  field: 'name',
                  render: (rowData) => {
                    return (
                      <span
                        className={
                          !rowData.isAvailable ? classes.unavailableGroup : ''
                        }
                      >
                        {rowData.name}
                      </span>
                    );
                  },
                },
                {
                  title: 'Action',
                  field: 'id',
                  width: 20,
                  align: 'center',
                  searchable: false,
                  render: (rowData) => {
                    return (
                      <IconButton
                        className={classes.narrowIcon}
                        onClick={() => dispatch(removeGroupFromSchema(rowData))}
                      >
                        <img src={IconMinus} alt="Minus" />
                      </IconButton>
                    );
                  },
                },
              ]}
              data={schemeGroups.map((o) => ({ ...o }))}
              components={{
                Toolbar: (props) => <ManageGroupsGridToolbar {...props} />,
              }}
              options={{
                sorting: false,
                search: true,
                draggable: false,
              }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </LoadingWrapper>
  );
}
