import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash';

import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import IconMore from '../../assets/more.svg';

import {
  clickGridRowMenu,
  closeGridRowMenu,
  anchorElement,
  rowIdentifier,
} from '../../store/reducers/global/gridRowMenu';
import { useStyles } from './styles';

export default function GridRowMenu(props) {
  const classes = useStyles();
  const anchorEl = useSelector(anchorElement);
  const rowId = useSelector(rowIdentifier);
  const dispatch = useDispatch();
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);

  useEffect(() => {
    setIsMenuVisible(
      some(props.menuItems, (menuItem) => {
        return typeof menuItem.isVisible === 'undefined' || menuItem.isVisible;
      })
    );
  }, [props.menuItems]);

  const handleIconClick = (e) => {
    dispatch(
      clickGridRowMenu({
        anchorElement: e.currentTarget,
        rowIdentifier: props.rowId,
      })
    );
  };

  const handleMenuClose = () => {
    dispatch(closeGridRowMenu());
  };

  const handleMenuItemClick = (menuItem) => {
    menuItem.handleClick();
    dispatch(closeGridRowMenu());
  };

  function loadMenuItems() {
    const menuItems = [];

    props.menuItems.forEach((menuItem, i) => {
      if (typeof menuItem.isVisible === undefined || menuItem.isVisible) {
        menuItems.push(
          <MenuItem
            className={classes.listItem}
            key={i}
            onClick={() => handleMenuItemClick(menuItem)}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.listItemText }}
              primary={menuItem.text}
            />
          </MenuItem>
        );
      }
    });

    return menuItems;
  }

  return (
    <>
      {isMenuVisible && (
        <div>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleIconClick}
          >
            <img src={IconMore} alt="More" />
          </IconButton>
          <Menu
            PopoverClasses={{ paper: classes.menu }}
            MenuListProps={{ className: classes.menuList }}
            id={'simple-menu'}
            anchorEl={anchorEl}
            transitionDuration={0}
            keepMounted
            open={props.rowId === rowId}
            onClose={handleMenuClose}
          >
            {loadMenuItems()}
          </Menu>
        </div>
      )}
    </>
  );
}
