export function safeSelector(selector) {
  return state => {
    let result;
    try {
      result = selector(state);
    } catch (err) {
      result = undefined;
    }
    return result;
  };
}

export default {
  safeSelector,
};
