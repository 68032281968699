import React from 'react';
import { useDispatch } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../store/userManager';
import { historyShape } from '../../support/prop-types';

import { getCurrentUserProfile } from '../../store/reducers/global/userProfile';

function CallbackPage(props) {
  const dispatch = useDispatch();

  const goToHome = () => {
    const { history } = props;
    const redirectUri = sessionStorage.getItem('redirectUri') || '/';

    history.push(redirectUri);
    sessionStorage.removeItem('redirectUri');
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => {
        dispatch(getCurrentUserProfile())
          .then(({ response }) => {
            const userProfile = response.data;
            if (userProfile) {
              sessionStorage.setItem(
                'cachedUserInfo',
                JSON.stringify(userProfile)
              );
            }
          })
          .finally(() => goToHome());
      }}
      errorCallback={(error) => {
        goToHome();
        // eslint-disable-next-line no-console
        console.error(error);
      }}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
}

CallbackPage.propTypes = {
  history: historyShape.isRequired,
};

export default CallbackPage;
