import * as userGroups from '../userGroups.json';
import { find } from 'lodash';
import { dark } from '@material-ui/core/styles/createPalette';

export const dateFormat = 'DD/MM/YYYY';

export const cancelledRequestErrorMessage = 'Cancelled request to API';

export const userRoles = {
  resolve: userGroups.resolve,
  auditor: userGroups.auditors,
  solicitor: userGroups.solicitors,
  insurer: userGroups.insurers,
};

export const userGroupTypes = {
  security: 1,
  solicitor: 2,
  insurer: 3,
};

export const schemeStatus = Object.freeze({
  1: {
    displayName: 'In Progress',
    color: 'blue',
  },
  2: {
    displayName: 'Live',
    color: 'gray',
  },
});

export const schemeType = Object.freeze({
  1: {
    shortcut: 'C',
    type: 'CWI',
    description: 'Cavity Wall Insulation',
  },
  2: {
    shortcut: 'M',
    type: 'MMC',
    description: 'Mortgage Breach',
  },
});

export const reinsuranceBasis = Object.freeze({
  1: 'Run off',
  2: 'Clean cut',
});

export const attachmentTypes = Object.freeze({
  None: 0,
  SchemePublic: 1,
});

export const complaintLimit = Object.freeze({
  1: 'InitialComplaint',
  2: 'AppealedComplaint',
  3: 'Other',
});

export const remunerationType = Object.freeze({
  1: {
    type: 'Commission',
    fieldsToDisplay: ['remunerationCommission'],
  },
  2: {
    type: 'ProfitCommission',
    fieldsToDisplay: ['remunerationProfitCommission'],
  },
  3: {
    type: 'Both',
    fieldsToDisplay: ['remunerationCommission', 'remunerationProfitCommission'],
  },
});

export const policyStatuses = Object.freeze({
  Open: {
    value: 1,
    displayName: 'Open',
    color: 'green',
    rolesAllowed: [userRoles.resolve],
  },
  Unwind: {
    value: 2,
    displayName: 'Unwind',
    color: 'red',
    rolesAllowed: [userRoles.resolve],
  },
  Claim: {
    value: 3,
    displayName: 'Claim',
    color: 'purple',
    rolesAllowed: [userRoles.resolve],
  },
  SettledWon: {
    value: 4,
    displayName: 'Settled Won',
    color: 'lightGreen',
    rolesAllowed: [],
  },
  SettledPartClaim: {
    value: 5,
    displayName: 'Settled Part Claim',
    color: 'blue',
    rolesAllowed: [userRoles.resolve],
  },
  CA14: {
    value: 6,
    displayName: 'CA14',
    color: 'darkRed',
    rolesAllowed: [userRoles.resolve],
  },
  Void: {
    value: 7,
    displayName: 'Void',
    color: 'darkGray',
    rolesAllowed: [userRoles.resolve],
  },
  CARef: {
    value: 8,
    displayName: 'CARef',
    color: 'darkRed',
    rolesAllowed: [userRoles.resolve],
  },
  CANoRef: {
    value: 9,
    displayName: 'CANoRef',
    color: 'darkRed',
    rolesAllowed: [userRoles.resolve],
  },
  CARec: {
    value: 10,
    displayName: 'CARec',
    color: 'darkRed',
    rolesAllowed: [userRoles.resolve],
  },
  Restrict: {
    value: 11,
    displayName: 'Restrict',
    color: 'darkRed',
    rolesAllowed: [],
  },
});

export const policyStage = Object.freeze({
  1: {
    displayName: 'pre-proceedings',
    color: 'gray',
  },
  2: {
    displayName: 'post proceedings',
    color: 'green',
  },
  3: {
    displayName: 'trial',
    color: 'blue',
  },
});

export const policyStages = {
  PreProceedings: {
    value: 1,
    displayName: 'pre-proceedings issue',
  },
  Proceedings: {
    value: 2,
    displayName: 'post proceedings issue',
  },
  Trial: {
    value: 3,
    displayName: '45 days or less to trial date',
  },
};

export const auditActions = {
  Proceed: {
    value: 1,
    displayName: 'Proceed',
  },
  Restrict: {
    value: 2,
    displayName: 'Restrict',
  },
};

export const schemeCriteriaAnswers = {
  yes: 'Yes',
  no: 'No',
  pending: 'Pending',
};

export const schemeCriteriaPosibleAnswers = {
  1: `${schemeCriteriaAnswers.yes}/${schemeCriteriaAnswers.no}`,
  2: `${schemeCriteriaAnswers.yes}/${schemeCriteriaAnswers.no}/${schemeCriteriaAnswers.pending}`,
};

export const propertyStyles = {
  1: 'Detached',
  2: 'Semi-detached',
  3: 'Terraced',
  4: 'Other',
};

export const propertyBuildTypes = {
  1: 'Random Stone',
  2: 'System Build',
};

export const propertySubTypes = {
  1: { displayName: 'Stone', propertyType: 1 },
  2: { displayName: 'Timber Framed', propertyType: 2 },
  3: { displayName: 'Concrete Framed', propertyType: 2 },
  4: { displayName: 'Steel Framed', propertyType: 2 },
};

export const movementStatuses = {
  New: {
    value: 1,
    displayName: 'New',
  },
  Approved: {
    value: 2,
    displayName: 'Approved',
  },
  Rejected: {
    value: 3,
    displayName: 'Rejected',
  },
  Paid: {
    value: 4,
    displayName: 'Paid',
  },
};

export const movementCreationOutcome = {
  None: {
    value: 0,
  },
  Ok: {
    value: 1,
  },
  Discarded: {
    value: 2,
  },
};

export const movementTypes = {
  UnwindRequest: {
    value: 1,
    displayName: 'Unwind request',
    rolesAllowed: [userRoles.solicitor, userRoles.resolve],
  },
  ClaimNotification: {
    value: 2,
    displayName: 'Claim notification',
    rolesAllowed: [userRoles.solicitor, userRoles.resolve],
  },
  Claim: {
    value: 3,
    displayName: 'Claim',
    rolesAllowed: [userRoles.resolve],
  },
  Audit: {
    value: 4,
    displayName: 'Audit',
    rolesAllowed: [],
  },
  Note: {
    value: 5,
    displayName: 'Note',
    rolesAllowed: [userRoles.solicitor, userRoles.resolve],
  },
  Premium: {
    value: 6,
    displayName: 'Premium',
    rolesAllowed: [],
  },
  OurPart36: {
    value: 7,
    displayName: 'Part 36 - Claimant',
    rolesAllowed: [userRoles.solicitor],
  },
  TheirPart36: {
    value: 8,
    displayName: 'Part 36 - Defendant',
    rolesAllowed: [userRoles.solicitor],
  },
  ProceedingsRequest: {
    value: 9,
    displayName: 'Proceedings request',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  QuantumChange: {
    value: 10,
    displayName: 'Quantum change',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  EventNotification: {
    value: 11,
    displayName: 'Event notification',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  SettlementNotification: {
    value: 12,
    displayName: 'Settlement notification',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  TrialRequest: {
    value: 13,
    displayName: 'Trial request',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  TrialDocumentation: {
    value: 14,
    displayName: 'Trial documentation',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  Cancellation14Days: {
    value: 15,
    displayName: 'Cancellation within 14 days',
    rolesAllowed: [userRoles.resolve, userRoles.solicitor],
  },
  Damages: {
    value: 16,
    displayName: 'Damages',
    rolesAllowed: [],
  },
  RestrictionWithdrawalRequest: {
    value: 17,
    displayName: 'Restriction withdrawal request',
    rolesAllowed: [userRoles.solicitor],
  },
  CancellationRecovery: {
    value: 18,
    displayName: 'Cancellation recovery',
    rolesAllowed: [],
  },
  CancellationRefund: {
    value: 19,
    displayName: 'Cancellation refund',
    rolesAllowed: [],
  },
  CancellationNoRefund: {
    value: 20,
    displayName: 'Cancellation no refund',
    rolesAllowed: [],
  },
  Void: {
    value: 21,
    displayName: 'Void',
    rolesAllowed: [userRoles.resolve],
  },
};

export const unwindReasons = {
  FailedConditionPrecedentProofOfInstallationUnsupportive: {
    value: 1,
    displayName:
      'Failed Condition Precedent -  Proof of installation unsupportive',
  },
  FailedConditionPrecedentNoViableThirdPartyConfirmed: {
    value: 2,
    displayName:
      'Failed Condition Precedent -  No Viable Third Party confirmed',
  },
  FailedConditionPrecedentDateOfKnowledgeIsUnsupportiveOrUnaccepted: {
    value: 3,
    displayName:
      'Failed Condition Precedent -  Date of Knowledge is unsupportive or unaccepted',
  },
  FailedConditionPrecedentCWIWasInstalledBeforeFebruary2013: {
    value: 4,
    displayName:
      'Failed Condition Precedent -  CWI was installed before February 2013',
  },
  FailedConditionPrecedentClaimValueHasDroppedBelowTheMinimumRequired: {
    value: 5,
    displayName:
      'Failed Condition Precedent -  Claim value has dropped below the minimum required',
  },
  FailedConditionPrecedentReasonableProspectsRequiredAtInceptionNotMet: {
    value: 6,
    displayName:
      'Failed Condition Precedent -  Reasonable Prospects required at inception not met',
  },
  FailedConditionPrecedentUnsupportiveSurvey: {
    value: 7,
    displayName: 'Failed Condition Precedent -  Unsupportive survey',
  },
  PremiumUnpaid: {
    value: 8,
    displayName: 'Premium Unpaid',
  },
  FailedConditionPrecedentNoValidCFAInPlace: {
    value: 9,
    displayName: 'Failed Condition Precedent -  No valid CFA in place',
  },
  Other: {
    value: 10,
    displayName: 'Other',
  },
};

export const claimTypes = {
  1: 'Reserve',
  2: 'Payment',
};

export const premiumTypes = {
  Funded: {
    value: 1,
    displayName: 'Funded',
  },
  Deferred: {
    value: 2,
    displayName: 'Deferred',
  },
};

export const paymentTypes = {
  1: 'Due',
  2: 'Undue',
};

export const disbursementTypes = {
  1: 'FeesCourt',
  2: 'ExpertReports',
  3: 'Vetting',
  4: 'Other',
  5: 'HearingFees',
  6: 'AdverseCosts',
  7: 'Contribution',
  8: 'Subrogation',
};

export const claimNotificationReasons = {
  1: 'Other',
  2: 'Failed Expert Report',
  3: 'Partially failed Expert Report',
  4: 'Exaggerated Expert Report',
  5: 'Wrong Defendant',
  6: 'Insolvent Defendant without Insurance',
  7: 'Failed Intermediate Hearing',
  8: 'Inaccurate Client Data',
  9: 'Limitation – Date of Knowledge',
  10: 'Failure of Process',
};

export const typesOfPart36Offer = {
  liability: {
    value: 1,
    displayName: 'Liability',
  },
  quantum: {
    value: 2,
    displayName: 'Quantum',
  },
  liabilityAndQuantum: {
    value: 3,
    displayName: 'Liability and quantum',
  },
};

export const courts = {
  countyCourt: {
    value: 1,
    displayName: 'County Court',
  },
  crownCourt: {
    value: 2,
    displayName: 'Crown Court',
  },
  highCourt: {
    value: 3,
    displayName: 'High Court',
  },
  courtOfAppeal: {
    value: 4,
    displayName: 'Court of Appeal',
  },
};

export const eventNotificationTypes = {
  liabilityAdmitted: {
    value: 1,
    displayName: 'Liability Admitted',
  },
  liabilityWithdrawn: {
    value: 2,
    displayName: 'Liability Withdrawn',
  },
  liabilityDenied: {
    value: 3,
    displayName: 'Liability Denied',
  },
  theClaimIsAllocatedToMediationOrArbitration: {
    value: 4,
    displayName: 'The case is allocated to mediation or arbitration',
  },
  failureToComplyWithPolicyTermsAndConditions: {
    value: 5,
    displayName: 'Failure to comply with policy terms and conditions',
  },
  nowPartOfMultipartyAction: {
    value: 6,
    displayName: 'Now part of a multiparty action',
  },
  failureToAcceptSolicitorAdvice: {
    value: 7,
    displayName: 'Failure to accept solicitor’s advice',
  },
  caseLostNoClaimOnPolicy: {
    value: 8,
    displayName: 'Case Lost - No Claim on a Policy',
  },
  letterOfClaimSent: {
    value: 9,
    displayName: 'Letter of claim sent',
  },
  responseToLetterOfClaimReceived: {
    value: 10,
    displayName: 'Response to letter of claim received',
  },
};

export const cancellationTypes = {
  CA14: {
    value: 1,
    displayName: 'Cancellation 14',
  },
  CARef: {
    value: 2,
    displayName: 'Cancellation Refund',
  },
  CANoRef: {
    value: 3,
    displayName: 'Cancellation No Refund',
  },
  CARec: {
    value: 4,
    displayName: 'Cancellation Recover',
  },
};

export const premiumStatuses = {
  Unpaid: {
    value: 1,
    displayName: 'Unpaid',
    color: 'red',
  },
  PartiallyPaid: {
    value: 2,
    displayName: 'Partially paid',
    color: 'blue',
  },
  Paid: {
    value: 3,
    displayName: 'Paid',
    color: 'green',
  },
};

export const courtsOptions = mapEnumToOptions(courts);
export const typesOfPart36OfferOptions = mapEnumToOptions(typesOfPart36Offer);
export const eventNotificationTypesOptions = mapEnumToOptions(
  eventNotificationTypes
);
export const unwindReasonsOptions = mapEnumToOptions(unwindReasons);
export const cancellationTypesOptions = mapEnumToOptions(cancellationTypes);
export const policyStatusesOptions = mapEnumToOptions(policyStatuses);

function mapEnumToOptions(enumDef) {
  return Object.values(enumDef).reduce(
    (prev, curr) => Object.assign(prev, { [curr.value]: curr.displayName }),
    {}
  );
}

export const getConstByValue = (consts, value) =>
  find(consts, { value: value });

export default {
  dateFormat,
  userRoles,
  schemeStatus,
  schemeType,
  reinsuranceBasis,
  complaintLimit,
  remunerationType,
  policyStatuses,
  policyStage,
  schemeCriteriaAnswers,
  propertyStyles,
  propertyBuildTypes,
  propertySubTypes,
  movementTypes,
  unwindReasons,
  claimTypes,
  disbursementTypes,
  typesOfPart36Offer,
};
