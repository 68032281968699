import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { AttachmentsEdit, CurrencyTextField, SelectWithOptions, DatePickerWithClear, Warning, Danger } from '../../components';
import { getMatchingQddlRow, cleanReducer } from '../../store/reducers/policy/matchingQddlRow';
import { courtsOptions } from '../../support/constants'; 

import { 
  shouldShowWarningAboutChangedBand,
  shouldShowDangerAboutQuantumOutsideQddl,
  shouldShowWarningAboutIncorrectCourt } from '../../support/movementHelpers';

import { useStyles } from './styles';

export default function TrialRequestMovement({ policy, movement, onChange, setCustomIsValid, showAllValidationErrors, setConfirmationContent }) {

  const maxProspectsValue = 100;
  const minProspectsValue = 60;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dirtyFields, setDirtyFieldsState] = useState([]);

  const {
    matchingQddlRow,
    isError,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.movement.matchingQddlRow);

  useEffect(() => {
    setCustomIsValid(false);
    dispatch(cleanReducer());
    setConfirmationContent(confirmationContent());
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const checkWhetherQuantumBandIsDifferentThanInPolicy = () => {
    if (movement?.quantumSpecial && movement?.quantumGeneral) {
      delayGettingQddlRow(movement.quantumSpecial, movement.quantumGeneral);
    } else {
      delayGettingQddlRow.cancel();
    }
  }

  const delayGettingQddlRow = useCallback(debounce((quantumSpecial, quantumGeneral) => getQddlRow(quantumSpecial, quantumGeneral), 300),[]);

  const getQddlRow = (quantumSpecial, quantumGeneral) => {
    dispatch(
      getMatchingQddlRow(
        policy.id,
        { quantumSpecial, quantumGeneral })
    );
  };

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);


  const onDateChange = (fieldName, date) => {
    onChange({
        target: {
          name: fieldName,
          value: date ? date.toISOString() : date
        }
      });
  };

  const onCheckboxChange = (event) => onChange({
    target: {
      name: event.target.name, 
      value: event.target.checked 
    }
  })

  const validation = {
    isSupportiveCounselsAdviceConfirmed: () => !!movement?.supportiveCounselsAdviceConfirmed,
    isSupportiveCounselsAdviceAttachmentsValid: () => true,
    isPropertyReSurveyedByHeadSurveyorConfirmed: () => !!movement?.propertyReSurveyedByHeadSurveyorConfirmed,
    isPropertyReSurveyedByHeadSurveyorAttachmentsValid: () => true,
    isTrialDateValid: () => !!movement?.trialDate,
    quantumGeneralIsValid: () => !!movement?.quantumGeneral,
    quantumSpecialIsValid: () => !!movement?.quantumSpecial,
    prospectsIsValid: () => !!movement?.prospects,
    isExpectedTrialCostValid: () => !!movement?.expectedTrialCost && movement.expectedTrialCost >= 0,
    isExpectedTrialLengthValid: () => !!movement?.expectedTrialLength && movement.expectedTrialLength >=0,
    courtIsValid: () => !!movement?.court
  };

  const isFormValid = () => {
    return validation.isSupportiveCounselsAdviceConfirmed() && validation.isSupportiveCounselsAdviceAttachmentsValid()
      && validation.isPropertyReSurveyedByHeadSurveyorConfirmed && validation.isPropertyReSurveyedByHeadSurveyorAttachmentsValid()
      && validation.isTrialDateValid() 
      && validation.quantumGeneralIsValid()
      && validation.quantumSpecialIsValid()
      && validation.prospectsIsValid()
      && validation.isExpectedTrialCostValid()
      && validation.isExpectedTrialLengthValid()
      && validation.courtIsValid();
  }

  const addAttachment = (attachmentName, attachment) => {
    const attachments = [...(movement[attachmentName] ?? []), attachment];
    updateAttachments(attachmentName, attachments);
  };

  const deleteAttachment = (attachmentName, attachmentIndex) => {
    let attachmentsCopy = [...movement[attachmentName]];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      updateAttachments(attachmentName, attachmentsCopy);
    }
  };

  const updateAttachments = (attachmentName, attachments) =>{
    onChange({
      target: {
        name: attachmentName,
        value: attachments
      }
    });
  };

  const isSupportiveCounselsAdviceDirty = () => showAllValidationErrors || dirtyFields.includes("supportiveCounselsAdviceConfirmed");
  const shouldShowSupportiveCounselsAdviceError = () => isSupportiveCounselsAdviceDirty() && !validation.isSupportiveCounselsAdviceConfirmed();
  const shouldShowSupportiveCounselsAdviceWarning = () => isSupportiveCounselsAdviceDirty() && !movement?.supportiveCounselsAdviceAttachments?.length;

  const isPropertyReSurveyedByHeadSurveyorConfirmationDirty = () => showAllValidationErrors || dirtyFields.includes("propertyReSurveyedByHeadSurveyorConfirmed");
  const shouldShowPropertyReSurveyedByHeadSurveyorError = () => isPropertyReSurveyedByHeadSurveyorConfirmationDirty() && !validation.isPropertyReSurveyedByHeadSurveyorConfirmed();
  const shouldShowPropertyReSurveyedByHeadSurveyorWarning = () => isPropertyReSurveyedByHeadSurveyorConfirmationDirty() && !movement?.propertyReSurveyedByHeadSurveyorAttachments?.length;

  const shouldShowDangerProspectsNotInRange = (movement) => movement?.prospects && (movement.prospects < minProspectsValue || movement.prospects > maxProspectsValue)

  const confirmationContent = () => 
    (<>
      <p> Thank you for your request. The case details you have provided will now be assessed by Resolve.</p>
      <p>You will receive our policy decision within <strong>3&nbsp;working&nbsp;days.</strong></p>
    </>);

  return (
    <>
      <div className={'reset-mb'}>
        <Typography 
          variant="subtitle2" 
          className={`
            ${classes.normalText} 
            ${shouldShowSupportiveCounselsAdviceError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            onChange={(event) => {
              setDirtyFields(event.target.name);
              onCheckboxChange(event);
            }}
            className={`${shouldShowSupportiveCounselsAdviceError() ? classes.error : ''}`}
            name="supportiveCounselsAdviceConfirmed" />
          Supportive Counsel's Advice*
        </Typography>
        <AttachmentsEdit
          className={classes.formFieldMarginBottom}
          addAttachment={(attachment) => addAttachment('supportiveCounselsAdviceAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('supportiveCounselsAdviceAttachments', attachmentIndex)}
          attachments={movement?.supportiveCounselsAdviceAttachments}
          limitListItemsWidth={false}
          hasWarning={shouldShowSupportiveCounselsAdviceWarning()}
          title={<>Attachments <div className={classes.attachmentsSubTitle}>(Please upload as soon as available)</div></>}
        />
      </div>
      {shouldShowSupportiveCounselsAdviceWarning() && <Warning className={'reset-mb warning-mt'}>File was not uploaded. Supportive Counsel’s Advice must be delivered before trial.</Warning>}
      <div></div>

      <div className={'reset-mb'}>
        <Typography 
          variant="subtitle2" 
          className={`
            ${classes.normalText} 
            ${shouldShowPropertyReSurveyedByHeadSurveyorError() ? classes.error : ''}`}>
          <Checkbox 
            color="primary" 
            onChange={(event) => {
              setDirtyFields(event.target.name);
              onCheckboxChange(event);
            }}
            className={`${shouldShowPropertyReSurveyedByHeadSurveyorError() ? classes.error : ''}`}
            name="propertyReSurveyedByHeadSurveyorConfirmed" />
          Property Re-Surveyed by Head Surveyor*
        </Typography>
        <AttachmentsEdit
          addAttachment={(attachment) => addAttachment('propertyReSurveyedByHeadSurveyorAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('propertyReSurveyedByHeadSurveyorAttachments', attachmentIndex)}
          attachments={movement?.propertyReSurveyedByHeadSurveyorAttachments}
          limitListItemsWidth={false}
          title={<>Attachments <div className={classes.attachmentsSubTitle}>(Please upload as soon as available)</div></>}
          hasWarning={shouldShowPropertyReSurveyedByHeadSurveyorWarning()}
        />
      </div>
      {shouldShowPropertyReSurveyedByHeadSurveyorWarning() && <Warning className={'reset-mb warning-mt'}>File was not uploaded. Property must be re-surveyed before trial.</Warning>}
      <div></div>

      <DatePickerWithClear
        name="trialDate"
        label="Trial date"
        required={true}
        value={movement.trialDate || null}
        onChange={(date) => onDateChange('trialDate', date)}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={
          (showAllValidationErrors || dirtyFields.includes('trialDate')) && !validation.isTrialDateValid()
        }
      />

      <Grid container className='reset-mb'>
        <Grid item xs={6}>
          <CurrencyTextField
            name="quantumGeneral"
            label="Quantum general"
            onChange={onChange}
            helperText="Must be equal to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('quantumGeneral')) && !validation.quantumGeneralIsValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
              checkWhetherQuantumBandIsDifferentThanInPolicy();
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyTextField
            name="quantumSpecial"
            label="Quantum special"
            onChange={onChange}
            helperText="Must be equal to to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('quantumSpecial')) && !validation.quantumSpecialIsValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
              checkWhetherQuantumBandIsDifferentThanInPolicy();
            }}
          />
        </Grid>
      </Grid>

      {shouldShowWarningAboutChangedBand(policy, matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning className={'reset-mb'}>Quantum has changed band.</Warning>}
      {shouldShowDangerAboutQuantumOutsideQddl(wasRecognizedAsOutsideQddl) && <Danger className={'reset-mb'}>Quantum is outisde allowed bands! Please correct the input, otherwise your request will not be saved.</Danger>}
      <div></div>

      <TextField
          name="prospects"
          label="Prospects"
          className='reset-mb'
          onChange={onChange}
          type="number"
          helperText={`Must be between ${minProspectsValue} and ${maxProspectsValue}`}
          variant="outlined"
          required={true}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">%</InputAdornment>
            ),
            inputProps: { step: 1 },
          }}
          error={
            (showAllValidationErrors || dirtyFields.includes('prospects')) && !validation.prospectsIsValid()
          }
          onBlur={(event) => setDirtyFields(event.target.name)}
        />

      {shouldShowDangerProspectsNotInRange(movement) && <Danger className={'reset-mb'}>{`Prospects must be between ${minProspectsValue} and ${maxProspectsValue}! Please correct the input, otherwise your request will not be saved.`}</Danger>}
      <div></div>

      <Grid container>
        <Grid item xs={6}>
          <CurrencyTextField
            name="expectedTrialCost"
            label="Exptected trial cost"
            onChange={onChange}
            helperText="Must be equal to or greater than 0"
            required={true}
            allowNegative={false}
            allowZero={true}
            error={
              (showAllValidationErrors || dirtyFields.includes('expectedTrialCost')) && !validation.isExpectedTrialCostValid()
            }
            InputProps={{ inputProps: { step: 1 } }}
            onBlur={(event) => {
              setDirtyFields(event.target.name);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="expectedTrialLength"
            label="Expected trial length (days)"
            onChange={onChange}
            type="number"
            helperText="Must be greater than 0"
            variant="outlined"
            required={true}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              inputProps: { min: 0 },
            }}
            error={
              (showAllValidationErrors || dirtyFields.includes('expectedTrialLength')) && !validation.isExpectedTrialLengthValid()
            }
          />
        </Grid>
      </Grid>

      <SelectWithOptions
        name="court"
        label="Court"
        className={'reset-mb'}
        value={movement.court}
        onChange={onChange}
        options={courtsOptions}
        required={true}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={(showAllValidationErrors || dirtyFields.includes('court')) && !validation.courtIsValid()}
      />

      {shouldShowWarningAboutIncorrectCourt(movement) && <Warning className={'reset-mb'}>'County Court' or 'High court' not selected.</Warning>}
      <div></div>

      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
      />

      <div>
        <Typography variant="overline" display="block" gutterBottom className={classes.disclaimer}>
          By pressing 'Confirm' you are confirming that all the evidence to support the case proceeding to trial and the policy conditions are in place and validated.
        </Typography>
      </div>
    </>
  );
}
