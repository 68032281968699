import React, { useEffect } from 'react';
import {
  Paper,
  FormGroup,
  TextField,
  FormControl,
  Typography,
} from '@material-ui/core';

import { CurrencyTextField } from '../../components';

import { useStyles } from './styles';
import RadioGroupWithYesNo from '../RadioGroupWithYesNo/RadioGroupWithYesNo';
import DatePickerWithClear from '../DatePickerWithClear/DatePickerWithClear';

const requiredRule = (dirtyFields, typeSpecificCauseOfAction, fieldName) =>
  dirtyFields.includes(fieldName) &&
  !Boolean(typeSpecificCauseOfAction[fieldName]) &&
  typeSpecificCauseOfAction[fieldName] === 0;

const checkIfAnySelectedForRadioButton = (value) =>
  value !== undefined && value !== null;

const validateRemortgage = (typeSpecificCauseOfAction) => {
  const areMandatoryFields = Boolean(
    typeSpecificCauseOfAction.dateOfRemortgage
  );

  return typeSpecificCauseOfAction.hasThereBeenRemortgage
    ? areMandatoryFields
    : checkIfAnySelectedForRadioButton(
      typeSpecificCauseOfAction.hasThereBeenRemortgage
    );
};

const validateHasOtherPartyRightsToMortgage = (typeSpecificCauseOfAction) => {
  const areMandatoryFields =
    Boolean(typeSpecificCauseOfAction.newLenderName) &&
    Boolean(typeSpecificCauseOfAction.newLenderReference);

  return typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage
    ? areMandatoryFields
    : checkIfAnySelectedForRadioButton(
      typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage
    );
};

const validateMortgage = (typeSpecificCauseOfAction) =>
  Boolean(typeSpecificCauseOfAction.dateOfMortgage) &&
  Boolean(typeSpecificCauseOfAction.originalLender) &&
  (Boolean(typeSpecificCauseOfAction.amount) ||
    typeSpecificCauseOfAction.amount === 0) &&
  Boolean(typeSpecificCauseOfAction.mortgageNumber) &&
  Boolean(typeSpecificCauseOfAction.fullAuditReportDate) &&
  Boolean(typeSpecificCauseOfAction.auditReportName);

const validateStep = (typeSpecificCauseOfAction) =>
  validateMortgage(typeSpecificCauseOfAction) &&
  validateRemortgage(typeSpecificCauseOfAction) &&
  validateHasOtherPartyRightsToMortgage(typeSpecificCauseOfAction);

export const SpecificCauseOfActionMMC = ({
  onChange,
  titleClassName,
  contentClassName,
  subtitleClassName,
  currentStepState,
  isBlockedForSolicitor,
}) => {
  const classes = useStyles();
  const [
    typeSpecificCauseOfAction,
    setTypeSpecificCauseOfAction,
  ] = React.useState(
    currentStepState ?? {
      dateOfMortgage: null,
      dateOfRemortgage: null,
      fullAuditReportDate: null,
      hasThereBeenRemortgage: null,
      hasOtherPartyRightsToMortgage: null,
    }
  );
  const [dirtyFields, setDirtyFieldsState] = React.useState([]);
  const setDirtyFields = (event) =>
    setDirtyFieldsState([...dirtyFields, event.target.name]);
  const updateTypeSpecificCauseOfAction = (detailsPart) => {
    setTypeSpecificCauseOfAction({
      ...typeSpecificCauseOfAction,
      ...detailsPart,
    });
  };
  const onInputChange = (event) =>
    updateTypeSpecificCauseOfAction({
      [event.target.name]: event.target.value,
    });
  const onRadioChange = (newValue, fieldName) => {
    updateTypeSpecificCauseOfAction({
      [fieldName]: newValue,
    });
  };
  const onDateChange = (date, fieldName) => {
    updateTypeSpecificCauseOfAction({
      [fieldName]: date ? date.toISOString() : date,
    });
  };

  useEffect(
    () =>
      onChange(validateStep(typeSpecificCauseOfAction), {
        typeSpecificCauseOfAction,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [typeSpecificCauseOfAction]
  );

  useEffect(() => {
    if (!typeSpecificCauseOfAction.hasThereBeenRemortgage) {
      updateTypeSpecificCauseOfAction({
        dateOfRemortgage: null
      });
    }
  }, [typeSpecificCauseOfAction.hasThereBeenRemortgage]);

  return (
    <div>
      <Typography variant="h4" className={titleClassName}>
        Specific Cause of Action
      </Typography>
      <Paper className={contentClassName}>
        <FormGroup className={classes.wizardForm}>
          <h4 className={subtitleClassName}>Mortgage Details</h4>
          <FormGroup className={classes.fourOfInputs}>
            <DatePickerWithClear
              name="dateOfMortgage"
              label="Date of Mortgage"
              value={typeSpecificCauseOfAction.dateOfMortgage}
              onChange={(date) => onDateChange(date, 'dateOfMortgage')}
              onBlur={setDirtyFields}
              required={true}
              isReadOnly={isBlockedForSolicitor}
              error={requiredRule(
                dirtyFields,
                typeSpecificCauseOfAction,
                'dateOfMortgage'
              )}
            />
          </FormGroup>
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="original-lender"
              label="Original Lender"
              name="originalLender"
              variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
              value={typeSpecificCauseOfAction.originalLender}
              onChange={onInputChange}
              required={true}
              onBlur={setDirtyFields}
              error={requiredRule(
                dirtyFields,
                typeSpecificCauseOfAction,
                'originalLender'
              )}
              InputProps={{
                readOnly: isBlockedForSolicitor,
              }}
            />
          </FormGroup>
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="mortgage-number"
              label="Mortgage Number"
              name="mortgageNumber"
              variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
              value={typeSpecificCauseOfAction.mortgageNumber}
              onChange={onInputChange}
              required={true}
              onBlur={setDirtyFields}
              error={requiredRule(
                dirtyFields,
                typeSpecificCauseOfAction,
                'mortgageNumber'
              )}
              InputProps={{
                readOnly: isBlockedForSolicitor,
              }}
            />
            <CurrencyTextField
              id="mortgage-amount"
              label="Amount"
              name="amount"
              value={typeSpecificCauseOfAction.amount}
              onChange={onInputChange}
              required={true}
              onBlur={setDirtyFields}
              error={requiredRule(
                dirtyFields,
                typeSpecificCauseOfAction,
                'amount'
              )}
              allowNegative={false}
              decimalScale={4}
            />
          </FormGroup>
          <FormControl component="fieldset">
            <RadioGroupWithYesNo
              label="Has there been re-mortgage?*"
              name="hasThereBeenRemortgage"
              value={typeSpecificCauseOfAction.hasThereBeenRemortgage}
              onValueChange={onRadioChange}
            />
          </FormControl>
          <FormGroup className={classes.fourOfInputs}>
            <DatePickerWithClear
              name="dateOfRemortgage"
              label="Date of Re-mortgage"
              isReadOnly={!typeSpecificCauseOfAction.hasThereBeenRemortgage}
              value={typeSpecificCauseOfAction.dateOfRemortgage}
              onChange={(date) => onDateChange(date, 'dateOfRemortgage')}
              onBlur={setDirtyFields}
              required={typeSpecificCauseOfAction.hasThereBeenRemortgage}
              error={
                typeSpecificCauseOfAction.hasThereBeenRemortgage
                  ? requiredRule(
                    dirtyFields,
                    typeSpecificCauseOfAction,
                    'dateOfRemortgage'
                  )
                  : null
              }
            />
          </FormGroup>
          <FormControl component="fieldset">
            <RadioGroupWithYesNo
              label="Has another party acquired the rights to this mortgage contract?*"
              name="hasOtherPartyRightsToMortgage"
              value={typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage}
              onValueChange={onRadioChange}
            />
          </FormControl>
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="new-lender-name"
              label="New Lender Name"
              name="newLenderName"
              variant="outlined"
              value={typeSpecificCauseOfAction.newLenderName}
              onChange={onInputChange}
              onBlur={setDirtyFields}
              required={typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage}
              error={
                typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage
                  ? requiredRule(
                    dirtyFields,
                    typeSpecificCauseOfAction,
                    'newLenderName'
                  )
                  : null
              }
            />
            <TextField
              id="new-lender-reference"
              label="New Lender Reference"
              name="newLenderReference"
              variant="outlined"
              value={typeSpecificCauseOfAction.newLenderReference}
              onChange={onInputChange}
              onBlur={setDirtyFields}
              required={typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage}
              error={
                typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage
                  ? requiredRule(
                    dirtyFields,
                    typeSpecificCauseOfAction,
                    'newLenderReference'
                  )
                  : null
              }
            />
          </FormGroup>
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="audit-report-name"
              label="Audit Report Name"
              name="auditReportName"
              variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
              value={typeSpecificCauseOfAction.auditReportName}
              onChange={onInputChange}
              onBlur={setDirtyFields}
              required={true}
              error={requiredRule(
                dirtyFields,
                typeSpecificCauseOfAction,
                'auditReportName'
              )}
              InputProps={{
                readOnly: isBlockedForSolicitor,
              }}
            />
            <TextField
              id="audit-version-number"
              label="Audit Version Number"
              name="auditVersionNumber"
              variant="outlined"
              value={typeSpecificCauseOfAction.auditVersionNumber}
              onChange={onInputChange}
              onBlur={setDirtyFields}
            />
          </FormGroup>

          <FormGroup className={classes.fourOfInputs}>
            <DatePickerWithClear
              name="fullAuditReportDate"
              label="Full Audit Report Date"
              value={typeSpecificCauseOfAction.fullAuditReportDate}
              onChange={(date) => onDateChange(date, 'fullAuditReportDate')}
              onBlur={setDirtyFields}
              required={true}
              error={requiredRule(
                dirtyFields,
                typeSpecificCauseOfAction,
                'fullAuditReportDate'
              )}
              isReadOnly={isBlockedForSolicitor}
            />
          </FormGroup>
        </FormGroup>
      </Paper>
    </div>
  );
};
