import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';
import gridStyles from '../../styles/gridStyles';

export const useStyles = makeStyles(() => ({
  unavailableGroup: {
    color: colors.warning,
    fontStyle: 'italic',
  },

  addedGroup: {
    color: colors.grey7F,
    fontStyle: 'italic',
    lineHeight: '23px',
  },

  narrowIcon: {
    padding: 0,
  },
}));

export const manageAccessGridStyles = {
  ...gridStyles,

  MuiPaper: {
    elevation2: {
      ...gridStyles?.MuiPaper?.elevation2,
      boxShadow: 'none',
      padding: '5px',
    },
  },

  MuiTableBody: {
    root: {
      ...gridStyles?.MuiTableBody?.root,
      height: '300px',

      // HACK: fix for displaying empty table using defined height
      "& tr[class^='MuiTableRow-root-']:only-of-type": {
        height: '300px !important',
      },
    },
  },

  MuiTableRow: {
    root: {
      ...gridStyles?.MuiTableRow?.root,
      height: '60px',
      '&:empty': {
        borderLeft: `1px solid ${colors.greyE6}`,
        borderRight: `1px solid ${colors.greyE6}`,
        height: '60px !important',
      },
      '&:last-child:empty': {
        height: '0px !important',
      },
    },
  },
};
