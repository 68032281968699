import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';

import {
  fetchPolicyById,
  fetchMovements,
} from '../../store/reducers/policy/details';
import { useStyles } from './policyDetailsTabsStyles';
import { includesAny } from '../../support/helpers';
import { userRoles } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';
import { AddMovementModal, MovementsList, AddAuditModal } from '../../components/';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';

export default function PolicyMovementsTabItem({ policy }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isMovementsLoading } = useSelector((state) => state.policy.details);
  const userGroups = useSelector(userGroupsSelector);
  const [openAddMovementModal, setOpenAddMovementModal] = useState(false);
  const [openAddAuditModal, setOpenAddAuditModal] = useState(false);
  const [defaultCreateMovementType, setDefaultCreateMovementType] = useState("");
  const [ openMovementCreationWindow, setOpenMovementCreationWindow ] = useState(null);

  useEffect(() => {
    if (!openMovementCreationWindow) {
      return;
    }

    setOpenAddMovementModal(!!openMovementCreationWindow.openWindow);
    setDefaultCreateMovementType(openMovementCreationWindow.movementType);
  }, [openMovementCreationWindow]);

  const handleOpenMovementModal = () => {
    setOpenAddMovementModal(true);
  };

  const handleOpenAuditModal = () => {
    setOpenAddAuditModal(true);
  };

  const reloadPolicy = () => {
    dispatch(fetchPolicyById(policy.id));
    dispatch(fetchMovements(policy.id));
  };

  const onClose = () => {
    setOpenMovementCreationWindow({
      openWindow: false,
      movementType: "",
    });
  };

  return (
    <>
      <div className={classes.tabHeader}>
        <Typography variant="h4">Movements</Typography>
        {includesAny(userGroups, [
          userRoles.resolve,
          userRoles.solicitor,
        ]) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenMovementModal}
              className={classes.button}
              startIcon={<AddIcon />}
            >
              Add Movement
            </Button>
          )}
        {includesAny(userGroups, [
          userRoles.auditor,
        ]) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAuditModal}
              className={classes.button}
              startIcon={<AddIcon />}
            >
              Audit
            </Button>
          )}
      </div>
      <LoadingWrapper loading={isMovementsLoading}>
        <MovementsList movements={policy.movements} policy={policy} setOpenMovementCreationWindow={setOpenMovementCreationWindow}/>
      </LoadingWrapper>

      {openAddMovementModal && (
        <AddMovementModal
          openModal={openAddMovementModal}
          setOpenModal={setOpenAddMovementModal}
          policy={policy}
          onSuccess={reloadPolicy}
          onClose={onClose}
          defaultMovementType={defaultCreateMovementType}
        />
      )}

      {openAddAuditModal && (
        <AddAuditModal
          openModal={openAddAuditModal}
          setOpenModal={setOpenAddAuditModal}
          policy={policy}
          onSuccess={reloadPolicy}
        />
      )}
    </>
  );
}
