import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography} from '@material-ui/core';

import { AttachmentsListExpandable, } from '../../components';

import { useStyles } from './styles';


export default function TrialDocumentationDetails({movement}) {

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          Supportive Counsel's Advice
        </Typography>
        <AttachmentsListExpandable attachments={movement.supportiveCounselsAdviceAttachments} showHeading={false} />
      </Grid>
      <hr/>
      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          Property Re-Surveyed by Head Surveyor
        </Typography>
        <AttachmentsListExpandable attachments={movement.propertyReSurveyedByHeadSurveyorAttachments} showHeading={false} />
      </Grid>

    </Grid>
  );
};