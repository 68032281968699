import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { fetchBordereau, cleanReducer } from '../../store/reducers/report/bordereau';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';

export default function BordereauReport() {
  const dispatch = useDispatch();

  const { isLoading, downloadSucceeded, url, filename } = useSelector((state) => state.report.bordereau);

  useEffect(() => {
    dispatch(fetchBordereau());
  }, [dispatch]);

  useEffect(() => {
    if (downloadSucceeded) {
      saveAs(url, filename);
    }
  }, [downloadSucceeded]);

  useEffect(() => {
    return () => {
      dispatch(cleanReducer());
    };
  }, []);

  return (
    <LoadingWrapper loading={isLoading} />
  );
}
