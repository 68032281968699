import React from 'react';

import { Divider, Grid, List, Paper } from '@material-ui/core';

import { AttachmentsListExpandable, ListItem } from '../../components';

import {
  attachmentTypes,
  schemeType,
  reinsuranceBasis,
} from '../../support/constants';
import { getFormattedDate } from '../../support/helpers';
import IconScheme from '../../assets/schemeDetails.svg';

import { useStyles } from './schemeDetailsHeaderStyles';

export default function SchemeDetailsHeader(props) {
  const classes = useStyles();
  const { scheme } = props;

  const periodDate =
    getFormattedDate(scheme.periodFrom) +
    '-' +
    getFormattedDate(scheme.periodTo);

  return (
    <Paper className={classes.header}>
      <Grid container>
        <img className={classes.detailIcon} src={IconScheme} alt="detail" />
        <Divider className={classes.divider} orientation="vertical" flexItem />

        <List className={`${classes.list} ${classes.listFullWidth}`}>
          <ListItem primary="Scheme Name" secondary={scheme.name} />
          <ListItem
            primary="Scheme Type"
            secondary={schemeType[scheme.type].type}
          />
        </List>
        <Divider className={classes.divider} orientation="vertical" flexItem />

        <List className={classes.list}>
          <ListItem primary="Period" secondary={periodDate} />
          <ListItem
            primary="Reinsurance Basis"
            secondary={reinsuranceBasis[scheme.reinsuranceBasis]}
          />
        </List>
        <Divider className={classes.divider} orientation="vertical" flexItem />

        <Grid item lg={2}>
          <AttachmentsListExpandable
            attachments={scheme.attachments.filter(
              (a) => a.type === attachmentTypes.None
            )}
            modalHeadingText="Scheme Attachments"
          />
        </Grid>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <Grid item lg={2}>
          <AttachmentsListExpandable
            attachments={scheme.attachments.filter(
              (a) => a.type === attachmentTypes.SchemePublic
            )}
            headingText="Policy Attachments"
            modalHeadingText="Policy Attachments"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
