import React from 'react';
import ReactDOM from 'react-dom';
import { processSilentRenew } from 'redux-oidc';
import AppWithStore from './AppWithStore';
import * as serviceWorker from './serviceWorker';

if (window.location.pathname === '/silent') {
  processSilentRenew();
} else {
  ReactDOM.render(<AppWithStore />, document.getElementById('root'));
  serviceWorker.register();
}
