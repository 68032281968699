import React, { useState } from 'react';

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { Modal } from '../../components';
import IconAttachment from '../../assets/attachment.svg';

import { useStyles } from './styles';

export default function AttachmentsListExpandable({
  attachments = [],
  showHeading = true,
  headingText = 'Attachments',
  modalHeadingText = 'Attachments',
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenAttachmentModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const AttachmentListItem = (props) => {
    const { attachment, ...rest } = props;
    return (
      <ListItem
        className={classes.link}
        component="a"
        href={attachment.url}
        title={attachment.name}
        target="_blank"
        download
        {...rest}
      >
        <ListItemIcon className={classes.icon}>
          <img src={IconAttachment} alt="attachment" />
        </ListItemIcon>
        <ListItemText
          primary={attachment.name}
          primaryTypographyProps={{ className: classes.linkText }}
        />
      </ListItem>
    );
  };

  return (
    <List className={classes.listExpandable}>
      {showHeading && (
        <ListItem>
          <ListItemText
            primary={headingText}
            primaryTypographyProps={{ variant: 'subtitle2' }}
          />
        </ListItem>
      )}

      {!attachments.length && '-'}

      {attachments?.slice(0, 3).map((attachment) => {
        return (
          <AttachmentListItem key={attachment.url} attachment={attachment} />
        );
      })}

      {attachments?.length > 3 && (
        <>
          <ListItem
            className={classes.link}
            component="a"
            href="#"
            onClick={handleOpenAttachmentModal}
          >
            <ListItemText
              primaryTypographyProps={{ className: classes.more }}
              primary="Show all attachments"
            />
          </ListItem>

          <Modal
            onClose={handleClose}
            open={openModal}
            titleText={modalHeadingText}
            PaperProps={{ className: classes.modal }}
          >
            <div className={classes.content}>
              {attachments?.map((attachment) => {
                return (
                  <AttachmentListItem
                    key={`${attachment.url}_modal`}
                    attachment={attachment}
                  />
                );
              })}
            </div>
          </Modal>
        </>
      )}
    </List>
  );
}
