import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  accordion: {
    position: 'relative',
    width: '100%',
    paddingBottom: 64,
    margin: '0 !important',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
  },
  accordionAction: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: 64,
    padding: 0,
    color: colors.primary,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    borderTop: `1px solid ${colors.greyE6}`,
    '& > div': {
      margin: '0 !important',
    },
    '& * img': {
      marginLeft: 10,
    },
  },
  accordionDetails: {
    display: 'block',
    padding: 0,
    marginBottom: 30,
  },
}));
