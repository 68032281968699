import React from 'react';
import { Grid, GridList, Typography } from '@material-ui/core';
import IconPhone from '../../assets/phone.svg';
import IconMail from '../../assets/email.svg';
import { uniqueId as getUniqueId } from '../../support/helpers';

import { useStyles } from './styles.js';

export const ContactInformation = (props) => {
  const { scheme, role, index, getValueByRole, restrictedForInsurer } = props;
  const classes = useStyles();

  const roleCompany = getValueByRole(scheme, role, 'company', index);

  return (
    <Grid>
      {roleCompany?.contacts && (
        <>
          {roleCompany.contacts.map((contact, index) => {
            return (
              <GridList
                className={classes.contactList}
                cols={2}
                cellHeight="auto"
                key={contact.order || getUniqueId()}
              >
                <Typography variant="subtitle2">Contact {index + 1}</Typography>
                <Typography>
                  <img src={IconPhone} alt="phone" />
                  {restrictedForInsurer(contact.telephone)}
                </Typography>
                <Typography>{restrictedForInsurer(contact.name)}</Typography>
                <Typography>
                  <img src={IconMail} alt="mail" />
                  {restrictedForInsurer(contact.email)}
                </Typography>
              </GridList>
            );
          })}
        </>
      )}
    </Grid>
  );
};
