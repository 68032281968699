import React from 'react';

export const PreviewElementContainer = ({
 classes, title, children 
}) => {
  return (
    <>
      <h2 className={classes.previewTitle}>{title}</h2>
      {children}
    </>
  );
};
