import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { fetchScheme } from '../../store/reducers/policy/save';
import schemesIcon from '../../assets/images/schemes.svg';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import { Breadcrumbs } from '../../components';
import { PolicyWizard } from '../../components/PolicyWizard/PolicyWizard';
import { savePolicy } from '../../store/reducers/policy/save';
import { cleanReducer as cleanPoliciesPremiumReducer } from '../../store/reducers/policy/premium';
import { cleanReducer as cleanPoliciesCreateReducer } from '../../store/reducers/policy/save';

import { useStyles } from './styles';

export const CreatePolicy = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const schemeId = props.match.params.schemeId;
  const dispatch = useDispatch();

  const {
    selectedScheme,
    isFetchingScheme,
    isSaving,
    savedPolicyId,
  } = useSelector((state) => state.policy.save);

  React.useEffect(() => {
    return () => {
      dispatch(cleanPoliciesCreateReducer());
      dispatch(cleanPoliciesPremiumReducer());
    };
  }, []);

  React.useEffect(() => {
    if (savedPolicyId) {
      history.push({
        pathname: `/policies/${savedPolicyId}`,
        state: { fresh: true },
      });
    }
  }, [savedPolicyId]);

  React.useEffect(() => {
    dispatch(fetchScheme(schemeId));
  }, [dispatch, schemeId]);

  return (
    <div className={classes.root}>
      <LoadingWrapper loading={isFetchingScheme}>
        {selectedScheme?.id ? (
          <>
            <Breadcrumbs current="Create Policy">
              <Link to={'/'}>
                <img src={schemesIcon} alt="Schemes" />
              </Link>
            </Breadcrumbs>

            <h1 className={classes.header}>
              {`Create a New Policy for Scheme ${selectedScheme.codePrefix} ${selectedScheme.codeSuffix}`}
            </h1>
            <PolicyWizard
              scheme={selectedScheme}
              classes={classes}
              isCreate={true}
              onSave={(policy) => {
                dispatch(
                  savePolicy(selectedScheme.type, {
                    ...policy,
                    schemeId: selectedScheme.id,
                  })
                );
              }}
              isSaving={isSaving}
            />
          </>
        ) : null}
      </LoadingWrapper>
    </div>
  );
};
