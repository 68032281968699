import { schemeCriteriaAnswers } from './constants';

export function prepareSchemaCriteriaForPolicy(criteria) {
  return criteria.map((c) => ({
    possibleAnswers:
      c.answerType === 1
        ? [schemeCriteriaAnswers.yes, schemeCriteriaAnswers.no]
        : [
            schemeCriteriaAnswers.yes,
            schemeCriteriaAnswers.no,
            schemeCriteriaAnswers.pending,
          ],
    question: c.question,
    incorrectAnswer: schemeCriteriaAnswers.no,
    inconclusiveAnswer: schemeCriteriaAnswers.pending,
    schemeCriterionId: c.id,
    order: c.order,
  }));
}
