import React from 'react';

import {
  Grid
} from '@material-ui/core';

import {
  ListItem,
  Section,
} from '../../components';

import {
  getFormattedAddress,
  defaultIfEmpty,
} from '../../support/helpers';

import IconContact from '../../assets/person.svg';
import IconPhone from '../../assets/phone.svg';
import IconMail from '../../assets/email.svg';

import { useStyles } from './styles';

export default function ContactsList(props) {
  const { contact, index = 0 } = props;
  const classes = useStyles();

  const ContactTitle = () => (
    <div className={classes.contactTitle}>
      <img src={IconContact} alt="contact" />
      Contact {index + 1}
    </div>
  );

  return (
    <>
      {contact && (
        <Section
          title={ContactTitle()}
          highlighted={true}
        >
          <Grid container>
            <Grid item lg={3}>
              <ListItem
                primary="Name"
                secondary={contact?.name}
              />
            </Grid>
            
            <Grid item lg={3}>
              <ListItem
                primary="Email"
                secondary={(
                  <>
                    <img src={IconMail} alt="email" className={classes.icon} />
                    {defaultIfEmpty(contact?.email)}
                  </>
                )}
              />
            </Grid>
            
            <Grid item lg={3}>
              <ListItem
                primary="Contact Number"
                secondary={(
                  <>
                    <img src={IconPhone} alt="phone" className={classes.icon} />
                    {defaultIfEmpty(contact?.telephone)}
                  </>
                )}
              />
            </Grid>
            
            <Grid item lg={3}>
              <ListItem
                primary="Address"
                secondary={getFormattedAddress(contact?.address)}
              />
            </Grid>
          </Grid>
        </Section>
      )}
    </>
  );
};
