import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TextField, Typography } from '@material-ui/core';

import { uniqueId as getUniqueId, capitalizeWords } from '../../support/helpers';

import { useStyles } from './styles';

export default function AddressFieldsEdit({
  type,
  required,
  data,
  onChange,
  heading,
  validator,
  addDirtyField,
}) {
  data = data || {};
  const classes = useStyles();
  const [uniqueId] = useState(getUniqueId());

  const onEventChange = (event) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );
    onChange(
      {
        ...data,
        [propertyName]: event.target.value,
      },
      type
    );
  };

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onEventChange(event);
  }

  const uppercaseInputChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    onEventChange(event);
  }

  return (
    <>
      {heading && (
        <Typography variant="h6" className={classes.inputSectionHeading}>
          {heading.toUpperCase()}
        </Typography>
      )}
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          variant="outlined"
          label="House Number and Street"
          id={`${uniqueId}-street`}
          name={`${uniqueId}-street`}
          value={data.street ?? ''}
          onChange={capitalizeInputChange}
          required={required}
          error={required && validator?.hasErrorToDisplay(`${uniqueId}-street`)}
          onBlur={addDirtyField}
          inputProps={{
            style: {textTransform: 'capitalize'},
          }}
        />
        <TextField
          variant="outlined"
          label="Postcode"
          id={`${uniqueId}-postcode`}
          name={`${uniqueId}-postcode`}
          value={data.postcode ?? ''}
          onChange={uppercaseInputChange}
          required={required}
          error={
            required && validator?.hasErrorToDisplay(`${uniqueId}-postcode`)
          }
          onBlur={addDirtyField}
          inputProps={{
            style: {textTransform: 'uppercase'},
          }}
        />
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          variant="outlined"
          label="Town/City"
          id={`${uniqueId}-city`}
          name={`${uniqueId}-city`}
          value={data.city ?? ''}
          onChange={capitalizeInputChange}
          required={required}
          error={required && validator?.hasErrorToDisplay(`${uniqueId}-city`)}
          onBlur={addDirtyField}
          inputProps={{
            style: {textTransform: 'capitalize'},
          }}
        />
        <TextField
          variant="outlined"
          label="County"
          id={`$${uniqueId}-county`}
          name={`${uniqueId}-county`}
          value={data.county ?? ''}
          onChange={capitalizeInputChange}
          onBlur={addDirtyField}
          inputProps={{
            style: {textTransform: 'capitalize'},
          }}
        />
      </FormGroup>
    </>
  );
}

AddressFieldsEdit.defaultProps = {
  required: true,
};

AddressFieldsEdit.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.object,
  required: PropTypes.bool,
  heading: PropTypes.string,
  validator: PropTypes.object,
  addDirtyField: PropTypes.func,
};
