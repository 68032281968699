import { createAction, createSlice } from '@reduxjs/toolkit';

export const storageSlice = createSlice({
  name: 'create',
  initialState: {
    isLoading: true,
    storageData: [],
  },
  reducers: {
    startSaving: (state) => {
      state.isLoading = true;
    },
    hasError: (state) => {
      state.isLoading = false;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.storageData = action.response.data;
    },
  },
});

export const { startSaving, hasError, success } = storageSlice.actions;

export const getFromStorage = createAction('getFromStorage', (id) => ({
  meta: {
    callAPITypes: [startSaving, success, hasError],
    requestURL: `/storage/${id}`,
    method: 'GET',
    requestConfig: {},
  },
  type: 'getFromStorage',
  payload: null,
}));

export const saveToStorage = createAction('saveToStorage', (data, id) => ({
  meta: {
    callAPITypes: [startSaving, success, hasError],
    requestURL: `/storage/${id}`,
    method: 'POST',
    requestConfig: {
      data: data,
    },
  },
  type: 'saveToStorage',
  payload: null,
}));

export const removeFromStorage = createAction('removeFromStorage', (id) => ({
  meta: {
    callAPITypes: [startSaving, success, hasError],
    requestURL: `/storage/${id}`,
    method: 'DELETE',
    requestConfig: {},
  },
  type: 'removeFromStorage',
  payload: null,
}));

export default storageSlice.reducer;
