import React from 'react';
import { PreviewElementContainer } from './PreviewElementContainer';
import { useStyles } from '../styles';
import { ViewSpecificCauseOfActionCWI } from '../../Policy/ViewSpecificCauseOfActionCWI';
import { ViewSpecificCauseOfActionMMC } from '../../Policy/ViewSpecificCauseOfActionMMC';

const SpecificCauseOfAction = ({
 schemeType, typeSpecificCauseOfAction 
}) => {
  switch (schemeType) {
    case 1:
      return (
        <ViewSpecificCauseOfActionCWI
          typeSpecificCauseOfAction={typeSpecificCauseOfAction}
        />
      );
    case 2:
      return (
        <ViewSpecificCauseOfActionMMC
          typeSpecificCauseOfAction={typeSpecificCauseOfAction}
        />
      );

    default:
      return 'Unknown scheme type';
  }
};

export const PreviewSpecificCauseOfAction = ({
  scheme,
  typeSpecificCauseOfAction,
}) => {
  const classes = useStyles();
  return (
    <PreviewElementContainer
      classes={classes}
      title="4. Specific Cause of Action"
    >
      <SpecificCauseOfAction
        schemeType={scheme.type}
        typeSpecificCauseOfAction={typeSpecificCauseOfAction}
      />
    </PreviewElementContainer>
  );
};
