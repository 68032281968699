import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid, List, Paper, Tooltip } from '@material-ui/core';
import { SyncProblem } from '@material-ui/icons';
import { isEmpty, xor } from 'lodash';

import PersonIcon from '../../assets/person.svg';
import { ListItem, Warning } from '../../components';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import { getCurrentUserProfile } from '../../store/reducers/global/userProfile';
import { userGroupTypes } from '../../support/constants';
import { transformToLabel } from '../../support/helpers';

import { useStyles } from './styles';

export default function Profile() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentUserProfile, isLoading } = useSelector(
    (state) => state.global.userProfile
  );

  const cachedUserProfile =
    JSON.parse(sessionStorage.getItem('cachedUserInfo')) || {};

  useEffect(() => {
    dispatch(getCurrentUserProfile());
  }, []);

  const wasUserChanged = () => {
    return (
      wasAnyPropertyChanged(['name', 'surname', 'displayName', 'mail']) ||
      wasAnySecurityRoleChanged() ||
      wasAnyCompanyChanged()
    );
  };

  const wasAnyPropertyChanged = (propertyNames) => {
    return propertyNames.reduce((accumulated, propertyName) => {
      return (
        cachedUserProfile[propertyName] !== currentUserProfile[propertyName] ||
        accumulated
      );
    }, false);
  };

  const wasAnySecurityRoleChanged = () => {
    const getIds = (roles) => roles.map((role) => role.id);

    const currentSecurityRoles = getIds(getSecurityRoles(currentUserProfile));
    const cachedSecurityRoles = getIds(getSecurityRoles(cachedUserProfile));

    return !isEmpty(xor(currentSecurityRoles, cachedSecurityRoles));
  };

  const wasAnyCompanyChanged = () => {
    const getIds = (roles) => roles.map((role) => role.id);

    const currentCompanies = getIds(getCompanies(currentUserProfile));
    const cachedCompanies = getIds(getCompanies(cachedUserProfile));

    return !isEmpty(xor(currentCompanies, cachedCompanies));
  };
  const ChangePropertyTooltip = ({ propertyName }) => {
    return (
      <ChangedTooltip wasChanged={wasAnyPropertyChanged([propertyName])} />
    );
  };

  const ChangedTooltip = ({ wasChanged }) => {
    return wasChanged ? (
      <Tooltip title="Changed outside the application">
        <SyncProblem className={classes.icon} />
      </Tooltip>
    ) : null;
  };

  const getSecurityRoles = (userProfile) => {
    return (userProfile?.groups || []).filter(
      (g) => g.type === userGroupTypes.security
    );
  };

  const getCompanies = (userProfile) => {
    return (userProfile?.groups || []).filter(
      (g) =>
        g.type === userGroupTypes.solicitor || g.type === userGroupTypes.insurer
    );
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <h1>Profile</h1>
      {currentUserProfile && (
        <>
          {wasUserChanged() && (
            <Warning className={classes.warning}>
              Your profile has been modified outside the application. Please log
              in again to application to refresh your view and permissions.
            </Warning>
          )}
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item className={classes.listFullWidth}>
                <img className={classes.userIcon} src={PersonIcon} alt="user" />
              </Grid>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />

              <Grid item lg={4}>
                <List>
                  <ListItem
                    primary={
                      <div>
                        Name:
                        <ChangePropertyTooltip propertyName="name" />
                      </div>
                    }
                    secondary={currentUserProfile.name}
                  />
                  <ListItem
                    primary={
                      <div>
                        Surname:
                        <ChangePropertyTooltip propertyName="surname" />
                      </div>
                    }
                    secondary={currentUserProfile.surname}
                  />
                  <ListItem
                    primary={
                      <div>
                        Display Name:
                        <ChangePropertyTooltip propertyName="displayName" />
                      </div>
                    }
                    secondary={currentUserProfile.displayName}
                  />
                  <ListItem
                    primary={
                      <div>
                        Mail:
                        <ChangePropertyTooltip propertyName="mail" />
                      </div>
                    }
                    secondary={currentUserProfile.mail}
                  />
                </List>
              </Grid>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Grid item lg={4}>
                <List>
                  <ListItem
                    primary={
                      <div>
                        Role:
                        <ChangedTooltip
                          wasChanged={wasAnySecurityRoleChanged()}
                        />
                      </div>
                    }
                    secondary={currentUserProfile.roles
                      .map((role) => transformToLabel(role))
                      .join(', ')}
                  />
                  <ListItem
                    primary={
                      <div>
                        Company:
                        <ChangedTooltip wasChanged={wasAnyCompanyChanged()} />
                      </div>
                    }
                    secondary={getCompanies(currentUserProfile)
                      .map((g) => g.name)
                      .join(', ')}
                  />
                </List>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </LoadingWrapper>
  );
}
