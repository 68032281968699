import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSending: false,
  wasSent: false,
};

export const requestMoreInfoSlice = createSlice({
  name: 'movements',
  initialState: { ...initialState },
  reducers: {
    startSending: (state) => {
      state.isSending = true;
    },
    hasError: (state) => {
      state.isSending = false;
    },
    sendingSuccess: (state, req) => {
      state.isSending = false;
      state.wasSent = true;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startSending,
  hasError,
  sendingSuccess,
  cleanReducer,
} = requestMoreInfoSlice.actions;

export const requestMoreInfo = createAction(
  'requestMoreInfo',
  (movementId, message) => ({
    meta: {
      callAPITypes: [startSending, sendingSuccess, hasError],
      requestURL: `/movements/requestMoreInfo`,
      method: 'POST',
      requestConfig: {
        data: {
          movementId: movementId,
          message: message,
        },
      },
    },
    type: 'requestMoreInfo',
    payload: {
      movementId: movementId,
      message: message,
    },
  })
);

export default requestMoreInfoSlice.reducer;
