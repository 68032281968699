import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex } from 'lodash';

import {
  deleteFile,
  uploadFile,
} from '../../store/reducers/global/filesUpload';
import {
  AttachmentsListFull,
  Info,
  Section,
  Uploader,
  Warning,
} from '../../components';

import { useStyles } from './styles';
import { attachmentTypes } from '../../support/constants';

export default function AttachmentsEdit({
  attachments,
  addAttachment,
  deleteAttachment,
  attachmentType = attachmentTypes.None,
  title = 'Attachments',
  limitListItemsWidth = true,
  hasError = false,
  hasWarning = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const attachmentsCountLimit = 10;
  const {
    isDeleting,
    isUploading,
    hasErrorAfterUpload,
    uploadedFile,
    deletedFileName,
  } = useSelector((state) => state.global.upload);

  const [
    isUploadToCurrentPlaceholder,
    setIsUploadToCurrentPlaceholder,
  ] = useState(false);
  const [
    hasErrorAfterUploadToCurrentPlaceholder,
    setHasErrorAfterUploadToCurrentPlaceholder,
  ] = useState(false);

  const [
    isDeletionFromCurrentPlaceholder,
    setIsDeletionFromCurrentPlaceholder,
  ] = useState(false);

  const isAttachmentsCountExceeded =
    attachments?.length >= attachmentsCountLimit;

  useEffect(() => {
    if (isUploadToCurrentPlaceholder && !isUploading) {
      if (!hasErrorAfterUpload) {
        addAttachment({ ...uploadedFile, type: attachmentType });
      } else {
        setHasErrorAfterUploadToCurrentPlaceholder(true);
      }

      setIsUploadToCurrentPlaceholder(false);
    }
  }, [isUploading]);

  useEffect(() => {
    if (isDeletionFromCurrentPlaceholder && !isDeleting) {
      const index = findIndex(attachments, (attachment) => {
        return attachment.name == deletedFileName;
      });

      if (index !== -1) {
        deleteAttachment(index);
        setIsDeletionFromCurrentPlaceholder(false);
      }
    }
  }, [isDeleting]);

  const handleFile = (file) => {
    setIsUploadToCurrentPlaceholder(true);
    setHasErrorAfterUploadToCurrentPlaceholder(false);
    dispatch(uploadFile(file));
  };

  const handleAttachmentDeletion = (fileName) => {
    setIsDeletionFromCurrentPlaceholder(true);
    dispatch(deleteFile(fileName));
  };

  return (
    <Section title={title} highlighted={true} additionalClasses={classes.container} warning={hasWarning} error={hasError}>
      <Uploader
        saveFile={handleFile}
        disabled={isUploading || isAttachmentsCountExceeded}
      />
      {isUploadToCurrentPlaceholder && (
        <Info className={classes.inlineControl}>
          The file is uploading... Please stay on this step till end of upload.
        </Info>
      )}
      {isAttachmentsCountExceeded && (
        <Info className={classes.inlineControl}>
          Limit for number of Attachments has been reached. No more Attachments
          can be added in this section.
        </Info>
      )}
      {hasErrorAfterUploadToCurrentPlaceholder && (
        <Warning className={classes.inlineControl}>Upload failed</Warning>
      )}
      <AttachmentsListFull
        attachments={attachments}
        deleteAttachment={handleAttachmentDeletion}
        limitListItemsWidth={limitListItemsWidth}
      />
    </Section>
  );
}
