import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MaterialTable from 'material-table';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import gridStyles from '../../styles/gridStyles';
import IconView from '../../assets/view.svg';
import editIcon from '../../assets/edit.svg';

import { CustomLookup, GridRowMenu, StatusBadge } from '../../components';
import { includesAny, transformObject } from '../../support/helpers';
import { userRoles, policyStatuses, policyStatusesOptions } from '../../support/constants';
import { fetchPolicies } from '../../store/reducers/policy/list';
import { saveFilters, resetFilters } from '../../store/reducers/policy/filters';
import { userGroupsSelector } from '../../store/selectors/global';
import { useTheme } from '@material-ui/styles';

export default function PolicyList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTheme = useTheme();
  const tableRef = React.useRef();

  const userGroups = useSelector(userGroupsSelector);
  const filters = useSelector((state) => state.policy.filters);

  const [policyStatusesWithIdKey] = useState(
    transformObject(policyStatuses, (obj) => obj.value)
  );

  const [wasInitialListDownloaded, setWasInitialListDownloaded] = useState(
    false
  );

  const getSavedFilterForField = (fieldName, multipleValues = false) => {
    var filtersForGivenField = filters?.filters?.find(filter => filter.field === fieldName);
    if (multipleValues) {
      return filtersForGivenField?.multipleValues ?? '';
    } else {
      return filtersForGivenField?.value ?? '';
    }
  }

  let [columns] = useState([
    { 
      title: 'Policy number', 
      field: 'number', 
      width: 200,
      defaultFilter: getSavedFilterForField('number'),
    },
    {
      title: 'Policy holder',
      field: 'policyHolder',
      hidden: userGroups.includes(userRoles.insurer),
      defaultFilter: getSavedFilterForField('policyHolder'),
    },
    { 
      title: 'Postcode', 
      field: 'postcode', 
      width: 30, 
      defaultFilter: getSavedFilterForField('postcode'),
    },
    { 
      title: 'Solicitor reference', 
      field: 'solicitorReference',
      defaultFilter: getSavedFilterForField('solicitorReference'),
    },
    { 
      title: 'Solicitor', 
      field: 'createdByGroupName',
      defaultFilter: getSavedFilterForField('createdByGroupName'), 
    },
    {
      title: 'Status',
      field: 'status',
      lookup: policyStatusesOptions,
      defaultFilter: getSavedFilterForField('status', true), 
      width: 50,
      render: (rowData) => {
        return (
          <StatusBadge
            status={rowData.status}
            statusType={policyStatusesWithIdKey}
          />
        );
      },
      filterComponent: (props) => <CustomLookup {...props} />
    },
    {
      title: 'Action',
      align: 'center',
      width: 20,
      render: (rowData) => (
        <GridRowMenu
          rowId={rowData.id}
          menuItems={[
            {
              icon: <img src={IconView} alt="View" />,
              text: 'View',
              isVisible: includesAny(userGroups, [
                userRoles.resolve,
                userRoles.insurer,
                userRoles.auditor,
                userRoles.solicitor,
              ]),
              handleClick: () =>
                history.push({
                  pathname: `/policies/${rowData.id}`,
                }),
            },
            {
              icon: <img src={editIcon} />,
              text: 'Amend',
              isVisible: includesAny(userGroups, [
                userRoles.resolve,
                userRoles.solicitor,
              ]),
              handleClick: () =>
                history.push(`/policies/${rowData.id}/amend`),
            },
          ]}
        />
      ),
    },
  ]);

  const saveCurrentPagesSetup = (currentPage, pageSize) => {
    dispatch(
      saveFilters({...filters,
        page: currentPage,
        pageSize: pageSize,
      })
    );
  }

  const saveCurrentFilters = (currentFilters) => {
    dispatch(
      saveFilters({...filters,
        filters: currentFilters
      })
    );
  }

  const clearFilters = () => {
    dispatch(resetFilters())
      .then(() => {
        window.location.reload();
      });
  }

  const listTheme = createMuiTheme(currentTheme, {
    overrides: gridStyles,
  });

  return (
    <React.Fragment>
      <MuiThemeProvider theme={listTheme}>
        <MaterialTable
          title="Policies"
          columns={columns}
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve, reject) => {

              var mappedFilters = query.filters.map(filter => ({
                field: filter.column.field,
                value: filter.value.constructor === Array ? null : filter.value,
                multipleValues: filter.value.constructor === Array ? filter.value : null,
              }));
              saveCurrentFilters(mappedFilters);

              // this is workaround for not working InitialPage property of MaterialTable (we want to set initial page other than 0)
              if (!wasInitialListDownloaded && query.page == 0 && filters.page && filters.page > 0) {
                reject();
                tableRef.current.onChangePage(null, filters.page);
                return;
              }

              dispatch(fetchPolicies(query.page, query.pageSize, mappedFilters))
                .then((req) => {
                  if (!req) {
                    return reject();
                  }
                  var result = req.response.data;
                  const policies = result.items.map((i) => ({ ...i }));
                  setWasInitialListDownloaded(true);
                  resolve({
                    data: policies,
                    page: result.pageIndex,
                    totalCount: result.totalItems,
                  });
                })
            })
          }
          options={{
            filtering: true,
            sorting: false,
            search: false,
            draggable: false,
            pageSize: filters.pageSize,
            debounceInterval: 500,
          }}
          actions={[
            {
              icon: 'restart_alt',
              tooltip: 'Clear filters',
              isFreeAction: true,
              onClick: clearFilters,
            }
          ]}
          onChangePage={saveCurrentPagesSetup}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}
