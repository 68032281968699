import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';

import { getFormattedMoney } from '../../support/helpers';

import QddlTableTemplate from './QddlTableTemplate';
import { useStyles } from './styles';

export default function QddlTablePreview(props) {
  const classes = useStyles();
  const { qddl } = props;

  return (
    <QddlTableTemplate>
      {qddl?.map((row, index) => (
        <TableRow className={classes.qddlTableRow} key={index}>
          <TableCell
            className={classes.qddlTableCell}
            align="center"
            component="th"
            scope="row"
          >
            {getFormattedMoney(row?.quantumBandFrom)} -{' '}
            {getFormattedMoney(row?.quantumBandTo)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.preProceedingsPremium?.funded)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.preProceedingsPremium?.deferred)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.preProceedingsPremium?.limit)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.proceedingsPremium?.funded)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.proceedingsPremium?.deferred)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.proceedingsPremium?.limit)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.trialPremium?.funded)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.trialPremium?.deferred)}
          </TableCell>
          <TableCell className={classes.qddlTableCell} align="center">
            {getFormattedMoney(row?.trialPremium?.limit)}
          </TableCell>
        </TableRow>
      ))}
    </QddlTableTemplate>
  );
}
