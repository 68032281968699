const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(() => ({
  inlineControl: {
    display: 'inline-block',
  },
  container: {
    padding: '18px 22px',
  },
}));
