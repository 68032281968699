import { colors } from './styles';

const gridStyles = {
  MuiPaper: {
    root: {
      padding: '0',
      boxShadow: 'none !important',
    },
  },

  MuiToolbar: {
    gutters: {
      padding: '0 10px !important',
      color: `${colors.text}`,
      backgroundColor: `${colors.secondary}`,
    },
    root: {
      backgroundColor: `${colors.secondary}`,
    },
  },

  MuiTablePagination: {
    toolbar: {
      backgroundColor: `${colors.white}`,
    },
  },

  MuiTypography: {
    h6: {
      fontFamily: [
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: '500',
      fontSize: '36px',
      marginBottom: '20px',
    },
  },

  MuiTable: {
    root: {
      boxShadow:
        '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
    },
  },

  MuiTableFooter: {
    root: {
      backgroundColor: `${colors.white}`,
    },
  },

  MuiTableCell: {
    root: {
      borderLeft: `1px solid ${colors.greyE6}`,
      borderRight: `1px solid ${colors.greyE6}`,
      borderBottom: `1px solid ${colors.greyE6}`,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },

    head: {
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '12px',
      padding: '18px 26px',
      borderBottom: `1px solid ${colors.greyE6}`,
      borderTop: `1px solid ${colors.greyE6}`,
    },

    footer: {
      border: `1px solid ${colors.greyE6}`,
    },
  },

  MuiTableRow: {
    root: {
      '&:nth-child(even)': {
        backgroundColor: colors.greyF5,
      },
      '&:nth-child(odd)': {
        backgroundColor: `${colors.white}`,
      },
      '&:empty': {
        height: '0px !important',
      },
    },
  },
};

export default gridStyles;
