import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';

import {
  fetchPolicyById,
  fetchMovements,
} from '../../store/reducers/policy/details';
import { includesAny } from '../../support/helpers';
import { movementTypes, policyStatuses, userRoles } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';
import {
  AddMovementModal,
  MovementClaimsTotals,
  MovementsList,
} from '../../components';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';

import { useStyles } from './policyDetailsTabsStyles';

export default function PolicyClaimsTabItem({ policy }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isMovementsLoading } = useSelector((state) => state.policy.details);
  const userGroups = useSelector(userGroupsSelector);
  const [openModal, setOpenModal] = React.useState(false);

  const claimMovements = policy.movements.filter(
    (movement) => movement.type === movementTypes.Claim.value
  );

  const handleOpenMovementModal = () => {
    setOpenModal(true);
  };

  const reloadPolicy = () => {
    dispatch(fetchPolicyById(policy.id));
    dispatch(fetchMovements(policy.id));
  };

  const displayButton = () => {
    return includesAny(userGroups, [userRoles.resolve]) && !includesAny([policy.status], [policyStatuses.CARef.value, policyStatuses.CANoRef.value]);
  }

  return (
    <>
      <div className={classes.tabHeader}>
        <Typography variant="h4">Claims</Typography>
      </div>
      <LoadingWrapper loading={isMovementsLoading}>
        <Typography className={classes.panelSectionHeading} variant="h5">
          Totals for Claims
        </Typography>
        <MovementClaimsTotals movements={claimMovements} />
        <div className={classes.panelSectionHeading}>
          <Typography variant="h5">Claims</Typography>
          {displayButton() && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenMovementModal}
              className={classes.button}
              startIcon={<AddIcon />}
            >
              Add Claim
            </Button>
          )}
        </div>
        <MovementsList movements={claimMovements} policy={policy} />
      </LoadingWrapper>
      {openModal && (
        <AddMovementModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          policy={policy}
          onSuccess={reloadPolicy}
          onlyClaims={true}
        />
      )}
    </>
  );
}
