import React from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import CopyAddressIcon from '@material-ui/icons/FileCopy';

import { Section } from '../../components';
import { useStyles } from './styles';
import deleteIcon from '../../assets/images/delete.svg';
import {capitalizeWords} from '../../support/helpers';

const ageValid = (age) =>
  Boolean(age) && parseInt(age) == age && age >= 18 && age <= 79;
const titleValid = (title) => Boolean(title);
const forenameValid = (forename) => Boolean(forename);
const surnameValid = (surname) => Boolean(surname);
const postcodeValid = (postcode) => Boolean(postcode);
const addressValid = (address) => Boolean(address);
const townValid = (town) => Boolean(town);

export const validateClaimant = (c) =>
  ageValid(c.age) &&
  titleValid(c.title) &&
  forenameValid(c.forename) &&
  surnameValid(c.surname) &&
  postcodeValid(c.postcode) &&
  addressValid(c.address) &&
  townValid(c.town);

export const ClaimantForm = ({
  titleClassName,
  claimant,
  onClaimantChange,
  claimantIndex,
  onDelete,
  isClaimantBlocked,
  copyLeadClaimantAddress,
}) => {
  const classes = useStyles();
  const [dirtyFields, setDirtyFieldsState] = React.useState([]);
  const setDirtyFields = (event) =>
    setDirtyFieldsState([...dirtyFields, event.target.name]);

  const onChange = (event) =>
    onClaimantChange(
      {
        ...claimant,
        [event.target.name]: event.target.value,
      },
      claimantIndex
    );

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onChange(event);
  }

  const uppercaseInputChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    onChange(event);
  }

  const deleteClaimant = () => onDelete(claimantIndex);

  const handleCopyLeadClaimantAddress = () => {
    copyLeadClaimantAddress(claimantIndex);
  };

  const CopyLeadClaimantAddressButton = !claimant.isLead ? (
    <Button onClick={handleCopyLeadClaimantAddress}>
      <CopyAddressIcon /> Copy Lead Claimant Address
    </Button>
  ) : (
    undefined
  );

  return (
    <>
      <FormGroup className={classes.wizardForm}>
        <h4 className={titleClassName}>
          {claimant.isLead ? 'Lead Claimant' : `Claimant ${claimantIndex + 1}`}
        </h4>
        <FormControl
          variant="outlined"
          className={classes.claimantTitle}
          required={true}
          error={dirtyFields.includes('title') && !titleValid(claimant.title)}
        >
          <InputLabel id="claimant-title-label">Claimant Title</InputLabel>
          <Select
            labelId="claimant-title-label"
            id="claimant-title"
            name="title"
            label="Claimant Title"
            value={claimant.title}
            onChange={onChange}
            onBlur={setDirtyFields}
          >
            <MenuItem value="mr">Mr.</MenuItem>
            <MenuItem value="mrs">Mrs.</MenuItem>
            <MenuItem value="miss">Miss</MenuItem>
            <MenuItem value="ms">Ms.</MenuItem>
          </Select>
        </FormControl>
        <FormGroup className={classes.pairOfInputs}>
          <TextField
            id="claimant-forename"
            label="Forename"
            name="forename"
            variant="outlined"
            value={claimant.forename}
            onChange={capitalizeInputChange}
            required={true}
            error={
              dirtyFields.includes('forename') &&
              !forenameValid(claimant.forename)
            }
            onBlur={setDirtyFields}
            inputProps={{
              style: {textTransform: 'capitalize'},
            }}
          />
          <TextField
            id="claimant-surname"
            label="Surname"
            name="surname"
            variant={isClaimantBlocked ? 'filled' : 'outlined'}
            value={claimant.surname}
            onChange={capitalizeInputChange}
            required={true}
            InputProps={{
              readOnly: isClaimantBlocked,
              style: {textTransform: 'capitalize'},
            }}
            error={
              dirtyFields.includes('surname') && !surnameValid(claimant.surname)
            }
            onBlur={setDirtyFields}
          />
        </FormGroup>
        <Section
          title="Address"
          mainSection={true}
          additionalActionComponent={CopyLeadClaimantAddressButton}
        >
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="claimant-postcode"
              label="Postcode"
              name="postcode"
              variant="outlined"
              value={claimant.postcode}
              onChange={uppercaseInputChange}
              required={true}
              error={
                dirtyFields.includes('postcode') &&
                !postcodeValid(claimant.postcode)
              }
              onBlur={setDirtyFields}
              inputProps={{
                style: {textTransform: 'uppercase'},
              }}
            />
            <TextField
              id="claimant-address"
              label="House Number and Street"
              name="address"
              variant="outlined"
              value={claimant.address}
              onChange={capitalizeInputChange}
              required={true}
              error={
                dirtyFields.includes('address') &&
                !addressValid(claimant.address)
              }
              onBlur={setDirtyFields}
              inputProps={{
                style: {textTransform: 'capitalize'},
              }}
            />
          </FormGroup>
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="claimant-town"
              label="Town/City"
              name="town"
              variant="outlined"
              value={claimant.town}
              onChange={capitalizeInputChange}
              required={true}
              error={dirtyFields.includes('town') && !townValid(claimant.town)}
              onBlur={setDirtyFields}
              inputProps={{
                style: {textTransform: 'capitalize'},
              }}
            />
            <TextField
              id="claimant-county"
              label="County"
              name="county"
              variant="outlined"
              value={claimant.county}
              onChange={capitalizeInputChange}
              required={false}
              onBlur={setDirtyFields}
              inputProps={{
                style: {textTransform: 'capitalize'},
              }}
            />
          </FormGroup>
        </Section>
        <div className={classes.pairOfInputs}>
          <FormGroup className={classes.pairOfInputs}>
            <TextField
              id="claimant-age"
              label="Age"
              name="age"
              variant="outlined"
              type="number"
              helperText="Must be between 18 and 79"
              error={dirtyFields.includes('age') && !ageValid(claimant.age)}
              value={claimant.age || ''}
              onChange={onChange}
              InputProps={{ inputProps: { min: 18, max: 79, step: 1 } }}
              required={true}
              onBlur={setDirtyFields}
            />
            <TextField
              id="claimant-number"
              name="telephone"
              label="Contact Number"
              variant="outlined"
              value={claimant.telephone || ''}
              onChange={onChange}
              onBlur={setDirtyFields}
            />
          </FormGroup>
        </div>
        {!claimant.isLead && !isClaimantBlocked ? (
          <div className={classes.deleteClaimant}>
            <Button onClick={deleteClaimant}>
              <img src={deleteIcon} alt="remove" />
              Remove Claimant
            </Button>
          </div>
        ) : null}
      </FormGroup>
    </>
  );
};
