import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { find } from 'lodash';

import { AttachmentsListExpandable, ListItem, Warning } from '../../components';
import { courts } from '../../support/constants';
import { getMatchingQddlRow } from '../../store/reducers/policy/matchingQddlRow';
import { 
  shouldShowWarningAboutDatesNotInRange,
  shouldShowWarningAboutNotSameDates,
  shouldShowWarningAboutDateHeld,
  shouldShowWarningAboutChangedBand,
  shouldShowWarningAboutIncorrectCourt } from '../../support/movementHelpers';
import { useStyles } from './styles';

import {
  getFormattedDate,
  getFormattedPercent,
  getFormattedMoney,
  defaultIfEmpty,
} from '../../support/helpers';

export default function ProceedingsRequestDetails({movement}) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    matchingQddlRow,
    isError,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.movement.matchingQddlRow);

  const { policy } = useSelector(
    (state) => state.policy.details
  );

  const getCourtDisplayName = () => find(courts, {value: movement.court}).displayName;

  useEffect(() => {
    dispatch(
      getMatchingQddlRow(
        policy.id,
        { quantumSpecial: movement.quantumSpecial, quantumGeneral: movement.quantumGeneral })
    );
  }, [])

  return (
    <Grid container>
      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={true} />
          Full & supportive RICS survey in court approved format on file
        </Typography>
        <AttachmentsListExpandable attachments={movement.ricsSurveyAttachments} showHeading={false} />
      </Grid>
      <hr/>

      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={true} />
          Still viable defendant with funds confirmed
        </Typography>
        <AttachmentsListExpandable attachments={movement.viableDefendantAttachments} showHeading={false} />
      </Grid>

      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={movement.letterOfClaimSentToCorrectDefendantConfirmed || false} />
          Letter of Claim sent to correct defendant
        </Typography>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={movement.letterOfClaimProofOfDeliveryConfirmed || false} />
          Proof of delivery
        </Typography>
        <AttachmentsListExpandable attachments={movement.letterOfClaimProofOfDeliveryAttachments} showHeading={false} />
      </Grid>

      <Grid item lg={12} className={classes.gridItemMargin}>
      <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={movement.defendantResponseToLetterOfClaimConfirmed ?? false} />
          Defendant response to the Letter of Claim received?
        </Typography>
        <AttachmentsListExpandable attachments={movement.defendantResponseToTheLetterOfClaimAttachments} showHeading={false} />
      </Grid>

      <Grid item lg={12} className={classes.gridItemMargin}>
      <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={movement?.defendantInsurerPolicyWordingConfirmed || false} />
          Defendant insurer policy wording, schedule and cover note
        </Typography>
        <AttachmentsListExpandable attachments={movement.defendantInsurerPolicyWordingAttachments} showHeading={false} />
      </Grid>

      <Grid item lg={12} className={classes.gridItemMargin}>
      <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={true} />
          Schedule of damages
        </Typography>
        <AttachmentsListExpandable attachments={movement.scheduleOfDamagesAttachments} showHeading={false} />
      </Grid>

      <Grid item lg={12}>
        <ListItem
          primary="Limitation validated"
          secondary={getFormattedDate(movement.limitationValidated)}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Date of proceedings to be issued"
          secondary={getFormattedDate(movement.dateOfProceedingsToBeIssued)}
        />
      </Grid>
      <Grid item lg={6}>
        {!movement.isDateOfProceedingsHeld && <ListItem
          primary="Date of proceedings to be served"
          secondary={getFormattedDate(movement.dateOfProceedingsToBeServed)}
        />}
        {movement.isDateOfProceedingsHeld &&<ListItem
          primary="Date of proceedings to be served"
          secondary={"On hold"}
        />}
      </Grid>
      <Grid item lg={12} className={classes.warning}>
        {shouldShowWarningAboutDatesNotInRange(movement) && <Warning className={'reset-mb'}>Served Date is more than one week grater than Issue Date.</Warning>}
        {shouldShowWarningAboutDateHeld(movement) && <Warning className={'reset-mb'}>Served Date is on hold.</Warning>}
        {shouldShowWarningAboutNotSameDates(movement) && <Warning className={'reset-mb'}>Served Date and Issue Date should generally be the same.</Warning>}
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Quantum general"
          secondary={getFormattedMoney(movement.quantumGeneral)}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Quantum special"
          secondary={getFormattedMoney(movement.quantumSpecial)}
        />
      </Grid>
      <Grid item lg={12} className={classes.warning}>
        {shouldShowWarningAboutChangedBand(policy, matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning>Quantum has changed band.</Warning>}
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Prospects"
          secondary={getFormattedPercent(movement.prospects)}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Court"
          secondary={getCourtDisplayName()}
        />
      </Grid>
      <Grid item lg={12} className={classes.warning}>
        {shouldShowWarningAboutIncorrectCourt(movement) &&<Warning>No 'County Court' or 'High court' selected.</Warning>}
      </Grid>
      <Grid item lg={12}>
        <ListItem
          primary="Comment"
          secondary={defaultIfEmpty(movement.comment)}
        />
      </Grid>
    </Grid>
  );
};