import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  uploadButton: {
    textTransform: 'uppercase',
    color: colors.green,
    cursor: 'pointer',
    '& img': {
      marginRight: 8,
    },
  },
  disabledUploadButton: {
    '& img': {
      filter: 'opacity(40%)',
    },
  },
}));
