import React from 'react';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { userRoles } from '../../support/constants';

export const menuItems = [
  {
    type: 'internal',
    url: '/policies-report',
    title: 'Policies Report',
    icon: <AssignmentOutlinedIcon />,
    order: 4,
    groupRequired: [userRoles.resolve],
  },
  {
    type: 'internal',
    url: '/bordereau',
    title: 'Bordereau Report',
    icon: <AssignmentOutlinedIcon />,
    order: 3,
    groupRequired: [userRoles.resolve],
  },
  {
    type: 'external',
    url: 'https://aad.portal.azure.com/',
    title: 'User Management',
    icon: <GroupOutlinedIcon />,
    order: 2,
    groupRequired: [userRoles.resolve],
  },
  {
    type: 'internal',
    url: '/policies',
    title: 'Policies',
    icon: <FileCopyOutlinedIcon />,
    order: 1,
  },
  {
    type: 'internal',
    url: '/',
    title: 'Schemes',
    icon: <AssignmentOutlinedIcon />,
    order: 0,
  },
].sort((a, b) => a.order - b.order);
