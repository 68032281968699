import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  changeButton: {
    display: 'flex',
    marginLeft: 15,
    fontSize: 14,
    fontWeight: 700,
    color: colors.primary,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '& img': {
      marginRight: 8,
    },
  },
  statusModal: {
    maxHeight: 400,
    width: 550,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > *:not(:last-child)': {
      marginBottom: '37px',
    },
  },
}));
