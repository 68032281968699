import React from 'react';
import { Grid } from '@material-ui/core';

import { ListItem } from '../../components';
import {
  getFormattedDate,
  getFormattedPercent,
  getFormattedMoney,
  defaultIfEmpty,
} from '../../support/helpers';

import { policyStage } from '../../support/constants';
import IconMail from '../../assets/email.svg';

import { useStyles } from './styles';

export const ViewCauseOfAction = ({ causeOfAction, policyPremium }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          Cause of Action Details
        </h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="CFA Signed Date"
          secondary={getFormattedDate(causeOfAction.cfaSignedDate)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Date of Knowledge"
          secondary={getFormattedDate(causeOfAction.dateOfKnowledge)}
        />
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Prospects of Success"
          secondary={getFormattedPercent(causeOfAction.prospectsOfSuccess)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Success Fee"
          secondary={getFormattedPercent(causeOfAction.successFee)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Estimated Damages - Special"
          secondary={getFormattedMoney(causeOfAction.estimatedDamagesSpecial)}
        />
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Estimated Damages - General"
          secondary={getFormattedMoney(causeOfAction.estimatedDamagesGeneral)}
        />
      </Grid>

      <Grid item lg={6}>
        <ListItem
          primary="Total Quantum"
          secondary={getFormattedMoney(causeOfAction.totalQuantum)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Funded Current Premium excl. IPT"
          secondary={getFormattedMoney(policyPremium.fundedCurrentExclIPT)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Deferred Current Premium excl. IPT"
          secondary={getFormattedMoney(policyPremium.deferredCurrentExclIPT)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="IPT"
          secondary={getFormattedPercent(policyPremium.ipt)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Total Current Premium incl. IPT"
          secondary={
            getFormattedMoney(policyPremium.totalCurrentInclIPT, 2, true)
          }
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Policy Inception Date"
          secondary={getFormattedDate(causeOfAction.policyInceptionDate)}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Policy Stage"
          secondary={policyStage[causeOfAction.policyStage].displayName.toUpperCase()}
        />
      </Grid>

      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          Solicitor
        </h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Solicitor Name"
          secondary={causeOfAction.solicitorName}
        />
      </Grid>
      <Grid item lg={9}>
        <ListItem
          primary="Solicitor Operating Address"
          secondary={causeOfAction.solicitorOperatingAddress}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Case Handler Name"
          secondary={causeOfAction.caseHandlerName}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Case Handler E-mail Address"
          secondary={
            <>
              <img src={IconMail} alt="mail" className={classes.icon} />
              {defaultIfEmpty(causeOfAction.caseHandlerEmailAddress)}
            </>
          }
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Case Handler Case Reference"
          secondary={causeOfAction.caseReference}
        />
      </Grid>
    </Grid>
  );
};
