import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  contactTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    '& img': {
      marginRight: 12,
      filter: 'grayscale(1)',
    },
  },
  inputSectionHeading: {
    marginBottom: 28,
  },
  pairOfInputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
    '& > *': {
      flex: '0 0 49%',
    },
    '& > &': {
      marginBottom: 0,
    },
  },
  tabs: {
    marginTop: '34px',
    marginBottom: '20px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
}));
