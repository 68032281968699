import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.greyE6,
    margin: '23px 0',
  },
}));
