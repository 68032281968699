import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { useStyles } from './styles';

export default function RadioGroupWithYesNo({
  label,
  name,
  value = null,
  onValueChange,
  isReadOnly,
  isRequired,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    const booleanValue = event.target.value === 'true';
    onValueChange(booleanValue, event.target.name);
  };
  return (
    <FormControl
      component="fieldset"
      error={false}
      className={classes.radioGroup}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={handleChange}>
        <FormControlLabel
          value={true}
          control={<Radio color="primary" required={isRequired} />}
          label="Yes"
          disabled={isReadOnly}
        />
        <FormControlLabel
          value={false}
          control={<Radio color="primary" required={isRequired} />}
          label="No"
          disabled={isReadOnly}
        />
      </RadioGroup>
    </FormControl>
  );
}
