import React, { useCallback, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import WizardStepSection from '../WizardStepSection';
import { Section } from '../../../components';
import { uniqueId as getUniqueId } from '../../../support/helpers';

import EligibleSolicitorSection from './EligibleSolicitorSection';

export default function EligibleSolicitors({ sectionKey }) {
  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const solicitorsInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.eligibleSolicitors,
    isEqual
  );
  const [solicitors, setSolicitors] = useState(
    JSON.parse(
      JSON.stringify(
        !solicitorsInit || solicitorsInit.length === 0
          ? [{ uniqueId: getUniqueId() }]
          : solicitorsInit
      )
    )
  );

  const onSolicitorsChange = useCallback((solicitor, index) => {
    setSolicitors((prevSolicitors) => {
      let solicitorsCopy = [...prevSolicitors];
      solicitorsCopy[index] = solicitor;
      return [...solicitorsCopy];
    }, []);
  }, []);

  const addEmptySolicitor = useCallback(() => {
    setSolicitors((prevSolicitors) => {
      return [...prevSolicitors, { uniqueId: getUniqueId() }];
    }, []);
  }, []);

  const deleteSolicitor = useCallback((index) => {
    setSolicitors((prevSolicitors) => {
      let solicitorsCopy = [...prevSolicitors];
      solicitorsCopy.splice(index, 1);
      return [...solicitorsCopy];
    }, []);
  }, []);

  const addDirtyField = useCallback(
    (event) => {
      validator.addDirtyField(event.target.name);
      forceUpdate();
    },
    [validator]
  );

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[solicitors]}
      dataToChange={{ eligibleSolicitors: solicitors }}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
    >
      <Section title="Eligible Solicitors" expanded={true}>
        {solicitors.map((solicitor, index) => (
          <EligibleSolicitorSection
            key={solicitor.id ?? solicitor.uniqueId}
            solicitor={solicitor}
            solicitorsCount={solicitors.length}
            index={index}
            onChange={onSolicitorsChange}
            validator={validator}
            deleteSolicitor={deleteSolicitor}
            addEmptySolicitor={addEmptySolicitor}
            addDirtyField={addDirtyField}
          />
        ))}
      </Section>
    </WizardStepSection>
  );
}
