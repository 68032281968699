import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';

export default function TheClaimIsAllocatedToMediationOrArbitration({ onChange, setCustomIsValid }) {

  useEffect(() => {
    setCustomIsValid(true);
  }, []);

  return (
    <>
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
      />
    </>
  );
}
