import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { findKey } from 'lodash';

import {
  fetchMovements,
  fetchPolicyById,
} from '../../store/reducers/policy/details';
import { Modal, Warning } from '../../components';
import { changePolicyStatus } from '../../store/reducers/policy/statusChange';
import { policyStatuses, schemeCriteriaAnswers, movementTypes, policyStages } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';

import IconChange from '../../assets/change.svg';

import { useStyles } from './policyStatusStyles';

export default function PolicyStatus({ status, policyId, policy, movements }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userGroups = useSelector(userGroupsSelector);
  const formRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState({});
  const [newStatus, setNewStatus] = useState('');
  const [readyToSave, setReadyToSave] = useState(false);
  const { isSaving } = useSelector((state) => state.policy.status);
  const hasAnyAvailableStatusToChange =
    Object.keys(availableStatuses).length > 0;
  const hasCriteriaWithNoAnswer = policy.schemeCriteriaResults.some(
    (sc) => sc.answer === schemeCriteriaAnswers.no
  );

  useEffect(() => {
    const filterByRolesAllowed = (policyStatusKey) =>
      policyStatuses[policyStatusKey].rolesAllowed.some((roles) =>
        userGroups.includes(roles)
      );

    const filterBasedOnCriteriaAnswers = (policyStatusKey) =>
      !hasCriteriaWithNoAnswer ||
      policyStatusKey === findKey(policyStatuses, policyStatuses.Unwind);

    const removeCurrentStatusFromList = (policyStatusKey) => {
      return policyStatuses[policyStatusKey].value !== status;
    };

    const removeOpenStatusWhenPolicyIsRestricted = (policyStatusKey) => {
      if (policyStatuses[policyStatusKey].value === policyStatuses.Open.value && policy.status === policyStatuses.Restrict.value){
        return false;
      }
      return true;
    }

    const removeStatusesWhichCannotBeSetWhenPolicyHasClaims = (policyStatusKey) => {
      var statusNotCA14orCARef = (policyStatuses[policyStatusKey].value !== policyStatuses.CA14.value && policyStatuses[policyStatusKey].value !== policyStatuses.CARef.value);
      var statusCA14OrCARef = (policyStatuses[policyStatusKey].value === policyStatuses.CA14.value || policyStatuses[policyStatusKey].value === policyStatuses.CARef.value)
      return statusNotCA14orCARef || (statusCA14OrCARef && !policy.hasClaimMovements);
    }

    const removeVoidIfPolicyHasClaimsOrIsNotPreProceedings = (policyStatusKey) => {
      var statusVoid = policyStatuses[policyStatusKey].value === policyStatuses.Void.value;
      var stagePreProceedings = policy.stage === policyStages.PreProceedings.value;
      return !(statusVoid && (policy.hasClaimMovements || !stagePreProceedings));
    }

    let policyStatusToDisplay = Object.assign({}, policyStatuses);

    policyStatusToDisplay = Object.keys(policyStatusToDisplay)
      .filter((key) => filterByRolesAllowed(key))
      .filter((key) => filterBasedOnCriteriaAnswers(key))
      .filter((key) => removeCurrentStatusFromList(key))
      .filter((key) => removeStatusesWhichCannotBeSetWhenPolicyHasClaims(key))
      .filter((key) => removeOpenStatusWhenPolicyIsRestricted(key))
      .filter((key) => removeVoidIfPolicyHasClaimsOrIsNotPreProceedings(key))
      .reduce((res, key) => ((res[key] = policyStatuses[key]), res), {});

    setAvailableStatuses(policyStatusToDisplay);
  }, [status, policy.movements]);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleClose = () => {
    setNewStatus('');
    setOpenModal(false);
  };

  const handleStatusChange = (event) => {
    const selectedStatus = parseInt(event.target.value, 10);

    setNewStatus(selectedStatus);
    setReadyToSave(!!selectedStatus);
  };

  const onSave = () => {
    dispatch(
      changePolicyStatus(policyId, {
        policyId,
        newStatus,
      })
    ).then(() => {
      dispatch(fetchPolicyById(policy.id));
      dispatch(fetchMovements(policy.id));
    });

    handleClose();
  };

  return (
    <>
      <Typography className={classes.changeButton} onClick={handleOpen}>
        <img src={IconChange} alt="change" />
        Change
      </Typography>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onSave}
        isReadyToConfirm={readyToSave}
        titleText="Change Policy Status"
        showActionButtons={true}
        isLoading={isSaving}
        PaperProps={{ className: classes.statusModal }}
      >
        <form ref={formRef} noValidate className={classes.form}>
          {hasCriteriaWithNoAnswer && (
            <Warning>
              Status change is not allowed. Change “No” information in Scheme
              Criteria on the Policy to enable this option.
            </Warning>
          )}
          {hasAnyAvailableStatusToChange && (
            <FormControl variant="outlined">
              <InputLabel id="policy-status-label">Policy Status</InputLabel>
              <Select
                name="policyStatus"
                id="policy-status-select"
                labelId="policy-status-label"
                value={newStatus}
                onChange={handleStatusChange}
                label="Policy Status"
              >
                {Object.keys(availableStatuses)
                  .sort()
                  .map((status) => (
                    <MenuItem key={status} value={policyStatuses[status].value}>
                      {policyStatuses[status]?.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {!hasAnyAvailableStatusToChange && !hasCriteriaWithNoAnswer && (
            <Warning>No Policy Status is available to change into.</Warning>
          )}
        </form>
      </Modal>
    </>
  );
}
