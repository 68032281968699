import React from 'react';

import { Grid } from '@material-ui/core';

import { AttachmentsListFull, ListItem, Section } from '../../../components';
import { getFormattedAddress } from '../../../support/helpers';

import ContactsList from '../ContactsList';

export default function RoleDetails({ data, sectionTitle, children }) {
  const CompanyInfo = ({ company }) => {
    return (
      <>
        <Grid container>
          <Grid item lg={3}>
            <ListItem primary="Name" secondary={company?.name ?? ''} />
          </Grid>
          <Grid item lg={3}>
            <ListItem
              primary="Company Number"
              secondary={company?.companyNumber ?? ''}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3}>
            <ListItem
              primary="Registered Address"
              secondary={getFormattedAddress(company?.registeredAddress)}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Operating Address"
              secondary={getFormattedAddress(company?.operatingAddress)}
            />
          </Grid>

          <Grid item lg={3}>
            <ListItem
              primary="Notice Address"
              secondary={getFormattedAddress(company?.noticeAddress)}
            />
          </Grid>
        </Grid>

        {company?.contacts?.map((contact, index) => (
          <ContactsList contact={contact} index={index} key={index} />
        ))}
      </>
    );
  };

  return (
    <Section title={sectionTitle} inside={true}>
      <CompanyInfo company={data?.company} />
      {children}
      <AttachmentsListFull attachments={data?.attachments} isPreview={true} />
    </Section>
  );
}
