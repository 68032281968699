import React from 'react';
import { Grid } from '@material-ui/core';

import { ListItem } from '../../components';
import {
  getFormattedDate,
  getFormattedMoney,
  defaultIfEmpty,
} from '../../support/helpers';

import {
  propertySubTypes,
  propertyBuildTypes,
  propertyStyles,
} from '../../support/constants';
import IconPhone from '../../assets/phone.svg';

import { useStyles } from './styles';

export const ViewSpecificCauseOfActionCWI = ({ typeSpecificCauseOfAction }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          Property Address
        </h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Postcode"
          secondary={typeSpecificCauseOfAction.propertyAddress.postcode}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="House Number and Street"
          secondary={typeSpecificCauseOfAction.propertyAddress.street}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Town/City"
          secondary={typeSpecificCauseOfAction.propertyAddress.city}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="County"
          secondary={typeSpecificCauseOfAction.propertyAddress.county}
        />
      </Grid>

      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          Property Details
        </h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Date of Installation"
          secondary={
            getFormattedDate(typeSpecificCauseOfAction.dateOfInstallation)
          }
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Property Style"
          secondary={propertyStyles[typeSpecificCauseOfAction.propertyStyle]}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Property Build Type"
          secondary={propertyBuildTypes[typeSpecificCauseOfAction.propertyBuildType]}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Property Sub Type"
          secondary={propertySubTypes[typeSpecificCauseOfAction.propertySubType].displayName}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Insulation Type"
          secondary={typeSpecificCauseOfAction.insulationType}
        />
      </Grid>
      <Grid item lg={9}>
        <ListItem
          primary="Number of Bedrooms"
          secondary={typeSpecificCauseOfAction.numberOfBedrooms}
        />
      </Grid>

      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          Defendant 1 (installer)
        </h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Name"
          secondary={typeSpecificCauseOfAction.defendant1Name}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Company Number"
          secondary={typeSpecificCauseOfAction.defendant1CompanyNumber}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Contact Number"
          secondary={
            <>
              <img src={IconPhone} alt="phone" className={classes.icon} />
              {defaultIfEmpty(typeSpecificCauseOfAction.defendant1ContactNumber)}
            </>
          }
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Postcode"
          secondary={typeSpecificCauseOfAction.defendant1Address.postcode}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="House Number and Street"
          secondary={typeSpecificCauseOfAction.defendant1Address.street}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Town/City"
          secondary={typeSpecificCauseOfAction.defendant1Address.city}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="County"
          secondary={typeSpecificCauseOfAction.defendant1Address.county}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Dissolved"
          secondary={
            typeSpecificCauseOfAction.hasDefendant1BeenDissolved
              ? 'Yes'
              : 'No'
          }
        />
      </Grid>

      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          Defendant Insurer
        </h3>
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Insurer Name"
          secondary={typeSpecificCauseOfAction.defendant2InsurerName}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Insurance Policy Number"
          secondary={typeSpecificCauseOfAction.defendant2InsurancePolicyNumber}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Policy Period"
          secondary={`
            ${getFormattedDate(
            typeSpecificCauseOfAction.defendant2PolicyPeriodFrom
          )} - ${getFormattedDate(
            typeSpecificCauseOfAction.defendant2PolicyPeriodTo
          )}
          `}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Policy Limit"
          secondary={
            getFormattedMoney(+typeSpecificCauseOfAction.defendant2PolicyLimit)
          }
        />
      </Grid>
      <Grid item lg={12}>
        <ListItem
          primary="Policy Aggregate Limit"
          secondary={
            getFormattedMoney(+typeSpecificCauseOfAction.defendant2PolicyAggregateLimit)
          }
        />
      </Grid>
    </Grid>
  );
};
