import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  saveValidationChanges,
  refreshSchemeInitForNextStep,
} from '../../store/reducers/scheme/save';
import { initializeStep } from '../../store/reducers/scheme/wizardStep';

export default function WizardStep({ sectionsCount, stepNumber, children }) {
  const dispatch = useDispatch();

  const isStepValidForLive = useSelector(
    (state) => state.scheme.wizardStep.isStepValidForLive
  );
  const isStepValidForDraft = useSelector(
    (state) => state.scheme.wizardStep.isStepValidForDraft
  );
  const wasInitialValidationSet = useSelector(
    (state) => state.scheme.wizardStep.wasInitialValidationSet
  );

  useEffect(() => {
    return () => {
      dispatch(refreshSchemeInitForNextStep());
    };
  }, []);

  useEffect(() => {
    dispatch(
      initializeStep({
        sectionsCount: sectionsCount,
        stepNumber: stepNumber,
      })
    );
  }, []);

  useEffect(() => {
    if (wasInitialValidationSet) {
      dispatch(
        saveValidationChanges({
          live: isStepValidForLive,
          draft: isStepValidForDraft,
          step: stepNumber,
        })
      );
    }
  }, [wasInitialValidationSet, isStepValidForLive, isStepValidForDraft]);

  return <>{children}</>;
}
