import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

import { DatePickerWithClear} from '../../../components';

export default function ResponseToLetterOfClaimReceived({ movement, onChange, setCustomIsValid, showAllValidationErrors }) {

  const [dirtyFields, setDirtyFieldsState] = useState([]);

  useEffect(() => {
    setCustomIsValid(false);
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const onDateChange = (fieldName, date) => {
    onChange({
        target: {
          name: fieldName,
          value: date ? date.toISOString() : date
        }
      });
  };

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);

  const validation = {
    dateIsValid: () => !!movement?.date,
  };

  const isFormValid = () => {
    return validation.dateIsValid();
  }

  return (
    <>
      <DatePickerWithClear
        name="date"
        label="Date"
        required={true}
        value={movement.date || null}
        onChange={(date) => onDateChange('date', date)}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={
          (showAllValidationErrors || dirtyFields.includes('date')) && !validation.dateIsValid()
        }
      />

      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
      />
    </>
  );
}
