import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { loadUser } from 'redux-oidc';

import callAPIMiddleware from './middlewares/callAPIMiddleware';
import userManager from './userManager';

import backendStorage from './reducers/global/backendStorage';
import notifications from './reducers/global/notifications';
import loadingWithScreenBlock from './reducers/global/loadingWithScreenBlock';
import identity from './reducers/identity';
import { reducer as oidcReducer } from 'redux-oidc';
import gridRowMenu from './reducers/global/gridRowMenu';
import filesUpload from './reducers/global/filesUpload';
import userProfile from './reducers/global/userProfile';
import createScheme from './reducers/scheme/save';
import schemeWizardStep from './reducers/scheme/wizardStep';
import schemeList from './reducers/scheme/list';
import schemeDetails from './reducers/scheme/details';
import savePolicy from './reducers/policy/save';
import policyList from './reducers/policy/list';
import policyDetails from './reducers/policy/details';
import policyStatusChange from './reducers/policy/statusChange';
import policyPremium from './reducers/policy/premium';
import matchingQddlRow from './reducers/policy/matchingQddlRow';
import groupsForScheme from './reducers/scheme/groupsForScheme';
import createMovement from './reducers/movement/create';
import reportBordereau from './reducers/report/bordereau';
import reportPolicies from './reducers/report/policies';
import policiesFilters from './reducers/policy/filters';

export default function createStore() {
  const globalReducer = combineReducers({
    backendStorage,
    notifications,
    oidc: oidcReducer,
    identity,
    gridRowMenu,
    loadingWithScreenBlock,
    upload: filesUpload,
    userProfile,
  });

  const schemeReducer = combineReducers({
    list: schemeList,
    details: schemeDetails,
    create: createScheme,
    groupsForScheme,
    wizardStep: schemeWizardStep,
  });

  const policyReducer = combineReducers({
    list: policyList,
    details: policyDetails,
    save: savePolicy,
    premium: policyPremium,
    status: policyStatusChange,
    filters: policiesFilters,
  });

  const movementReducer = combineReducers({
    create: createMovement,
    matchingQddlRow: matchingQddlRow,
  });

  const reportReducer = combineReducers({
    bordereau: reportBordereau,
    policies: reportPolicies,
  });

  const rootReducer = combineReducers({
    global: globalReducer,
    scheme: schemeReducer,
    policy: policyReducer,
    movement: movementReducer,
    report: reportReducer,
  });

  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: [thunk, callAPIMiddleware],
  });

  loadUser(store, userManager);

  return store;
}
