import React from 'react';

import { Section, SchemeCriteria } from '../../../components';

import { schemeType } from '../../../support/constants';

export default function PreviewSchemeCriteria({ scheme, isValidForLive }) {
  return (
    <Section
      title="3. Scheme Criteria"
      expanded={true}
      preview={true}
      areDataValidOnPreview={isValidForLive}
    >
      <Section title={schemeType[scheme?.type]?.description} inside={true}>
        <SchemeCriteria criteria={scheme?.criteria} />
      </Section>
    </Section>
  );
}
