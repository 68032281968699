import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUploading: false,
  hasErrorAfterUpload: false,
  uploadedFile: null,
};

export const fileUploadSlice = createSlice({
  name: 'upload',
  initialState: initialState,
  reducers: {
    startUploading: (state) => {
      state.isUploading = true;
    },
    hasUploadError: (state) => {
      state.hasErrorAfterUpload = true;
      state.isUploading = false;
    },
    successUpload: (state, action) => {
      state.uploadedFile = {
        name: action.response.data.fileName,
        url: action.response.data.fileUri,
      };
      state.isUploading = false;
    },
    startDeleting: (state) => {
      state.isDeleting = true;
    },
    hasDeletionError: (state, action) => {
      state.hasErrorAfterDelete = true;
      state.isDeleting = false;
      state.deletedFileName = action.deletedFileName;
    },
    successDeletion: (state, action) => {
      state.isDeleting = false;
      state.deletedFileName = action.deletedFileName;
    },
  },
});

export const {
  startUploading,
  hasUploadError,
  successUpload,
  startDeleting,
  hasDeletionError,
  successDeletion,
} = fileUploadSlice.actions;

export const uploadFile = createAction('uploadFile', (file) => {
  var fileFormData = new FormData();
  fileFormData.append('file', file);

  return {
    meta: {
      callAPITypes: [startUploading, successUpload, hasUploadError],
      requestURL: `/files`,
      method: 'POST',
      requestConfig: {
        data: fileFormData,
      },
    },
    type: 'uploadFile',
    payload: null,
  };
});

export const deleteFile = createAction('deleteFile', (fileName) => ({
  meta: {
    callAPITypes: [startDeleting, successDeletion, hasDeletionError],
    requestURL: `/files/${fileName}`,
    method: 'DELETE',
    requestConfig: {},
  },
  type: 'deleteFile',
  payload: { deletedFileName: fileName },
}));

export default fileUploadSlice.reducer;
