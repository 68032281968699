import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';

import NumberFormatCustom from './NumberFormatCustom';

export default function CurrencyTextField(props) {
  const {
    allowNegative,
    allowZero,
    decimalScale,
    isReadOnly,
    showCurrency,
    variant,
    ...other
  } = props;

  return (
    <TextField
      {...other}
      inputProps={{
        allowNegative: allowNegative,
        allowZero: allowZero,
        decimalScale: decimalScale,
      }}
      InputProps={{
        readOnly: isReadOnly,
        inputComponent: NumberFormatCustom,
        ...(showCurrency && {
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }),
      }}
      variant={variant ?? (isReadOnly ? 'filled' : 'outlined')}
    />
  );
}

CurrencyTextField.defaultProps = {
  isReadOnly: false,
  showCurrency: true,
};

CurrencyTextField.propTypes = {
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  isReadOnly: PropTypes.bool,
  showCurrency: PropTypes.bool,
  variant: PropTypes.string,
};
