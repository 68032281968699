import React, {useEffect, useState} from 'react';
import { TextField, Typography } from '@material-ui/core';

import { useStyles } from './styles';


export default function Cancellation14DaysMovement({ movement, onChange, setCustomIsValid, showAllValidationErrors  }) {

  const classes = useStyles();
  const [dirtyFields, setDirtyFieldsState] = useState([]);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const setDirtyFields = (fieldName) => setDirtyFieldsState([...dirtyFields, fieldName]);

  const validation = {
    isCommentValid: () => !!movement?.comment,
  };

  const isFormValid = () => {
    return validation.isCommentValid();
  }

  return (
    <>
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
        error={(showAllValidationErrors || dirtyFields.includes('comment')) && !validation.isCommentValid()}
        onBlur={(event) => {setDirtyFields(event.target.name);}}
      />

      <div>
        <Typography variant="overline" display="block" gutterBottom className={classes.disclaimer}>
          Solicitor confirms that the Client has cancelled the Policy within 14 days from the date of its creation.
        </Typography>
      </div>
    </>
  );
}
