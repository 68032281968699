import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSaving: false,
  wasSaved: false,
  status: null,
};

export const statusSlice = createSlice({
  name: 'policyStatus',
  initialState: initialState,
  reducers: {
    startSaving: (state) => {
      state.isSaving = true;
    },
    hasError: (state) => {
      state.isSaving = false;
    },
    changeSuccess: (state, action) => {
      state.status = action.newStatus;
      state.isSaving = false;
      state.wasSaved = true;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startSaving,
  hasError,
  changeSuccess,
  cleanReducer,
} = statusSlice.actions;

export const changePolicyStatus = createAction(
  'changePolicyStatus',
  (id, newStatus) => ({
    meta: {
      callAPITypes: [startSaving, changeSuccess, hasError],
      requestURL: `/policies/${id}/status`,
      method: 'PUT',
      requestConfig: {
        data: {
          ...newStatus,
        },
      },
    },
    type: 'changePolicyStatus',
    payload: newStatus,
  })
);

export default statusSlice.reducer;
