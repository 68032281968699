import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  moneyContainer: {
    display: 'flex',
  },
  money: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 8px',
    fontSize: '12px',
    fontWeight: '500',
    background: colors.greyE6,
    borderRadius: '4px',
    '& > img': {
      marginRight: 5,
    },
  },
}));
