import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  scheme: {},
  isLoading: true,
  isSolicitorSaving: false,
};

export const detailsSlice = createSlice({
  name: 'schemeDetails',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state) => {
      state.isLoading = false;
    },
    schemeSuccess: (state, action) => {
      state.scheme = action.response.data;
      state.isLoading = false;
    },

    startSolicitorSaving: (state) => {
      state.isSolicitorSaving = true;
    },
    savingSolicitorError: (state) => {
      state.isSolicitorSaving = false;
    },
    savingSolicitorSuccess: (state, action) => {
      state.isSolicitorSaving = false;
      state.scheme = {
        ...state.scheme,
        eligibleSolicitors: [
          ...state.scheme.eligibleSolicitors,
          { ...action.eligibleSolicitor },
        ],
      };
    },

    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  schemeSuccess,
  startSolicitorSaving,
  savingSolicitorError,
  savingSolicitorSuccess,
  cleanReducer,
} = detailsSlice.actions;

export const fetchSchemeById = createAction('fetchSchemeById', (id) => ({
  meta: {
    callAPITypes: [startLoading, schemeSuccess, hasError],
    requestURL: `/schemes/${id}`,
    method: 'GET',
  },
  type: 'fetchSchemeById',
  payload: null,
}));

export const addEligibleSolicitor = createAction(
  'addEligibleSolicitor',
  (schemeId, eligibleSolicitor) => ({
    meta: {
      callAPITypes: [
        startSolicitorSaving,
        savingSolicitorSuccess,
        savingSolicitorError,
      ],
      requestURL: `/schemes/${schemeId}/EligibleSolicitors`,
      method: 'POST',
      requestConfig: {
        data: {
          ...eligibleSolicitor,
        },
      },
    },
    type: 'addEligibleSolicitor',
    payload: { eligibleSolicitor: eligibleSolicitor },
  })
);

export default detailsSlice.reducer;
