import React, { useRef } from 'react';

import { Typography, Box } from '@material-ui/core';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const panel = useRef();
  return (
    <div ref={panel}>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    </div>
  );
}
