import React from 'react';
import PropTypes from 'prop-types';

import { ListItem as MuiListItem, ListItemText } from '@material-ui/core';

import { defaultIfEmpty } from '../../support/helpers';

import { useStyles } from './styles';

export default function ListItem(props) {
  const { primary, secondary, ...other } = props;
  const classes = useStyles();
  const second = typeof secondary === 'string' ? secondary.trim() : secondary;

  return (
    <MuiListItem className={classes.listItem}>
      <ListItemText
        primary={primary}
        secondary={defaultIfEmpty(second)}
        primaryTypographyProps={{
          variant: 'subtitle2',
        }}
        secondaryTypographyProps={{
          variant: 'body1',
        }}
        {...other}
      />
    </MuiListItem>
  );
}

ListItem.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  secondary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
};
