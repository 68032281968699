import { createSelector } from 'reselect';
import { safeSelector } from './helpers';

export const userLoadingSelector = safeSelector(
  (state) => state.global.oidc.isLoadingUser
);
export const userSelector = safeSelector((state) => state.global.oidc.user);

export const isUserLoggedSelector = createSelector(
  userSelector,
  (user) => !!user
);

export const userProfileSelector = createSelector(userSelector, (user) =>
  user ? user.profile : undefined
);

export const userGroupsSelector = createSelector(
  userProfileSelector,
  (userProfile) => (userProfile ? userProfile.groups : undefined)
);

export const accessTokenSelector = createSelector(userSelector, (user) =>
  user ? user.access_token : undefined
);

export const userIdSelector = createSelector(
  userProfileSelector,
  (userProfile) => (userProfile ? userProfile.sub : undefined)
);
