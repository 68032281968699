import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';

import { MultiplyAction } from '..';
import { uniqueId as getUniqueId } from '../../support/helpers';
import IconContact from '../../assets/person.svg';

import ContactFieldsEdit from './ContactFieldsEdit';
import TabPanel from './TabPanel';
import { useStyles } from './styles';

export default function ContactsPanels({
  data,
  onChange,
  validator,
  addDirtyField,
}) {
  data = !data || data.length === 0 ? [{ uniqueId: getUniqueId() }] : data;

  const classes = useStyles();

  const addEmptyItem = () => {
    const contactsCount = data.length;
    onChange([...data, { uniqueId: getUniqueId() }], 'contacts');
    handleContactTab(null, contactsCount);
  };

  const deleteItem = (index) => {
    const isLastElement = index === data.length - 1;
    if (isLastElement) {
      handleContactTab(null, 0);
    }

    const updatedItems = [...data.filter((d, i) => i !== index)];
    onChange(updatedItems, 'contacts');
  };

  const updateItem = (contact, index) => {
    let contactsCopy = [...data];
    contactsCopy[index] = contact;

    onChange(contactsCopy, 'contacts');
  };

  const ContactTitle = (index) => (
    <div className={classes.contactTitle}>
      <img src={IconContact} alt="contact" />
      Contact {index + 1}
    </div>
  );

  const [contactTab, setContactTab] = useState(0);

  const handleContactTab = (event, newValue) => {
    setContactTab(newValue);
  };

  return (
    <>
      <Tabs
        value={contactTab}
        onChange={handleContactTab}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        {data.map((contact, index) => (
          <Tab
            label={ContactTitle(index)}
            key={contact.id ?? contact.uniqueId}
          />
        ))}
      </Tabs>
      {data.map((contact, index) => (
        <TabPanel
          value={contactTab}
          index={index}
          key={contact.id ?? contact.uniqueId}
        >
          <MultiplyAction
            dataLength={data.length}
            index={index}
            actionText="contact"
            possibleLength={4}
            onDeleteItem={deleteItem}
            onAddItem={addEmptyItem}
            addOnlyForLast={false}
          >
            <ContactFieldsEdit
              data={contact}
              onChange={updateItem}
              validator={validator}
              addDirtyField={addDirtyField}
              index={index}
            />
          </MultiplyAction>
        </TabPanel>
      ))}
    </>
  );
}
