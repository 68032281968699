import { createAction, createSlice } from '@reduxjs/toolkit';
import { cancelledRequestErrorMessage } from '../../../support/constants';

const initialState = {
  isError: false,
  wasRecognizedAsOutsideQddl: false,
  matchingQddlRow: null,
  emptyQddlRow: {
    id: null,
  },
};

export const matchingQddlRowSlice = createSlice({
  name: 'matchingQddlRow',
  initialState: initialState,
  reducers: {
    startQuery: (state, action) => {
      if (state.requestSource) {
        state.requestSource.cancel(cancelledRequestErrorMessage);
      }

      state.requestSource = action.requestSource;
    },
    hasError: (state, payload) => {
      state.isError = true;
      state.wasRecognizedAsOutsideQddl = payload.error.response.status === 400;
      state.matchingQddlRow = state.emptyQddlRow;
    },
    success: (state, payload) => {
      state.isError = false;
      state.wasRecognizedAsOutsideQddl = false;
      state.matchingQddlRow = payload.response.data;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startQuery,
  hasError,
  success,
  cleanReducer,
} = matchingQddlRowSlice.actions;

export const getMatchingQddlRow = (policyId, quantum) => (dispatch) => {
  const getMatchingQddlRow = createAction(
    'getMatchingQddlRow',
    (policyId, quantum) => ({
      meta: {
        callAPITypes: [startQuery, success, hasError],
        requestURL: `/policies/${policyId}/matchingQddlRow`,
        method: 'GET',
        showErrorNotification: false,
        requestConfig: {
          params: {
            ...quantum,
          },
        },
      },
      type: 'getMatchingQddlRow',
      payload: null,
    })
  );

  dispatch(getMatchingQddlRow(policyId, quantum));
};

export default matchingQddlRowSlice.reducer;
