import { colors } from '../styles/styles';

export const mainTheme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          backgroundColor: '#F5F7F5',
          height: '100%',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h3: {
      fontSize: 36,
      fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 20,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 600,
      paddingTop: 20,
      paddingBottom: 10,
      '& + hr': {
        marginBottom: 10,
      },
    },
    subtitle2: {
      marginBottom: 6,
      fontWeight: 700,
      color: colors.text,
    },
    body1: {
      color: colors.text,
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '20px',
      color: colors.text,
      letterSpacing: '0.4px',
    },
    button: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  },
};
