import React from 'react';
import moment from 'moment';

/**
 * Function to check current date in date range, if is the same of after/before is false
 * @param {string} dateFrom - date from we check current date
 * @param {string} dateTo - date to we check current date
 *
 * @returns {boolean} - boolean value if current date is in the range
 */
export function checkDateInRange(dateFrom, dateTo, dateToCheck = null) {
  dateToCheck = dateToCheck ?? getCurrentDate();

  return (
    (!dateFrom || moment(dateToCheck).isSameOrAfter(dateFrom, 'day')) &&
    (!dateTo || moment(dateToCheck).isSameOrBefore(dateTo, 'day'))
  );
}

export function isSameOrAfter(dateToCheck, givenDate) {
  return moment(dateToCheck).isSameOrAfter(givenDate, 'day');
}

export function areDatesSame(date1, date2) {
  return !date1 || !date2 || moment(date1).isSame(date2, 'day');
}

/**
 * Function to check if dates follow each other
 * @param {string} dateFrom - date from
 * @param {string} dateTo - date to
 *
 * @returns {boolean} - boolean value if dates follow each other
 */
export function areDatesForPeriod(dateFrom, dateTo) {
  return !dateFrom || !dateTo || moment(dateFrom).isSameOrBefore(dateTo, 'day');
}

/**
 * Function to format date to have one place where import package and use the same format
 * @param {string} date - date as a string to format
 * @param {string} format - pass date format, default (DD/MM/YYYY - e.g. 01/01/2021)
 */
export function getFormattedDate(date, format = 'DD/MM/YYYY') {
  if (!date || !moment(date)._isValid) {
    return date || '';
  }

  return moment(date).format(format);
}

/**
 * Format price value to GB style and currency with fraction digits precision
 * if value is a number otherwise return value
 * @param {int|float} value - value price to format with GB style
 * @param {int} precision - number of fraction digits (default = 4)
 * @param {boolean} enablePrecision - enable/disable always display precision
 * @returns {string} formatted price with pound char or given value if it's not a number
 */
export function getFormattedMoney(value, precision = 4, enablePrecision) {
  if (!isNumber(value)) {
    return value || '-';
  }

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: isFloat(value) || enablePrecision ? precision : 0,
  });

  return formatter.format(value);
}

export const formattedMoneyWithoutPound = (value) => {
  // format to price and remove pound sign from formatted price
  return getFormattedMoney(value).replace(/\D/, '');
};

/**
 * Format value to percent format if value is a number otherwise return value
 * @param {int|float} value - integer or float value to percent format
 * @returns {string} formatted percent or given value if it's not a number
 */
export function getFormattedPercent(value) {
  if (!isNumber(value)) {
    return value || '-';
  }

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    minimumFractionDigits: isFloat(value) ? 4 : 0,
  });

  return formatter.format(value / 100);
}

export function getFormattedAddress(address = {}) {
  const copyAddress = JSON.parse(JSON.stringify(address));
  const addressArr = [];

  if (!isEmpty(copyAddress['street'])) {
    addressArr.push(copyAddress['street']);
  }

  if (!isEmpty(copyAddress['city'])) {
    addressArr.push(copyAddress['city']);
  }

  if (!isEmpty(copyAddress['county'])) {
    addressArr.push(copyAddress['county']);
  }

  if (!isEmpty(copyAddress['postcode'])) {
    addressArr.push(copyAddress['postcode']);
  }

  if (isEmpty(copyAddress) || isEmpty(addressArr)) {
    return '-';
  }

  return addressArr.join(', ');
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function isNumber(n) {
  return Number(n) === n;
}

export function getCurrentDate() {
  return moment().startOf('day');
}

export function includesAny(arrayToSearchIn, valuesToSearch) {
  return valuesToSearch.some((v) => arrayToSearchIn.indexOf(v) !== -1);
}

export function transformToLabel(text) {
  if (isEmpty(text)) {
    return defaultIfEmpty(text);
  }

  //shortcuts should not be transformed into labels
  if (text === text.toUpperCase()) {
    return text;
  }

  return (
    text
      // insert a space before all upper-case letters
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
}

/**
 * Restricted data for user if have role not allowed to see some confidential data and return special text
 * @param {array} userGroup - collection with available user group in app
 * @param {string|array} roles - single or collection with restricted role to don't display information
 * @param {string|number} element - element to display if user have permission to display
 * @param {string} text - text to display user when they don't have access
 *
 * @returns {string} - text to display if have permission or not
 */
export function restrictedData(userGroup, roles, element, text = 'restricted') {
  if (userGroup.some((group) => roles.includes(group))) {
    return <i className="restricted">{text}</i>;
  }

  return element || '-';
}

export const camelCaseToSentence = (camelCase) => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  return finalResult.trim();
};

export function isEmpty(value) {
  return (
    value === null || value === undefined || value === '' || value.length === 0
  );
}
export function defaultIfEmpty(value, defaultValue = '-') {
  return isEmpty(value) ? defaultValue : value;
}

export function uniqueId() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

/**
 * Tranform object to use another structure
 * @param {object} toTransform - object to tranform
 * @param {function} newKeyFunction - function to find new key
 *
 * @returns {object} - transformed object
 */
export function transformObject(toTransform, newKeyFunction) {
  let result = {};
  Object.values(toTransform).forEach((value) => {
    result = { ...result, [newKeyFunction(value)]: value };
  });

  return result;
}

export function capitalizeWords(input) {
  return input.replace(/^\w|\s\w|-\w|\.\w/g, (t) => t.toUpperCase());
}
