import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { markUserProfileAsCached } from '../../store/reducers/global/userProfile';
import {
  userSelector,
  userLoadingSelector,
  userGroupsSelector,
} from '../../store/selectors';
import { userShape } from '../../support/prop-types';
import { includesAny } from '../../support/helpers';
import {
  checkIfAccountIsValidIfUserIsInsurer,
  checkIfAccountIsValidIfUserIsSolicitor,
  getAssignedGroupsCount,
} from '../../support/userHelpers';

function PrivateRoute(props) {
  const {
    component: Component,
    user,
    userLoading,
    userGroups,
    requiredRoles,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const validateUserGroups = (groupsAssignedToUser) => {
    const securityGroupsErrorMessage = validateAssignedSecurityGroupsCount(
      userGroups
    );

    if (securityGroupsErrorMessage) {
      return securityGroupsErrorMessage;
    }

    const userConfigurationErrorMessage = validateIfUserIsCorrectlyConfigured(
      groupsAssignedToUser
    );

    if (userConfigurationErrorMessage) {
      return userConfigurationErrorMessage;
    }
  };

  const validateAssignedSecurityGroupsCount = (groupsAssignedToUser) => {
    const assignedGroupsCount = getAssignedGroupsCount(groupsAssignedToUser);

    if (assignedGroupsCount === 0) {
      return (
        <div>
          Sorry, you have no roles assigned - please contact with Resolve team
          to solve a problem with your account.
        </div>
      );
    }

    if (assignedGroupsCount > 1) {
      return (
        <div>
          Sorry, you have too many roles assigned - please contact with Resolve
          team to solve a problem with your account.
        </div>
      );
    }
  };

  const validateIfUserIsCorrectlyConfigured = (groupsAssignedToUser) => {
    const cachedUserProfile = JSON.parse(
      sessionStorage.getItem('cachedUserInfo')
    );

    if (!cachedUserProfile) {
      return <div>Please log in again to refresh user information.</div>;
    }

    dispatch(markUserProfileAsCached());

    const isValidForSolicitor = checkIfAccountIsValidIfUserIsSolicitor(
      cachedUserProfile,
      groupsAssignedToUser
    );
    if (!isValidForSolicitor) {
      return (
        <div>
          Sorry, your Solicitor account is incorrectly configured - please
          contact with Resolve team to solve a problem with your account.
        </div>
      );
    }

    const isValidForInsurer = checkIfAccountIsValidIfUserIsInsurer(
      cachedUserProfile,
      groupsAssignedToUser
    );
    if (!isValidForInsurer) {
      return (
        <div>
          Sorry, your Insurer account is incorrectly configured - please contact
          with Resolve team to solve a problem with your account.
        </div>
      );
    }
  };

  return (
    <Route
      {...rest}
      render={(componentProps) => {
        if (userLoading) {
          return <div />;
        }
        if (!user || user.expired) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: componentProps.location },
              }}
            />
          );
        }

        const groupsAssignedToUser = userGroups;
        const userGroupsErrorMessage = validateUserGroups(groupsAssignedToUser);
        if (userGroupsErrorMessage) {
          return userGroupsErrorMessage;
        }

        if (requiredRoles !== '*') {
          const unauthorizedNode = (
            <div>
              Sorry, you don't have the required permissions to view this page.
            </div>
          );

          if (Array.isArray(requiredRoles)) {
            if (!includesAny(groupsAssignedToUser, requiredRoles)) {
              return unauthorizedNode;
            }
          } else if (!groupsAssignedToUser.includes(requiredRoles)) {
            return unauthorizedNode;
          }
        }
        return <Component {...componentProps} />;
      }}
    />
  );
}

PrivateRoute.defaultProps = {
  user: undefined,
  userLoading: true,
  requiredRoles: '*',
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  user: userShape,
  userLoading: PropTypes.bool,
  requiredRoles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

function mapStateToProps(state) {
  return {
    user: userSelector(state),
    userLoading: userLoadingSelector(state),
    userGroups: userGroupsSelector(state),
  };
}

export default connect(mapStateToProps)(PrivateRoute);
