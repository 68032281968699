import React from 'react';
import { PreviewSchemeCriteria } from './PreviewElements/PreviewSchemeCriteria';
import { Paper } from '@material-ui/core';
import { PreviewClaimants } from './PreviewElements/PreviewClaimants';
import { PreviewCauseOfAction } from './PreviewElements/PreviewCauseOfAction';
import { PreviewSpecificCauseOfAction } from './PreviewElements/PreviewSpecificCauseOfAction';

export const Preview = ({
  scheme,
  policy,
  contentClassName,
  titleClassName,
}) => {
  return (
    <div>
      <h2 className={titleClassName}>Preview</h2>
      <Paper className={contentClassName}>
        <PreviewSchemeCriteria scheme={scheme} policy={policy} />
      </Paper>
      <Paper className={contentClassName}>
        <PreviewClaimants claimants={policy.claimants} />
      </Paper>
      <Paper className={contentClassName}>
        <PreviewCauseOfAction
          causeOfAction={policy.causeOfAction}
          policyPremium={policy.policyPremium}
        />
      </Paper>
      <Paper className={contentClassName}>
        <PreviewSpecificCauseOfAction
          scheme={scheme}
          typeSpecificCauseOfAction={policy.typeSpecificCauseOfAction}
        />
      </Paper>
    </div>
  );
};
