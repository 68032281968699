import React, { useEffect, useState } from 'react';
import { FormGroup, TextField } from '@material-ui/core';

import {
  DatePickerWithClear,
  RadioGroupWithYesNo,
  SelectWithOptions,
} from '../../../components';

import {
  propertyBuildTypes,
  propertyStyles,
  propertySubTypes,
} from '../../../support/constants';

import { getFormattedDate } from '../../../support/helpers';

import { useStyles } from '../styles';

import {
  Address,
  getEmptyAddress,
  initializeAddress,
} from '../../Address/Address';

export const SpecificCauseOfActionCwiProperty = ({
  leadClaimant,
  onEventChange,
  onDataChange,
  onUpdateChange,
  dirtyFields,
  setDirtyFields,
  hasErrorToDisplay,
  hasRequiredDate,
  typeSpecificCauseOfAction,
  typeSpecificCauseOfActionBeforeChange,
  subtitleClassName,
  isBlockedForSolicitor,
  isDateOfInstallationBetweenDates,
}) => {
  const classes = useStyles();
  const [propertySubTypesToDisplay, setPropertySubTypesToDisplay] = useState(
    {}
  );

  const isPropertyAddressReadOnly =
    isBlockedForSolicitor &&
    typeSpecificCauseOfActionBeforeChange.isPropertyAddressDifferentThanClaimantAddress;

  useEffect(() => {
    const getPropertySubTypesToDisplay = () =>
      Object.keys(propertySubTypes)
        .filter(
          (key) =>
            propertySubTypes[key].propertyType ==
            typeSpecificCauseOfAction.propertyBuildType
        )
        .reduce((res, key) => ((res[key] = propertySubTypes[key]), res), {});

    const cleanPropertySubType = () => {
      const propertyType = typeSpecificCauseOfAction.propertyBuildType;
      const propertySubType = typeSpecificCauseOfAction.propertySubType;
      if (
        propertySubType &&
        propertySubTypes[propertySubType].propertyType != propertyType
      ) {
        onDataChange('propertySubType')(null);
      }
    };

    cleanPropertySubType();
    setPropertySubTypesToDisplay(getPropertySubTypesToDisplay());
  }, [typeSpecificCauseOfAction.propertyBuildType]);

  useEffect(() => {
    onPropertyAddressSourceChanged(typeSpecificCauseOfAction.isPropertyAddressDifferentThanClaimantAddress)
  }, []);

  const onPropertyAddressSourceChanged = (value) => {
    let isAddressDifferentThanClaimant = value;
    let address = isAddressDifferentThanClaimant
      ? getEmptyAddress()
      : initializeAddress(
          leadClaimant.postcode,
          leadClaimant.address,
          leadClaimant.town,
          leadClaimant.county
        );

    onUpdateChange({
      propertyAddress: address,
      isPropertyAddressDifferentThanClaimantAddress: isAddressDifferentThanClaimant,
    });
  };

  const getHelperTextForDateOfInstallation = () => {
    const getDateText = (date, label) =>
      date ? `${label}: ${getFormattedDate(date)}` : null;

    const fromDate = typeSpecificCauseOfAction.defendant2PolicyPeriodFrom;
    const toDate = typeSpecificCauseOfAction.defendant2PolicyPeriodTo;

    const fromDateText = getDateText(fromDate, 'from');
    const toDateText = getDateText(toDate, 'to');
    const periodText = [fromDateText, toDateText]
      .filter((text) => !!text)
      .join(', ');

    return `Must be within the Defendant Insurer Policy Period dates (${
      periodText ? periodText : 'not defined'
    })`;
  };

  return (
    <>
      <h4 className={subtitleClassName}>Property Address</h4>
      <RadioGroupWithYesNo
        label="Is the property address different from the lead claimant address?*"
        name="isPropertyAddressDifferentThanClaimantAddress"
        value={
          typeSpecificCauseOfAction.isPropertyAddressDifferentThanClaimantAddress
        }
        onValueChange={onPropertyAddressSourceChanged}
        isReadOnly={isPropertyAddressReadOnly}
      />
      <Address
        parentPropertyName="propertyAddress"
        parentProperty={typeSpecificCauseOfAction.propertyAddress}
        onDataChange={onDataChange}
        setDirtyFields={setDirtyFields}
        hasErrorToDisplay={hasErrorToDisplay}
        readOnly={
          isPropertyAddressReadOnly ||
          !Boolean(
            Number(
              typeSpecificCauseOfAction.isPropertyAddressDifferentThanClaimantAddress
            )
          )
        }
      />
      <h4 className={subtitleClassName}>Property Details</h4>
      <FormGroup className={classes.fourOfInputs}>
        <DatePickerWithClear
          name="dateOfInstallation"
          label="Date of Installation"
          required={true}
          value={typeSpecificCauseOfAction.dateOfInstallation}
          onChange={onDataChange('dateOfInstallation')}
          error={
            (dirtyFields.includes('dateOfInstallation') &&
              !hasRequiredDate('dateOfInstallation')) ||
            !isDateOfInstallationBetweenDates()
          }
          onBlur={setDirtyFields}
          isReadOnly={isBlockedForSolicitor}
          helperText={getHelperTextForDateOfInstallation()}
        />
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <SelectWithOptions
          name="propertyStyle"
          label="Property Style"
          value={typeSpecificCauseOfAction.propertyStyle}
          onChange={onEventChange}
          options={propertyStyles}
          required={true}
          error={hasErrorToDisplay('propertyStyle')}
          onBlur={setDirtyFields}
        />
        <SelectWithOptions
          name="propertyBuildType"
          label="Property Build Type"
          value={typeSpecificCauseOfAction.propertyBuildType}
          onChange={onEventChange}
          options={propertyBuildTypes}
          required={true}
          error={hasErrorToDisplay('propertyBuildType')}
          onBlur={setDirtyFields}
          isReadOnly={isBlockedForSolicitor}
        />
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <SelectWithOptions
          name="propertySubType"
          label="Property Sub Type"
          value={typeSpecificCauseOfAction.propertySubType}
          onChange={onEventChange}
          options={propertySubTypesToDisplay}
          required={true}
          error={hasErrorToDisplay('propertySubType')}
          onBlur={setDirtyFields}
          isReadOnly={
            isBlockedForSolicitor ||
            !typeSpecificCauseOfAction.propertyBuildType
          }
          getValueToDisplay={(value) => value.displayName}
        />
        <TextField
          name="insulationType"
          label="Insulation Type"
          value={typeSpecificCauseOfAction.insulationType}
          onChange={onEventChange}
          variant="outlined"
          error={hasErrorToDisplay('insulationType')}
          onBlur={setDirtyFields}
        />
      </FormGroup>
      <FormGroup className={classes.fourOfInputs}>
        <TextField
          name="numberOfBedrooms"
          label="Number of Bedrooms"
          value={typeSpecificCauseOfAction.numberOfBedrooms}
          onChange={onEventChange}
          type="number"
          InputProps={{
            inputProps: { min: 0, step: 1 },
          }}
          variant="outlined"
          required={true}
          error={hasErrorToDisplay('numberOfBedrooms')}
          onBlur={setDirtyFields}
        />
      </FormGroup>
    </>
  );
};
