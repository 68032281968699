import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  contactList: {
    paddingRight: 60,
    '&:not(:last-child)': {
      marginBottom: '25px !important',
    },
    '& * img': {
      marginRight: 8,
    },
    '& > p': {
      wordBreak: 'break-all',
    },
  },
}));
