import React from 'react';
import { Badge } from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';

import { useStyles } from './styles';

export default function Danger(props) {
  const classes = useStyles();
  return (
    <span className={`${classes.error} ${props.className}`}>
      <Badge>
        <PriorityHigh />
      </Badge>
      {props.children}
    </span>
  );
}
