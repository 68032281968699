import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';

import { AttachmentsEdit, CurrencyTextField, DatePickerWithClear, Warning, Danger } from '../../components';
import { policyStages } from '../../support/constants'; 
import { getPolicyPremium, cleanReducer } from '../../store/reducers/policy/premium';
import { 
  shouldShowWarningAboutChangedBand,
  shouldShowDangerAboutQuantumOutsideQddl, 
} from '../../support/movementHelpers';

import { useStyles } from './styles';

export default function SettlementNotificationMovement({
  policy, 
  movement,
  onChange,
  setMovementFields,
  setCustomIsValid,
  showAllValidationErrors 
}) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [dirtyFields, setDirtyFieldsState] = useState([]);
  const [isSettlementConfirmed, setIsSettlementConfirmed] = useState(false);

  const {
    premium,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.policy.premium);

  useEffect(() => {
    setCustomIsValid(false);
    dispatch(cleanReducer());
    setMovementFields({
      settlementStage: policy.stage,
    });

  }, []);

  useEffect(() => {
    fetchQuantumBandDetails();
  }, [movement?.settlementStage, movement?.damagesSpecial, movement?.damagesGeneral]);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const fetchQuantumBandDetails = () => {
    if (movement?.damagesSpecial && movement?.damagesGeneral) {
      delayUpdatePremium(movement.settlementStage, movement.damagesSpecial, movement.damagesGeneral);
    } else {
      delayUpdatePremium.cancel();
      dispatch(cleanReducer());
    }
  }

  const delayUpdatePremium = useCallback(debounce((stage, quantumSpecial, quantumGeneral) => updatePremium(stage, quantumSpecial, quantumGeneral), 300), []);

  const updatePremium = (stage, quantumSpecial, quantumGeneral) => {
    dispatch(
      getPolicyPremium({
        schemeId: policy.scheme.id,
        policyId: policy.id,
        policyStatus: policy.status,
        policyStage: stage,
        estimatedDamagesSpecial: quantumSpecial,
        estimatedDamagesGeneral: quantumGeneral,
      })
    );
  };

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);

  const onDateChange = (fieldName, date) => {
    onChange({
        target: {
          name: fieldName,
          value: date ? date.toISOString() : date
        }
      });
  };

  const areDamagesEmpty = () => !movement?.damagesSpecial && !movement.damagesGeneral;
  const areDamagesNotEmpty = () => !!movement?.damagesSpecial && !!movement?.damagesGeneral;

  const validation = {
    isSettlementConfirmed: () => !!isSettlementConfirmed,
    isSettlementDateValid: () => !!movement?.settlementDate,
    areDamagesValid: () => isGlobalSettlement() || (isDamagesSettlement() && areDamagesEmpty()) || (isDamagesSettlement() && areDamagesNotEmpty()),
    isAmountValid: () => isGlobalSettlement() && !!movement?.amountReceived,
  };

  const isFormValid = () => {
    return validation.isSettlementConfirmed()
      && validation.isSettlementDateValid()
      && validation.areDamagesValid();
  }

  const addAttachment = (attachmentName, attachment) => {
    const attachments = [...(movement[attachmentName] ?? []), attachment];
    updateAttachments(attachmentName, attachments);
  };

  const deleteAttachment = (attachmentName, attachmentIndex) => {
    let attachmentsCopy = [...movement[attachmentName]];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      updateAttachments(attachmentName, attachmentsCopy);
    }
  };

  const updateAttachments = (attachmentName, attachments) =>{
    onChange({
      target: {
        name: attachmentName,
        value: attachments
      }
    });
  };

  const shouldShowSettlementConfirmationError = () => (showAllValidationErrors || dirtyFields.includes("settlementConfirmation")) && !validation.isSettlementConfirmed();

  const isGlobalSettlement = () => movement?.globalSettlement === "True";
  const isDamagesSettlement = () => movement?.globalSettlement === "False";

  return (
    <>
      <FormControl component="fieldset">
        <Typography variant="subtitle2" className={`${classes.normalText}`}>
        You are confirming that the current Policy stage as indicated is correct. If it is not, please let us know as the Premium payable will be incorrect and we will need to advise you of the correct Premium due. 
        </Typography>
        {/* Currently it should not be changed by users */}
        <RadioGroup value={+movement?.settlementStage} className={classes.radios}>
          {Object.keys(policyStages).map((key, index) => {
            return (+movement?.settlementStage === policyStages[key].value && <FormControlLabel
              key={index}
              value={policyStages[key].value}
              name="settlementStage"
              control={<Radio color="primary" disabled/>}
              label={policyStages[key].displayName}
            />)})}
        </RadioGroup>
      </FormControl>

      <DatePickerWithClear
        name="settlementDate"
        label="Settlement Date"
        required={true}
        value={movement.settlementDate || null}
        onChange={(date) => onDateChange('settlementDate', date)}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={
          (showAllValidationErrors || dirtyFields.includes('settlementDate')) && !validation.isSettlementDateValid()
        }
      />

      <FormControl component="fieldset" error={true}>
        <Typography variant="subtitle2" className={classes.normalText}>
          Is this notification concerning a:
        </Typography>
        <RadioGroup  name="globalSettlement" value={movement?.globalSettlement || null} className={classes.radios} onChange={onChange}>
          <FormControlLabel
              key={1}
              value={"True"}
              control={<Radio color="primary"/>}
              label={"Global settlement"}
            />
          <FormControlLabel
              key={2}
              value={"False"}
              control={<Radio color="primary"/>}
              label={"Damages settlement"}
            />
        </RadioGroup>
        {(showAllValidationErrors && !isDamagesSettlement() && !isGlobalSettlement()) && <FormHelperText>Please select</FormHelperText>}
      </FormControl>

      {isGlobalSettlement() && <>
        <CurrencyTextField
          name="amountReceived"
          label="Amount"
          required={true}
          onChange={onChange}
          allowNegative={false}
          error={(showAllValidationErrors || dirtyFields.includes('amountReceived')) && !validation.isAmountValid() }
        />

        <DatePickerWithClear
          name="dateOfReceiptOfSettlement"
          label="If received, date of receipt of settlement"
          value={movement.dateOfReceiptOfSettlement || null}
          InputLabelProps={{
            shrink: true
          }}
          onChange={(date) => onDateChange('dateOfReceiptOfSettlement', date)}
        />

        <CurrencyTextField
          name="TotalCurrentInclIPT"
          label="Total Premium payable incl.IPT."
          className="reset-mb"
          value={policy?.policyPremium.totalCurrentInclIPT}
          isReadOnly={true}
        />
        <Alert severity="info" className={classes.smallAlert}>You must remit this amount less the Premium you have already paid.</Alert>
      </>}

      {isDamagesSettlement() && <>
        <div className="reset-mb">
          <Typography variant="subtitle2" className={`${classes.normalText} ${classes.marginFontSize}`}>
            You have advised Quantum is as follows:
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={6}>
            <CurrencyTextField
              name="cuerentDamagesGeneral"
              label="Damages general"
              value={policy?.currentQuantum.quantumGeneral}
              isReadOnly={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyTextField
              name="currentDamagesSpecial"
              label={"Damages special"}
              value={policy?.currentQuantum.quantumSpecial}
              isReadOnly={true}
            />
          </Grid>
        </Grid>
        <CurrencyTextField
          name="totalQuantum"
          label="Total Quantum"
          value={+policy?.currentQuantum.totalQuantum}
          isReadOnly={true}
        />

        <div className={classes.divider}></div>

        <div className="reset-mb">
          <Typography variant="subtitle2" className={`${classes.normalText} ${classes.marginFontSize}`}>
            If Quantum has changed please indicate  settlement quantum here:
          </Typography>
        </div>

        <Grid container>
          <Grid item xs={6}>
            <CurrencyTextField
              name="damagesGeneral"
              label="Damages general"
              onChange={onChange}
              helperText="Must be equal to or greater than 0"
              allowNegative={false}
              allowZero={true}
              error={
                (showAllValidationErrors || dirtyFields.includes('damagesGeneral')) && !validation.areDamagesValid()
              }
              InputProps={{ inputProps: { step: 1 } }}
              onBlur={(event) => {
                setDirtyFields(event.target.name);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyTextField
              name="damagesSpecial"
              label={"Damages special"}
              onChange={onChange}
              helperText="Must be equal to or greater than 0"
              allowNegative={false}
              allowZero={true}
              error={
                (showAllValidationErrors || dirtyFields.includes('damagesSpecial')) && !validation.areDamagesValid()
              }
              InputProps={{ inputProps: { step: 1 } }}
              onBlur={(event) => {
                setDirtyFields(event.target.name);
              }}
            />
          </Grid>
        </Grid>
        <CurrencyTextField
          name="totalQuantum"
          label="Total Quantum"
          className="reset-mb"
          value={+movement?.damagesSpecial + +movement?.damagesGeneral}
          required={true}
          isReadOnly={true}
        />
        {shouldShowWarningAboutChangedBand(policy, premium?.matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning className={'reset-mb'}>Quantum has changed band.</Warning>}
        {shouldShowDangerAboutQuantumOutsideQddl(wasRecognizedAsOutsideQddl) && <Danger className={'reset-mb'}>Quantum is outisde allowed bands! Please correct the input, otherwise your request will not be saved.</Danger>}
        <div></div>
        <div className={classes.divider}></div>

        <CurrencyTextField
          name="TotalCurrentInclIPT"
          label="Total Premium payable incl.IPT."
          className="reset-mb"
          value={premium?.totalCurrentInclIPT || policy?.policyPremium.totalCurrentInclIPT}
          isReadOnly={true}
        />
        <Alert severity="info" className={classes.smallAlert}>You must remit this amount less the Premium you have already paid.</Alert>

        <div>
          <Typography 
            variant="subtitle2" 
            className={classes.normalText} >
              Upload breakdown of settlement
          </Typography>
          <AttachmentsEdit
            title="Attachments"
            addAttachment={(attachment) => addAttachment('attachments', attachment)}
            deleteAttachment={(attachmentIndex) => deleteAttachment('attachments', attachmentIndex)}
            attachments={movement?.attachments}
            limitListItemsWidth={false}
          />
        </div>
      </>}

      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        rows={3}
      />

      <Grid 
        container
        alignItems="center"
        justify="center">
        <Grid item xs={2} className={classes.settlementConfirmationChechbox}>
          <Checkbox 
            color="primary" 
            onChange={(event) => {
              setDirtyFields(event.target.name);
              setIsSettlementConfirmed(event.target.checked)}
            }
            name="settlementConfirmation"
            className={`${shouldShowSettlementConfirmationError() ? classes.error : ''}`}/>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="overline" display="block" gutterBottom className={`${classes.disclaimer} ${shouldShowSettlementConfirmationError() ? classes.error : ''}`}>
            I confirm this case has won at the stage indicated above and the Policy Premium Due will be forwarded to Resolve from the Claimant's damages immediately upon receipt of the settlement from the third party. Please do not archive this case for 6 months. *
          </Typography>
        </Grid>
      </Grid>
        
    </>
  );
}
