import React, { useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup } from '@material-ui/core';
import { isEqual } from 'lodash';

import WizardStepSection from '../WizardStepSection';
import {
  CurrencyTextField,
  Section,
  RadioGroupWithYesNo,
  SelectWithOptions,
} from '../../../components';
import { complaintLimit } from '../../../support/constants';

import { useStyles } from '../styles';

export default function ReferralLimit({ sectionKey }) {
  const classes = useStyles();

  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const schemeInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep,
    isEqual
  );

  const [scheme, setScheme] = useState(JSON.parse(JSON.stringify(schemeInit)));
  const [propertyToChange, setPropertyToChange] = useState(null);

  const onEventChange = (event) => {
    const propertyToChange = { [event.target.name]: event.target.value };
    setScheme({
      ...scheme,
      ...propertyToChange,
    });

    setPropertyToChange(propertyToChange);
  };

  const onDeclineReferralChange = (value, name) => {
    const propertyToChange = { [name]: value };
    setScheme({
      ...scheme,
      ...propertyToChange,
    });

    setPropertyToChange(propertyToChange);
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[propertyToChange]}
      dataToChange={propertyToChange}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
    >
      <Section title="Referral Limit" mainSection={true}>
        <FormGroup className={classes.fourOfInputs}>
          <CurrencyTextField
            name="claimLimit"
            label="Claim Limit"
            value={scheme.claimLimit}
            onChange={onEventChange}
            required={true}
            error={validator?.hasErrorToDisplay('claimLimit')}
            onBlur={addDirtyField}
            allowNegative={false}
          />
        </FormGroup>
        <FormGroup className={classes.fourOfInputs}>
          <RadioGroupWithYesNo
            label="Decline Referral*"
            name="declineReferral"
            value={scheme.declineReferral}
            onValueChange={onDeclineReferralChange}
            isRequired={true}
          />
        </FormGroup>
        <FormGroup className={classes.fourOfInputs}>
          <SelectWithOptions
            name="complaintLimit"
            label="Complaint Limit"
            value={scheme.complaintLimit}
            onChange={onEventChange}
            options={complaintLimit}
            required={true}
            error={validator?.hasErrorToDisplay('remunerationType')}
            onBlur={addDirtyField}
          />
        </FormGroup>
      </Section>
    </WizardStepSection>
  );
}
