import React from 'react';
import _startCase from 'lodash/startCase';
import { sortBy, findLast, filter, every, some } from 'lodash';
import moment from 'moment';

import { Divider, Grid, List, Paper, Typography, CircularProgress } from '@material-ui/core';

import { AttachmentsListExpandable, ListItem, StatusBadge } from '../../components';
import { getFormattedDate, getFormattedMoney, transformObject } from '../../support/helpers';
import { getMovementsByType } from '../../support/movementHelpers';
import IconPolicy from '../../assets/policyDetails.svg';
import IconScheme from '../../assets/scheme.svg';
import IconSolicitor from '../../assets/person.svg';
import IconAudit from '../../assets/audit.svg';
import IconPremiumAndClaim from '../../assets/table.svg';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import { useStyles } from './policyDetailsHeaderStyles';
import { eventNotificationTypes, getConstByValue, premiumStatuses, premiumTypes, movementTypes, movementStatuses } from '../../support/constants';

export default function PolicyDetailsHeader(props) {
  const classes = useStyles();
  const { policy, restrictedForInsurer } = props;

  const premiumStatusesWithIdKey = transformObject(premiumStatuses, (obj) => obj.value);

  const leadClaimaint = policy.claimants.find((claimant) => claimant.isLead);
  const leadClaimantName = restrictedForInsurer(
    `${_startCase(leadClaimaint.title)} ${leadClaimaint.forename} ${
      leadClaimaint.surname
    }`
  );

  const ListHeading = (props) => {
    const { text, icon } = props;

    return (
      <Typography className={classes.overline} variant="overline">
        {React.isValidElement(icon) && icon}
        {!React.isValidElement(icon) && <img src={icon} alt={text} />}
        {text}
      </Typography>
    );
  };

  const sortMovementsByCreationDate = (movements) => sortBy(movements, [function(movement) { 
    return moment(movement.createdDate);
  }]);

  const getLiabilityValue = (policy) => {
    if (policy?.movements?.length) {
      var movementsSortedByCreationDate = sortMovementsByCreationDate(policy.movements);

      var lastLiabilityMovement = findLast(movementsSortedByCreationDate, function(movement) { 
        return movement.eventNotificationType === eventNotificationTypes.liabilityAdmitted.value
          || movement.eventNotificationType === eventNotificationTypes.liabilityWithdrawn.value 
          || movement.eventNotificationType === eventNotificationTypes.liabilityDenied.value;
      });

      return lastLiabilityMovement ? getConstByValue(eventNotificationTypes, lastLiabilityMovement.eventNotificationType)?.displayName || '' : '';
    }

    return '';
  };

  const getClaimLetterDates = (policy) => {
    var claimLetterDates = {
      letterOfClaimSentDate: null,
      responseToLetterOfClaimReceived: null,
    };

    if (policy?.movements?.length){
      
      var movementsSortedByCreationDate = sortMovementsByCreationDate(policy.movements);
      var letterSentMovement = findLast(movementsSortedByCreationDate, (movement) => movement.eventNotificationType === eventNotificationTypes.letterOfClaimSent.value);
      var responseToLetterReceivedMovement = findLast(movementsSortedByCreationDate, (movement) => movement.eventNotificationType === eventNotificationTypes.responseToLetterOfClaimReceived.value);

      claimLetterDates.letterOfClaimSentDate = letterSentMovement?.date;
      claimLetterDates.responseToLetterOfClaimReceived = responseToLetterReceivedMovement?.date;      
    }

    return claimLetterDates;
  }

  const getFundedPremiums = (movements) => {
    var premiumMovements = getMovementsByType(movements, movementTypes.Premium);
    return filter(premiumMovements, {premiumType: premiumTypes.Funded.value});
  }

  const getDeferredPremiums = (movements) => {
    var premiumMovements = getMovementsByType(movements, movementTypes.Premium);
    return filter(premiumMovements, {premiumType: premiumTypes.Deferred.value});
  }

  const getFundedPremiumStatus = (policy) => {
    var fundedPremiums = getFundedPremiums(policy.movements);
    return getPremiumStatus(fundedPremiums);
  }

  const getDeferredPremiumStatus = (policy) => {
    var deferredPremiums = getDeferredPremiums(policy.movements);
    return getPremiumStatus(deferredPremiums);
  }

  const getPremiumStatus = (premiums) => {
    if (every(premiums, { status: movementStatuses.Paid.value })){
      return premiumStatuses.Paid.value;
    }

    if (some(premiums, { status: movementStatuses.Paid.value })) {
      return premiumStatuses.PartiallyPaid.value;
    }

    return premiumStatuses.Unpaid.value;
  }

  return (
    <Paper className={classes.header}>
      <Grid container>
        <img className={classes.detailIcon} src={IconPolicy} alt="detail" />
        <Divider className={classes.divider} orientation="vertical" flexItem />

        <Grid item lg={2}>
          <ListHeading text="scheme" icon={IconScheme} />
          <List className={classes.list}>
            <ListItem primary="Scheme Name" secondary={policy.scheme.name} />
            <ListItem
              primary="Scheme Code"
              secondary={`
                ${policy.scheme.codePrefix} ${policy.scheme.codeSuffix}
              `}
            />
          </List>

          <ListHeading text="solicitor" icon={IconSolicitor} />
          <List className={classes.list}>
            <ListItem
              primary="Name"
              secondary={policy.causeOfAction.solicitorName}
            />
          </List>

          <ListHeading text="Lead Claimant" icon={IconSolicitor} />
          <List className={classes.list}>
            <ListItem primary="Name" secondary={leadClaimantName} />
          </List>
        </Grid>
        <Divider className={classes.divider} orientation="vertical" flexItem />

        <Grid item className={classes.listFullWidth}>
          <ListHeading text="audit" icon={IconAudit} />
          <List className={`${classes.list} ${classes.twoColumn}`}>
            <ListItem
              primary="Date of Last Audit"
              secondary={getFormattedDate(policy.dateOfLastAudit)}
            />
            <ListItem
              primary="Total Number of Audits"
              secondary={policy.totalNumberOfAudits}
            />
          </List>

          <ListHeading text="premium and claim" icon={IconPremiumAndClaim} />
          <List className={`${classes.list} ${classes.twoColumn}`}>
            <ListItem
              primary={(
                <span>
                  Premium Funded 
                  {!policy?.movements && <CircularProgress size="1em" className={classes.premiumStatusLoader}/>}
                  {(!!policy?.movements && policy.policyPremium?.fundedCurrentExclIPT > 0) && <StatusBadge
                    size='small'
                    status={getFundedPremiumStatus(policy)}
                    statusType={premiumStatusesWithIdKey}
                    className={classes.premiumStatus}
                  />}
                </span>)}
              secondary={getFormattedMoney(
                policy.policyPremium?.fundedCurrentExclIPT
              )}
            />
            <ListItem
              primary={(
                <span>
                  Premium Deferred 
                  {!policy?.movements && <CircularProgress size="1em" className={classes.premiumStatusLoader}/>}
                  {(!!policy?.movements && policy.policyPremium?.deferredCurrentExclIPT > 0) && <StatusBadge
                    size='small'
                    status={getDeferredPremiumStatus(policy)}
                    statusType={premiumStatusesWithIdKey}
                    className={classes.premiumStatus}
                  />}
                </span>)}
              secondary={getFormattedMoney(
                policy.policyPremium?.deferredCurrentExclIPT
              )}
            />
            <ListItem
              primary="Total Current Premium incl. IPT"
              secondary={getFormattedMoney(policy.policyPremium?.totalCurrentInclIPT, 2, true)}
            />
            <ListItem
              primary="Liability"
              secondary={getLiabilityValue(policy)}
            />
          </List>
          <List className={`${classes.list} ${classes.twoColumn}`}>
            <ListItem
              primary="Claim Reserved gross"
              secondary={getFormattedMoney(policy.claimValues?.reservedGross)}
            />
            <ListItem
              primary="Claim Recovery Reserved"
              secondary={getFormattedMoney(
                policy.claimValues?.recoveryReserved
              )}
            />
            <ListItem
              primary="Claim Paid gross"
              secondary={getFormattedMoney(policy.claimValues?.paidGross)}
            />
            <ListItem
              primary="Claim Recovery Paid"
              secondary={getFormattedMoney(policy.claimValues?.recoveryPaid)}
            />
          </List>

          <ListHeading text="letter of claim" icon={<EmailOutlinedIcon />} />
          <List className={`${classes.list} ${classes.twoColumn}`}>
            <ListItem
              primary="Sent date"
              secondary={getFormattedDate(getClaimLetterDates(policy).letterOfClaimSentDate)}
            />
            <ListItem
              primary="Response received date"
              secondary={getFormattedDate(getClaimLetterDates(policy).responseToLetterOfClaimReceived)}
            />
          </List>
        </Grid>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <Grid item lg={2}>
          <AttachmentsListExpandable attachments={policy.scheme.attachments} />
        </Grid>
      </Grid>
    </Paper>
  );
}
