import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { ListItem } from '../../components';
import {
  propertyStyles,
  propertyBuildTypes,
  propertySubTypes,
} from '../../support/constants';

import { getFormattedDate, getFormattedMoney } from '../../support/helpers';

import IconPhone from '../../assets/phone.svg';

import { useStyles } from './policyDetailsTabsStyles';

export default function PolicyDetailsTabItemSpecificCauseOfActionCwi(props) {
  const classes = useStyles();
  const { policy, restrictedForInsurer } = props;

  return (
    <>
      <Typography className={classes.panelSectionHeading} variant="h5">
        Property Address
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Postcode"
            secondary={
              policy.typeSpecificCauseOfAction.propertyAddress.postcode
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="House Number and Street"
            secondary={restrictedForInsurer(
              policy.typeSpecificCauseOfAction.propertyAddress.street
            )}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Town/City"
            secondary={
              policy.typeSpecificCauseOfAction.propertyAddress.city
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="County"
            secondary={
              policy.typeSpecificCauseOfAction.propertyAddress.county
            }
          />
        </Grid>
      </Grid>

      <Typography className={classes.panelSectionHeading} variant="h5">
        Property Details
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Date of Installation"
            secondary={getFormattedDate(
              policy.typeSpecificCauseOfAction.dateOfInstallation
            )}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Property Style"
            secondary={
              propertyStyles[policy.typeSpecificCauseOfAction.propertyStyle]
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Property Build Type"
            secondary={
              propertyBuildTypes[
                policy.typeSpecificCauseOfAction.propertyBuildType
              ]
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Property Sub Type"
            secondary={
              propertySubTypes[policy.typeSpecificCauseOfAction.propertySubType].displayName
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Insulation Type"
            secondary={policy.typeSpecificCauseOfAction.insulationType}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Number of Bedrooms"
            secondary={policy.typeSpecificCauseOfAction.numberOfBedrooms}
          />
        </Grid>
      </Grid>

      <Typography className={classes.panelSectionHeading} variant="h5">
        Defendant 1 (installer)
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Name"
            secondary={policy.typeSpecificCauseOfAction.defendant1Name}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Company Number"
            secondary={
              policy.typeSpecificCauseOfAction.defendant1CompanyNumber
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Contact Number"
            secondary={
              <>
                <img src={IconPhone} alt="phone" className={classes.icon} />
                {restrictedForInsurer(
                  policy.typeSpecificCauseOfAction.defendant1ContactNumber
                )}
              </>
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Postcode"
            secondary={
              policy.typeSpecificCauseOfAction.defendant1Address.postcode
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="House Number and Street"
            secondary={
              policy.typeSpecificCauseOfAction.defendant1Address.street
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Town/City"
            secondary={
              policy.typeSpecificCauseOfAction.defendant1Address.city
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="County"
            secondary={
              policy.typeSpecificCauseOfAction.defendant1Address.county
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Dissolved"
            secondary={
              policy.typeSpecificCauseOfAction.hasDefendant1BeenDissolved
                ? 'Yes'
                : 'No'
            }
          />
        </Grid>
      </Grid>

      <Typography className={classes.panelSectionHeading} variant="h5">
        Defendant Insurer
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Insurer Name"
            secondary={
              policy.typeSpecificCauseOfAction.defendant2InsurerName
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Insurance Policy Number"
            secondary={
              policy.typeSpecificCauseOfAction.defendant2InsurancePolicyNumber
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Policy Period"
            secondary={`
              ${getFormattedDate(
                policy.typeSpecificCauseOfAction.defendant2PolicyPeriodFrom
              )} - ${getFormattedDate(
              policy.typeSpecificCauseOfAction.defendant2PolicyPeriodTo
            )}
            `}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Policy Limit"
            secondary={getFormattedMoney(
              policy.typeSpecificCauseOfAction.defendant2PolicyLimit
            )}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Policy Aggregate Limit"
            secondary={getFormattedMoney(
              policy.typeSpecificCauseOfAction.defendant2PolicyAggregateLimit
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
