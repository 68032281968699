import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import config from '../../config';
import styles from './NotificationsArea.module.scss';
import {
  hideNotification,
  queue,
} from '../../store/reducers/global/notifications';

export default function NotificationsArea() {
  const notificationQueue = useSelector(queue);
  const dispatch = useDispatch();
  const timersArray = [];

  const dismissNotification = (guid) => {
    dispatch(hideNotification(guid));
  };

  const dismissMessage = (guid) => () => {
    clearTimeout(timersArray[`${guid}Timer`]);
    dismissNotification(guid);
  };

  const startSelfDestructTimer = (guid) => () => {
    timersArray[`${guid}Timer`] = setTimeout(() => {
      dismissNotification(guid);
    }, config.notificationShowDuration);
  };

  return (
    <TransitionGroup
      className={`${styles.notificationContainer} position-fixed d-flex flex-column`}
    >
      {notificationQueue.map(({ guid, message, color }) => (
        <CSSTransition
          key={guid}
          timeout={500}
          classNames={{
            enter: styles.notificationEnter,
            enterActive: styles.notificationEnterActive,
            exit: styles.notificationExit,
            exitActive: styles.notificationExitActive,
          }}
          onEntered={startSelfDestructTimer(guid)}
        >
          <div className={`alert alert-${color} fade show`} role="alert">
            <span className={`${styles.multiline}`}>{message}</span>
            <button
              type="button"
              className="close"
              onClick={dismissMessage(guid)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}
