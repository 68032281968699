import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import createStore from './store/createStore';
import userManager from './store/userManager';
import App from './App';

import 'open-iconic/font/css/open-iconic-bootstrap.css';
import './styles/styles.scss';

const store = createStore();
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const AppWithStore = () => (
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
        <Router basename={baseUrl}>
          <App />
        </Router>
    </OidcProvider>
  </Provider>
);

export default AppWithStore;
