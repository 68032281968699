import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { find } from 'lodash';
import moment from 'moment';

import { Warning } from '../../components';
import { movementStatuses, movementTypes, policyStages, policyStatuses, schemeCriteriaAnswers } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';

import AuditMovement from './AuditMovement';
import ClaimMovement from './ClaimMovement';
import ClaimNotificationMovement from './ClaimNotificationMovement';
import NoteMovement from './NoteMovement';
import OurPart36Movement from './OurPart36Movement';
import ProceedingsRequest from './ProceedingsRequestMovement';
import TheirPart36Movement from './TheirPart36Movement';
import UnwindRequestMovement from './UnwindRequestMovement';
import QuantumChangeMovement from './QuantumChangeMovement';
import EventNotificationMovement from './EventNotificationMovement';
import SettlementNotificationMovement from './SettlementNotification';
import TrialRequestMovement from './TrialRequestMovement';
import TrialDocumentationMovement from './TrialDocumentationMovement';
import Cancellation14DaysMovement from './Cancellation14DaysMovement';
import RestrictionWithdrawalRequest from './RestrictionWIthdrawalRequest';
import VoidMovement from './VoidMovement';

import { checkDateInRange, getCurrentDate } from '../../support/helpers';
import { areThereAnyMissingDocumensForTrialRequest } from '../../support/movementHelpers';

import { useStyles } from './styles';


export default function AddMovement({
  policy,
  movement,
  setMovement,
  setIsReadyToConfirm,
  onlyClaims = false,
  setCustomIsValid,
  customIsValid = true,
  shouldShowAllValidationErrors,
  setConfirmationContent,
}) {
  const classes = useStyles();
  const formRef = useRef(null);
  const userGroups = useSelector(userGroupsSelector);
  const [isWarningAboutClaim, setIsWarningAboutClaim] = useState(false);
  const [availableMovementTypes, setAvailableMovementTypes] = useState({});
  const hasCriteriaWithNoOrPendingAnswer = policy.schemeCriteriaResults.some(
    (sc) =>
      sc.answer === schemeCriteriaAnswers.pending ||
      sc.answer === schemeCriteriaAnswers.no
  );

  const handleMovementTypeChange = (event) => {
    setMovement({
      type: event.target.value,
      policyId: policy.id,
    });

    // Reset his value for all movements, child components which use this state should change it respecitively to their state
    setCustomIsValid(true);
  };

  const handleChange = (event) => {
    setMovement({
      ...movement,
      [event.target.name]: event.target.value,
    });
  };

  const setMovementFields = (updatedFields) => {
    setMovement({
      ...movement,
      ...updatedFields,
    });
  };

  const isMovementOfType = (movementTypeToCompare) =>
    movement.type === movementTypeToCompare.value;

  useEffect(() => {
    const getMovementsCount = () => Object.keys(movementTypesToDisplay).length;

    let movementTypesToDisplay = Object.assign({}, movementTypes);

    movementTypesToDisplay = Object.keys(movementTypesToDisplay)
      .filter(
        (key) =>
          movementTypes[key].rolesAllowed.some((r) => userGroups.includes(r)) &&
          (!onlyClaims || movementTypes[key] === movementTypes.Claim)
      )
      .reduce((res, key) => ((res[key] = movementTypes[key]), res), {});

    const countBeforeCriteriaCheck = getMovementsCount();
    if (hasCriteriaWithNoOrPendingAnswer) {
      delete movementTypesToDisplay.Claim;
      delete movementTypesToDisplay.ClaimNotification;
      delete movementTypesToDisplay.ProceedingsRequest
    }

    if (policy.status === policyStatuses.CARef.value || policy.status === policyStatuses.CANoRef.value) {
      delete movementTypesToDisplay.Claim;
    }

    deleteProceedingsRequestWhenThereAreOtherRequestsWaitingForApprovalOrPolicyIsNotPreProceedingsOrPolicyIsRestricted(movementTypesToDisplay);
    deleteQuantumChangeWhenThereAreOtherMovementsWhichCanChangeTheBandAndAreWaitingForApproval(movementTypesToDisplay);
    deleteTrialRequestWhenPolicyIsNotProceedingsOrAnotherTrialRequestIsOnTheListOrPolicyIsRestricted(movementTypesToDisplay);
    deleteTrialDocumentationWhenThereIsNoTrialRequestWithoutDocumentation(movementTypesToDisplay);
    deleteCancellation14DaysWhendPolicyIsOlderThan30Days(movementTypesToDisplay);
    deleteCancellation14DaysWhenPolicyHasClaims(movementTypesToDisplay);
    deleteRestrictionWithDrawalRequestWhenPolicIsNotRestricted(movementTypesToDisplay);
    deleteVoidWhenPolicyIsNotPreProceedingsOrHasClaims(movementTypesToDisplay);

    setAvailableMovementTypes(movementTypesToDisplay);

    if (hasCriteriaWithNoOrPendingAnswer && getMovementsCount() < countBeforeCriteriaCheck) {
      setIsWarningAboutClaim(true);
    } else {
      if (Object.keys(movementTypesToDisplay).length === 1) {
        setMovement({
          type:
            movementTypesToDisplay[Object.keys(movementTypesToDisplay)[0]]
              .value,
          policyId: policy.id,
        });
      }
    }
  }, []);

  const deleteRestrictionWithDrawalRequestWhenPolicIsNotRestricted = (movementTypesToDisplay) => {
    if (policy.status !== policyStatuses.Restrict.value){
      delete movementTypesToDisplay.RestrictionWithdrawalRequest
    }
  }

  const deleteProceedingsRequestWhenThereAreOtherRequestsWaitingForApprovalOrPolicyIsNotPreProceedingsOrPolicyIsRestricted = (movementTypesToDisplay) => {
    if (policy.stage != policyStages.PreProceedings.value 
      || find(policy.movements, { type: movementTypes.ProceedingsRequest.value, status: 1})
      || policy.status == policyStatuses.Restrict.value){
      delete movementTypesToDisplay.ProceedingsRequest;
    }
  };

  const deleteQuantumChangeWhenThereAreOtherMovementsWhichCanChangeTheBandAndAreWaitingForApproval = (movementTypesToDisplay) => {
    if (find(policy.movements, {type: movementTypes.ProceedingsRequest.value, status: movementStatuses.New.value})) {
      delete movementTypesToDisplay.QuantumChange;
    }
  };

  const deleteTrialRequestWhenPolicyIsNotProceedingsOrAnotherTrialRequestIsOnTheListOrPolicyIsRestricted = (movementTypesToDisplay) => {
    if (policy.stage != policyStages.Proceedings.value 
      || find(policy.movements, {type: movementTypes.TrialRequest.value, status: 1})
      || policy.status == policyStatuses.Restrict.value){
      delete movementTypesToDisplay.TrialRequest;
    }
  }

  const deleteTrialDocumentationWhenThereIsNoTrialRequestWithoutDocumentation = (movementTypesToDisplay) => {
    if (areThereAnyMissingDocumensForTrialRequest(policy.movements)){
      return
    } else {
      delete movementTypesToDisplay.TrialDocumentation;
    }
  }

  const deleteCancellation14DaysWhendPolicyIsOlderThan30Days = (movementTypesToDisplay) => {
    if (!checkDateInRange(
        policy.causeOfAction.policyInceptionDate,
        moment(policy.causeOfAction.policyInceptionDate).add(30, 'd'),
        getCurrentDate()))
    {
      delete movementTypesToDisplay.Cancellation14Days;
    }
  }

  const deleteCancellation14DaysWhenPolicyHasClaims = (movementTypesToDisplay) => {
    if (policy.hasClaimMovements) {
      delete movementTypesToDisplay.Cancellation14Days;
    }
  }

  const deleteVoidWhenPolicyIsNotPreProceedingsOrHasClaims = (movementTypesToDisplay) => {
    if (policy.stage != policyStages.PreProceedings.value || policy.hasClaimMovements) {
      delete movementTypesToDisplay.Void;
    }
  }

  useEffect(() => {
    // Different approach for proceedings request (and Quantum Change, and event notification). From the beginning all modals were implemented without validation, button Confirm is greyed out all the time
    // until form is valid. Now we want to change approach and display Confirm button always available, so  when user clicks that button, we show validation
    // errors. One of the complains from the client was that there is no information what is wrong on various forms.
    // So to keep backward compability with all existing modals, I use different behavior for proceedings request.
    // Another change required in order to provide such functionality, is related to customIsValid state object, 
    // which is used to enable/disable Confirm button for older modals, but for proceedings it used in parent component to indicate whether we should show
    // validation errors (setShouldShowAllValidationErrors variable) when the Confirm button was clicked.
    // For more comments see AddMovementModal.jsx
    if (
        isMovementOfType(movementTypes.ProceedingsRequest) || 
        isMovementOfType(movementTypes.QuantumChange) ||
        isMovementOfType(movementTypes.EventNotification) ||
        isMovementOfType(movementTypes.SettlementNotification) ||
        isMovementOfType(movementTypes.TrialRequest) ||
        isMovementOfType(movementTypes.TrialDocumentation) ||
        isMovementOfType(movementTypes.Cancellation14Days) ||
        isMovementOfType(movementTypes.RestrictionWithdrawalRequest) ||
        isMovementOfType(movementTypes.Void)
       ) {
      return setIsReadyToConfirm(true);
    }

    const isMovementValid = formRef?.current && formRef.current.checkValidity() && customIsValid;
    setIsReadyToConfirm(isMovementValid);
  }, [movement]);

  return (
    <form ref={formRef} noValidate className={classes.form}>
      {isWarningAboutClaim && (
        <Warning>
          “Claim” and "Proceedings request" movements are not allowed. Supply the “Pending” information or
          change “No” information in Scheme Criteria on the Policy to enable
          this movement.
        </Warning>
      )}
      <FormControl variant="outlined">
        <InputLabel id="movement-type-label">Type of Movement</InputLabel>
        <Select
          name="movementType"
          id="movement-type-select"
          labelId="movement-type-label"
          value={movement.type}
          onChange={handleMovementTypeChange}
          label="Type of Movement"
          required={true}
        >
          {Object.keys(availableMovementTypes)
            .sort()
            .map((key) => (
              <MenuItem key={key} value={movementTypes[key].value}>
                {movementTypes[key].displayName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {isMovementOfType(movementTypes.UnwindRequest) && (
        <UnwindRequestMovement movement={movement} onChange={handleChange} />
      )}
      {isMovementOfType(movementTypes.ClaimNotification) && (
        <ClaimNotificationMovement
          movement={movement}
          onChange={handleChange}
        />
      )}
      {isMovementOfType(movementTypes.Claim) && (
        <ClaimMovement movement={movement} onChange={handleChange} />
      )}
      {isMovementOfType(movementTypes.Audit) && (
        <AuditMovement onChange={handleChange} />
      )}
      {isMovementOfType(movementTypes.Note) && (
        <NoteMovement onChange={handleChange} />
      )}
      {isMovementOfType(movementTypes.OurPart36) && (
        <OurPart36Movement onChange={handleChange} />
      )}
      {isMovementOfType(movementTypes.TheirPart36) && (
        <TheirPart36Movement movement={movement} onChange={handleChange} setCustomIsValid={setCustomIsValid}/>
      )}
      {isMovementOfType(movementTypes.ProceedingsRequest) && (
        <ProceedingsRequest
        policy={policy}
        movement={movement} 
        onChange={handleChange} 
        setCustomIsValid={setCustomIsValid} 
        showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.QuantumChange) && (
        <QuantumChangeMovement 
          movement={movement} 
          policy={policy}
          onChange={handleChange} 
          setCustomIsValid={setCustomIsValid}
          showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.EventNotification) && (
        <EventNotificationMovement
          movement={movement} 
          policy={policy}
          onChange={handleChange} 
          setCustomIsValid={setCustomIsValid}
          showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.SettlementNotification) && (
        <SettlementNotificationMovement
          movement={movement} 
          policy={policy}
          onChange={handleChange}
          setMovementFields={setMovementFields}
          setCustomIsValid={setCustomIsValid}
          showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.TrialRequest) && (
        <TrialRequestMovement
          policy={policy}
          movement={movement} 
          onChange={handleChange} 
          setCustomIsValid={setCustomIsValid} 
          showAllValidationErrors={shouldShowAllValidationErrors}
          setConfirmationContent={setConfirmationContent}/>
      )}
      {isMovementOfType(movementTypes.TrialDocumentation) && (
        <TrialDocumentationMovement
          policy={policy}
          movement={movement} 
          onChange={handleChange}
          setMovementFields={setMovementFields}
          setCustomIsValid={setCustomIsValid} 
          showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.Cancellation14Days) && (
        <Cancellation14DaysMovement
          movement={movement} 
          onChange={handleChange}
          setCustomIsValid={setCustomIsValid} 
          showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.RestrictionWithdrawalRequest) && (
        <RestrictionWithdrawalRequest
          movement={movement}
          onChange={handleChange} 
          setCustomIsValid={setCustomIsValid} 
          showAllValidationErrors={shouldShowAllValidationErrors}/>
      )}
      {isMovementOfType(movementTypes.Void) && (
        <VoidMovement 
          movement={movement} 
          onChange={handleChange}
          setCustomIsValid={setCustomIsValid}
          showAllValidationErrors={shouldShowAllValidationErrors} />
      )}
    </form>
  );
}
