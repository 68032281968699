import { createAction, createSlice } from '@reduxjs/toolkit';
import { kebabCase } from 'lodash';
import { movementCreationOutcome } from './../../../support/constants';

const initialState = {
  isSaving: false,
  wasSaved: false,
  creationOutcome: movementCreationOutcome.None.value,
};

export const movementsCreateSlice = createSlice({
  name: 'movements',
  initialState: { ...initialState },
  reducers: {
    startSaving: (state) => {
      state.isSaving = true;
    },
    hasError: (state) => {
      state.isSaving = false;
    },
    savingSuccess: (state, req) => {
      state.isSaving = false;
      state.wasSaved = true;
      state.creationOutcome =
        req?.response?.data?.creationOutcome || movementCreationOutcome.Ok;
    },

    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startSaving,
  hasError,
  savingSuccess,
  cleanReducer,
} = movementsCreateSlice.actions;

export const saveMovement = createAction(
  'saveMovement',
  (movementType, movement) => ({
    meta: {
      callAPITypes: [startSaving, savingSuccess, hasError],
      requestURL: `/movements/${kebabCase(movementType)}`,
      method: 'POST',
      requestConfig: {
        data: {
          ...movement,
        },
      },
    },
    type: 'saveMovement',
    payload: movement,
  })
);

export default movementsCreateSlice.reducer;
