import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { linkShape } from '../../support/prop-types';
import PrivateContent from '../PrivateContent/PrivateContent';

function Navigation(props) {
  const { links } = props;

  return (
    <Nav navbar>
      {links.map(({ requiredRoles, href, text }) => {
        const content = (
          <NavItem key={href}>
            <NavLink
              exact={href === '/'}
              to={href}
              className="nav-link"
              activeClassName="active"
            >
              {text}
            </NavLink>
          </NavItem>
        );
        return requiredRoles ? (
          <PrivateContent key={href} requiredRoles={requiredRoles}>
            {content}
          </PrivateContent>
        ) : (
          content
        );
      })}
    </Nav>
  );
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(linkShape).isRequired,
};

export default Navigation;
