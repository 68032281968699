import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

const circleCommon = {
  borderRadius: '12px',
  width: '24px',
  height: '24px',
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold',
  border: `2px solid ${colors.grey7F}`,
};

const contentHorizontalPadding = '20px';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    marginBottom: '20px',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  button: {
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '14px',
    '& .MuiSvgIcon-root': {
      marginRight: '6px',
    },
    '&.MuiButton-containedPrimary': {
      padding: '6px 63px',
    },
    '&.MuiButton-outlinedPrimary': {
      padding: '6px 63px',
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    border: `1px solid ${colors.greyE6}`,
    boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)`,
    borderRadius: '4px',
  },
  completed: {
    display: 'inline-block',
  },
  circle: {
    ...circleCommon,
    backgroundColor: colors.grey7F,
    color: colors.white,
  },
  circleActive: {
    ...circleCommon,
    backgroundColor: colors.white,
    border: `2px solid ${colors.green}`,
    color: colors.green,
  },
  circleCompleted: {
    ...circleCommon,
    backgroundColor: colors.green,
    border: `2px solid ${colors.green}`,
    color: colors.white,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '600',
  },
  stepTitle: {
    marginTop: 24,
    marginBottom: 26,
  },
  stepContent: {
    background: colors.white,
    border: `1px solid ${colors.greyE6}`,
    boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)`,
    padding: `${contentHorizontalPadding} 34px`,
    '&:not(:last-of-type)': {
      marginBottom: '12px',
    },
  },
  breadcrumbs: common.breadcrumbs,
  subtitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${colors.greyE6}`,
  },
  list: {
    paddingInlineStart: contentHorizontalPadding,
    marginTop: '8px',
  },
  listElement: {
    fontWeight: 'bold',
    fontSize: '14px',
    paddingLeft: '26px',
    marginBottom: '25px',
    '& .MuiFormLabel-root': {
      lineHeight: '21px',
      fontWeight: 'bold',
      fontSize: '14px',
      color: colors.text,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& span svg': {
      marginRight: '7px',
    },
    '& button': {
      color: colors.green,
      fontWeight: 'bold',
    },
  },
  disclaimer: {
    display: 'block',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '14px',
    color: colors.text,
    opacity: 0.75,
    marginTop: '20px',
  },
}));
