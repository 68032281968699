import * as autofilledConfig from './autoFilledConfig.json';
import GitInfo from 'react-git-info/macro';

const gitInfo = GitInfo();

const config = {
  azureTenantId:
    process.env.REACT_APP_AAD_TENANT_ID || autofilledConfig.tenantId,
  aadClientId:
    process.env.REACT_APP_AAD_CLIENT_ID || autofilledConfig.aadClientId,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || autofilledConfig.apiBaseUrl,
  version: gitInfo.branch,
  hostUrl: process.env.REACT_APP_PUBLIC_URL,
  identityProviderUrl:
    process.env.REACT_APP_IDENTITY_PROVIDER_URL ||
    autofilledConfig.identityBaseUrl,
  // How long will the notifications stay on screen (in ms)
  notificationShowDuration: 5000,
  // Maximum number of notifications show on the screen
  notificationOnScreenLimit: 5,
  navigationLinks: [
    {
      text: 'Schemes',
      href: '/',
      group: 'main',
    },
    {
      text: 'Profile',
      href: '/profile',
      group: 'user',
    },
  ],
};

const DEV = process.env.NODE_ENV !== 'production';

if (!DEV) {
  const windowEnv = window && window.env;

  if (windowEnv) {
    Object.keys(windowEnv).forEach((key) => {
      if (windowEnv[key]) {
        config[key] = windowEnv[key];
      }
    });
  }
}

export default config;
