import React from 'react';
import { IconButton, TableRow, TableCell, TextField } from '@material-ui/core';

import IconDelete from '../../../assets/images/delete.svg';

import { useStyles } from '../styles.js';
import { CurrencyTextField } from '../../../components';

export default function QddlRowEdit({
  row,
  onRowChange,
  onRowDelete,
  index,
  isFirst,
  isLast,
  isTheOnly,
  validator,
  addDirtyField,
}) {
  const classes = useStyles();
  const uniqueId = row.id ?? row.uniqueId;

  const onEventChange = (event) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );
    onRowChange(
      {
        ...row,
        [propertyName]: event.target.value,
      },
      index
    );
  };

  const onPremiumChange = (event, categoryName) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );

    onRowChange(
      {
        ...row,
        [categoryName]: {
          ...row[categoryName],
          [propertyName]: event.target.value,
        },
      },
      index
    );
  };

  const numericPremiumTextField = (categoryName, propertyName) => (
    <CurrencyTextField
      name={`${uniqueId}-${categoryName}-${propertyName}`}
      value={row?.[categoryName]?.[propertyName]}
      required={true}
      onChange={(event) => onPremiumChange(event, categoryName)}
      error={validator?.hasErrorToDisplay(
        `${uniqueId}-${categoryName}-${propertyName}`
      )}
      onBlur={addDirtyField}
      className={classes.qddlInput}
      showCurrency={false}
      variant="standard"
    />
  );

  const premiumFields = (category) => (
    <>
      <TableCell className={classes.qddlTableCell} align="center">
        {numericPremiumTextField(category, 'funded')}
      </TableCell>
      <TableCell className={classes.qddlTableCell} align="center">
        {numericPremiumTextField(category, 'deferred')}
      </TableCell>
      <TableCell className={classes.qddlTableCell} align="center">
        {numericPremiumTextField(category, 'limit')}
      </TableCell>
    </>
  );

  return (
    <TableRow className={classes.qddlTableRow} key={index}>
      <TableCell
        className={classes.qddlTableCell}
        align="center"
        component="th"
      >
        <CurrencyTextField
          name={`${uniqueId}-quantumBandFrom`}
          value={row.quantumBandFrom}
          required={true}
          isReadOnly={!isFirst}
          onChange={onEventChange}
          error={
            validator?.hasErrorToDisplay(`${uniqueId}-quantumBandFrom`) ||
            row.quantumBandFrom >= Number(row.quantumBandTo)
          }
          onBlur={addDirtyField}
          className={classes.narrowQddlInput}
          decimalScale={4}
          showCurrency={false}
          variant="standard"
        />
        <span className={classes.quantumBandSeparator}>-</span>
        <CurrencyTextField
          name={`${uniqueId}-quantumBandTo`}
          value={row.quantumBandTo}
          required={true}
          isReadOnly={!isLast}
          onChange={onEventChange}
          error={
            validator?.hasErrorToDisplay(`${uniqueId}-quantumBandTo`) ||
            row.quantumBandTo <= Number(row.quantumBandFrom)
          }
          onBlur={addDirtyField}
          className={classes.narrowQddlInput}
          decimalScale={4}
          showCurrency={false}
          variant="standard"
        />
      </TableCell>
      {premiumFields('preProceedingsPremium')}
      {premiumFields('proceedingsPremium')}
      {premiumFields('trialPremium')}
      <TableCell
        className={classes.qddlTableCell}
        align="center"
        component="th"
      >
        {!isTheOnly && (isFirst || isLast) && (
          <IconButton onClick={() => onRowDelete(index)}>
            <img src={IconDelete} alt="Delete" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
