import React, { useEffect, useState } from 'react';
import { findKey } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';

import { movementTypes, auditActions } from '../../support/constants';
import { Modal } from '../../components';
import { saveMovement } from '../../store/reducers/movement/create';
import { cleanReducer as cleanMovementsCreateReducer } from '../../store/reducers/movement/create';

import { useStyles } from './styles';

export default function AddAuditModal({
  openModal,
  setOpenModal,
  onSuccess = null,
  policy,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { wasSaved, isSaving } = useSelector((state) => state.movement.create);
  const [shouldShowAllValidationErrors, setShouldShowAllValidationErrors] = useState(false);
  const [dirtyFields, setDirtyFieldsState] = useState([]);
  const [movement, setMovement] = useState({ 
    type: movementTypes.Audit.value,
    policyId: policy.id
  });

  const [customIsValid, setCustomIsValid] = useState(true);

  const setDirtyFields = (fieldName) =>
    setDirtyFieldsState([...dirtyFields, fieldName]);

  useEffect(() => {
    if (wasSaved) {
      handleClose();

      if (onSuccess) {
        onSuccess();
      }
    }
  }, [wasSaved]);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const handleMovementConfirm = () => {
    if (!customIsValid) {
      return setShouldShowAllValidationErrors(true);
    }

    dispatch(
      saveMovement(findKey(movementTypes, ['value', movement.type]), {
        ...movement,
      })
    );
  };

  const handleClose = () => {
    dispatch(cleanMovementsCreateReducer());
    setOpenModal(false);
  };

  const handleChange = (event) => {
    setMovement({
      ...movement,
      [event.target.name]: event.target.value,
    });
  };

  const restrictSelected = () => {
    return +movement?.action === auditActions.Restrict.value;
  }

  const proceedSelected = () => {
    return +movement?.action === auditActions.Proceed.value
  }

  const validation = {
    isActionValid: () => !!movement?.action,
    isCommentValid: () => (!!movement?.comment && restrictSelected()) || proceedSelected() || (!movement?.action && !!movement?.comment),
  };

  const isFormValid = () => {
    return validation.isActionValid() && validation.isCommentValid();
  }

  return (
    <>
    <Modal
      onClose={handleClose}
      onConfirm={handleMovementConfirm}
      open={openModal}
      titleText="Audit"
      showActionButtons={true}
      isReadyToConfirm={true}
      isLoading={isSaving}
      PaperProps={{ className: classes.modal }}
    >
      <div>
        <RadioGroup 
            row 
            value={+movement?.action} 
            className={`${classes.radios} ${classes.formControl} ${(shouldShowAllValidationErrors && !validation.isActionValid() ? 'error' : '')}`}>
          {Object.keys(auditActions).map((key, index) => {
            return (<FormControlLabel
              key={index}
              value={auditActions[key].value}
              name="action"
              control={<Radio color="primary"/>}
              label={auditActions[key].displayName}
              onChange={handleChange}
            />)})}
        </RadioGroup>
        <TextField
            name="comment"
            label={restrictSelected() ? "Reason" : "Note"}
            className={classes.formControl}
            onChange={handleChange}
            variant="outlined"
            required={restrictSelected()}
            multiline={true}
            rows={3}
            onBlur={(event) => {
                setDirtyFields(event.target.name);
            }}
            error={
                (shouldShowAllValidationErrors || dirtyFields.includes('comment')) && !validation.isCommentValid()
              }
        />
      </div>
    </Modal>
    </>
  );
}
