import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { find } from 'lodash';

import { AttachmentsListExpandable, ListItem, Warning } from '../../components';
import { courts } from '../../support/constants';
import { getMatchingQddlRow } from '../../store/reducers/policy/matchingQddlRow';
import { 
  shouldShowWarningAboutChangedBand,
  shouldShowWarningAboutIncorrectCourt } from '../../support/movementHelpers';
import { useStyles } from './styles';

import {
  getFormattedDate,
  getFormattedPercent,
  getFormattedMoney,
  defaultIfEmpty,
} from '../../support/helpers';

export default function TrialRequestDetails({movement}) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    matchingQddlRow,
    isError,
    wasRecognizedAsOutsideQddl,
  } = useSelector((state) => state.movement.matchingQddlRow);

  const { policy } = useSelector(
    (state) => state.policy.details
  );

  const getCourtDisplayName = () => find(courts, {value: movement.court}).displayName;

  useEffect(() => {
    dispatch(
      getMatchingQddlRow(
        policy.id,
        { quantumSpecial: movement.quantumSpecial, quantumGeneral: movement.quantumGeneral })
    );
  }, [])

  return (
    <Grid container>
      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={true} />
          Supportive Counsel's Advice
        </Typography>
        <AttachmentsListExpandable attachments={movement.supportiveCounselsAdviceAttachments} showHeading={false} />
      </Grid>
      <hr/>
      <Grid item lg={12} className={classes.gridItemMargin}>
        <Typography variant="subtitle2" className={classes.checkboxMargin}>
          <Checkbox color="primary" checked={true} />
          Property Re-Surveyed by Head Surveyor
        </Typography>
        <AttachmentsListExpandable attachments={movement.propertyReSurveyedByHeadSurveyorAttachments} showHeading={false} />
      </Grid>

      <Grid item lg={12}>
        <ListItem
          primary="Trial date"
          secondary={getFormattedDate(movement.trialDate)}
        />
      </Grid>

      <Grid item lg={6}>
        <ListItem
          primary="Quantum general"
          secondary={getFormattedMoney(movement.quantumGeneral)}
        />
      </Grid>
      <Grid item lg={6}>
        <ListItem
          primary="Quantum special"
          secondary={getFormattedMoney(movement.quantumSpecial)}
        />
      </Grid>
      <Grid item lg={12} className={classes.warning}>
        {shouldShowWarningAboutChangedBand(policy, matchingQddlRow, wasRecognizedAsOutsideQddl) && <Warning>Quantum has changed band.</Warning>}
      </Grid>
      <Grid item lg={12}>
        <ListItem
          primary="Prospects"
          secondary={getFormattedPercent(movement.prospects)}
        />
      </Grid>

      <Grid item lg={6}>
        <ListItem
          primary="Exptected trial cost"
          secondary={getFormattedMoney(movement.expectedTrialCost)}
        />
      </Grid>

      <Grid item lg={6}>
        <ListItem
          primary="Expected trial length (days)"
          secondary={defaultIfEmpty(movement.expectedTrialLength)}
        />
      </Grid>

      <Grid item lg={12}>
        <ListItem
          primary="Court"
          secondary={getCourtDisplayName()}
        />
      </Grid>
      <Grid item lg={12} className={classes.warning}>
        {shouldShowWarningAboutIncorrectCourt(movement) &&<Warning>No 'County Court' or 'High court' selected.</Warning>}
      </Grid>

      <Grid item lg={12}>
        <ListItem
          primary="Comment"
          secondary={defaultIfEmpty(movement.comment)}
        />
      </Grid>

    </Grid>
  );
};