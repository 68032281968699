import React, { useEffect, useRef } from 'react';
import { FormGroup, Paper } from '@material-ui/core';

import { useStyles } from '../styles';
import { areDatesForPeriod, checkDateInRange } from '../../../support/helpers';

import { SpecificCauseOfActionCwiDefendant1 } from './SpecificCauseOfActionCwiDefendant1';
import { SpecificCauseOfActionCwiDefendant2 } from './SpecificCauseOfActionCwiDefendant2';
import { SpecificCauseOfActionCwiProperty } from './SpecificCauseOfActionCwiProperty';
import { getEmptyAddress } from '../../Address/Address';

export const SpecificCauseOfActionCwi = ({
  onChange,
  titleClassName,
  contentClassName,
  subtitleClassName,
  leadClaimant,
  currentStepState,
  stepStateBeforeChange,
  isBlockedForSolicitor,
}) => {
  const classes = useStyles();
  const [
    typeSpecificCauseOfAction,
    setTypeSpecificCauseOfAction,
  ] = React.useState(
    currentStepState ?? {
      isPropertyAddressDifferentThanClaimantAddress: false,
      propertyAddress: getEmptyAddress(),
      defendant1Address: {},
      hasDefendant1BeenDissolved: true,
      dateOfInstallation: null,
      defendant2PolicyPeriodFrom: null,
      defendant2PolicyPeriodTo: null,
    }
  );
  const [formValidationStatus, setFormValidationStatus] = React.useState(false);
  const [dirtyFields, setDirtyFieldsState] = React.useState([]);
  const formRef = useRef(null);
  const setDirtyFields = (event) => {
    setDirtyFieldsState([...dirtyFields, event.target.name]);
  };

  useEffect(
    () => onChange(formValidationStatus, { typeSpecificCauseOfAction }),
    [typeSpecificCauseOfAction, formValidationStatus]
  );

  useEffect(() => {
    const validate = () =>
      setFormValidationStatus(
        formRef?.current &&
          formRef.current.checkValidity() &&
          hasRequiredDate('dateOfInstallation') &&
          isDateOfInstallationBetweenDates() &&
          (!typeSpecificCauseOfAction.hasDefendant1BeenDissolved ||
            (hasRequiredDate('defendant2PolicyPeriodFrom') &&
              hasRequiredDate('defendant2PolicyPeriodTo'))) &&
          areDatesForPeriod(
            typeSpecificCauseOfAction.defendant2PolicyPeriodFrom,
            typeSpecificCauseOfAction.defendant2PolicyPeriodTo
          )
      );

    validate();
  }, [typeSpecificCauseOfAction]);

  const onSpecificCauseOfActionEventChange = (event) => {
    setTypeSpecificCauseOfAction({
      ...typeSpecificCauseOfAction,
      [event.target.name]: event.target.value,
    });
  };

  const onSpecificCauseOfActionDataChange = (propertyName) => (data) => {
    setTypeSpecificCauseOfAction({
      ...typeSpecificCauseOfAction,
      [propertyName]: data,
    });
  };

  const onSpecificCauseOfActionUpdateChange = (changes) => {
    setTypeSpecificCauseOfAction({
      ...typeSpecificCauseOfAction,
      ...changes,
    });
  };

  const hasError = (property) => {
    return (
      !formRef?.current || !formRef.current.elements[property].validity.valid
    );
  };

  const hasErrorToDisplay = (property) => {
    return dirtyFields.includes(property) && hasError(property);
  };

  // DatePicker control is rendered with readonly input inside. For HTML5 validation, readonly elements are not validated
  // Additional validate function is necessary instead of default HTML 5 validation
  const hasRequiredDate = (property) => {
    return !!typeSpecificCauseOfAction[property];
  };

  const isDateOfInstallationBetweenDates = () => {
    const dateOfInstallation = typeSpecificCauseOfAction.dateOfInstallation;
    const fromDate = typeSpecificCauseOfAction.defendant2PolicyPeriodFrom;
    const toDate = typeSpecificCauseOfAction.defendant2PolicyPeriodTo;
    return (
      !dateOfInstallation ||
      checkDateInRange(fromDate, toDate, dateOfInstallation)
    );
  };

  return (
    <>
      <form ref={formRef} noValidate>
        <h3 className={titleClassName}>Specific Cause of Action</h3>
        <Paper className={contentClassName}>
          <FormGroup className={classes.wizardForm}>
            <SpecificCauseOfActionCwiProperty
              leadClaimant={leadClaimant}
              onEventChange={onSpecificCauseOfActionEventChange}
              onDataChange={onSpecificCauseOfActionDataChange}
              onUpdateChange={onSpecificCauseOfActionUpdateChange}
              dirtyFields={dirtyFields}
              setDirtyFields={setDirtyFields}
              hasErrorToDisplay={hasErrorToDisplay}
              hasRequiredDate={hasRequiredDate}
              typeSpecificCauseOfAction={typeSpecificCauseOfAction}
              typeSpecificCauseOfActionBeforeChange={stepStateBeforeChange}
              subtitleClassName={subtitleClassName}
              isBlockedForSolicitor={isBlockedForSolicitor}
              isDateOfInstallationBetweenDates={
                isDateOfInstallationBetweenDates
              }
            />
            <SpecificCauseOfActionCwiDefendant1
              onEventChange={onSpecificCauseOfActionEventChange}
              onDataChange={onSpecificCauseOfActionDataChange}
              setDirtyFields={setDirtyFields}
              hasErrorToDisplay={hasErrorToDisplay}
              typeSpecificCauseOfAction={typeSpecificCauseOfAction}
              subtitleClassName={subtitleClassName}
              isBlockedForSolicitor={isBlockedForSolicitor}
            />
            <SpecificCauseOfActionCwiDefendant2
              onEventChange={onSpecificCauseOfActionEventChange}
              onDataChange={onSpecificCauseOfActionDataChange}
              dirtyFields={dirtyFields}
              setDirtyFields={setDirtyFields}
              hasErrorToDisplay={hasErrorToDisplay}
              hasRequiredDate={hasRequiredDate}
              typeSpecificCauseOfAction={typeSpecificCauseOfAction}
              subtitleClassName={subtitleClassName}
            />
          </FormGroup>
        </Paper>
      </form>
    </>
  );
};
