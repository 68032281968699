import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MaterialUiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

function Breadcrumbs(props) {
  const { children, current } = props;
  const classes = useStyles();

  return (
    <MaterialUiBreadcrumbs
      aria-label="breadcrumb"
      className={classes.breadcrumbs}
      separator={<ChevronRightOutlinedIcon />}
    >
      {children}
      <Typography className="current-page">{current}</Typography>
    </MaterialUiBreadcrumbs>
  );
}

Breadcrumbs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  current: PropTypes.string.isRequired,
};

export default withRouter(Breadcrumbs);
