import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const badgeStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const useStyles = makeStyles(() => ({
  gray: {
    ...badgeStyle,
    color: colors.grey7F,
    backgroundColor: '#ECECEC',
  },

  darkGray: {
    ...badgeStyle,
    color: '#333333',
    backgroundColor: '#33333315',
  },

  blue: {
    ...badgeStyle,
    color: '#337CA0',
    backgroundColor: '#DCE7ED',
  },

  green: {
    ...badgeStyle,
    color: colors.green,
    backgroundColor: '#00B01926',
  },

  lightGreen: {
    ...badgeStyle,
    color: '#1AA47B',
    backgroundColor: '#1AA47B15',
  },

  red: {
    ...badgeStyle,
    color: '#A41A33',
    backgroundColor: '#A41A3315',
  },

  darkRed: {
    ...badgeStyle,
    color: '#710000',
    backgroundColor: '#71000015',
  },

  purple: {
    ...badgeStyle,
    color: '#596AB1',
    backgroundColor: '#596AB115',
  },
}));
