import React from 'react';
import _startCase from 'lodash/startCase';

import { Grid } from '@material-ui/core';

import { ListItem } from '../../components';
import { defaultIfEmpty } from '../../support/helpers';
import IconPhone from '../../assets/phone.svg';

import { useStyles } from './styles';

export const ViewClaimantDetails = ({ claimant, index }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item lg={12}>
        <h3 className={classes.gridSectionTitle}>
          {claimant.isLead ? 'Lead Claimant' : `Claimant ${index + 1}`}
        </h3>
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Claimant Title"
          secondary={_startCase(claimant.title)}
        />
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="Forename and Surname"
          secondary={`
            ${claimant.forename} ${claimant.surname}
          `}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem primary="Age" secondary={claimant.age} />
      </Grid>
      <Grid item lg={3}>
        <ListItem
          primary="Contact Number"
          secondary={
            <>
              <img src={IconPhone} alt="phone" className={classes.icon} />
              {defaultIfEmpty(claimant.telephone)}
            </>
          }
        />
      </Grid>

      <Grid item lg={3}>
        <ListItem primary="Postcode" secondary={claimant.postcode} />
      </Grid>

      <Grid item lg={3}>
        <ListItem
          primary="House Number and Street"
          secondary={claimant.address}
        />
      </Grid>
      <Grid item lg={3}>
        <ListItem primary="Town/City" secondary={claimant.town} />
      </Grid>
      <Grid item lg={3}>
        <ListItem primary="County" secondary={claimant.county} />
      </Grid>
    </Grid>
  );
};
