import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '22px 34px',
    marginTop: 16,
  },
  reinsurerContainer: {
    paddingBottom: 15,
  },
  detailsList: {
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 60,
    '& > li': {
      padding: 0,
    },
    '& * span': {
      marginBottom: 4,
    },
    '&::before': {
      content: "''",
      width: 1,
      height: '100%',
      position: 'absolute',
      left: -25,
      backgroundColor: colors.greyE6,
    },
  },
}));
