import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';

import { useStyles } from '../styles';
import { capitalizeWords } from '../../../support/helpers';

import { Address } from '../../Address/Address';

export const SpecificCauseOfActionCwiDefendant1 = ({
  onEventChange,
  onDataChange,
  setDirtyFields,
  hasErrorToDisplay,
  typeSpecificCauseOfAction,
  subtitleClassName,
  isBlockedForSolicitor,
}) => {
  const classes = useStyles();

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onEventChange(event);
  }

  return (
    <>
      <h4 className={subtitleClassName}>Defendant 1 (installer)</h4>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          name="defendant1Name"
          label="Defendant 1 Name"
          value={typeSpecificCauseOfAction.defendant1Name}
          onChange={capitalizeInputChange}
          variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
          required={true}
          error={hasErrorToDisplay('defendant1Name')}
          onBlur={setDirtyFields}
          InputProps={{
            readOnly: isBlockedForSolicitor,
            style: {textTransform: 'capitalize'},
          }}
        />
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          name="defendant1CompanyNumber"
          label="Company Number"
          value={typeSpecificCauseOfAction.defendant1CompanyNumber}
          onChange={onEventChange}
          required={true}
          variant={isBlockedForSolicitor ? 'filled' : 'outlined'}
          error={hasErrorToDisplay('defendant1CompanyNumber')}
          onBlur={setDirtyFields}
          InputProps={{
            readOnly: isBlockedForSolicitor,
          }}
        />
        <TextField
          name="defendant1ContactNumber"
          label="Contact Number"
          value={typeSpecificCauseOfAction.defendant1ContactNumber}
          onChange={onEventChange}
          variant="outlined"
          error={hasErrorToDisplay('defendant1ContactNumber')}
          onBlur={setDirtyFields}
        />
      </FormGroup>
      <Address
        parentPropertyName="defendant1Address"
        parentProperty={typeSpecificCauseOfAction.defendant1Address}
        onDataChange={onDataChange}
        setDirtyFields={setDirtyFields}
        hasErrorToDisplay={hasErrorToDisplay}
        readOnly={isBlockedForSolicitor}
      />
    </>
  );
};
