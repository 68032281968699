import React from 'react';

import { Typography } from '@material-ui/core';

import { SchemeCriteria } from '../../components';
import { schemeType, schemeCriteriaAnswers } from '../../support/constants';

import { useStyles } from './policyDetailsTabsStyles';

export default function PolicyDetailsTabItemSchemeCriteria(props) {
  const classes = useStyles();
  const { policy } = props;

  const getAnswer = (criterion) => {
    return policy.schemeCriteriaResults.find((result) => {
      return result.schemeCriterionId === criterion.id;
    })?.answer;
  };

  const showWarningAboutPending = (criterion) => {
    return getAnswer(criterion) === schemeCriteriaAnswers.pending;
  };

  const showWarningAboutNo = (criterion) => {
    return getAnswer(criterion) === schemeCriteriaAnswers.no;
  };

  return (
    <>
      <Typography className={classes.panelSectionHeading} variant="h5">
        {schemeType[policy.scheme.type].description}
      </Typography>

      <SchemeCriteria
        criteria={policy.scheme.criteria}
        showQuestionName={false}
        showWarningAboutPending={showWarningAboutPending}
        showWarningAboutNo={showWarningAboutNo}
        getAnswer={getAnswer}
      />
    </>
  );
}
