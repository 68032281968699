import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { Button, Typography, Grid, Checkbox } from '@material-ui/core';

import { Section } from '../../components';
import ReportConfigurationOption from './ReportConfigurationOption';

import { fetchPoliciesReport, cleanReducer } from '../../store/reducers/report/policies';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import { useStyles } from './PoliciesReportStyles';

export default function PoliciesReport() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [reportConfiguration, setReportConfiguration] = useState({
    generalConfig:{
      ExcludeEmptyColumns: true,
      ShowOnlyLastMovement: false,
    },
    policyDetails: {
      SchemeCode: true,
      Id: true,
      Number: true,
      Status: true,
      Stage: true,
    },
    causeOfAction: {
      ProspectsOfSuccess: true,
      SuccessFee: true,
      EstimatedDamagesSpecial: true,
      EstimatedDamagesGeneral: true,
      TotalQuantum: true,
      CfaSignedDate: true,
      DateOfKnowledge: true,
      PolicyInceptionDate: true,
      SolicitorName: true,
      SolicitorOperatingAddress: true,
      CaseHandlerName: true,
      CaseHandlerEmailAddress: true,
      CaseReference: true,
    },
    cwiSpecificCauseOfAction: {
      IsPropertyAddressDifferentThanClaimantAddress: true,
      PropertyAddress: true,
      DateOfInstallation: true,
      PropertyStyle: true,
      PropertyBuildType: true,
      PropertySubType: true,
      InsulationType: true,
      NumberOfBedrooms: true,
      Defendant1Name: true,
      Defendant1ContactNumber: true,
      Defendant1Address: true,
      HasDefendant1BeenDissolved: true,
      Defendant2InsurerName: true,
      Defendant2InsurancePolicyNumber: true,
      Defendant2PolicyPeriodFrom: true,
      Defendant2PolicyPeriodTo: true,
      Defendant2PolicyLimit: true,
      Defendant2PolicyAggregateLimit: true,
    },
    mmcSpecificCauseOfAction: {
      DateOfMortgage: true,
      OriginalLender: true,
      MortgageNumber: true,
      Amount: true,
      HasThereBeenRemortgage: true,
      DateOfRemortgage: true,
      HasOtherPartyRightsToMortgage: true,
      NewLenderName: true,
      NewLenderReference: true,
      AuditReportName: true,
      AuditVersionNumber: true,
      FullAuditReportDate: true,
    },
    claimants: {
      Title: true,
      Age: true,
      Forename: true,
      Surname: true,
      Address: true,
      Telephone: true,
    },
    criterias: {
      Question: true,
      Answer: true,
    },
    premium: {
      FundedCurrentExclIPT: true,
      DeferredCurrentExclIPT: true,
      TotalCurrentInclIPT: true,
      Ipt: true,
    },
    movements: {
      CreatedWho: true,
      CreatedWhen: true,
      Code: true,
      Number: true,
      Text: true,
      ApprovedWhen: true,
    },
    claims: {
      ExpensesPaid: true,
      ExpensesOutstanding: true,
      RecoveriesPaid: true,
      RecoveriesOutstanding: true,
    }
  });

  const history = useHistory();

  const { isLoading, downloadSucceeded, url, filename } = useSelector((state) => state.report.policies);

  const generateReport = () => {
    dispatch(fetchPoliciesReport(reportConfiguration));
  }

  useEffect(() => {
    if (downloadSucceeded) {
      saveAs(url, filename);
      history.push("/policies");
    }
  }, [downloadSucceeded]);

  useEffect(() => {
    return () => {
      dispatch(cleanReducer());
    };
  }, []);

  const onCheckboxChange = (subsection, event) => {
    var configuration = {...reportConfiguration};
    configuration[subsection][event.target.name] = event.target.checked;
    setReportConfiguration(configuration);
  };

  const onChangeSubsection = (subsection, event) => {
    var configuration = {...reportConfiguration};
    var subsectionConfiguration = configuration[subsection];

    // set all to true or false
    Object.keys(subsectionConfiguration).forEach(option => subsectionConfiguration[option] = event.target.checked);
    // except policy id, ugly hack
    configuration.policyDetails.Id = true;

    setReportConfiguration(configuration);
  };

  const allAreTrueOrAllAreFalse = (obj) => {
    return Object.values(obj).every(option => !!option) || Object.values(obj).every(option => !option);
  }

  const allAreTrue = obj => {
    return Object.values(obj).every(option => !!option);
  }

  return (
    <>
      <LoadingWrapper loading={isLoading || downloadSucceeded} />
      {(!isLoading && !downloadSucceeded)&& (
          <>
            <div className={classes.toolbar}>
              <Typography variant="h3" className={classes.title}>
                Policies report configuration
              </Typography>
            </div>

            <Section title="General configuration" highlighted={true} mainSection={true}>
              <Grid container>
                <Grid item>
                  <ReportConfigurationOption caption="Exclude empty columns" subsection="generalConfig" fieldName="ExcludeEmptyColumns" onChange={onCheckboxChange} value={reportConfiguration.generalConfig.ExcludeEmptyColumns} />
                </Grid>
                <Grid item>
                  <ReportConfigurationOption caption="Show only last movement" subsection="generalConfig" fieldName="ShowOnlyLastMovement" onChange={onCheckboxChange} value={reportConfiguration.generalConfig.ShowOnlyLastMovement} />
                </Grid>
              </Grid>
            </Section>

            <Section title="Columns configuration" highlighted={true} mainSection={true}>
              <Typography variant="body1">Select all columns that should be visible in the report. Keep in mind that if you select "Exclude empty columns" above, selected columns will not be shown in the report if they are empty. Report configuration takes precedence over columns configuration.</Typography>
              <hr/>
          
              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.policyDetails)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.policyDetails)}
                        onChange={(event) => { onChangeSubsection("policyDetails", event)}}/>
                      Policy details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Scheme code" subsection="policyDetails" fieldName="SchemeCode" onChange={onCheckboxChange} value={reportConfiguration.policyDetails.SchemeCode} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Id" subsection="policyDetails" fieldName="Id" onChange={onCheckboxChange} value={reportConfiguration.policyDetails.Id} disabled={true} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Number" subsection="policyDetails" fieldName="Number" onChange={onCheckboxChange} value={reportConfiguration.policyDetails.Number} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Status" subsection="policyDetails" fieldName="Status" onChange={onCheckboxChange} value={reportConfiguration.policyDetails.Status} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Stage" subsection="policyDetails" fieldName="Stage" onChange={onCheckboxChange} value={reportConfiguration.policyDetails.Stage} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.causeOfAction)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.causeOfAction)}
                        onChange={(event) => { onChangeSubsection("causeOfAction", event)}}/>
                      Cause of action
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Prospects of success" subsection="causeOfAction" fieldName="ProspectsOfSuccess" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.ProspectsOfSuccess} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Success fee" subsection="causeOfAction" fieldName="SuccessFee" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.SuccessFee} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Estimated damages special" subsection="causeOfAction" fieldName="EstimatedDamagesSpecial" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.EstimatedDamagesSpecial} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Estimated damages general" subsection="causeOfAction" fieldName="EstimatedDamagesGeneral" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.EstimatedDamagesGeneral} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Total quantum" subsection="causeOfAction" fieldName="TotalQuantum" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.TotalQuantum} />
                  </Grid>
                </Grid>
                <Grid container xs={4} direction="column">
                  <Grid>
                    <Typography variant="h5" className={classes.subsectionTitle}>&nbsp;</Typography>
                  </Grid>
                  <Grid>
                    <ReportConfigurationOption caption="CFA signed date" subsection="causeOfAction" fieldName="CfaSignedDate" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.CfaSignedDate} />
                  </Grid>
                  <Grid>
                    <ReportConfigurationOption caption="Date of knowledge" subsection="causeOfAction" fieldName="DateOfKnowledge" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.DateOfKnowledge} />
                  </Grid>
                  <Grid>
                    <ReportConfigurationOption caption="Policy inception date" subsection="causeOfAction" fieldName="PolicyInceptionDate" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.PolicyInceptionDate} />
                  </Grid>
                  <Grid>
                    <ReportConfigurationOption caption="Solicitor name" subsection="causeOfAction" fieldName="SolicitorName" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.SolicitorName} />
                  </Grid>
                  <Grid>
                    <ReportConfigurationOption caption="Total quantum" subsection="causeOfAction" fieldName="SolicitorOperatingAddress" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.SolicitorOperatingAddress} />
                  </Grid>
                </Grid>
                <Grid container xs={4} direction='column'>
                  <Grid item >
                    <Typography variant="h5" className={classes.subsectionTitle}>&nbsp;</Typography>
                  </Grid>
                  <Grid item >
                    <ReportConfigurationOption caption="Case handler name" subsection="causeOfAction" fieldName="CaseHandlerName" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.CaseHandlerName} />
                  </Grid>
                  <Grid item >
                    <ReportConfigurationOption caption="Case handler e-mail address" subsection="causeOfAction" fieldName="CaseHandlerEmailAddress" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.CaseHandlerEmailAddress} />
                  </Grid>
                  <Grid item >
                    <ReportConfigurationOption caption="Case reference" subsection="causeOfAction" fieldName="CaseReference" onChange={onCheckboxChange} value={reportConfiguration.causeOfAction.CaseReference} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.cwiSpecificCauseOfAction)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.cwiSpecificCauseOfAction)}
                        onChange={(event) => { onChangeSubsection("cwiSpecificCauseOfAction", event)}}/>
                      CWI specific cause of action
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Is property address different than claimant address" subsection="cwiSpecificCauseOfAction" fieldName="IsPropertyAddressDifferentThanClaimantAddress" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.IsPropertyAddressDifferentThanClaimantAddress} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Property address" subsection="cwiSpecificCauseOfAction" fieldName="PropertyAddress" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.PropertyAddress} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Date of installation" subsection="cwiSpecificCauseOfAction" fieldName="DateOfInstallation" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.DateOfInstallation} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Property style" subsection="cwiSpecificCauseOfAction" fieldName="PropertyStyle" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.PropertyStyle} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Property build type" subsection="cwiSpecificCauseOfAction" fieldName="PropertyBuildType" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.PropertyBuildType} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Property subtype" subsection="cwiSpecificCauseOfAction" fieldName="PropertySubType" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.PropertySubType} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Insulation type" subsection="cwiSpecificCauseOfAction" fieldName="InsulationType" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.InsulationType} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Number of bedrooms" subsection="cwiSpecificCauseOfAction" fieldName="NumberOfBedrooms" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.NumberOfBedrooms} />
                  </Grid>
                </Grid>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 1 name" subsection="cwiSpecificCauseOfAction" fieldName="Defendant1Name" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant1Name} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 1 contact number" subsection="cwiSpecificCauseOfAction" fieldName="Defendant1ContactNumber" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant1ContactNumber} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 1 address" subsection="cwiSpecificCauseOfAction" fieldName="Defendant1Address" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant1Address} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Has defendant 1 been dissolved" subsection="cwiSpecificCauseOfAction" fieldName="HasDefendant1BeenDissolved" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.HasDefendant1BeenDissolved} />
                  </Grid>
                </Grid>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 2 insurer name" subsection="cwiSpecificCauseOfAction" fieldName="Defendant2InsurerName" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant2InsurerName} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 2 insurance policy number" subsection="cwiSpecificCauseOfAction" fieldName="Defendant2InsurancePolicyNumber" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant2InsurancePolicyNumber} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 2 policy period from" subsection="cwiSpecificCauseOfAction" fieldName="Defendant2PolicyPeriodFrom" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant2PolicyPeriodFrom} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 2 policy period to" subsection="cwiSpecificCauseOfAction" fieldName="Defendant2PolicyPeriodTo" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant2PolicyPeriodTo} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 2 policy limit" subsection="cwiSpecificCauseOfAction" fieldName="Defendant2PolicyLimit" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant2PolicyLimit} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Defendant 2 policy aggregate limit" subsection="cwiSpecificCauseOfAction" fieldName="Defendant2PolicyAggregateLimit" onChange={onCheckboxChange} value={reportConfiguration.cwiSpecificCauseOfAction.Defendant2PolicyAggregateLimit} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                     <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.mmcSpecificCauseOfAction)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.mmcSpecificCauseOfAction)}
                        onChange={(event) => { onChangeSubsection("mmcSpecificCauseOfAction", event)}}/>
                      MMC specific cause of action
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Date of mortgage" subsection="mmcSpecificCauseOfAction" fieldName="DateOfMortgage" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.DateOfMortgage} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Original lender" subsection="mmcSpecificCauseOfAction" fieldName="OriginalLender" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.OriginalLender} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Morgage number" subsection="mmcSpecificCauseOfAction" fieldName="MortgageNumber" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.MortgageNumber} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Amount" subsection="mmcSpecificCauseOfAction" fieldName="Amount" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.Amount} />
                  </Grid>
                </Grid>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Has there been remortgage" subsection="mmcSpecificCauseOfAction" fieldName="HasThereBeenRemortgage" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.HasThereBeenRemortgage} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Date of remortgage" subsection="mmcSpecificCauseOfAction" fieldName="DateOfRemortgage" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.DateOfRemortgage} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Has other party rights to mortgage" subsection="mmcSpecificCauseOfAction" fieldName="HasOtherPartyRightsToMortgage" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.HasOtherPartyRightsToMortgage} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="New lender name" subsection="mmcSpecificCauseOfAction" fieldName="NewLenderName" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.NewLenderName} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="New lender reference" subsection="mmcSpecificCauseOfAction" fieldName="NewLenderReference" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.NewLenderReference} />
                  </Grid>
                </Grid>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Audit report name" subsection="mmcSpecificCauseOfAction" fieldName="AuditReportName" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.AuditReportName} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Audit version number" subsection="mmcSpecificCauseOfAction" fieldName="AuditVersionNumber" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.AuditVersionNumber} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Full audit report date" subsection="mmcSpecificCauseOfAction" fieldName="FullAuditReportDate" onChange={onCheckboxChange} value={reportConfiguration.mmcSpecificCauseOfAction.FullAuditReportDate} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.claimants)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.claimants)}
                        onChange={(event) => { onChangeSubsection("claimants", event)}}/>
                      Claimants
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Title" subsection="claimants" fieldName="Title" onChange={onCheckboxChange} value={reportConfiguration.claimants.Title} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Age" subsection="claimants" fieldName="Age" onChange={onCheckboxChange} value={reportConfiguration.claimants.Age} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Forename" subsection="claimants" fieldName="Forename" onChange={onCheckboxChange} value={reportConfiguration.claimants.Forename} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Surname" subsection="claimants" fieldName="Surname" onChange={onCheckboxChange} value={reportConfiguration.claimants.Surname} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Address" subsection="claimants" fieldName="Address" onChange={onCheckboxChange} value={reportConfiguration.claimants.Address} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Telephone" subsection="claimants" fieldName="Telephone" onChange={onCheckboxChange} value={reportConfiguration.claimants.Telephone} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.criterias)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.criterias)}
                        onChange={(event) => { onChangeSubsection("criterias", event)}}/>
                      Criteria
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Question" subsection="criterias" fieldName="Question" onChange={onCheckboxChange} value={reportConfiguration.criterias.Question} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Answer" subsection="criterias" fieldName="Answer" onChange={onCheckboxChange} value={reportConfiguration.criterias.Answer} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.premium)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.premium)}
                        onChange={(event) => { onChangeSubsection("premium", event)}}/>
                      Premium
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Funded current excl. IPT" subsection="premium" fieldName="FundedCurrentExclIPT" onChange={onCheckboxChange} value={reportConfiguration.premium.FundedCurrentExclIPT} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Deferred current excl. IPT" subsection="premium" fieldName="DeferredCurrentExclIPT" onChange={onCheckboxChange} value={reportConfiguration.premium.DeferredCurrentExclIPT} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Total current incl. IPT" subsection="premium" fieldName="TotalCurrentInclIPT" onChange={onCheckboxChange} value={reportConfiguration.premium.TotalCurrentInclIPT} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="IPT" subsection="premium" fieldName="Ipt" onChange={onCheckboxChange} value={reportConfiguration.premium.Ipt} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.movements)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.movements)}
                        onChange={(event) => { onChangeSubsection("movements", event)}}/>
                      Movements
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Created who" subsection="movements" fieldName="CreatedWho" onChange={onCheckboxChange} value={reportConfiguration.movements.CreatedWho} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Created when" subsection="movements" fieldName="CreatedWhen" onChange={onCheckboxChange} value={reportConfiguration.movements.CreatedWhen} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Code" subsection="movements" fieldName="Code" onChange={onCheckboxChange} value={reportConfiguration.movements.Code} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Number" subsection="movements" fieldName="Number" onChange={onCheckboxChange} value={reportConfiguration.movements.Number} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Text" subsection="movements" fieldName="Text" onChange={onCheckboxChange} value={reportConfiguration.movements.Text} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Approved when" subsection="movements" fieldName="ApprovedWhen" onChange={onCheckboxChange} value={reportConfiguration.movements.ApprovedWhen} />
                  </Grid>
                </Grid>
              </Grid>

              <hr/>

              <Grid container>
                <Grid container xs={4} direction="column">
                  <Grid item>
                    <Typography variant="h5" className={classes.subsectionTitle}>
                      <Checkbox 
                        color="primary" 
                        className={classes.reportOptionCheckbox}
                        checked={allAreTrue(reportConfiguration.claims)}
                        indeterminate={!allAreTrueOrAllAreFalse(reportConfiguration.claims)}
                        onChange={(event) => { onChangeSubsection("claims", event)}}/>
                      Claims
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Expenses paid" subsection="claims" fieldName="ExpensesPaid" onChange={onCheckboxChange} value={reportConfiguration.claims.ExpensesPaid} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Expenses outstanding" subsection="claims" fieldName="ExpensesOutstanding" onChange={onCheckboxChange} value={reportConfiguration.claims.ExpensesOutstanding} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Recoveries paid" subsection="claims" fieldName="RecoveriesPaid" onChange={onCheckboxChange} value={reportConfiguration.claims.RecoveriesPaid} />
                  </Grid>
                  <Grid item>
                    <ReportConfigurationOption caption="Recoveries outstanding" subsection="claims" fieldName="RecoveriesOutstanding" onChange={onCheckboxChange} value={reportConfiguration.claims.RecoveriesOutstanding} />
                  </Grid>
                </Grid>
              </Grid>
            </Section>

            <div className={classes.bottomToolbar}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={generateReport}
              >
                Generate report
              </Button>
            </div>
          </>
        )}
    </>
  );
}
