import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Divider, Paper, Grid, Typography } from '@material-ui/core';

import EditIcon from '../../assets/edit.svg';
import { ClaimLimit, ListItem, QddlTablePreview } from '../../components';
import { includesAny } from '../../support/helpers';
import { userRoles } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';

import { isEmpty } from '../../support/helpers';
import { SchemeDetailsEligibleSolicitorsSection } from './SchemeDetailsEligibleSolicitorsSection';

export const SchemeDetailsTabItem = (props) => {
  const { scheme, classes, restrictedForInsurer } = props;

  const history = useHistory();
  const userGroups = useSelector(userGroupsSelector);

  const displayWithDefault = (displayFunc, value) => {
    if (isEmpty(value)) {
      return '-';
    }

    return displayFunc(value);
  };

  const handleAmendRedirect = () => {
    history.push(`/schemes/${scheme.id}/amend`);
  };

  return (
    <>
      <div className={classes.tabHeader}>
        <Typography variant="h4">Scheme Details</Typography>
        {includesAny(userGroups, [userRoles.resolve]) && scheme.status === 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAmendRedirect}
            className={classes.button}
            startIcon={
              <img src={EditIcon} alt="edit" className={classes.editIcon} />
            }
          >
            Amend
          </Button>
        )}
      </div>
      <Paper className={classes.root}>
        <Grid container className={classes.heading}>
          <Grid item xs={9}>
            <Typography variant="overline">
              Name & contact information
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="overline">Attachments</Typography>
          </Grid>
        </Grid>
        <SchemeDetailsEligibleSolicitorsSection
          scheme={scheme}
          classes={classes}
          restrictedForInsurer={restrictedForInsurer}
        />
      </Paper>

      <Paper className={classes.root}>
        <Typography variant="subtitle1">QDDL - Incremental</Typography>

        <Divider />
        <QddlTablePreview qddl={scheme.qddlRows} />
      </Paper>

      <Paper className={classes.root}>
        <Typography variant="subtitle1">Referral Limits</Typography>

        <Divider />
        <Grid
          container
          spacing={0}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item lg={4}>
            <ListItem
              primary="Claim Limit:"
              secondary={displayWithDefault(
                (value) => (
                  <ClaimLimit claimLimit={value} />
                ),
                scheme?.claimLimit
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <ListItem
              primary="Decline Referral:"
              secondary={displayWithDefault(
                (value) => (value ? 'Yes' : 'No'),
                scheme.declineReferral
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <ListItem
              primary="Complaint Limit:"
              secondary={displayWithDefault(
                (value) =>
                  value === 1
                    ? 'Initial Complaint'
                    : value === 2
                    ? 'AppealedComplaint'
                    : 'Other',
                scheme.complaintLimit
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
