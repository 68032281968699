import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Divider, Grid, Typography, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '../../assets/plus.svg';
import {
  AttachmentsListExpandable,
  ContactInformation,
  EligibleSolicitorEdit,
  ExpanderPanel,
  Modal,
  Section,
} from '../../components';
import FormValidator from '../../support/FormValidator';
import { includesAny, uniqueId as getUniqueId } from '../../support/helpers';
import { userRoles } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';
import { addEligibleSolicitor } from '../../store/reducers/scheme/details';

import Company from './Company';
import { getValueByRole } from './getValueByRole';
import { showNotification } from '../../store/reducers/global/notifications';

export const SchemeDetailsEligibleSolicitorsSection = ({
  scheme,
  classes,
  restrictedForInsurer,
}) => {
  const dispatch = useDispatch();
  const userGroups = useSelector(userGroupsSelector);

  const maxSolicitorsCount = 10;
  const [newSolicitor, setNewSolicitor] = useState({});
  const [isNewSolicitorValid, setIsNewSolicitorValid] = useState(false);
  const [openNewSolicitorModal, setOpenNewSolicitorModal] = useState(false);
  const formRef = useRef(null);
  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    setIsNewSolicitorValid(validator?.isFormValid());
  }, [newSolicitor]);

  const handleOpenNewSolicitorModal = () => {
    setNewSolicitor({});
    setValidator(new FormValidator(formRef));
    setOpenNewSolicitorModal(true);
  };

  const handleCloseNewSolicitorModal = () => {
    setOpenNewSolicitorModal(false);
    setNewSolicitor({});
  };

  const handleSavingNewSolicitor = () => {
    dispatch(addEligibleSolicitor(scheme.id, newSolicitor))
      .then(() => {
        setOpenNewSolicitorModal(false);
        setNewSolicitor({});
        dispatch(
          showNotification({
            color: 'success',
            message: 'Eligible Solicitor was added to Scheme.',
          })
        );
      })
      .catch(() => {
        dispatch(
          showNotification({
            color: 'danger',
            message: 'Action failed. Eligible Solicitor was not saved.',
          })
        );
      });
  };

  const onNewSolicitorChange = (solicitor) => {
    setNewSolicitor(solicitor);
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  const getTooltipText = () => {
    if (scheme.eligibleSolicitors.length >= maxSolicitorsCount) {
      return 'Limit for number of Eligible Solicitors has been reached. No more Eligible Silicitors can be added.';
    }

    return '';
  };

  const EligibleSolicitor = ({ index }) => {
    return (
      <>
        <Divider />
        <Company
          scheme={scheme}
          role="eligibleSolicitors"
          index={index}
          restrictedForInsurer={restrictedForInsurer}
        />
        <Grid container className={classes.solicitorsContainer}>
          <Grid item xs={9}>
            <ContactInformation
              scheme={scheme}
              role="eligibleSolicitors"
              index={index}
              getValueByRole={getValueByRole}
              restrictedForInsurer={restrictedForInsurer}
            />
          </Grid>
          <Grid item xs={3}>
            <AttachmentsListExpandable
              attachments={getValueByRole(
                scheme,
                'eligibleSolicitors',
                'attachments',
                index
              )}
              showHeading={false}
              modalHeadingText="Eligible Solicitors Attachments"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Typography variant="subtitle1">Eligible Solicitors</Typography>
      {scheme.eligibleSolicitors?.length > 0 && <EligibleSolicitor index={0} />}
      {scheme.eligibleSolicitors?.length > 1 && (
        <ExpanderPanel
          count={scheme.eligibleSolicitors.length - 1}
          expandedText="eligible solicitors"
        >
          {scheme.eligibleSolicitors
            .slice(1, scheme.eligibleSolicitors.length)
            .map((eligibleSolicitor, index) => {
              return (
                <EligibleSolicitor
                  index={index + 1}
                  key={eligibleSolicitor.id || getUniqueId()}
                />
              );
            })}
        </ExpanderPanel>
      )}
      {includesAny(userGroups, [userRoles.resolve]) && scheme.status === 2 && (
        <div className={classes.addSolicitor}>
          <Tooltip title={getTooltipText()}>
            <span>
              <Button
                color="primary"
                onClick={handleOpenNewSolicitorModal}
                disabled={
                  scheme.eligibleSolicitors.length >= maxSolicitorsCount
                }
              >
                <img src={AddIcon} alt="add" />
                Add Solicitor
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
      {openNewSolicitorModal && (
        <Modal
          onClose={handleCloseNewSolicitorModal}
          onConfirm={handleSavingNewSolicitor}
          open={openNewSolicitorModal}
          titleText="Add Eligible Solicitor"
          showActionButtons={true}
          isReadyToConfirm={isNewSolicitorValid}
        >
          <form ref={formRef} noValidate>
            <Section mainSection={true}>
              <EligibleSolicitorEdit
                solicitor={newSolicitor}
                onChange={onNewSolicitorChange}
                validator={validator}
                addDirtyField={addDirtyField}
              />
            </Section>
          </form>
        </Modal>
      )}
    </>
  );
};
