import React from 'react';
import {
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';

import { SchemeCriteria } from '../../components';

import { schemeType } from '../../support/constants';

export const SchemeCriteriaTabItem = ({ scheme, classes }) => {
  return (
    <>  
      <Typography variant="h4">
        Scheme Criteria
      </Typography>
      <Paper className={classes.root}>
        <Typography variant="subtitle1">
          {schemeType[scheme.type].description}
        </Typography>
        <Divider />

        <SchemeCriteria criteria={scheme.criteria} />
      </Paper>
    </>
  );
};
