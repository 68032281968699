import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Paper, Tabs, Tab, Typography } from '@material-ui/core';

import { includesAny } from '../../support/helpers';
import { userRoles } from '../../support/constants';
import { userGroupsSelector, accessTokenSelector } from '../../store/selectors/global';
import { showLoader, hideLoader} from '../../store/reducers/global/loadingWithScreenBlock';
import EditIcon from '../../assets/edit.svg';
import GetAppIcon from '@material-ui/icons/GetApp';

import PolicyDetailsTabItemSchemeCriteria from './PolicyDetailsTabItemSchemeCriteria';
import PolicyDetailsTabItemClaimants from './PolicyDetailsTabItemClaimants';
import PolicyDetailsTabItemCauseOfAction from './PolicyDetailsTabItemCauseOfAction';
import PolicyDetailsTabItemSpecificCauseOfActionCwi from './PolicyDetailsTabItemSpecificCauseOfActionCwi';
import PolicyDetailsTabItemSpecificCauseOfActionMmc from './PolicyDetailsTabItemSpecificCauseOfActionMmc';
import { useStyles } from './policyDetailsTabsStyles';
import filesDownloader from '../../support/files-downloader';

export default function PolicyDetailsTabItem(props) {
  const { policy, restrictedForInsurer } = props;

  const history = useHistory();
  const classes = useStyles();
  const userGroups = useSelector(userGroupsSelector);
  const accessToken = useSelector(accessTokenSelector);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleAmendRedirect = () => {
    history.push(`/policies/${policy.id}/amend`);
  };

  const getFileName = () => {
    return `policy-documents-${policy.number}_${policy.claimants[0]?.surname}_${policy.causeOfAction?.solicitorName}.zip`;
  }

  const handleDownloadAllDocuments = () => {
    dispatch(showLoader());
    filesDownloader({
      filename: getFileName(),
      accessToken: accessToken,
      requestURL: `/policies/${policy.id}/documents`,
      method: 'GET',
      requestConfig: {
        responseType: 'blob',
      },
    },
    () => {
      dispatch(hideLoader());
    })
  };

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        className={classes.tabVerticalPanel}
        role="tabpanel"
        hidden={value !== index}
        id={`policy-details-tabpanel-${index}`}
        aria-labelledby={`policy-details-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const getSpecificCauseOfAction = () => {
    switch (policy.scheme.type) {
      case 1:
        return (
          <PolicyDetailsTabItemSpecificCauseOfActionCwi
            policy={policy}
            classes={classes}
            restrictedForInsurer={restrictedForInsurer}
          />
        );
      case 2:
        return (
          <PolicyDetailsTabItemSpecificCauseOfActionMmc
            policy={policy}
            classes={classes}
          />
        );
      default:
        return 'Unknown scheme type';
    }
  };

  return (
    <>
      <div className={classes.tabHeader}>
        <Typography variant="h4">Policy Details</Typography>
        <div>
        {includesAny(userGroups, [userRoles.resolve]) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadAllDocuments}
            className={classes.button}
            startIcon={<GetAppIcon />}
          >
            Download all documents
          </Button>
        )}
        {includesAny(userGroups, [userRoles.resolve, userRoles.solicitor]) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAmendRedirect}
            className={classes.button}
            startIcon={
              <img src={EditIcon} alt="edit" className={classes.editIcon} />
            }
          >
            Amend
          </Button>
        )}
        </div>
      </div>
      <Paper className={classes.policyDetailsTabItem}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          orientation="vertical"
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabsVertical}
        >
          <Tab tabIndex={0} label="1. Scheme Criteria" />
          <Tab tabIndex={1} label="2. Claimants" />
          <Tab tabIndex={2} label="3. Cause of Action" />
          <Tab tabIndex={2} label="4. Specific Cause of Action" />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <PolicyDetailsTabItemSchemeCriteria
            policy={policy}
            classes={classes}
          />
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <PolicyDetailsTabItemClaimants
            policy={policy}
            classes={classes}
            restrictedForInsurer={restrictedForInsurer}
          />
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <PolicyDetailsTabItemCauseOfAction
            policy={policy}
            classes={classes}
            restrictedForInsurer={restrictedForInsurer}
          />
        </TabPanel>

        <TabPanel value={currentTab} index={3}>
          {getSpecificCauseOfAction()}
        </TabPanel>
      </Paper>
    </>
  );
}
