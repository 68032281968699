import { createAction, createSlice } from '@reduxjs/toolkit';
import { schemeType } from '../../../support/constants';
import { prepareSchemaCriteriaForPolicy } from '../../../support/policyHelpers';

const initialState = {
  downloadedPolicy: null,
  selectedScheme: {},
  savedPolicyId: null,
  isFetchingScheme: false,
  isSaving: false,
};

export const policiesSlice = createSlice({
  name: 'policies',
  initialState: initialState,
  reducers: {
    startPolicySaving: (state) => {
      state.isSaving = true;
    },
    policySavingError: (state) => {
      state.isSaving = false;
    },
    creatingPolicySuccess: (state, action) => {
      state.isSaving = false;
      state.savedPolicyId = action.response.data;
    },
    updatingPolicySuccess: (state, action) => {
      state.isSaving = false;
      state.savedPolicyId = action.id;
    },
    startFetchingPolicy: (state) => {
      state.isLoading = true;
    },
    policyFetchingSuccess: (state, action) => {
      state.downloadedPolicy = action.response.data;
      state.isLoading = false;
    },
    policyFetchingError: (state) => {
      state.isLoading = false;
    },

    startFetchingScheme: (state) => {
      state.isFetchingScheme = true;
    },
    schemeFetchingSuccess: (state, action) => {
      state.selectedScheme = {
        ...action.response.data,
        criteria: prepareSchemaCriteriaForPolicy(action.response.data.criteria),
      };
      state.isFetchingScheme = false;
    },
    schemeFetchingError: (state) => {
      state.isFetchingScheme = false;
    },
    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  startPolicySaving,
  policySavingError,
  creatingPolicySuccess,
  updatingPolicySuccess,
  startFetchingPolicy,
  policyFetchingError,
  policyFetchingSuccess,
  startFetchingScheme,
  schemeFetchingError,
  schemeFetchingSuccess,
  cleanReducer,
} = policiesSlice.actions;

export const savePolicy = createAction(
  'savePolicy',
  (schemeTypeId, policy) => ({
    meta: {
      callAPITypes: [
        startPolicySaving,
        creatingPolicySuccess,
        policySavingError,
      ],
      requestURL: `/policies/create${schemeType[schemeTypeId].type}`,
      method: 'POST',
      requestConfig: {
        data: {
          ...policy,
        },
      },
    },
    type: 'savePolicy',
    payload: policy,
  })
);

export const updatePolicy = createAction(
  'updatePolicy',
  (schemeTypeId, policy) => ({
    meta: {
      callAPITypes: [
        startPolicySaving,
        updatingPolicySuccess,
        policySavingError,
      ],
      requestURL: `/policies/${schemeType[schemeTypeId].type}/${policy.id}`,
      method: 'PUT',
      requestConfig: {
        data: {
          ...policy,
        },
      },
    },
    type: 'updatePolicy',
    payload: policy,
  })
);

export const fetchPolicy = createAction('fetchPolicy', (id) => ({
  meta: {
    callAPITypes: [
      startFetchingPolicy,
      policyFetchingSuccess,
      policyFetchingError,
    ],
    requestURL: `/policies/${id}`,
    method: 'GET',
  },
  type: 'fetchPolicy',
  payload: null,
}));

export const fetchScheme = createAction('fetchScheme', (schemeId) => ({
  meta: {
    callAPITypes: [
      startFetchingScheme,
      schemeFetchingSuccess,
      schemeFetchingError,
    ],
    requestURL: `/schemes/${schemeId}`,
    method: 'GET',
  },
  type: 'fetchScheme',
  payload: null,
}));

export default policiesSlice.reducer;
