import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const column = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 60,
  '& > li': {
    padding: 0,
  },
  '& * span': {
    marginBottom: 4,
  },
  '& * p': {
    wordBreak: 'break-word',
  },
};

export const useStyles = makeStyles(() => ({
  companyName: {
    paddingTop: 12,
    paddingBottom: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.primary,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& img': {
      marginRight: 8,
    },
  },
  leftColumn: {
    ...column,
  },
  rightColumn: {
    ...column,
    height: '100%',
    paddingRight: 30,
    '&::before': {
      content: "''",
      width: 1,
      height: '100%',
      position: 'absolute',
      left: -25,
      backgroundColor: colors.greyE6,
    },
  },
  contactContainer: {
    padding: '12px 20px',
    marginTop: 16,
    backgroundColor: colors.greyFA,
    boxShadow: 'none',
  },
  contactHeading: {
    display: 'flex',
    fontWeight: 600,
    '& img': {
      marginRight: 8,
      filter: 'grayscale(1)',
    },
  },
  modal: {
    minWidth: 600,
  },
}));
