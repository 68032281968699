import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { userGroupsSelector } from '../../store/selectors/global';
import { userRoles } from '../../support/constants';

import { SchemeCriteria } from './SchemeCriteria';
import { Claimants } from './Claimants';
import { CauseOfAction } from './CauseOfAction';
import { SpecificCauseOfActionMMC } from './SpecificCauseOfActionMMC';
import { SpecificCauseOfActionCwi } from './SpecificCauseOfActionCwi/SpecificCauseOfActionCwi';
import { Preview } from './Preview';

const getSteps = () => {
  return [
    'Scheme criteria',
    'Claimants',
    'Cause of action',
    'Specific cause of action',
    'Preview',
  ];
};

const getStepContent = (
  step,
  styles,
  scheme,
  policy,
  policyBeforeChange,
  onChange,
  isBlockedForSolicitor,
  isCreate
) => {
  switch (step) {
    case 0:
      return (
        <SchemeCriteria
          onChange={onChange}
          titleClassName={styles.stepTitle}
          contentClassName={styles.stepContent}
          listElementClassName={styles.listElement}
          subtitleClassName={styles.subtitle}
          listClassName={styles.list}
          scheme={scheme}
          currentStepState={policy.schemeCriteriaResults}
          stepStateBeforeChange={policyBeforeChange.schemeCriteriaResults}
          isBlockedForSolicitor={isBlockedForSolicitor}
          isCreate={isCreate}
        />
      );
    case 1:
      return (
        <Claimants
          onChange={onChange}
          titleClassName={styles.stepTitle}
          contentClassName={styles.stepContent}
          subtitleClassName={styles.subtitle}
          actionsClassName={styles.actions}
          currentStepState={policy.claimants}
          isBlockedForSolicitor={isBlockedForSolicitor}
        />
      );
    case 2:
      return (
        <CauseOfAction
          onChange={onChange}
          titleClassName={styles.stepTitle}
          contentClassName={styles.stepContent}
          subtitleClassName={styles.subtitle}
          scheme={scheme}
          policy={policy}
          currentCauseOfAction={policy.causeOfAction}
          currentPolicyPremium={policy.policyPremium}
          isBlockedForSolicitor={isBlockedForSolicitor}
          isCreate={isCreate}
        />
      );
    case 3:
      switch (scheme.type) {
        case 1:
          return (
            <SpecificCauseOfActionCwi
              onChange={onChange}
              titleClassName={styles.stepTitle}
              contentClassName={styles.stepContent}
              subtitleClassName={styles.subtitle}
              leadClaimant={policy.claimants.find((c) => c.isLead)}
              currentStepState={policy.typeSpecificCauseOfAction}
              stepStateBeforeChange={
                policyBeforeChange.typeSpecificCauseOfAction
              }
              isBlockedForSolicitor={isBlockedForSolicitor}
            />
          );
        case 2:
          return (
            <SpecificCauseOfActionMMC
              onChange={onChange}
              titleClassName={styles.stepTitle}
              contentClassName={styles.stepContent}
              subtitleClassName={styles.subtitle}
              currentStepState={policy.typeSpecificCauseOfAction}
              isBlockedForSolicitor={isBlockedForSolicitor}
            />
          );
        default:
          return 'Unknown scheme type';
      }
    case 4:
      return (
        <Preview
          scheme={scheme}
          policy={policy}
          titleClassName={styles.stepTitle}
          contentClassName={styles.stepContent}
        />
      );
    default:
      return 'Unknown step';
  }
};

export const PolicyWizard = ({
  classes,
  scheme,
  isCreate,
  onSave,
  isSaving,
  incomingPolicy = null,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepValid, setActiveStepValid] = React.useState(false);
  const [policy, setPolicy] = React.useState(
    incomingPolicy ? incomingPolicy : {}
  );
  const [policyBeforeChange, setPolicyBeforeChange] = React.useState(
    JSON.parse(JSON.stringify(incomingPolicy)) || {}
  );

  const steps = getSteps();
  const history = useHistory();

  const userGroups = useSelector(userGroupsSelector);
  const isBlockedForSolicitor =
    !isCreate && userGroups.includes(userRoles.solicitor);

  const onStepContentUpdate = (validationStatus, policyPart) => {
    setActiveStepValid(validationStatus);
    setPolicy({
      ...policy,
      ...policyPart,
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSave = () => {
    onSave(policy);
  };

  const handleCancel = () => {
    history.push('/');
  };

  return (
    <>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => {
          const getCircleIconClass = ({ active, completed }) =>
            active
              ? classes.circleActive
              : completed
              ? classes.circleCompleted
              : classes.circle;
          const stepProps = {};
          const labelProps = {
            StepIconComponent: (props) => (
              <span className={getCircleIconClass(props)}>{props.icon}</span>
            ),
          };
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} className={classes.label}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions} component="div">
            {getStepContent(
              activeStep,
              classes,
              scheme,
              policy,
              policyBeforeChange,
              onStepContentUpdate,
              isBlockedForSolicitor,
              isCreate
            )}

            {activeStep === 0 && (
              <span className={classes.disclaimer}>
                By clicking on NEXT you are confirming the policy has been
                explained to the Claimant(s) and that it is being purchased by
                you on the Claimant(s) behalf in accordance with its terms and
                conditions.
              </span>
            )}
            {isCreate && activeStep === steps.length - 1 && (
              <span className={classes.disclaimer}>
                Before clicking “Confirm”, make sure all the information is
                correct. The policy will be created and after confirmation some
                fields will no longer be editable.
              </span>
            )}
          </Typography>
          <div className={classes.navigation}>
            <Button
              onClick={handleCancel}
              className={classes.button}
              color="primary"
            >
              <CancelOutlinedIcon /> Cancel
            </Button>
            <div>
              {activeStep !== 0 && (
                <Button
                  disabled={isSaving}
                  variant="outlined"
                  color="primary"
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={!activeStepValid || isSaving}
                variant="contained"
                color="primary"
                onClick={
                  activeStep === steps.length - 1 ? handleSave : handleNext
                }
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
