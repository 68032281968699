import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

export default function FailureToAcceptSolicitorAdvice({ movement, onChange, setCustomIsValid, showAllValidationErrors }) {

    const [dirtyFields, setDirtyFieldsState] = useState([]);

    useEffect(() => {
        setCustomIsValid(false);
      }, []);
    
    useEffect(() => {
        setCustomIsValid(isFormValid());
    });

    const setDirtyFields = (fieldName) =>
        setDirtyFieldsState([...dirtyFields, fieldName]);

    const validation = {
        commentIsValid: () => !!movement?.comment,
    };

    const isFormValid = () => {
        return validation.commentIsValid();
    }

  return (
    <>
      <TextField
        name="comment"
        label="Comment"
        onChange={onChange}
        variant="outlined"
        multiline={true}
        required={true}
        rows={3}
        onBlur={(event) => setDirtyFields(event.target.name)}
        error={
            (showAllValidationErrors || dirtyFields.includes('comment')) && !validation.commentIsValid()
          }
      />
    </>
  );
}
