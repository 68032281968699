import axios from 'axios';
import config from '../config';
import { saveAs } from 'file-saver';

function filesDownloader(meta, callback) {
  return axios({
    method: meta.method,
    url: config.apiBaseUrl + meta.requestURL,
    headers: {
      Authorization: meta.accessToken
        ? `Bearer ${meta.accessToken}`
        : undefined,
    },
    responseType: 'blob',
  })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, meta.filename);
    })
    .finally(() => {
      callback();
    });
}

export default filesDownloader;
