import React, { useCallback, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { uniqueId as getUniqueId } from '../../../support/helpers';
import { schemeType } from '../../../support/constants';
import { Section } from '../../../components';
import WizardStepSection from '../WizardStepSection';
import WizardStep from '../WizardStep';

import Criterion from './Criterion';

export default function SchemeCriteria({ stepNumber }) {
  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const schemeTypeInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.type,
    isEqual
  );
  const criteriaInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.criteria,
    isEqual
  );
  const [criteria, setCriteria] = useState(() => {
    return JSON.parse(
      JSON.stringify(
        !criteriaInit || criteriaInit.length === 0
          ? [{ answerType: null, uniqueId: getUniqueId() }]
          : criteriaInit
      )
    );
  });

  const onCriterionChange = useCallback((criterion, index) => {
    setCriteria((prevCriteria) => {
      let criteriaCopy = [...prevCriteria];
      criteriaCopy[index] = criterion;

      return [...criteriaCopy];
    }, []);
  }, []);

  const deleteCriterion = useCallback((index) => {
    setCriteria((prevCriteria) => {
      let criteriaCopy = [...prevCriteria];
      criteriaCopy.splice(index, 1);
      return [...criteriaCopy];
    }, []);
  }, []);

  const addEmptyCriterion = useCallback(() => {
    setCriteria((prevCriteria) => {
      return [...prevCriteria, { answerType: null, uniqueId: getUniqueId() }];
    }, []);
  }, []);

  const addDirtyField = useCallback(
    (event) => {
      validator.addDirtyField(event.target.name);
      forceUpdate();
    },
    [validator]
  );

  return (
    <WizardStep sectionsCount={1} stepNumber={stepNumber}>
      <WizardStepSection
        dataArrayToTriggerUpdate={[criteria]}
        dataToChange={{ criteria: criteria }}
        sectionKey={0}
        validator={validator}
        setValidator={setValidator}
      >
        <Section
          title={schemeType[schemeTypeInit]?.description}
          mainSection={true}
        >
          {criteria.map((criterion, index) => (
            <Criterion
              key={criterion.id ?? criterion.uniqueId}
              criterion={criterion}
              criteriaCount={criteria.length}
              index={index}
              validator={validator}
              onChange={onCriterionChange}
              deleteCriterion={deleteCriterion}
              addEmptyCriterion={addEmptyCriterion}
              addDirtyField={addDirtyField}
            />
          ))}
        </Section>
      </WizardStepSection>
    </WizardStep>
  );
}
