import React from 'react';

import WizardStep from '../WizardStep';

import EligibleSolicitors from './EligibleSolicitors';
import Qddl from './Qddl';
import ReferralLimits from './ReferralLimits';

export default function SchemeDetails({ stepNumber }) {
  const sections = {
    EligibleSolicitors: 0,
    Qddl: 1,
    ReferralLimits: 2,
  };

  return (
    <WizardStep
      sectionsCount={Object.keys(sections).length}
      stepNumber={stepNumber}
    >
      <EligibleSolicitors sectionKey={sections.EligibleSolicitors} />
      <Qddl sectionKey={sections.Qddl} />
      <ReferralLimits sectionKey={sections.ReferralLimits} />
    </WizardStep>
  );
}
