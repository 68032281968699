import React, { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from '@material-ui/core';
import { every, isEqual } from 'lodash';

import WizardStepSection from '../WizardStepSection';
import { Section } from '../../../components';
import QddlTableTemplate from '../../../components/QddlTable/QddlTableTemplate';
import { uniqueId as getUniqueId } from '../../../support/helpers';
import IconAdd from '../../../assets/plus.svg';

import { useStyles } from '../styles';

import QddlRowEdit from './QddlRowEdit';

export default function Qddl({ sectionKey }) {
  const classes = useStyles();

  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const generateEmptyQddlRow = () => ({
    uniqueId: getUniqueId(),
    preProceedingsPremium: {},
    proceedingsPremium: {},
    trialPremium: {},
  });

  const qddlInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.qddlRows,
    isEqual
  );

  const [qddl, setQddl] = useState(
    JSON.parse(
      JSON.stringify(
        !qddlInit || qddlInit.length === 0 ? [generateEmptyQddlRow()] : qddlInit
      )
    )
  );

  const onQddlRowChange = (qddlRow, index) => {
    let qddlCopy = [...qddl];
    qddlCopy[index] = qddlRow;

    setQddl(qddlCopy);
  };

  const deleteQddlRow = (index) => {
    let qddlCopy = [...qddl];
    if (index !== -1) {
      qddlCopy.splice(index, 1);
      setQddl(qddlCopy);
    }
  };

  const addQddlRow = () => {
    let qddlRowToAdd = generateEmptyQddlRow();
    if (qddl.length != 0) {
      qddlRowToAdd.quantumBandFrom = qddl[qddl.length - 1].quantumBandTo;
    }

    setQddl([...qddl, qddlRowToAdd]);
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  const isQddlRowValid = (qddlRow) =>
     +qddlRow.quantumBandFrom < +qddlRow.quantumBandTo;

  const checkIfLiveIsValid = () => {
    return (
      validator?.isFormValid() &&
      every(qddl, (qddlRow) => isQddlRowValid(qddlRow))
    );
  };

  const isLastQddlValid =
    qddl.length != 0 ? isQddlRowValid(qddl[qddl.length - 1]) : true;

  const getTooltipText = () => {
    if (qddl.length >= 5) {
      return 'Limit for number of QDDL rows has been reached. No more QDDL rows can be added.';
    }

    if (!isLastQddlValid) {
      return "'Quantum Band To' must be valid to add a new QDDL row.";
    }

    return '';
  };

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[qddl]}
      dataToChange={{ qddlRows: qddl }}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
      checkIfLiveIsValid={checkIfLiveIsValid}
    >
      <Section title="QDDL - Incremental" mainSection={true}>
        <QddlTableTemplate isEdit={true}>
          {qddl?.map((row, index) => (
            <QddlRowEdit
              key={row.id || row.uniqueId}
              row={row}
              onRowChange={onQddlRowChange}
              onRowDelete={deleteQddlRow}
              index={index}
              isFirst={index === 0}
              isLast={index === qddl.length - 1}
              isTheOnly={qddl.length === 1}
              validator={validator}
              addDirtyField={addDirtyField}
            />
          ))}
        </QddlTableTemplate>
        <div>
          <div className={classes.actionButton}>
            <Tooltip title={getTooltipText()}>
              <span>
                <Button
                  color="primary"
                  onClick={addQddlRow}
                  disabled={!isLastQddlValid || qddl.length >= 5}
                >
                  <img src={IconAdd} alt="add" />
                  Add Row
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </Section>
    </WizardStepSection>
  );
}
