import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  menu: {
    minWidth: 220,
    border: `1px solid ${colors.greyE6}`,
    boxShadow:
      '2px 4px 8px rgba(0, 0, 0, 0.05), 0px 4px 15px rgba(43, 64, 78, 0.05) !important',
  },
  menuList: {
    padding: 0,
  },
  listItem: {
    '& + &': {
      borderTop: `1px solid ${colors.greyE6}`,
    },
  },
  listItemText: {
    color: colors.green,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));
