import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { Breadcrumbs } from '../../components';
import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import SchemeWizard from '../../components/SchemeWizard/SchemeWizard';
import {
  fetchSchemeInfo,
  cleanReducer,
} from '../../store/reducers/scheme/save';
import schemesIcon from '../../assets/images/schemes.svg';

import { useStyles } from './styles';

export default function AmendScheme() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const schemeCode = useSelector((state) => state.scheme.create.schemeCode);
  const isLoading = useSelector((state) => state.scheme.create.isLoading);
  const savedSchemeId = useSelector(
    (state) => state.scheme.create.savedSchemeId
  );
  const shouldRedirect = useSelector(
    (state) => state.scheme.create.shouldRedirect
  );

  const { schemeId } = useParams();

  useEffect(() => {
    dispatch(fetchSchemeInfo(schemeId));
  }, [dispatch, schemeId]);

  useEffect(() => {
    if (shouldRedirect) {
      history.push({
        pathname: `/schemes/${savedSchemeId}`,
        state: { updated: true },
      });
    }
  }, [shouldRedirect, savedSchemeId]);

  useEffect(() => {
    return () => {
      dispatch(cleanReducer());
    };
  }, []);

  return (
    <LoadingWrapper loading={isLoading}>
      {!isLoading && (
        <>
          <Breadcrumbs current="Amend Scheme">
            <Link to={'/'}>
              <img src={schemesIcon} alt="Schemes" />
            </Link>
            <Link to={`/schemes/${schemeId}`}>{`Scheme ${schemeCode}`}</Link>
          </Breadcrumbs>
          <Typography variant="h3" className={classes.header}>
            Amend Scheme {`${schemeCode}`}
          </Typography>
          <SchemeWizard />
        </>
      )}
    </LoadingWrapper>
  );
}
