import React, { Fragment } from 'react';
import _startCase from 'lodash/startCase';

import { Grid, Typography } from '@material-ui/core';

import { ListItem } from '../../components';
import IconPhone from '../../assets/phone.svg';

import { useStyles } from './policyDetailsTabsStyles';

export default function PolicyDetailsTabItemClaimants(props) {
  const classes = useStyles();
  const { policy, restrictedForInsurer } = props;

  return (
    <>
      {policy.claimants
        .slice()
        .sort((a, b) => b.isLead - a.isLead)
        .map((claimant, index) => {
          return (
            <Fragment key={index}>
              <Typography className={classes.panelSectionHeading} variant="h5">
                {claimant.isLead ? 'Lead Claimant' : `Claimant ${index + 1}`}
              </Typography>

              <Grid container>
                <Grid item lg={4}>
                  <ListItem
                    primary="Claimant Title"
                    secondary={restrictedForInsurer(_startCase(claimant.title))}
                  />
                </Grid>

                <Grid item lg={4}>
                  <ListItem
                    primary="Forename and Surname"
                    secondary={restrictedForInsurer(
                      `${claimant.forename} ${claimant.surname}`
                    )}
                  />
                </Grid>

                <Grid item lg={4}>
                  <ListItem
                    primary="Age"
                    secondary={restrictedForInsurer(claimant.age)}
                  />
                </Grid>

                <Grid item lg={4}>
                  <ListItem
                    primary="Contact Number"
                    secondary={
                      <>
                        <img
                          src={IconPhone}
                          alt="phone"
                          className={classes.icon}
                        />
                        {restrictedForInsurer(claimant.telephone)}
                      </>
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <ListItem primary="Postcode" secondary={claimant.postcode} />
                </Grid>

                <Grid item lg={4}>
                  <ListItem
                    primary="House Number and Street"
                    secondary={restrictedForInsurer(claimant.address)}
                  />
                </Grid>

                <Grid item lg={4}>
                  <ListItem primary="Town/City" secondary={claimant.town} />
                </Grid>

                <Grid item lg={4}>
                  <ListItem primary="County" secondary={claimant.county} />
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
    </>
  );
}
