import { makeStyles } from '@material-ui/core';

const spinner = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

export const useStyles = makeStyles({
  spinner: { ...spinner },

  fullScreenSpinner: {
    ...spinner,
    '& .MuiCircularProgress-root': {
      height: '90px !important',
      width: '90px !important',
    },
  },

  fullScreenBackground: {
    position: 'fixed',
    width: '100%',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    backgroundColor: 'black',
    opacity: '0.4',
    zIndex: '9999',
  },
});
