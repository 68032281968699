import React from 'react';

import Modal from '../Modal/Modal';
import IconApprove from '../../assets/icon_approve.svg';

import { useStyles } from './styles';

export const ConfirmationModal = ({ modalOpen, handleClose, isCreate }) => {
  const classes = useStyles();

  return (
    <Modal
      titleText={`Policy ${isCreate ? 'Creation' : 'Update'} Confirmed`}
      open={modalOpen}
      onClose={handleClose}
    >
      <div className={classes.modalContainer}>
        <div>
          <img src={IconApprove} alt="approve" />
        </div>
        <div className={classes.modalText}>
          {`The policy has now been ${
            isCreate ? 'incepted' : 'updated'
          }. You may print or save it to file from the
          Schedule tab on the policy page.`}
        </div>
      </div>
    </Modal>
  );
};
