import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.greyE6}`,
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
    ['@media print']: {
      display: 'none',
    },
  },
  accountName: {
    color: colors.text,
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '8px',
    marginRight: '16px',
    textTransform: 'none',
  },
  userMenu: {
    width: '80%',
  },
  avatar: {
    width: '34px',
  },
}));
