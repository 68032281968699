import React, {useEffect, useState} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Modal, SelectWithOptions } from '../../components';
import { useStyles } from './styles';
import { cancellationTypes, cancellationTypesOptions, movementTypes } from '../../support/constants';
import Checkbox from '@material-ui/core/Checkbox';

export default function ApproveEventNotificationWithEffect({
  movements,
  openModal,
  setOpenModal,
  setApprovalData,
  onConfirm
}) {
  const classes = useStyles();
  const [cancelPolicyChecked, setCancelPolicyChecked] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [currentApprovalData, setCurrentApprovalData] = useState({
    cancellation: null,
  });

  useEffect(() => {
    if (!cancelPolicyChecked) {
      setCurrentApprovalData({
        ...currentApprovalData,
        cancellation: null
      });
    }
  }, [cancelPolicyChecked]);

  const updateCancellationData = (newCancellationType) => {
    setCurrentApprovalData({
      ...currentApprovalData,
      cancellation: newCancellationType,
    });
  }

  useEffect(() => {
    setApprovalData(currentApprovalData);
  }, [currentApprovalData])

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleConfirmButton = () => {
    if (validation.cancellationTypeIsValid()) {
      onConfirm();
    } else {
      setShowErrors(true);
    }
  };

  const validation = {
    cancellationTypeIsValid: () => !cancelPolicyChecked || (cancelPolicyChecked && !!currentApprovalData.cancellation),
  };

  var cancellationOptions = Object.keys(cancellationTypesOptions).reduce((res, key) => ((res[key] = cancellationTypesOptions[key]), res), {});
  delete cancellationOptions[cancellationTypes.CA14.value];

  if (movements.filter((movement) => movement.type === movementTypes.Claim.value).length) {
    delete cancellationOptions[cancellationTypes.CARef.value];
  }

  return (
    <>
    <Modal
      onClose={handleClose}
      onConfirm={handleConfirmButton}
      open={openModal}
      titleText="Approval condition"
      showActionButtons={true}
      isReadyToConfirm={true}
      PaperProps={{ className: classes.modal }}
    >
      <div>
        <Typography variant="subtitle2" className={`${classes.normalText} reset-mb`}>
          Approve an event and
        </Typography>
        <Checkbox 
          color="primary" 
          checked={cancelPolicyChecked} 
          onChange={(event) => { setCancelPolicyChecked(event.target.checked) }}
          name="cancelPolicyChecked" />
        cancel policy
        
        <Grid container>
          {cancelPolicyChecked && <Grid item xs={12}>
            <SelectWithOptions
              name="cancellationType"
              label="Cancellation type"
              value={currentApprovalData.cancellation}
              className={classes.formControl}
              onChange={(event) => updateCancellationData(event.target.value)}
              options={cancellationOptions}
              error={showErrors && !validation.cancellationTypeIsValid()}
            />
          </Grid>}
        </Grid>
      </div>
    </Modal>
    </>
  );
}
