import React, { useEffect } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

import { AttachmentsEdit, CurrencyTextField, DatePickerWithClear, SelectWithOptions } from '../../components';
import { typesOfPart36OfferOptions, typesOfPart36Offer } from '../../support/constants'; 

export default function TheirPart36Movement({ movement, onChange, setCustomIsValid }) {

  const onDateChange = (date) => {
    onChange({
        target: {
          name: "dateOfOffer",
          value: date ? date.toISOString() : date
        }
      });

      setCustomIsValid(!!date);
  };

  useEffect(() => {
    onChange({
      target: {
        name: "claimantPercentage",
        value: 100
      }
    });

    setCustomIsValid(false);
  }, []);

  const addAttachment = (attachment) => {
    const attachments = [...(movement.attachments ?? []), attachment];
    updateAttachments(attachments);
  };

  const deleteAttachment = (attachmentIndex) => {
    let attachmentsCopy = [...movement.attachments];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      updateAttachments(attachmentsCopy);
    }
  };

  const updateAttachments = (attachments) =>{
    onChange({
      target: {
        name: "attachments",
        value: attachments
      }
    });
  }

  const isAmountRequired = () => (movement.typeOfOffer == typesOfPart36Offer.quantum.value || movement.typeOfOffer == typesOfPart36Offer.liabilityAndQuantum.value);

  const isClaimantPercentageRequired = () => (movement.typeOfOffer == typesOfPart36Offer.liability.value || movement.typeOfOffer == typesOfPart36Offer.liabilityAndQuantum.value);

  return (
    <>
      <DatePickerWithClear
        name="dateOfOffer"
        label="Date of offer"
        required={true}
        value={movement.dateOfOffer || null}
        onChange={onDateChange}
      />
      <SelectWithOptions
        name="typeOfOffer"
        label="Type of offer"
        value={movement.typeOfOffer}
        onChange={onChange}
        options={typesOfPart36OfferOptions}
        required={true}
      />
      {isAmountRequired() && 
        <CurrencyTextField
          name="amount"
          label="Quantum Amount Offered (compared to initial survey)"
          onChange={onChange}
          helperText="Must be equal to or greater than 0"
          allowNegative={false}
          allowZero={true}
          required={isAmountRequired()}
        />
      }
      {isClaimantPercentageRequired() && 
        <TextField
          name="claimantPercentage"
          label="Split Liability Claimant Share"
          value={movement.claimantPercentage}
          onChange={onChange}
          type="number"
          helperText="Must be between 0 and 100"
          variant="outlined"
          required={isClaimantPercentageRequired()}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">%</InputAdornment>
            ),
            inputProps: { min: 0, max: 100 },
          }}
        />
      }
      <CurrencyTextField
        name="value"
        label="Total value of offer"
        onChange={onChange}
        helperText="Must be greater than 0"
        required={true}
        allowNegative={false}
        allowZero={false}
      />
      <TextField
        name="comment"
        label="Comment including solicitor recommendation"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
      />
      <AttachmentsEdit
        title="Attachments (optional)"
        addAttachment={addAttachment}
        deleteAttachment={deleteAttachment}
        attachments={movement?.attachments}
        limitListItemsWidth={false}
      />
    </>
  );
}
