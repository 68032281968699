import React, { useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, TextField } from '@material-ui/core';
import { isEqual } from 'lodash';

import { Section } from '../../../components';
import WizardStepSection from '../WizardStepSection';
import WizardStep from '../WizardStep';
import { useStyles } from '../styles';

export default function Notes({ stepNumber }) {
  const classes = useStyles();

  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const schemeInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep,
    isEqual
  );
  const [scheme, setScheme] = useState(() =>
    JSON.parse(JSON.stringify(schemeInit))
  );
  const [propertyToChange, setPropertyToChange] = useState(null);

  const onEventChange = (event) => {
    const propertyToChange = { [event.target.name]: event.target.value };
    setScheme({
      ...scheme,
      ...propertyToChange,
    });

    setPropertyToChange(propertyToChange);
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  return (
    <WizardStep sectionsCount={1} stepNumber={stepNumber}>
      <WizardStepSection
        dataArrayToTriggerUpdate={[propertyToChange]}
        dataToChange={propertyToChange}
        sectionKey={0}
        validator={validator}
        setValidator={setValidator}
      >
        <Section title="Notes" mainSection={true}>
          <FormGroup>
            <TextField
              name="notes"
              label="Note Text"
              value={scheme.notes}
              onChange={onEventChange}
              variant="outlined"
              required={true}
              multiline={true}
              InputProps={{
                className: classes.textarea,
              }}
              error={validator?.hasErrorToDisplay('notes')}
              onBlur={addDirtyField}
            />
          </FormGroup>
        </Section>
      </WizardStepSection>
    </WizardStep>
  );
}
