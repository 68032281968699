import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';

import { includesAny, isSameOrAfter } from '../../support/helpers';
import { userRoles } from '../../support/constants';
import { userGroupsSelector } from '../../store/selectors/global';

import { useStyles } from './policyDetailsTabsStyles';
import PolicyDetailsTabItem from './PolicyDetailsTabItem';
import PolicyScheduleDetailsTabItemCwiV1 from './PolicyScheduleDetailsTabItemCwiV1';
import PolicyScheduleDetailsTabItemCwiV2 from './PolicyScheduleDetailsTabItemCwiV2';
import PolicyScheduleDetailsTabItemMmc from './PolicyScheduleDetailsTabItemMmc';
import PolicyMovementsTabItem from './PolicyMovementsTabItem';
import PolicyClaimsTabItem from './PolicyClaimsTabItem';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`policy-tabpanel-${index}`}
      aria-labelledby={`policy-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default function PolicyDetailsTabs({ policy, restrictedForInsurer }) {
  const classes = useStyles();
  const userGroups = useSelector(userGroupsSelector);
  const dayOfIntroductionOfNewCwiScheduleVersion = '2022-07-01';

  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isClaimsTabVisible = includesAny(userGroups, [
    userRoles.resolve,
    userRoles.auditor,
    userRoles.insurer,
  ]);

  const getSchedule = () => {
    switch (policy.scheme.type) {
      case 1:
        if (isSameOrAfter(policy.scheme.periodFrom, dayOfIntroductionOfNewCwiScheduleVersion)){
          return (
            <PolicyScheduleDetailsTabItemCwiV2
              policy={policy}
              classes={classes}
              restrictedForInsurer={restrictedForInsurer}
            />
          );
        } else {
          return (
            <PolicyScheduleDetailsTabItemCwiV1
              policy={policy}
              classes={classes}
              restrictedForInsurer={restrictedForInsurer}
            />
          );
        }
      case 2:
        return (
          <PolicyScheduleDetailsTabItemMmc
            policy={policy}
            classes={classes}
            restrictedForInsurer={restrictedForInsurer}
          />
        );
      default:
        return 'Unknown scheme type';
    }
  };

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab tabIndex={0} label="Policy Details" />
        <Tab tabIndex={1} label="Schedule Details" />
        <Tab tabIndex={2} label="Movements" />
        {isClaimsTabVisible && <Tab tabIndex={3} label="Claims" />}
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <PolicyDetailsTabItem
          policy={policy}
          classes={classes}
          restrictedForInsurer={restrictedForInsurer}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        {getSchedule()}
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <PolicyMovementsTabItem policy={policy} classes={classes} />
      </TabPanel>

      {isClaimsTabVisible && (
        <TabPanel value={currentTab} index={3}>
          <PolicyClaimsTabItem policy={policy} classes={classes} />
        </TabPanel>
      )}
    </div>
  );
}
