import { createAction, createSlice } from '@reduxjs/toolkit';
import { remove, some, sortBy } from 'lodash';

import { userGroupTypes } from '../../../support/constants';

const initialState = {
  isSaving: false,
  wasSaved: false,
  isReadyToDisplay: false,

  availableGroups: [],
  isLoadingForAvailableGroups: null,
  hasErrorForAvailableGroups: false,

  schemeGroups: [],
  isLoadingForSchemeGroups: null,
  hasErrorForSchemeGroups: false,

  hasAnyUnavailableGroups: false,
};

export const groupsForSchemeSlice = createSlice({
  name: 'groupsForScheme',
  initialState: { ...initialState },
  reducers: {
    startSaving: (state) => {
      state.isSaving = true;
    },

    savingSuccess: (state) => {
      state.isSaving = false;
      state.wasSaved = true;
    },

    savingFailure: (state) => {
      state.isSaving = false;
      state.wasSaved = false;
    },

    startLoadingForAvailableGroups: (state) => {
      state.isLoadingForAvailableGroups = true;
    },
    hasErrorForAvailableGroups: (state) => {
      state.hasErrorForAvailableGroups = true;
      state.isLoadingForAvailableGroups = false;
    },
    availableGroups: (state, action) => {
      state.availableGroups = sort(action.response.data);
      state.isLoadingForAvailableGroups = false;
      markSchemeGroups(state);
    },

    startLoadingForSchemeGroups: (state) => {
      state.isLoadingForSchemeGroups = true;
    },
    hasErrorForSchemeGroups: (state) => {
      state.hasErrorForSchemeGroups = true;
      state.isLoadingForSchemeGroups = false;
    },
    schemeGroups: (state, action) => {
      state.schemeGroups = sort(action.response.data);
      state.isLoadingForSchemeGroups = false;
      markSchemeGroups(state);
    },

    addGroupToScheme: (state, action) => {
      let groupToAdd = action.payload;
      state.schemeGroups.push({
        id: groupToAdd.id,
        name: groupToAdd.name,
        surname: groupToAdd.surname,
        isAvailable: true,
      });
      state.schemeGroups = sort(state.schemeGroups);

      let groupFromAvailable = state.availableGroups.find(
        (u) => u.id === groupToAdd.id
      );
      groupFromAvailable.isAssignedToScheme = true;
    },

    removeGroupFromSchema: (state, action) => {
      let groupToRemove = action.payload;
      remove(state.schemeGroups, (g) => g.id === groupToRemove.id);

      state.hasAnyUnavailableGroups = some(state.schemeGroups, [
        'isAvailable',
        false,
      ]);

      let groupFromAvailable = state.availableGroups.find(
        (g) => g.id === groupToRemove.id
      );
      if (groupFromAvailable) {
        groupFromAvailable.isAssignedToScheme = false;
      }
    },

    cleanReducer: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

function markSchemeGroups(state) {
  if (
    state.isLoadingForAvailableGroups ||
    state.isLoadingForSchemeGroups ||
    state.hasErrorForAvailableGroups ||
    state.hasErrorForSchemeGroups
  ) {
    return;
  }

  state.schemeGroups.forEach((schemeGroup) => {
    let foundAvailableGroup = state.availableGroups.find(
      (u) => u.id === schemeGroup.id
    );
    if (foundAvailableGroup) {
      foundAvailableGroup.isAssignedToScheme = true;
      schemeGroup.isAvailable = true;
    } else {
      schemeGroup.isAvailable = false;
      state.hasAnyUnavailableGroups = true;
    }
  });

  state.isReadyToDisplay = true;
}

function sort(array) {
  return sortBy(array, ['surname', 'name']);
}

export const {
  startSaving,
  savingSuccess,
  savingFailure,
} = groupsForSchemeSlice.actions;
export const {
  startLoadingForAvailableGroups,
  hasErrorForAvailableGroups,
  availableGroups,
} = groupsForSchemeSlice.actions;
export const {
  startLoadingForSchemeGroups,
  hasErrorForSchemeGroups,
  schemeGroups,
  hasAnyUnavailableGroups,
} = groupsForSchemeSlice.actions;
export const {
  addGroupToScheme,
  removeGroupFromSchema,
} = groupsForSchemeSlice.actions;
export const { cleanReducer } = groupsForSchemeSlice.actions;

export const fetchAvailableGroups = createAction(
  'fetchAvailableGroups',
  () => ({
    meta: {
      callAPITypes: [
        startLoadingForAvailableGroups,
        availableGroups,
        hasErrorForAvailableGroups,
      ],
      requestURL: `/users/groups?groupTypes=${userGroupTypes.solicitor}&groupTypes=${userGroupTypes.insurer}`,
      method: 'GET',
    },
    type: 'fetchAvailableGroups',
    payload: null,
  })
);

export const fetchSchemeGroups = createAction(
  'fetchSchemeGroups',
  (schemeId) => ({
    meta: {
      callAPITypes: [
        startLoadingForSchemeGroups,
        schemeGroups,
        hasErrorForSchemeGroups,
      ],
      requestURL: `/schemes/${schemeId}/allowedGroups`,
      method: 'GET',
    },
    type: 'fetchSchemeGroups',
    payload: null,
  })
);

let saveGroupsForSchemaAction = createAction(
  'saveGroupsForSchema',
  (schemeId, groupsIds) => ({
    meta: {
      callAPITypes: [startSaving, savingSuccess, savingFailure],
      requestURL: `/schemes/${schemeId}/AllowedGroups`,
      method: 'POST',
      requestConfig: {
        data: {
          groups: groupsIds,
        },
      },
    },
    type: 'saveGroupsForSchema',
  })
);

export function saveGroupsForSchema(schemeId) {
  return (dispatch, getState) => {
    let { schemeGroups } = getState().scheme.groupsForScheme;
    let groupsIdsToSave = schemeGroups
      .filter((u) => u.isAvailable)
      .map((u) => u.id);
    dispatch(saveGroupsForSchemaAction(schemeId, groupsIdsToSave));
  };
}

export default groupsForSchemeSlice.reducer;
