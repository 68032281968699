import { createAction, createSlice } from '@reduxjs/toolkit';

import { cancelledRequestErrorMessage } from '../../../support/constants';

export const policiesSlice = createSlice({
  name: 'policies',
  initialState: {
    policiesToDisplay: [],
    pageIndex: 0,
    totalCount: 0,
    isLoading: null,
  },
  reducers: {
    startLoading: (state, action) => {
      if (state.requestSource) {
        state.requestSource.cancel(cancelledRequestErrorMessage);
      }

      state.isLoading = true;
      state.requestSource = action.requestSource;
    },
    hasError: (state) => {
      state.isLoading = false;
    },
    policiesSuccess: (state, action) => {
      const data = action.response.data;
      state.policiesToDisplay = data.items;
      state.pageIndex = data.pageIndex;
      state.totalCount = data.totalItems;
      state.isLoading = false;
    },
  },
});

export const {
  startLoading,
  hasError,
  policiesSuccess,
} = policiesSlice.actions;

export const fetchPolicies = createAction(
  'fetchPolicies',
  (pageIndex, pageSize, filters) => ({
    meta: {
      callAPITypes: [startLoading, policiesSuccess, hasError],
      requestURL: '/policies',
      method: 'POST',
      showErrorNotification: false,
      shouldShowLoader: false,
      requestConfig: {
        data: {
          pageIndex: pageIndex,
          pageSize: pageSize,
          filters: filters,
        },
      },
    },
    type: 'fetchPolicies',
    payload: null,
  })
);

export default policiesSlice.reducer;
