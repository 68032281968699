import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Paper,
  Typography,
} from '@material-ui/core';

import { Error, Warning } from '../../components';
import { schemeCriteriaAnswers, schemeType } from '../../support/constants';

export const SchemeCriteria = ({
  onChange,
  titleClassName,
  contentClassName,
  listElementClassName,
  subtitleClassName,
  listClassName,
  scheme,
  currentStepState,
  stepStateBeforeChange,
  isCreate,
  isBlockedForSolicitor,
}) => {
  const [questions, setQuestionsState] = React.useState(
    currentStepState ?? scheme.criteria
  );

  React.useEffect(() => {
    setQuestions(markDisabledCriteria(sort(questions)));
  }, []);

  React.useEffect(() => {
    const allQuestionsAnsweredCorrectly = questions.reduce(
      (accumulator, current) =>
        accumulator &&
        current.answer &&
        (!isCreate || !hasCriteriaWithNoAnswer(current)),
      true
    );

    if (allQuestionsAnsweredCorrectly) {
      onChange(true, { schemeCriteriaResults: questions });
    } else {
      onChange(false, { schemeCriteriaResults: questions });
    }
  }, [questions]);

  const sort = (criteria) => criteria.slice().sort((a, b) => a.order - b.order);

  const markDisabledCriteria = (currentCriteria) => {
    if (isBlockedForSolicitor) {
      currentCriteria.forEach((criteriaItem) => {
        const criteriaItemBeforeChange = stepStateBeforeChange.find(
          (x) => x.schemeCriterionId === criteriaItem.schemeCriterionId
        );

        if (
          criteriaItemBeforeChange?.answer &&
          criteriaItemBeforeChange?.answer !== schemeCriteriaAnswers.pending
        ) {
          criteriaItem.isDisabled = true;
        }
      });
    }

    return currentCriteria;
  };

  const setQuestions = (questionsToSet) => {
    setQuestionsState(sort(questionsToSet));
  };

  const hasCriteriaWithNoAnswer = React.useCallback(
    (criteria) =>
      questions.some(
        (x) =>
          x.question === criteria.question &&
          x.answer &&
          x.answer === x.incorrectAnswer
      ),
    [questions]
  );
  const hasCriteriaWithPendingAnswer = React.useCallback(
    (criteria) =>
      questions.some(
        (x) =>
          x.question === criteria.question &&
          x.answer &&
          x.answer === x.inconclusiveAnswer
      ),
    [questions]
  );

  return (
    <>
      <Typography variant="h4" className={titleClassName}>
        Criteria from Scheme {`${scheme.codePrefix}${scheme.codeSuffix}`}
      </Typography>
      <Paper className={contentClassName}>
        <h4 className={subtitleClassName}>
          {schemeType[scheme.type].description}
        </h4>
        <ol className={listClassName}>
          {questions.map((criteria) => {
            return (
              <li key={criteria.order} className={listElementClassName}>
                <FormControl
                  component="fieldset"
                  error={hasCriteriaWithNoAnswer(criteria)}
                >
                  <FormLabel component="legend">{criteria.question}</FormLabel>
                  <RadioGroup value={criteria?.answer}>
                    {criteria.possibleAnswers?.map((possibleAnswer, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          onChange={(event) =>
                            setQuestions([
                              ...questions.filter(
                                (x) => x.question !== criteria.question
                              ),
                              {
                                ...criteria,
                                answer: event.target.value,
                              },
                            ])
                          }
                          disabled={criteria?.isDisabled}
                          value={possibleAnswer}
                          control={<Radio color="primary" />}
                          label={possibleAnswer}
                        />
                      );
                    })}
                  </RadioGroup>
                  {hasCriteriaWithNoAnswer(criteria) ? (
                    <Error>
                      {isCreate
                        ? `This Policy cannot be set up unless 
                      this information has been obtained and can be confirmed`
                        : `This case no longer meets the Policy criteria and you may not proceed. 
                      Please unwind the Policy to avoid unnecessary charges`}
                    </Error>
                  ) : null}
                  {hasCriteriaWithPendingAnswer(criteria) ? (
                    <Warning>
                      Any “Pending” information must be supplied before the
                      issue of proceedings or a claim will be allowed
                    </Warning>
                  ) : null}
                </FormControl>
              </li>
            );
          })}
        </ol>
      </Paper>
    </>
  );
};
