import { CLAIMS_GET_REQUEST, CLAIMS_GET_SUCCESS } from '../actionTypes';

const initialState = { claims: [], isLoading: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLAIMS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CLAIMS_GET_SUCCESS:
      return {
        ...state,
        claims: action.response.data,
        isLoading: false,
      };
    default:
      return state;
  }
}
