import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  footer: {
    flex: '0 0 100%',
    textAlign: 'left',
    paddingRight: '30px',
    color: colors.grey7F,
    position: 'absolute',
    bottom: 0,
    marginBottom: '5px',
  },
}));
