import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MaterialTable, { MTableActions } from 'material-table';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

import { LoadingWrapper } from '../../components/Loading/LoadingWrapper';
import gridStyles from '../../styles/gridStyles';
import IconMangeAccess from '../../assets/manage_access.svg';
import IconView from '../../assets/view.svg';
import IconEdit from '../../assets/edit.svg';

import { GridRowMenu, ManageSchemeAccess, StatusBadge } from '../../components';

import { userRoles, schemeStatus, schemeType } from '../../support/constants';

import {
  checkDateInRange,
  getFormattedDate,
  includesAny,
} from '../../support/helpers';

import { fetchSchemes } from '../../store/reducers/scheme/list';
import { cleanReducer as cleanUsersForSchemaReducer } from '../../store/reducers/scheme/groupsForScheme';
import { userGroupsSelector } from '../../store/selectors/global';

import { useStyles } from './styles';

export default function SchemeList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { schemes, isLoading } = useSelector((state) => state.scheme.list);
  const classes = useStyles();
  const [openAccessModal, setOpenAccessModal] = React.useState(false);
  const [openScheme, setOpenScheme] = React.useState(null);
  const userGroups = useSelector(userGroupsSelector);

  const theme = createMuiTheme({
    overrides: gridStyles,
  });

  useEffect(() => {
    dispatch(fetchSchemes());
  }, [dispatch]);

  const handleCloseAccessModal = () => {
    dispatch(cleanUsersForSchemaReducer());
    setOpenAccessModal(false);
  };

  const handleManageAccessClick = (rowData) => {
    setOpenScheme({
      id: rowData.id,
      name: `${rowData.codePrefix} ${rowData.codeSuffix}`,
    });
    setOpenAccessModal(true);
  };

  const handleCreateScheme = () => {
    history.push('/schemes/new-scheme');
  };

  const hasPolicyInceptionDate = (rowData) => {
    return checkDateInRange(rowData.periodFrom, rowData.periodTo);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            title="Schemes"
            columns={[
              {
                title: 'Code Prefix',
                field: 'codePrefix',
                width: 135,
                align: 'center',
              },
              {
                title: 'Code Suffix',
                field: 'codeSuffix',
                width: 135,
                align: 'center',
              },
              {
                title: 'Type',
                field: 'type',
                align: 'center',
                width: 135,
                customFilterAndSearch: (query, rowData) =>
                  schemeType[rowData.type]?.type
                    .toLowerCase()
                    .includes(query.toLowerCase()),
                render: (rowData) => schemeType[rowData.type].type,
              },
              {
                title: 'Introducer',
                field: 'introducerName',
              },
              {
                title: 'Fronting Insurer',
                field: 'frontingInsurerName',
              },
              {
                title: 'Period',
                customFilterAndSearch: (query, rowData) =>
                  getFormattedDate(rowData.periodFrom).includes(query) ||
                  getFormattedDate(rowData.periodTo).includes(query),
                render: (rowData) =>
                  getFormattedDate(rowData.periodFrom) +
                  '-' +
                  getFormattedDate(rowData.periodTo),
              },
              {
                title: 'Status',
                field: 'status',
                width: 50,
                customFilterAndSearch: (query, rowData) =>
                  schemeStatus[rowData.status]?.displayName
                    .toLowerCase()
                    .includes(query.toLowerCase()),
                render: (rowData) => {
                  return (
                    <StatusBadge
                      status={rowData.status}
                      statusType={schemeStatus}
                    />
                  );
                },
              },
              {
                title: 'Action',
                field: 'id',
                align: 'center',
                width: 120,
                searchable: false,
                render: (rowData) => (
                  <GridRowMenu
                    rowId={rowData.id}
                    menuItems={[
                      {
                        icon: <img src={IconView} alt="View" />,
                        text: 'View',
                        isVisible: includesAny(userGroups, [
                          userRoles.resolve,
                          userRoles.insurer,
                          userRoles.auditor,
                        ]),
                        handleClick: () =>
                          history.push({
                            pathname: `/schemes/${rowData.id}`,
                          }),
                      },
                      {
                        icon: <img src={IconEdit} alt="Edit" />,
                        text: 'Amend',
                        isVisible:
                          userGroups.includes(userRoles.resolve) &&
                          rowData.status === 1,
                        handleClick: () =>
                          history.push({
                            pathname: `/schemes/${rowData.id}/amend`,
                          }),
                      },
                      {
                        icon: <img src={IconMangeAccess} alt="Manage Access" />,
                        text: 'Manage Access',
                        isVisible: userGroups.includes(userRoles.resolve),
                        handleClick: () => handleManageAccessClick(rowData),
                      },
                      {
                        icon: (
                          <AddCircleOutlineIcon
                            className={classes.contextMenu}
                          />
                        ),
                        text: 'Create Policy',
                        // only create policy if scheme has status blocked
                        isVisible:
                          hasPolicyInceptionDate(rowData) &&
                          rowData.status === 2 &&
                          userGroups.includes(userRoles.solicitor),
                        handleClick: () =>
                          history.push(`/schemes/${rowData.id}/new-policy`),
                      },
                    ]}
                  />
                ),
              },
            ]}
            data={schemes.map((o) => ({ ...o }))}
            options={{
              sorting: false,
              search: true,
              draggable: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            components={{
              Actions: (props) => (
                <>
                  <MTableActions {...props} />
                  {userGroups.includes(userRoles.resolve) && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleCreateScheme}
                      startIcon={<AddIcon />}
                    >
                      Add Scheme
                    </Button>
                  )}
                </>
              ),
            }}
          />
        </MuiThemeProvider>
        {openAccessModal && (
          <ManageSchemeAccess
            open={openAccessModal}
            handleClose={handleCloseAccessModal}
            scheme={openScheme}
          />
        )}
      </>
    </LoadingWrapper>
  );
}
