import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

const actionItem = {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  '& img': {
    marginRight: 8,
  },
  '& button': {
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
};

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: colors.white,
    boxSizing: 'border-box',
    borderRadius: 4,
    '&:not(:last-of-type)': {
      marginBottom: 12,
    },
    '& &': {
      padding: '18px 22px',
      marginTop: 10,
      border: `1px solid ${colors.greyE6}`,
      boxShadow: 'none',
      '&:not(:last-of-type)': {
        marginBottom: 26,
      },
      '&.highlighted': {
        borderLeft: `4px solid ${colors.primary}`,
        '& h5': {
          marginBottom: 14,
          border: 'none',
          fontSize: 14,
          textTransform: 'uppercase',
        },
      },
    },
    '&.mainSection': {
      padding: '20px 34px 34px',
      border: `1px solid ${colors.greyE6}`,
      boxShadow:
        '0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)',
    },
    '&.inside': {
      padding: 0,
      border: 'none',
      '& h5': {
        marginBottom: 14,
        border: 'none',
        fontSize: 14,
        textTransform: 'uppercase',
      },
      '& + &': {
        borderTop: `1px solid ${colors.greyE6}`,
        paddingTop: '20px',
      },
    },
    '&.preview': {
      '& > div:first-of-type h5': {
        fontSize: 24,
        marginBottom: 0,
        border: 'none',
        '&::before': {
          content: "''",
          position: 'absolute',
          left: -34,
          width: 4,
          height: 36,
          backgroundColor: colors.grey7F,
        },
      },
      '&.withoutErrors': {
        '& > div:first-of-type h5': {
          '&::before': {
            backgroundColor: colors.primary,
          },
        },
      },
      '&.withErrors': {
        '& > div:first-of-type h5': {
          '&::before': {
            backgroundColor: 'red',
          },
        },
      },
      '& > .Mui-expanded': {
        '& > div:first-of-type h5': {
          marginBottom: 6,
          paddingBottom: 24,
        },
        '&::after': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          height: 1,
          /* minus margins to expand line to whole width */
          margin: '0px -34px 10px -34px',
          backgroundColor: colors.greyE6,
        },
      },
    },
    '&.highlighted': {
      borderLeft: `6px solid ${colors.primary}`,
      '& &': {
        borderLeft: `4px solid ${colors.primary}`,
      },
      '&.warning': {
        borderColor: colors.warning,
      },
      '&.error': {
        borderColor: colors.error,
      },
    },
  },
  title: {
    paddingBottom: 14,
    marginBottom: 22,
    borderBottom: `1px solid ${colors.greyE6}`,

    '&.warning': {
      color: colors.warning,
    },
    '&.error': {
      color: colors.error,
    },
  },
  accordion: {
    padding: '20px 34px',
    marginTop: '0 !important',
    '&::before': {
      display: 'none',
    },
    '& &': {
      '& h5': {
        paddingBottom: 14,
        marginBottom: 0,
        borderBottom: `1px solid ${colors.greyE6}`,
        fontSize: 18,
        textTransform: 'none',
      },
    },
  },
  accordionAction: {
    padding: 0,
    minHeight: '0 !important',
    '& > div': {
      margin: '0 !important',
    },
    '& h5': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 0,
      marginBottom: 0,
      fontSize: 18,
      fontWeight: 600,
      border: 'none',
      textTransform: 'none',
      '& > div': {
        display: 'flex',
      },
    },
    '& img': {
      marginRight: 8,
    },
    '& span': {
      color: colors.primary,
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    '& .Mui-expanded': {
      '& h5': {
        paddingBottom: 14,
        marginBottom: 22,
      },
    },
  },
  accordionDetails: {
    padding: 0,
    flexDirection: 'column',
  },
  addItem: {
    ...actionItem,
    justifyContent: 'flex-end',
  },
  deleteItem: {
    ...actionItem,
    justifyContent: 'flex-start',
  },

  sectionHeader: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& span': {
      color: colors.primary,
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
}));
