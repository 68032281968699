import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 25,
    '& > h3': {
      fontSize: '36px',
      fontFamily:
        'Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 500,
    },
  },
  button: {
    fontWeight: 700,
    fontSize: 14,
    '&.MuiButton-containedPrimary': {
      marginLeft: 24,
      padding: '6px 63px',
    },
    '&.MuiButton-outlinedPrimary': {
      marginLeft: 38,
      padding: '6px 63px',
      backgroundColor: colors.white,
    },
  },
  bottomToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  reportOptionCheckbox: {
    paddingTop: '6px',
  },
  subsectionTitle: {
    marginBottom: '7px',
  },
}));
