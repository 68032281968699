import React from 'react';
import _startCase from 'lodash/startCase';

import {
  Button,
  Paper,
  Typography,
} from '@material-ui/core';

import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

import Logo from '../../assets/images/logo.svg';
import { schemeType } from '../../support/constants';
import {
  getFormattedDate,
  getFormattedMoney,
  getFormattedAddress,
} from '../../support/helpers';

import { useStyles } from './policyDetailsTabsStyles';
import styles from './policyScheduleDetailsTabItemStyles.module.scss';

export default function PolicyScheduleDetailsTabItemCwiV1(props) {
  const { policy, restrictedForInsurer, version } = props;
  const classes = useStyles();

  const leadClaimaint = policy.policySchedule.claimants.find((claimant) => claimant.isLead);
  const leadClaimantName = restrictedForInsurer(`${_startCase(leadClaimaint.title)} ${leadClaimaint.forename} ${leadClaimaint.surname}`);

  const otherClaimaints = policy.policySchedule.claimants
    .filter((claimant) => !claimant.isLead)
    .map((claimant) => {
      return `${_startCase(claimant.title)} ${claimant.forename} ${claimant.surname}`
    })
    .join(', ');

  const propertyAddress = policy.policySchedule.propertyAddress;
  const differentPropertyAddress = policy.policySchedule.isPropertyAddressDifferentThanClaimantAddress
    ? restrictedForInsurer(getFormattedAddress(propertyAddress))
    : '-';

  const opponentAddress = policy.policySchedule.defendant1Address;

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <div className={classes.tabHeader}>
        <Typography variant="h4">
          Schedule Details
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handlePrint}
          className={classes.button}
          startIcon={<PrintOutlinedIcon />}
        >
          Print Schedule
        </Button>
      </div>

      <Paper className={`${classes.root} ${styles.scheduleContainer}`}>
        <div className={styles.header}>
          <img
            src={Logo}
            alt="logo"
            className={styles.logo}
          />

          <ul className={styles.address}>
            <li>Resolve Services Limited</li>
            <li>First Floor, 1 Station Road</li>
            <li>Edenbridge, Kent TN8 5HP</li>
            <li className={styles.companyNumber}>
              Company Number: 11795288
            </li>
          </ul>
        </div>

        <div className={styles.heading}>
          <h2>
            After the Event Legal Expenses Insurance Policy Schedule
          </h2>
          <h3 className={styles.policyNumber}>
            Policy Number: {policy.policySchedule.policyNumber}
          </h3>
        </div>

        <ol>
          <li>
            <strong>Insured Lead Claimant Name:</strong>
            {leadClaimantName}

            <ol>
              <li>
                <strong>Address:</strong>
                {restrictedForInsurer([leadClaimaint.address, leadClaimaint.town, leadClaimaint.county, leadClaimaint.postcode].filter(a => !!a).join(', '))}
              </li>
              <li>
                <strong>Property Address (if different):</strong>
                {differentPropertyAddress}
              </li>
              <li>
                <strong>Additional Insured Co-Claimant Name(s):</strong>
                {restrictedForInsurer(otherClaimaints) || '-'}
              </li>
            </ol>
          </li>
          <li>
            <strong>Policy Issue and Inception Date:</strong>
            {getFormattedDate(policy.policySchedule.policyInceptionDate)}
          </li>
          <li>
            <strong>Opponent(s):</strong>
            <div className={styles.inlineTable}>
              <span>Name: {policy.policySchedule.defendant1Name}</span>
              <span>Address: {getFormattedAddress(opponentAddress)}</span>
            </div>
          </li>
          <li>
            <strong>Cause of Action:</strong>
            {schemeType[policy.scheme.type]?.description}
          </li>
          <li>
            <strong>Your Solicitor:</strong>
            {policy.policySchedule.solicitorName}

            <ol>
              <li>
                <strong>Address:</strong>
                {policy.policySchedule.solicitorOperatingAddress || '-'}
              </li>
            </ol>
          </li>
          <li>
            <strong>Insurer:</strong>
            {policy.scheme.frontingInsurerName}
          </li>
          <li>
            <strong> Pre-Proceedings Premium (including IPT):</strong>
            {getFormattedMoney(policy.policySchedule.totalPremiumCurrentInclIPT, 2, true)}

            <ol>
              <li>
                The Premiums due and Limits of Indemnity applicable in respect of Your Claim depend on the value of Your Claim and the stage at which Your Claim settles as shown in the Premium and Limit of Indemnity table below. All Premiums below are exclusive of Insurance Premium Tax which must be added at the rate applicable on the day of payment.

                <table>
                  <tbody>
                    <tr>
                      <td>Value of Your Claim (Quantum)</td>
                      <td>Pre-Proceedings Premium</td>
                      <td>Pre-Proceedings Limit of Indemnity</td>
                      <td>Post-Proceedings Additional Premium</td>
                      <td>Post-Proceedings Limit of Indemnity</td>
                      <td>45 Days To Trial Additional Premium</td>
                      <td>45 Days To Trial Limit of Indemnity</td>
                    </tr>
                    <tr>
                      <td>
                        {getFormattedMoney(policy.policySchedule.quantumBandFrom)} - {getFormattedMoney(policy.policySchedule.quantumBandTo)}
                      </td>
                      <td>
                        {getFormattedMoney(policy.policySchedule.preProceedingsPremium)}
                      </td>
                      <td>
                        {getFormattedMoney(policy.policySchedule.preProceedingsLimit)}
                      </td>
                      <td>
                        {getFormattedMoney(policy.policySchedule.postProceedingsPremium)}
                      </td>
                      <td>
                        {getFormattedMoney(policy.policySchedule.postProceedingsLimit)}
                      </td>
                      <td>
                        {getFormattedMoney(policy.policySchedule.trialPremium)}
                      </td>
                      <td>
                        {getFormattedMoney(policy.policySchedule.trialLimit)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                The "pre-proceedings" Premium is due and payable by Your Solicitor on Your behalf at the inception of Your Policy;
              </li>
              <li>
                The "post-proceedings" Premium is incurred when proceedings are issued and is due for payment upon the Successful conclusion of Your Claim;
              </li>
              <li>
                The "45 days to trial" Premium is incurred when trial is listed and 45 days remain before Your Claim is due to be heard in court and is due for payment upon the Successful conclusion of Your Claim;
              </li>
              <li>
                Insurance premium tax (‘IPT’) should be applied at the rate prevailing on each occasion that a premium is paid;
              </li>
              <li>
                The Premium is contingent and the Policy explains the circumstances in which We shall refund the net "pre-proceedings" Premium to the Solicitor from whom we received it;
              </li>
              <li>
                In purchasing this Policy you agree the Premium will be paid to the Insurer on Your behalf at the times indicated via Your Solicitor and will ultimately be deducted from damages awarded to You immediately they are received by Your Solicitor.
              </li>
            </ol>
          </li>
          <li>
            <strong>CANCELLATION TERMS:</strong>
            are as set out in sections 5 and 6 of Your Policy wording document.
          </li>
          <li>
            Terms in this Policy Schedule which are not personal names or titles and which are not set out in bold and which start with a capital letter have the meaning set out in the Policy wording.
            </li>
          <li>
            <strong>ENDORSEMENTS:</strong>
          </li>
        </ol>
      </Paper>
    </>
  );
}
