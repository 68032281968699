import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Modal } from '../../components';
import { useStyles } from './styles';

export default function ApprovalConditionModal({
  openModal,
  setOpenModal,
  setApprovalData,
  onConfirm
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
    <Modal
      onClose={handleClose}
      onConfirm={onConfirm}
      open={openModal}
      titleText="Approval condition"
      showActionButtons={true}
      isReadyToConfirm={true}
      PaperProps={{ className: classes.modal }}
    >
      <div>
        <Typography variant="subtitle2" className={`${classes.normalText} reset-mb`}>
          Please provide conditions of approval. An approval notification with the following conditions will be send to the case handler. Approving without providing any conditions will lead to standard movement approval.
        </Typography>
        
        <TextField
          name="conditions"
          label="Conditions"
          className={classes.formControl}
          onChange={(event) => setApprovalData({comment: event.target.value})}
          variant="outlined"
          multiline={true}
          rows={3}
        />
      </div>
    </Modal>
    </>
  );
}
