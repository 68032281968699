import React, { useEffect } from 'react';

const NotFound = () => {
  useEffect(() => {
    sessionStorage.removeItem('redirectUri');
  }, []);

  return (
    <>
      <p className="d-flex flex-column text-center display-1 font-weight-bold">
        404 - Not Found
      </p>
    </>
  )
};

export default NotFound;
