import React, { useCallback, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import WizardStepSection from '../WizardStepSection';
import { Section } from '../../../components';
import { uniqueId as getUniqueId } from '../../../support/helpers';

import Reinsurer from './Reinsurer';

export default function Reinsurers({ sectionKey }) {
  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const reinsurersInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.reinsurers,
    isEqual
  );
  const [reinsurers, setReinsurers] = useState(
    JSON.parse(
      JSON.stringify(
        !reinsurersInit || reinsurersInit.length === 0
          ? [{ uniqueId: getUniqueId() }]
          : reinsurersInit
      )
    )
  );

  const onReinsurerChange = useCallback((reinsurer, index) => {
    setReinsurers((prevReinsurers) => {
      let reinsurersCopy = [...prevReinsurers];
      reinsurersCopy[index] = reinsurer;
      return [...reinsurersCopy];
    }, []);
  }, []);

  const deleteReinsurer = useCallback((index) => {
    setReinsurers((prevReinsurers) => {
      let reinsurersCopy = [...prevReinsurers];
      reinsurersCopy.splice(index, 1);
      return [...reinsurersCopy];
    }, []);
  }, []);

  const addEmptyReinsurer = useCallback(() => {
    setReinsurers((prevReinsurers) => {
      return [...prevReinsurers, { uniqueId: getUniqueId() }];
    }, []);
  }, []);

  const addDirtyField = useCallback(
    (event) => {
      validator.addDirtyField(event.target.name);
      forceUpdate();
    },
    [validator]
  );

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[reinsurers]}
      dataToChange={{ reinsurers: reinsurers }}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
    >
      <Section title="Reinsurers" expanded={true}>
        {reinsurers.map((reinsurer, index) => (
          <Reinsurer
            key={reinsurer.id ?? reinsurer.uniqueId}
            reinsurer={reinsurer}
            reinsurersCount={reinsurers.length}
            index={index}
            onChange={onReinsurerChange}
            validator={validator}
            deleteReinsurer={deleteReinsurer}
            addEmptyReinsurer={addEmptyReinsurer}
            addDirtyField={addDirtyField}
          />
        ))}
      </Section>
    </WizardStepSection>
  );
}
