import { makeStyles } from '@material-ui/core';
import { colors } from './../../styles/styles';

export const useStyles = makeStyles(() => ({
  radioGroup: {
    '& .MuiFormLabel-root': {
      lineHeight: '21px',
      fontWeight: 'bold',
      fontSize: '14px',
      color: colors.text,
    },
  },
}));
