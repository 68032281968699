import React, { memo } from 'react';

import {
  EligibleSolicitorEdit,
  MultiplyAction,
  Section,
} from '../../../components';

const EligibleSolicitorSection = memo(function Component(props) {
  const {
    solicitor,
    solicitorsCount,
    onChange,
    index,
    validator,
    deleteSolicitor,
    addEmptySolicitor,
    addDirtyField,
  } = props;

  return (
    <Section
      title={`Eligible Solicitor ${index + 1}`}
      expanded={true}
      inside={true}
    >
      <MultiplyAction
        dataLength={solicitorsCount}
        index={index}
        actionText="solicitor"
        possibleLength={9}
        onDeleteItem={deleteSolicitor}
        onAddItem={addEmptySolicitor}
      >
        <EligibleSolicitorEdit
          solicitor={solicitor}
          onChange={onChange}
          index={index}
          validator={validator}
          addDirtyField={addDirtyField}
        />
      </MultiplyAction>
    </Section>
  );
});

export default EligibleSolicitorSection;
