const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(() => ({
  modal: {
    width: 550,
  },

  normalText: {
    fontWeight: 'normal',
  },

  checkboxMargin: {
    marginLeft: '-1em',
  },

  gridItemMargin: {
    marginBottom: '1.6em',
  },

  warning: {
    marginTop: '-8px',
    marginLeft: '-9px',
  },

  radios: {
    marginLeft: '15px',
    '& > label': {
      marginBottom: '0',
      textTransform: 'uppercase',
    },
  },

  smallAlert: {
    marginTop: '10px',
    marginBottom: '10px',
    '& > .MuiAlert-icon': {
      padding: '0',
    },
    '& > .MuiAlert-message': {
      padding: '2px 0',
      fontSize: '12px',
    },
  },
}));
