import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, InputAdornment, TextField } from '@material-ui/core';
import moment from 'moment';
import { isEqual, pullAllBy, filter } from 'lodash';

import { checkIfSchemeCodeIsAvailable } from '../../../store/reducers/scheme/save';
import WizardStepSection from '../WizardStepSection';

import {
  attachmentTypes,
  reinsuranceBasis,
  schemeType,
} from '../../../support/constants';
import {
  AttachmentsEdit,
  DatePickerWithClear,
  InfoTooltip,
  Section,
  SelectWithOptions,
} from '../../../components';
import { areDatesForPeriod } from '../../../support/helpers';
import { useStyles } from '../styles';

export default function SchemeInformation({ sectionKey }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const schemeInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep,
    isEqual
  );
  const isSchemeCodeAvailable = useSelector(
    (state) => state.scheme.create.isCodeAvailable,
    isEqual
  );
  const isSchemeCodeAvailableChecking = useSelector(
    (state) => state.scheme.create.isCodeAvailableChecking,
    isEqual
  );

  const [scheme, setScheme] = useState(JSON.parse(JSON.stringify(schemeInit)));
  const [propertyToChange, setPropertyToChange] = useState(null);

  const checkIfLiveIsValid = () => {
    return (
      validator?.isFormValid() &&
      !validator?.hasRequiredDateError('periodFrom', scheme) &&
      !validator?.hasRequiredDateError('periodTo', scheme) &&
      areDatesForPeriod(scheme.periodFrom, scheme.periodTo) &&
      !isSchemeCodeAvailableChecking &&
      isSchemeCodeAvailable !== false
    );
  };

  const checkIfDraftIsValid = () => {
    return (
      !!validator &&
      !validator?.hasError('codePrefix') &&
      scheme.codeSuffix &&
      !isSchemeCodeAvailableChecking &&
      isSchemeCodeAvailable !== false
    );
  };

  const hasRequiredFieldsForSchemeCode = () => {
    return (
      !!validator &&
      !validator?.hasError('codePrefix') &&
      !validator?.hasRequiredDateError('periodFrom', scheme) &&
      !validator?.hasError('type')
    );
  };

  useEffect(() => {
    let schemeCodeSuffix = '';
    if (scheme.periodFrom && scheme.type) {
      schemeCodeSuffix =
        moment(scheme.periodFrom).format('YYMM') +
        schemeType[scheme.type].shortcut;
    }

    onDataChange('codeSuffix', schemeCodeSuffix);
  }, [scheme.periodFrom, scheme.type]);

  useEffect(() => {
    if (hasRequiredFieldsForSchemeCode()) {
      dispatch(
        checkIfSchemeCodeIsAvailable(
          scheme.type,
          scheme.periodFrom,
          scheme.codePrefix
        )
      );
    }
  }, [scheme.type, scheme.periodFrom, scheme.codePrefix, validator]);

  const changeScheme = (propertyToChange) => {
    setScheme({
      ...scheme,
      ...propertyToChange,
    });

    setPropertyToChange(propertyToChange);
  };

  const onEventChange = (event) => {
    const propertyToChange = { [event.target.name]: event.target.value };
    changeScheme(propertyToChange);
  };

  const onDateChange = (date, propertyName) => {
    const propertyToChange = {
      [propertyName]: date ? date.toISOString() : date,
    };
    changeScheme(propertyToChange);
  };

  const onDataChange = (propertyName, data) => {
    const propertyToChange = { [propertyName]: data };
    changeScheme(propertyToChange);
  };

  const addAttachment = (attachment) => {
    const attachments = [...(scheme.attachments || []), attachment];
    const propertyToChange = { attachments: attachments };
    setScheme({ ...scheme, ...propertyToChange });
    setPropertyToChange(propertyToChange);
  };

  const deleteAttachment = (attachmentType, attachmentIndex) => {
    let attachmentsCopy = [...scheme.attachments];
    let attachmentsOfType = filter(attachmentsCopy, {type: attachmentType});
    pullAllBy(attachmentsCopy, [{type: attachmentType}], 'type');
    if (attachmentIndex !== -1) {
      attachmentsOfType.splice(attachmentIndex, 1);
      const propertyToChange = { attachments: [...attachmentsCopy, ...attachmentsOfType] };
      setScheme({ ...scheme, ...propertyToChange });
      setPropertyToChange(propertyToChange);
    }
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[
        propertyToChange,
        isSchemeCodeAvailable,
        isSchemeCodeAvailableChecking,
      ]}
      dataToChange={propertyToChange}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
      checkIfLiveIsValid={checkIfLiveIsValid}
      checkIfDraftIsValid={checkIfDraftIsValid}
    >
      <Section title="Scheme Information" highlighted={true} mainSection={true}>
        <FormGroup className={classes.pairOfInputs}>
          <TextField
            name="name"
            label="Scheme Name"
            value={scheme.name}
            onChange={onEventChange}
            variant="outlined"
            required={true}
            error={validator?.hasErrorToDisplay('name')}
            onBlur={addDirtyField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"><InfoTooltip title="General scheme name"></InfoTooltip></InputAdornment>
              ),
            }}
          />
          <FormGroup className={classes.pairOfInputs}>
            <SelectWithOptions
              name="type"
              label="Scheme Type"
              value={scheme.type}
              onChange={onEventChange}
              options={schemeType}
              required={true}
              error={validator?.hasErrorToDisplay('type')}
              onBlur={addDirtyField}
              getValueToDisplay={(value) => value.type}
              startAdornment={(
                <InputAdornment position="start"><InfoTooltip title="Specific scheme type"></InfoTooltip></InputAdornment>
              )}
            />
            <SelectWithOptions
              name="reinsuranceBasis"
              label="Reinsurance Basis"
              value={scheme.reinsuranceBasis}
              onChange={onEventChange}
              options={reinsuranceBasis}
              required={true}
              error={validator?.hasErrorToDisplay('reinsuranceBasis')}
              onBlur={addDirtyField}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup className={classes.fourOfInputs}>
          <DatePickerWithClear
            name="periodFrom"
            label="Scheme Period From"
            required={true}
            value={scheme.periodFrom}
            onChange={(date) => onDateChange(date, 'periodFrom')}
            error={
              validator?.hasDateErrorToDisplay('periodFrom', scheme) ||
              !areDatesForPeriod(scheme.periodFrom, scheme.periodTo)
            }
            helperText={
              !areDatesForPeriod(scheme.periodFrom, scheme.periodTo) &&
              'Must be before or same as period end date.'
            }
            onBlur={addDirtyField}
            startAdornment={(
              <InputAdornment position="start"><InfoTooltip title="Solicitors will be allowed to create policies under the scheme starting from this date"></InfoTooltip></InputAdornment>
            )}
          />
          <DatePickerWithClear
            name="periodTo"
            label="Scheme Period To"
            required={true}
            value={scheme.periodTo}
            onChange={(date) => onDateChange(date, 'periodTo')}
            error={
              validator?.hasDateErrorToDisplay('periodTo', scheme) ||
              !areDatesForPeriod(scheme.periodFrom, scheme.periodTo)
            }
            helperText={
              !areDatesForPeriod(scheme.periodFrom, scheme.periodTo) &&
              'Must be after or same as period start date.'
            }
            onBlur={addDirtyField}
            startAdornment={(
              <InputAdornment position="start"><InfoTooltip title="Solicitors will be allowed to create policies under the scheme up to this date"></InfoTooltip></InputAdornment>
            )}
          />
          <TextField
            name="codePrefix"
            label="Scheme Code Prefix"
            value={scheme.codePrefix}
            onChange={onEventChange}
            variant="outlined"
            required={true}
            error={
              validator?.hasErrorToDisplay('codePrefix') ||
              (hasRequiredFieldsForSchemeCode() &&
                isSchemeCodeAvailable === false)
            }
            onBlur={addDirtyField}
            inputProps={{ pattern: '[a-zA-Z0-9]{2,2}', maxLength: 2 }}
            helperText={
              <span>
                Must have exactly 2 characters (mandatory to save draft). <br />
                Scheme Code must be unique.
              </span>
            }
          />
          <TextField
            name="codeSuffix"
            label="Scheme Code Suffix"
            value={scheme.codeSuffix}
            variant="filled"
            required={true}
            error={
              hasRequiredFieldsForSchemeCode() &&
              isSchemeCodeAvailable === false
            }
            onBlur={addDirtyField}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">&nbsp;</InputAdornment>
              ),
            }}
            helperText={
              <span>
                Generated based on Scheme Type and Period From (mandatory to
                save draft). <br /> Scheme Code must be unique.
              </span>
            }
          />
        </FormGroup>
        <AttachmentsEdit
          addAttachment={addAttachment}
          deleteAttachment={index => deleteAttachment(attachmentTypes.None, index)}
          attachments={scheme?.attachments?.filter(
            (a) => a.type === attachmentTypes.None
          )}
        />
        <AttachmentsEdit
          addAttachment={addAttachment}
          deleteAttachment={(index) => deleteAttachment(attachmentTypes.SchemePublic, index)}
          attachments={scheme?.attachments?.filter(
            (a) => a.type === attachmentTypes.SchemePublic
          )}
          attachmentType={attachmentTypes.SchemePublic}
          title="Policy Attachments"
        />
      </Section>
    </WizardStepSection>
  );
}
