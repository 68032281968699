import React, { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, InputAdornment, TextField } from '@material-ui/core';
import { isEqual } from 'lodash';

import WizardStepSection from '../WizardStepSection';
import {
  AttachmentsEdit,
  CurrencyTextField,
  CompanyFieldsEdit,
  Section,
} from '../../../components';
import { useStyles } from '../styles';

export default function FrontingInsurer({ sectionKey }) {
  const classes = useStyles();

  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const frontingInsurerInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.frontingInsurer,
    isEqual
  );

  const [frontingInsurer, setFrontingInsurer] = useState(
    JSON.parse(JSON.stringify(frontingInsurerInit || {}))
  );

  const onEventChange = (event) => {
    setFrontingInsurer({
      ...frontingInsurer,
      [event.target.name]: event.target.value,
    });
  };

  const onDataPartChange = (newData) => {
    setFrontingInsurer({
      ...frontingInsurer,
      ...newData,
    });
  };

  const addAttachment = (attachment) => {
    const attachments = [...(frontingInsurer.attachments ?? []), attachment];
    setFrontingInsurer({ ...frontingInsurer, attachments });
  };

  const deleteAttachment = (attachmentIndex) => {
    let attachmentsCopy = [...frontingInsurer.attachments];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      setFrontingInsurer({ ...frontingInsurer, attachments: attachmentsCopy });
    }
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[frontingInsurer]}
      dataToChange={{ frontingInsurer: frontingInsurer }}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
    >
      <Section title="Fronting Insurer" expanded={true}>
        <CompanyFieldsEdit
          data={frontingInsurer.company}
          onChange={onDataPartChange}
          validator={validator}
          addDirtyField={addDirtyField}
        />
        <Section title="Details" inside={true}>
          <FormGroup className={classes.fourOfInputs}>
            <TextField
              name="share"
              label="Share"
              type="number"
              value={frontingInsurer.share}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, step: 1 },
              }}
              variant="outlined"
              onChange={onEventChange}
              error={validator?.hasErrorToDisplay('share')}
              onBlur={addDirtyField}
            />
            <TextField
              name="fee"
              label="Fee"
              type="number"
              value={frontingInsurer.fee}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0, step: 1 },
              }}
              variant="outlined"
              onChange={onEventChange}
              error={validator?.hasErrorToDisplay('fee')}
              onBlur={addDirtyField}
            />
            <CurrencyTextField
              name="referralLimitClaim"
              label="Referral Limit Claim"
              value={frontingInsurer.referralLimitClaim}
              required={true}
              onChange={onEventChange}
              error={validator?.hasErrorToDisplay('referralLimitClaim')}
              onBlur={addDirtyField}
              allowNegative={false}
              decimalScale={4}
            />
            <div />
          </FormGroup>
          <AttachmentsEdit
            addAttachment={addAttachment}
            deleteAttachment={deleteAttachment}
            attachments={frontingInsurer?.attachments}
          />
        </Section>
      </Section>
    </WizardStepSection>
  );
}
