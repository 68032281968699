import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';

import { areDatesForPeriod, capitalizeWords } from '../../../support/helpers';
import {
  CurrencyTextField,
  DatePickerWithClear,
  RadioGroupWithYesNo,
} from '../../../components';
import { useStyles } from '../styles';

export const SpecificCauseOfActionCwiDefendant2 = ({
  onEventChange,
  onDataChange,
  dirtyFields,
  setDirtyFields,
  hasErrorToDisplay,
  hasRequiredDate,
  typeSpecificCauseOfAction,
  subtitleClassName,
}) => {
  const classes = useStyles();

  const isRequired = () => typeSpecificCauseOfAction.hasDefendant1BeenDissolved;

  const arePolicyDatesValidAsPeriod = () =>
    areDatesForPeriod(
      typeSpecificCauseOfAction.defendant2PolicyPeriodFrom,
      typeSpecificCauseOfAction.defendant2PolicyPeriodTo
    );

    const capitalizeInputChange = (event) => {
      event.target.value = capitalizeWords(event.target.value);
      onEventChange(event);
    }

  return (
    <>
      <RadioGroupWithYesNo
        label="Has Defendant 1 been dissolved?*"
        name="hasDefendant1BeenDissolved"
        value={typeSpecificCauseOfAction.hasDefendant1BeenDissolved}
        onValueChange={(value) =>
          onDataChange('hasDefendant1BeenDissolved')(value)
        }
      />
      <h4 className={subtitleClassName}>Defendant Insurer</h4>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          name="defendant2InsurerName"
          label="Defendant Insurer Name"
          value={typeSpecificCauseOfAction.defendant2InsurerName}
          onChange={capitalizeInputChange}
          variant="outlined"
          error={hasErrorToDisplay('defendant2InsurerName')}
          onBlur={setDirtyFields}
          required={isRequired()}
          InputProps={{
            style: {textTransform: 'capitalize'},
          }}
        />
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          name="defendant2InsurancePolicyNumber"
          label="Insurance Policy Number"
          value={typeSpecificCauseOfAction.defendant2InsurancePolicyNumber}
          onChange={onEventChange}
          variant="outlined"
          error={hasErrorToDisplay('defendant2InsurancePolicyNumber')}
          onBlur={setDirtyFields}
          className={classes.halfOfWidth}
          required={isRequired()}
        />
        <FormGroup className={classes.pairOfInputs}>
          <DatePickerWithClear
            name="defendant2PolicyPeriodFrom"
            label="Policy Period From"
            value={typeSpecificCauseOfAction.defendant2PolicyPeriodFrom}
            onChange={onDataChange('defendant2PolicyPeriodFrom')}
            error={
              dirtyFields.includes('defendant2PolicyPeriodFrom') &&
              ((isRequired() &&
                !hasRequiredDate('defendant2PolicyPeriodFrom')) ||
                !arePolicyDatesValidAsPeriod())
            }
            onBlur={setDirtyFields}
            required={isRequired()}
            helperText={
              !arePolicyDatesValidAsPeriod() &&
              'Must be before or same as period end date.'
            }
          />
          <DatePickerWithClear
            name="defendant2PolicyPeriodTo"
            label="Policy Period To"
            value={typeSpecificCauseOfAction.defendant2PolicyPeriodTo}
            onChange={onDataChange('defendant2PolicyPeriodTo')}
            error={
              dirtyFields.includes('defendant2PolicyPeriodTo') &&
              ((isRequired() && !hasRequiredDate('defendant2PolicyPeriodTo')) ||
                !arePolicyDatesValidAsPeriod())
            }
            onBlur={setDirtyFields}
            required={isRequired()}
            helperText={
              !arePolicyDatesValidAsPeriod() &&
              'Must be after or same as period start date.'
            }
          />
        </FormGroup>
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <CurrencyTextField
          name="defendant2PolicyLimit"
          label="Policy Limit"
          value={typeSpecificCauseOfAction.defendant2PolicyLimit}
          onChange={onEventChange}
          error={hasErrorToDisplay('defendant2PolicyLimit')}
          onBlur={setDirtyFields}
          allowNegative={false}
          decimalScale={0}
        />
        <CurrencyTextField
          name="defendant2PolicyAggregateLimit"
          label="Policy Aggregate Limit"
          value={typeSpecificCauseOfAction.defendant2PolicyAggregateLimit}
          onChange={onEventChange}
          error={hasErrorToDisplay('defendant2PolicyAggregateLimit')}
          onBlur={setDirtyFields}
          allowNegative={false}
          decimalScale={0}
        />
      </FormGroup>
    </>
  );
};
