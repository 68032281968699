import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { ListItem } from '../../components';

import { getFormattedDate, getFormattedMoney } from '../../support/helpers';

import { useStyles } from './policyDetailsTabsStyles';

export default function PolicyDetailsTabItemSpecificCauseOfActionMmc(props) {
  const classes = useStyles();
  const { policy } = props;

  return (
    <>
      <Typography className={classes.panelSectionHeading} variant="h5">
        Mortgage Details
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Date of Mortgage"
            secondary={getFormattedDate(
              policy.typeSpecificCauseOfAction.dateOfMortgage
            )}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Original Lender"
            secondary={policy.typeSpecificCauseOfAction.originalLender}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Mortgage Number"
            secondary={policy.typeSpecificCauseOfAction.mortgageNumber}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Amount"
            secondary={getFormattedMoney(
              policy.typeSpecificCauseOfAction.amount
            )}
          />
        </Grid>
      </Grid>

      <Typography className={classes.panelSectionHeading} variant="h5">
        Re-mortgage Details
      </Typography>

      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Re-mortgage"
            secondary={
              policy.typeSpecificCauseOfAction.hasThereBeenRemortgage
                ? 'Yes'
                : 'No'
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Date of Re-mortgage"
            secondary={
              policy.typeSpecificCauseOfAction.hasThereBeenRemortgage
                ? getFormattedDate(policy.typeSpecificCauseOfAction.dateOfRemortgage)
                : '-'
            }
          />
        </Grid>
      </Grid>
      <Typography className={classes.panelSectionHeading} variant="h5">
        Other party
      </Typography>
      <Grid container>
        <Grid item lg={4}>
          <ListItem
            primary="Another party acquired the rights to this mortgage contract"
            secondary={
              policy.typeSpecificCauseOfAction.hasOtherPartyRightsToMortgage
                ? 'Yes'
                : 'No'
            }
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="New Lender Name"
            secondary={policy.typeSpecificCauseOfAction.newLenderName}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="New Lender Reference"
            secondary={policy.typeSpecificCauseOfAction.newLenderReference}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Audit Report Name"
            secondary={policy.typeSpecificCauseOfAction.auditReportName}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Audit Version Number"
            secondary={policy.typeSpecificCauseOfAction.auditVersionNumber}
          />
        </Grid>

        <Grid item lg={4}>
          <ListItem
            primary="Full Audit Report Date"
            secondary={getFormattedDate(
              policy.typeSpecificCauseOfAction.fullAuditReportDate
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
