import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

export const LoadingWrapper = ({ children, loading }) => {
  const classes = useStyles();

  return loading ? (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  ) : (
    <>{children}</>
  );
};
