import { colors } from '../../styles/styles';

const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > *:not(:last-child)': {
      marginBottom: '37px',
    },
    '& > .reset-mb': {
      marginBottom: 0,
    },
    '& > .warning-mt': {
      marginTop: '0.5em',
    },
  },

  modal: {
    width: 550,
  },

  normalText: {
    fontWeight: 'normal',
  },

  disclaimer: {
    fontSize: '0.75em',
  },

  error: {
    color: colors.error,
  },

  modalContainer: {
    width: '566px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '34px 0',
  },

  modalText: {
    fontWeight: '600',
    fontSize: '18px',
    flex: '0 1 320px',
  },

  modalIconLarge: {
    width: '100px',
    height: '100px',
  },

  radios: {
    marginLeft: '15px',
    '& > label': {
      marginBottom: '0',
      textTransform: 'uppercase',
    },
  },

  settlementConfirmationChechbox: {
    marginTop: '-5px',
    textAlign: 'center',
  },

  attachmentsSubTitle: {
    display: 'inline',
    fontSize: '0.85em',
    fontWeight: 'normal',
    textTransform: 'none',
    color: 'inherit',
  },

  inputWithCheckbox: {
    '& > div:nth-child(1)': {
      paddingTop: '7px',
    },
    '& > div:nth-child(2) > div': {
      width: '100%',
    },
  },

  extendedLabel: {
    '& > label': {
      width: '130%',
    },
  },

  marginFontSize: {
    marginBottom: '1em',
  },

  divider: {
    widht: '100%',
    borderBottom: `1px solid ${colors.greyE6}`,
  },

  smallAlert: {
    marginTop: '10px',
    '& > .MuiAlert-icon': {
      padding: '0',
    },
    '& > .MuiAlert-message': {
      padding: '2px 0',
      fontSize: '12px',
    },
  },
}));
