import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 0,
  pageSize: 10,
  filters: [],
};

export const policiesFiltersSlice = createSlice({
  name: 'policiesFilters',
  initialState: initialState,
  reducers: {
    saveFilters: (state, action) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
      state.filters = action.payload.filters;
    },
    resetFilters: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const { saveFilters, resetFilters } = policiesFiltersSlice.actions;

export default policiesFiltersSlice.reducer;
