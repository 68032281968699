import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import userManager from '../../store/userManager';
import { useStyles } from './styles';

export default function Login() {
  const classes = useStyles();
  const onLoginButtonClick = e => {
    e.preventDefault();
    userManager.signinRedirect();
  };
  return (
    <Container maxWidth="sm">
      <Card className={classes.root}>
        <CardContent>
          <Typography
            align="justify"
            variant="h1"
            className={classes.logo}
            gutterBottom
          >
            Resolve
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button className={classes.button} onClick={onLoginButtonClick}>
            Login
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}
