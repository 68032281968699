import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';

import { capitalizeWords } from '../../support/helpers';
import { useStyles } from '../PolicyWizard/styles';

export const getEmptyAddress = () => {
  return {
    postcode: '',
    street: '',
    city: '',
    county: '',
  };
};

export const initializeAddress = (postcode, street, city, county) => {
  return {
    postcode,
    street,
    city,
    county,
  };
};

export const Address = ({
  parentPropertyName,
  parentProperty,
  onDataChange,
  setDirtyFields,
  hasErrorToDisplay,
  readOnly = false,
}) => {
  const classes = useStyles();
  const seperatorMark = '-';

  const onAddressEventChange = (event) => {
    let propertyToChange = event.target.name.split(seperatorMark).pop();
    let newAddress = {
      ...parentProperty,
      [propertyToChange]: event.target.value,
    };

    onDataChange(parentPropertyName)(newAddress);
  };

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onAddressEventChange(event);
  }

  const uppercaseInputChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    onAddressEventChange(event);
  }

  return (
    <>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          name={`${parentPropertyName}${seperatorMark}postcode`}
          value={parentProperty.postcode}
          label="Postcode"
          onChange={uppercaseInputChange}
          variant={readOnly ? 'filled' : 'outlined'}
          required={true}
          error={hasErrorToDisplay(
            `${parentPropertyName}${seperatorMark}postcode`
          )}
          onBlur={setDirtyFields}
          InputProps={{
            readOnly: readOnly,
            style: {textTransform: 'uppercase'},
          }}
        />
        <TextField
          name={`${parentPropertyName}${seperatorMark}street`}
          value={parentProperty['street']}
          label="House Number and Street"
          onChange={capitalizeInputChange}
          variant={readOnly ? 'filled' : 'outlined'}
          required={true}
          error={hasErrorToDisplay(
            `${parentPropertyName}${seperatorMark}street`
          )}
          onBlur={setDirtyFields}
          InputProps={{
            readOnly: readOnly,
            style: {textTransform: 'capitalize'},
          }}
        />
      </FormGroup>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          name={`${parentPropertyName}${seperatorMark}city`}
          value={parentProperty['city']}
          label="Town/City"
          onChange={capitalizeInputChange}
          variant={readOnly ? 'filled' : 'outlined'}
          required={true}
          error={hasErrorToDisplay(`${parentPropertyName}${seperatorMark}city`)}
          onBlur={setDirtyFields}
          InputProps={{
            readOnly: readOnly,
            style: {textTransform: 'capitalize'},
          }}
        />
        <TextField
          name={`${parentPropertyName}${seperatorMark}county`}
          value={parentProperty['county']}
          label="County"
          onChange={capitalizeInputChange}
          variant={readOnly ? 'filled' : 'outlined'}
          required={false}
          error={hasErrorToDisplay(
            `${parentPropertyName}${seperatorMark}county`
          )}
          onBlur={setDirtyFields}
          InputProps={{
            readOnly: readOnly,
            style: {textTransform: 'capitalize'},
          }}
        />
      </FormGroup>
    </>
  );
};
