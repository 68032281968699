import React from 'react';
import { Tabs, Tab } from '@material-ui/core';

import { useStyles } from './SchemeDetailsTabsStyles';
import { SchemeDetailsTabItem } from './SchemeDetailsTabItem';
import { SchemeCriteriaTabItem } from './SchemeCriteriaTabItem';
import { SchemePolicyTabItem } from './SchemePolicyTabItem';
import { SchemeNotesTabItem } from './SchemeNotesTabItem';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const SchemeDetailsTabs = ({ scheme, restrictedForInsurer }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab tabIndex={0} label="Scheme Details" />
        <Tab tabIndex={1} label="Scheme Criteria" />
        <Tab tabIndex={2} label="Policy" />
        <Tab tabIndex={3} label="Notes" />
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <SchemeDetailsTabItem
          scheme={scheme}
          classes={classes}
          restrictedForInsurer={restrictedForInsurer}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <SchemeCriteriaTabItem scheme={scheme} classes={classes} />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <SchemePolicyTabItem scheme={scheme} classes={classes} />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <SchemeNotesTabItem scheme={scheme} classes={classes} />
      </TabPanel>
    </div>
  );
};
