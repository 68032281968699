import React from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { useStyles } from './styles';

export default function QddlTable({ isEdit = false, children }) {
  const classes = useStyles();

  return (
    <Table className={classes.qddlTable} aria-label="qddl table">
      <TableHead>
        <TableRow>
          <TableCell
            className={classes.qddlTableCell}
            rowSpan={2}
            align="center"
          >
            quantum band (gbp)
          </TableCell>
          <TableCell
            className={classes.qddlTableCell}
            colSpan={3}
            align="center"
          >
            pre-proceedings premium
          </TableCell>
          <TableCell
            className={classes.qddlTableCell}
            colSpan={3}
            align="center"
          >
            issue (proceedings) premium
          </TableCell>
          <TableCell
            className={classes.qddlTableCell}
            colSpan={3}
            align="center"
          >
            45 days to trial (trial) premium
          </TableCell>
          {isEdit && (
            <TableCell
              className={classes.qddlTableCell}
              rowSpan={2}
              align="center"
            >
              Action
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell className={classes.qddlTableSubheaderLeft} align="center">
            funded
          </TableCell>
          <TableCell align="center">deferred</TableCell>
          <TableCell align="center">limit</TableCell>
          <TableCell className={classes.qddlTableSubheaderLeft} align="center">
            funded
          </TableCell>
          <TableCell align="center">deferred</TableCell>
          <TableCell align="center">limit</TableCell>
          <TableCell className={classes.qddlTableSubheaderLeft} align="center">
            funded
          </TableCell>
          <TableCell align="center">deferred</TableCell>
          <TableCell align="center">limit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}
