import React from 'react';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { DatePicker } from '@material-ui/pickers';

import { dateFormat } from '../../support/constants';

export default function DatePickerWithClear(props) {
  const { isReadOnly, ...other } = props;

  return (
    <DatePicker
      {...other}
      format={dateFormat}
      autoOk
      variant="inline"
      inputVariant={isReadOnly ? 'filled' : 'outlined'}
      readOnly={isReadOnly}
      views={['year', 'month', 'date']}
      openTo="date"
      InputProps={{
        endAdornment: (
          <IconButton
            edge="end"
            size="small"
            disabled={!props.value || isReadOnly}
            onClick={() => props.onChange(null)}
          >
            <ClearIcon />
          </IconButton>
        ),
        startAdornment: props.startAdornment
      }}
    />
  );
}
