import React from 'react';
import { TextField } from '@material-ui/core';
import { CurrencyTextField } from '../../components';

export default function OurPart36Movement({ onChange }) {
  return (
    <>
      <CurrencyTextField
        name="value"
        label="Total value of offer"
        onChange={onChange}
        helperText="Must be egual or greater than 0"
        required={true}
        allowNegative={false}
        allowZero={true}
      />
      <TextField
        name="comment"
        label="Breakdown (if not a global offer) and supplementary comments if any"
        onChange={onChange}
        variant="outlined"
        required={true}
        multiline={true}
        rows={3}
      />
    </>
  );
}
