import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, Input, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { StatusBadge } from '../../components';
import { policyStatuses } from '../../support/constants';
import { transformObject } from '../../support/helpers';
import { mainTheme } from '../../support/mainTheme';

import { useStyles } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomLookup(props) {

    const classes = useStyles();

    const [selectedFilter, setSelectedFilter] = React.useState(
        props.columnDef.tableData.filterValue || []
    );

    const [policyStatusesWithIdKey] = useState(
        transformObject(policyStatuses, (obj) => obj.value)
      );

    useEffect(() => {
        setSelectedFilter(props.columnDef.tableData.filterValue || []);
    }, [props.columnDef.tableData.filterValue]);

    const theme = createMuiTheme(mainTheme);

    return (
        <ThemeProvider theme={theme}>
            <FormControl style={{ width: "100%" }}>
            <InputLabel
                htmlFor={"select-multiple-checkbox" + props.columnDef.tableData.id}
                style={{ marginTop: -16 }}
            >
                {props.columnDef.filterPlaceholder}
            </InputLabel>
            <Select
                multiple
                value={selectedFilter}
                onClose={() => {
                if (props.columnDef.filterOnItemSelect !== true)
                    props.onFilterChanged(
                        props.columnDef.tableData.id,
                    selectedFilter
                    );
                }}
                onChange={(event) => {
                setSelectedFilter(event.target.value);
                if (props.columnDef.filterOnItemSelect === true)
                    props.onFilterChanged(
                        props.columnDef.tableData.id,
                    event.target.value
                    );
                }}
                input={
                <Input id={"select-multiple-checkbox" + props.columnDef.tableData.id} />
                }
                renderValue={(selecteds) =>
                selecteds.map((selected) => props.columnDef.lookup[selected]).join(", ")
                }
                MenuProps={MenuProps}
                style={{ marginTop: 0 }}
                className={classes.dropdownBox}
            >
                {Object.keys(props.columnDef.lookup).map((key) => (
                <MenuItem key={key} value={key}>
                    <Checkbox color="primary" checked={selectedFilter.indexOf(key.toString()) > -1} />
                    <StatusBadge
                        status={key}
                        statusType={policyStatusesWithIdKey}
                    />
                </MenuItem>
                ))}
            </Select>
            </FormControl>
        </ThemeProvider>
    );
  };