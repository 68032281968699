import { makeStyles } from '@material-ui/core';
import { colors, common } from '../../styles/styles';

const contentHorizontalPadding = '20px';
const circleCommon = {
  borderRadius: 12,
  width: 24,
  height: 24,
  fontSize: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold',
  border: `2px solid ${colors.grey7F}`,
};

const label = {
  textTransform: 'uppercase',
};

export const useStyles = makeStyles((theme) => ({
  breadcrumbs: common.breadcrumbs,
  stepper: {
    marginBottom: 22,
    border: `1px solid ${colors.greyE6}`,
    boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)`,
    borderRadius: 4,
  },
  clickableStep: {
    cursor: 'pointer',
  },
  completed: {
    display: 'inline-block',
  },
  circle: {
    ...circleCommon,
    backgroundColor: colors.grey7F,
    color: colors.white,
  },
  circleActiveValid: {
    ...circleCommon,
    backgroundColor: colors.white,
    border: `2px solid ${colors.green}`,
    color: colors.green,
  },
  circleActiveWithErrors: {
    ...circleCommon,
    backgroundColor: colors.white,
    border: `2px solid ${colors.warning}`,
    color: colors.warning,
  },
  circleCompleted: {
    ...circleCommon,
    backgroundColor: colors.green,
    border: `2px solid ${colors.green}`,
    color: colors.white,
  },
  circleError: {
    ...circleCommon,
    backgroundColor: 'red',
    border: `2px solid red`,
    color: colors.white,
  },
  circleWarning: {
    ...circleCommon,
    backgroundColor: colors.warning,
    border: `2px solid ${colors.warning}`,
    color: colors.white,
  },
  label: label,
  labelActive: {
    ...label,
    textDecoration: 'underline',
    color: colors.grey7F,
  },
  stepTitle: {
    marginTop: 24,
    marginBottom: 26,
  },
  stepContent: {
    background: colors.white,
    border: `1px solid ${colors.greyE6}`,
    boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 6px rgba(43, 64, 78, 0.05)`,
    padding: `${contentHorizontalPadding} 34px`,
    '&:not(:last-of-type)': {
      marginBottom: 12,
    },
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  button: {
    fontWeight: 700,
    fontSize: 14,
    '&.MuiButton-containedPrimary': {
      marginLeft: 24,
      padding: '6px 63px',
    },
    '&.MuiButton-outlinedPrimary': {
      marginLeft: 38,
      padding: '6px 63px',
      backgroundColor: colors.white,
    },
  },
  icon: {
    marginRight: 8,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
