import React, { useEffect } from 'react';
import _ from 'lodash';

import { Typography } from '@material-ui/core';

import { AttachmentsEdit, Warning } from '../../components';
import { movementTypes } from '../../support/constants';

import { useStyles } from './styles';

export default function TrialDocumentationMovement({ policy, movement, onChange, setMovementFields, setCustomIsValid, showAllValidationErrors }) {

  const classes = useStyles();

  useEffect(() => {
    setCustomIsValid(false);
    var targetTrialRequest = 
        _.last(_.sortBy(_.filter(policy.movements, (movement) => movement.type == movementTypes.TrialRequest.value), ['order']));

    setMovementFields({
        trialRequestId: targetTrialRequest.id,
    });
  }, []);

  useEffect(() => {
    setCustomIsValid(isFormValid());
  });

  const validation = {
    isSupportiveCounselsAdviceAttachmentsValid: () => true,
    isPropertyReSurveyedByHeadSurveyorAttachmentsValid: () => true,
    atLeastOneDocumentIsUploaded: () => !!movement?.supportiveCounselsAdviceAttachments?.length || !!movement?.propertyReSurveyedByHeadSurveyorAttachments?.length,
  };

  const isFormValid = () => {
    return validation.isSupportiveCounselsAdviceAttachmentsValid()
        && validation.isPropertyReSurveyedByHeadSurveyorAttachmentsValid()
        && validation.atLeastOneDocumentIsUploaded();
  }

  const addAttachment = (attachmentName, attachment) => {
    const attachments = [...(movement[attachmentName] ?? []), attachment];
    updateAttachments(attachmentName, attachments);
  };

  const deleteAttachment = (attachmentName, attachmentIndex) => {
    let attachmentsCopy = [...movement[attachmentName]];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      updateAttachments(attachmentName, attachmentsCopy);
    }
  };

  const updateAttachments = (attachmentName, attachments) =>{
    onChange({
      target: {
        name: attachmentName,
        value: attachments
      }
    });
  };

  const shouldShowSupportiveCounselsAdviceWarning = () => showAllValidationErrors && !movement?.supportiveCounselsAdviceAttachments?.length;
  const shouldShowPropertyReSurveyedByHeadSurveyorWarning = () => showAllValidationErrors && !movement?.propertyReSurveyedByHeadSurveyorAttachments?.length;

  return (
    <>
      <div className={'reset-mb'}>
        <Typography 
          variant="subtitle2" 
          className={classes.normalText}>
          Supportive Counsel's Advice
        </Typography>
        <AttachmentsEdit
          className={classes.formFieldMarginBottom}
          addAttachment={(attachment) => addAttachment('supportiveCounselsAdviceAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('supportiveCounselsAdviceAttachments', attachmentIndex)}
          attachments={movement?.supportiveCounselsAdviceAttachments}
          limitListItemsWidth={false}
          hasWarning={shouldShowSupportiveCounselsAdviceWarning()}
          title={<>Attachments <div className={classes.attachmentsSubTitle}>(Please upload as soon as available)</div></>}
        />
      </div>
      <div></div>

      <div className={'reset-mb'}>
        <Typography 
          variant="subtitle2" 
          className={classes.normalText}>
          Property Re-Surveyed by Head Surveyor
        </Typography>
        <AttachmentsEdit
          addAttachment={(attachment) => addAttachment('propertyReSurveyedByHeadSurveyorAttachments', attachment)}
          deleteAttachment={(attachmentIndex) => deleteAttachment('propertyReSurveyedByHeadSurveyorAttachments', attachmentIndex)}
          attachments={movement?.propertyReSurveyedByHeadSurveyorAttachments}
          limitListItemsWidth={false}
          title={<>Attachments <div className={classes.attachmentsSubTitle}>(Please upload as soon as available)</div></>}
          hasWarning={shouldShowPropertyReSurveyedByHeadSurveyorWarning()}
        />
      </div>
      <div></div>
    </>
  );
}
