import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/styles';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: '1 0 1px',
    backgroundColor: colors.secondary,
    padding: '35px',
    position: 'relative',
  },
}));
