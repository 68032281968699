import React from 'react';
import { FormHelperText } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { useStyles } from './styles';

export default function Error(props) {
  const classes = useStyles();
  return (
    <FormHelperText className={`${classes.error} ${props.className}`}>
      <ClearIcon />
      {props.children}
    </FormHelperText>
  );
}
