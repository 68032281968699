import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, TextField } from '@material-ui/core';
import { isEqual } from 'lodash';

import WizardStepSection from '../WizardStepSection';
import {
  AttachmentsEdit,
  CompanyFieldsEdit,
  Section,
  SelectWithOptions,
} from '../../../components';
import { remunerationType } from '../../../support/constants';
import { transformToLabel } from '../../../support/helpers';

import { useStyles } from '../styles';

export default function Introducer({ sectionKey }) {
  const remunerationCommission = 'remunerationCommission';
  const remunerationProfitCommission = 'remunerationProfitCommission';

  const classes = useStyles();

  const [validator, setValidator] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const introducerInit = useSelector(
    (state) => state.scheme.create.schemeInitForStep.introducer,
    isEqual
  );

  const [introducer, setIntroducer] = useState(
    JSON.parse(JSON.stringify(introducerInit || {}))
  );

  useEffect(() => {
    const remunerationsToClear = {};

    const checkIfRemunerationToClear = (remuneration) => {
      if (!isRemunerationActive(remuneration)) {
        remunerationsToClear[remuneration] = '';
      }
    };

    checkIfRemunerationToClear(remunerationCommission);
    checkIfRemunerationToClear(remunerationProfitCommission);
    setIntroducer({ ...introducer, ...remunerationsToClear });
  }, [introducer.remunerationType]);

  const onEventChange = (event) => {
    setIntroducer({
      ...introducer,
      [event.target.name]: event.target.value,
    });
  };

  const onDataPartChange = (newData) => {
    setIntroducer({
      ...introducer,
      ...newData,
    });
  };

  const addDirtyField = (event) => {
    validator.addDirtyField(event.target.name);
    forceUpdate();
  };

  const addAttachment = (attachment) => {
    const attachments = [...(introducer.attachments ?? []), attachment];
    setIntroducer({ ...introducer, attachments });
  };

  const deleteAttachment = (attachmentIndex) => {
    let attachmentsCopy = [...introducer.attachments];
    if (attachmentIndex !== -1) {
      attachmentsCopy.splice(attachmentIndex, 1);
      setIntroducer({ ...introducer, attachments: attachmentsCopy });
    }
  };

  const isRemunerationActive = (remunerationName) =>
    introducer.remunerationType
      ? remunerationType[introducer.remunerationType].fieldsToDisplay.includes(
          remunerationName
        )
      : false;

  const remunerationControl = (remuneration) => (
    <TextField
      name={remuneration}
      label={transformToLabel(remuneration)}
      value={introducer[remuneration]}
      onChange={onEventChange}
      variant={isRemunerationActive(remuneration) ? 'outlined' : 'filled'}
      InputProps={{
        readOnly: !isRemunerationActive(remuneration),
      }}
      required={isRemunerationActive(remuneration)}
      error={
        isRemunerationActive(remuneration) &&
        validator?.hasErrorToDisplay(remuneration)
      }
      multiline={true}
      rows={3}
      onBlur={addDirtyField}
    />
  );

  return (
    <WizardStepSection
      dataArrayToTriggerUpdate={[introducer]}
      dataToChange={{ introducer: introducer }}
      sectionKey={sectionKey}
      validator={validator}
      setValidator={setValidator}
    >
      <Section title="Introducer" expanded={true}>
        <CompanyFieldsEdit
          data={introducer.company}
          onChange={onDataPartChange}
          validator={validator}
          addDirtyField={addDirtyField}
        />
        <Section title="Details" inside={true}>
          <FormGroup className={classes.pairOfInputs}>
            <SelectWithOptions
              name="remunerationType"
              label="Type of Remuneration"
              value={introducer.remunerationType}
              onChange={onEventChange}
              options={remunerationType}
              required={true}
              error={validator?.hasErrorToDisplay('remunerationType')}
              onBlur={addDirtyField}
              getValueToDisplay={(value) => value.type}
            />
          </FormGroup>
          <FormGroup className={classes.pairOfInputs}>
            {remunerationControl(remunerationCommission)}
            {remunerationControl(remunerationProfitCommission)}
          </FormGroup>
          <AttachmentsEdit
            addAttachment={addAttachment}
            deleteAttachment={deleteAttachment}
            attachments={introducer?.attachments}
          />
        </Section>
      </Section>
    </WizardStepSection>
  );
}
