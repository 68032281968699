import React from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, Container } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { isUserLoggedSelector } from '../../store/selectors/global';
import { AsideMenu, Header, NotificationsArea, Footer } from '../../components';
import { LoadingWithScreenBlock } from '../../components/Loading/LoadingWithScreenBlock';

import config from '../../config';
import { useStyles } from './styles';

export default function Main({ children }) {
  const classes = useStyles();
  const isUserLogged = useSelector(isUserLoggedSelector);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        { isUserLogged && <Header />}
        { isUserLogged && <AsideMenu />}
        <CssBaseline />
        <Container
          tag="main"
          maxWidth={false}
          className={classes.mainContainer}
        >
          {children}
          {isUserLogged && <Footer version={config.version} />}
        </Container>
        <NotificationsArea />
        <LoadingWithScreenBlock />
      </>
    </MuiPickersUtilsProvider>
  );
}
