import React, { useState } from 'react';
import { Tabs, Tab, FormGroup, TextField } from '@material-ui/core';

import { uniqueId as getUniqueId, capitalizeWords } from '../../support/helpers';

import AddressFieldsEdit from './AddressFieldsEdit';
import ContactsPanels from './ContactsPanels';
import TabPanel from './TabPanel';
import { useStyles } from './styles';

export default function CompanyFieldsEdit({
  data = {},
  onChange,
  validator,
  addDirtyField,
}) {
  const classes = useStyles();
  const [uniqueId] = useState(getUniqueId());

  const onEventChange = (event) => {
    const propertyName = event.target.name.substring(
      event.target.name.lastIndexOf('-') + 1
    );

    onChange({
      company: {
        ...data,
        [propertyName]: event.target.value,
      },
    });
  };

  const capitalizeInputChange = (event) => {
    event.target.value = capitalizeWords(event.target.value);
    onEventChange(event);
  }


  const onDataPartChange = (newData, dataType) => {
    onChange({ company: { ...data, [dataType]: newData } });
  };

  const [addressTab, setAddressTab] = useState(0);

  const handleAddressTab = (event, newValue) => {
    setAddressTab(newValue);
  };

  return (
    <>
      <FormGroup className={classes.pairOfInputs}>
        <TextField
          variant="outlined"
          label="Company Name"
          id={`company-${uniqueId}-name`}
          name={`company-${uniqueId}-name`}
          value={data.name}
          onChange={capitalizeInputChange}
          required={true}
          error={validator?.hasErrorToDisplay(`company-${uniqueId}-name`)}
          onBlur={addDirtyField}
          inputProps={{
            style: {textTransform: 'capitalize'},
          }}
        />
        <TextField
          variant="outlined"
          label="Company Number"
          id={`company-${uniqueId}-companyNumber`}
          name={`company-${uniqueId}-companyNumber`}
          value={data.companyNumber}
          onChange={onEventChange}
          required={true}
          error={validator?.hasErrorToDisplay(
            `company-${uniqueId}-companyNumber`
          )}
          onBlur={addDirtyField}
        />
      </FormGroup>
      <Tabs
        value={addressTab}
        onChange={handleAddressTab}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab label="Registered Address" />
        <Tab label="Operating Address" />
        <Tab label="Notice Address" />
      </Tabs>
      <TabPanel value={addressTab} index={0}>
        <AddressFieldsEdit
          type="registeredAddress"
          required={true}
          data={data.registeredAddress}
          onChange={onDataPartChange}
          validator={validator}
          addDirtyField={addDirtyField}
        />
      </TabPanel>
      <TabPanel value={addressTab} index={1}>
        <AddressFieldsEdit
          type="operatingAddress"
          required={false}
          data={data.operatingAddress}
          onChange={onDataPartChange}
          validator={validator}
          addDirtyField={addDirtyField}
        />
      </TabPanel>
      <TabPanel value={addressTab} index={2}>
        <AddressFieldsEdit
          type="noticeAddress"
          required={false}
          data={data.noticeAddress}
          onChange={onDataPartChange}
          validator={validator}
          addDirtyField={addDirtyField}
        />
      </TabPanel>
      <ContactsPanels
        data={data.contacts}
        onChange={onDataPartChange}
        validator={validator}
        addDirtyField={addDirtyField}
      />
    </>
  );
}
