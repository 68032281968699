import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import { Divider } from '..';

import IconAdd from '../../assets/plus.svg';
import IconDelete from '../../assets/images/delete.svg';

import { useStyles } from './styles';

export default function MultiplyAction(props) {
  const classes = useStyles();
  const {
    dataLength,
    index,
    children,
    actionText,
    possibleLength,
    onDeleteItem,
    onAddItem,
    addOnlyForLast = true,
    removeShowForFirst = false,
  } = props;

  const handleDelete = () => onDeleteItem(index);

  return (
    <>
      {children}

      {dataLength > 1 && (removeShowForFirst ? true : index !== 0) && (
        <div className={classes.deleteItem}>
          <Button color="primary" onClick={handleDelete}>
            <img src={IconDelete} alt="remove" />
            Remove {actionText}
          </Button>
        </div>
      )}

      {dataLength <= possibleLength &&
        (addOnlyForLast ? index === dataLength - 1 : true) && (
          <>
            <Divider />
            <div className={classes.addItem}>
              <Button color="primary" onClick={onAddItem}>
                <img src={IconAdd} alt="add" />
                Add {actionText}
              </Button>
            </div>
          </>
        )}
    </>
  );
}

MultiplyAction.defaultProps = {
  actionText: '',
  possibleLength: 5,
};

MultiplyAction.propTypes = {
  dataLength: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  actionText: PropTypes.string,
  possibleLength: PropTypes.number,
  onDeleteItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
};
