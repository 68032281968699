import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { Section } from '..';
import IconAttachment from '../../assets/attachment.svg';

import { useStyles } from './styles';

export default function AttachmentsListFull({
  attachments,
  deleteAttachment,
  showHeading = false,
  isPreview = false,
  limitListItemsWidth = true,
}) {
  const classes = useStyles();

  const AttachmentsListComponent = (
    <List className={` ${classes.listFull} ${limitListItemsWidth ? classes.limitListItemsWidth : ''}`}>
      {attachments?.map((attachment, index) => (
        <ListItem
          key={index}
          component="a"
          href={attachment.url}
          title={attachment.name}
          target="_blank"
          download
        >
          <ListItemIcon className={classes.icon}>
            <img src={IconAttachment} alt="attachment" />
          </ListItemIcon>
          <ListItemText
            primary={attachment.name}
            primaryTypographyProps={{ className: classes.linkText }}
          />
          {deleteAttachment && (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                className={classes.linkDelete}
                onClick={() => deleteAttachment(attachment.name)}
              >
                <ClearIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {showHeading && !isPreview && (
        <Typography variant="subtitle2">Attachments</Typography>
      )}
      {attachments &&
        (isPreview ? (
          <Section title="Attachments" highlighted={true}>
            {AttachmentsListComponent}
          </Section>
        ) : (
          <div title="Attachments">{AttachmentsListComponent}</div>
        ))}
    </>
  );
}
